import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Date: {input: Date; output: Date};
  Time: {input: Date; output: Date};
  Upload: {input: File; output: File};
};

export type AddOrganizationToContractInput = {
  contractID: Scalars['ID']['input'];
  organizationID: Scalars['ID']['input'];
};

export type AllUrlGroupsResult = {
  __typename?: 'AllURLGroupsResult';
  searchAnalyticsName: Scalars['String']['output'];
  urlGroup: UrlGroup;
};

export type ArchiveNotificationInput = {
  id: Scalars['ID']['input'];
};

export type AvailablePropertyTimeInput = {
  days: Scalars['String']['input'];
  jobsToAdd?: InputMaybe<Scalars['Int']['input']>;
  property: Scalars['String']['input'];
};

export enum BrowserCategory {
  AndroidWebview = 'ANDROID_WEBVIEW',
  Chrome = 'CHROME',
  Edge = 'EDGE',
  Firefox = 'FIREFOX',
  Opera = 'OPERA',
  Other = 'OTHER',
  Safari = 'SAFARI',
  SafariInApp = 'SAFARI_IN_APP',
}

export type CancelInvitationInput = {
  userOrganizationID: Scalars['ID']['input'];
};

export type ChangeOrganizationRoleInput = {
  email: Scalars['String']['input'];
  organizationID: Scalars['ID']['input'];
  role: UserOrganizationRole;
};

export type Contract = Node & {
  __typename?: 'Contract';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options: Array<ContractOption>;
  organizations: Array<Organization>;
  /** サブスクリプションのステータスを返します。 */
  stripeSubscriptionStatus: StripeSubscriptionStatus;
  /**
   * サブスクリプションのプランを返します。
   * stripeSubscriptionStatusがACTIVE、TRIALINGの場合のみ有効です。
   */
  subscriptionPlan?: Maybe<Plan>;
  type: ContractType;
  updatedAt: Scalars['Time']['output'];
};

export enum ContractOption {
  GuestInvite = 'GUEST_INVITE',
}

export enum ContractType {
  Corporation = 'CORPORATION',
  Individual = 'INDIVIDUAL',
}

export type CreateContractInput = {
  name: Scalars['String']['input'];
  type: ContractType;
};

export type CreateDashboardInput = {
  name: Scalars['String']['input'];
  organizationID: Scalars['ID']['input'];
};

export type CreateDashboardItemInput = {
  dashboardID: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  featureID?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type: DashboardItemType;
};

export type CreateIndexWorkerInput = {
  color: Scalars['String']['input'];
  ignoreURLParameters: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note: Scalars['String']['input'];
  projectID: Scalars['ID']['input'];
  propertyID: Scalars['ID']['input'];
  rssFeed?: InputMaybe<Scalars['String']['input']>;
  rssFeedSamplingPeriod?: InputMaybe<Scalars['Int']['input']>;
  samplingCount: Scalars['Int']['input'];
  /**
   * minute, hour, dowの3つのスケジュールをcron形式で指定
   * 例: 0 0 *
   */
  schedule?: InputMaybe<Scalars['String']['input']>;
  sitemap?: InputMaybe<Scalars['String']['input']>;
  urlGroup?: InputMaybe<IndexWorkerUrlGroupInput>;
  urlType: IndexWorkerUrlType;
  urls?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateOrganizationInput = {
  name: Scalars['String']['input'];
};

export type CreateProjectInput = {
  color: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  kind: ProjectKind;
  name: Scalars['String']['input'];
  organizationID: Scalars['ID']['input'];
};

export type CreateQueryGroupInput = {
  name: Scalars['String']['input'];
  op: LogicalOperator;
  patterns: Array<CreateQueryGroupPatternInput>;
  /** 先頭に追加するかどうか */
  prepend?: InputMaybe<Scalars['Boolean']['input']>;
  searchAnalyticsId: Scalars['ID']['input'];
};

export type CreateQueryGroupPatternInput = {
  pattern: Scalars['String']['input'];
  type: QueryGroupPatternType;
};

export type CreateSearchAnalyticsChatInput = {
  searchAnalyticsID: Scalars['ID']['input'];
};

export type CreateSearchAnalyticsInput = {
  datasetName: Scalars['String']['input'];
  googleCloudProjectID: Scalars['String']['input'];
  projectID: Scalars['ID']['input'];
  spreadsheetID?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSearchAnalyticsManagedByJadeInput = {
  projectID: Scalars['ID']['input'];
  spreadsheetID?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSearchAnalyticsViewInput = {
  /** 国でフィルタリング */
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 対象の日付範囲 */
  dataDate?: SearchAnalyticsDataDateInput;
  description?: InputMaybe<Scalars['String']['input']>;
  /** デバイスでフィルタリング */
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 匿名化クエリでフィルタリング */
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  /** Queryでフィルタリング */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Queryのフィルタリングタイプ */
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** QueryGroupでフィルタリング */
  queryGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 集計時のグループ化の対象を指定 */
  rawDataGroupBy?: Array<SearchAnalyticsRawDataGroupBy>;
  /** 集計時のグループ化の並び順を指定 */
  rawDataOrderBy?: SearchAnalyticsRawDataOrderInput;
  searchAnalyticsID: Scalars['ID']['input'];
  /** 検索タイプでフィルタリング */
  searchTypes?: InputMaybe<Array<SearchAnalyticsSearchType>>;
  /** spreadsheet吐き出しする場合はid */
  spreadsheetID?: InputMaybe<Scalars['String']['input']>;
  /** URLでフィルタリング */
  url?: InputMaybe<Scalars['String']['input']>;
  /** urlのフィルタリングタイプ */
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** URLGroupでフィルタリング */
  urlGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CreateUrlGroupInput = {
  name: Scalars['String']['input'];
  op: LogicalOperator;
  patterns: Array<CreateUrlGroupPatternInput>;
  /** 先頭に追加するかどうか */
  prepend?: InputMaybe<Scalars['Boolean']['input']>;
  search_analytics_id: Scalars['ID']['input'];
};

export type CreateUrlGroupPatternInput = {
  pattern: Scalars['String']['input'];
  type: UrlGroupPatternType;
};

export type CreateUrUserAnalyticsUrlGroupInput = {
  name: Scalars['String']['input'];
  op: LogicalOperator;
  patterns: Array<CreateUrlGroupPatternInput>;
  /** 先頭に追加するかどうか */
  prepend?: InputMaybe<Scalars['Boolean']['input']>;
  userAnalyticsID: Scalars['ID']['input'];
};

export type CreateUserAnalyticsInput = {
  datasetName: Scalars['String']['input'];
  googleCloudProjectID: Scalars['String']['input'];
  keyEvents?: Array<Scalars['String']['input']>;
  projectID: Scalars['ID']['input'];
};

export type CreateUserAnalyticsViewInput = {
  browsers?: InputMaybe<Array<BrowserCategory>>;
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignsFilterType?: InputMaybe<Array<UserAnalyticsFilter>>;
  defaultChannelGroup?: InputMaybe<Array<DefaultChannelGroup>>;
  defaultDateRange?: InputMaybe<UserAnalyticsDataDateInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  detailGroupBy: Array<SessionPerformanceDetailGroupBy>;
  detailOrderBy: SessionPerformanceDetailOrderByInput;
  devices?: InputMaybe<Array<DeviceCategory>>;
  events?: InputMaybe<Array<Scalars['String']['input']>>;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageLocationURL?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  mediums?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  os?: InputMaybe<Array<OsCategory>>;
  pageLocationCount?: InputMaybe<IntRangeInput>;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocationURL?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  userAnalyticsID: Scalars['ID']['input'];
  visitedPage?: InputMaybe<Scalars['String']['input']>;
  visitedPageFilterType?: InputMaybe<UserAnalyticsFilter>;
};

export type Dashboard = Node & {
  __typename?: 'Dashboard';
  createdAt: Scalars['Time']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<DashboardItemDivider | DashboardItemMetricWithTimeSeries>;
  name: Scalars['String']['output'];
  organization: Organization;
  organizationID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type DashboardItem = {
  createdAt: Scalars['Time']['output'];
  dashboard: Dashboard;
  dashboardID: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: DashboardItemType;
  updatedAt: Scalars['Time']['output'];
};

export type DashboardItemDivider = DashboardItem &
  Node & {
    __typename?: 'DashboardItemDivider';
    createdAt: Scalars['Time']['output'];
    dashboard: Dashboard;
    dashboardID: Scalars['ID']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    type: DashboardItemType;
    updatedAt: Scalars['Time']['output'];
  };

export type DashboardItemMetricWithTimeSeries = DashboardItem &
  Node & {
    __typename?: 'DashboardItemMetricWithTimeSeries';
    createdAt: Scalars['Time']['output'];
    dashboard: Dashboard;
    dashboardID: Scalars['ID']['output'];
    description?: Maybe<Scalars['String']['output']>;
    featureID?: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    timeSeries: Array<DateToFloatValue>;
    type: DashboardItemType;
    updatedAt: Scalars['Time']['output'];
  };

export type DashboardItemMetricWithTimeSeriesTimeSeriesArgs = {
  timeRange: TimeRangeInput;
};

export enum DashboardItemType {
  Divider = 'DIVIDER',
  IwCrawl = 'IW_CRAWL',
  IwIndex = 'IW_INDEX',
  IwNotIndex = 'IW_NOT_INDEX',
  SaClick = 'SA_CLICK',
  SaCtr = 'SA_CTR',
  SaImp = 'SA_IMP',
  SaRank = 'SA_RANK',
  SaUniqQuery = 'SA_UNIQ_QUERY',
  SaUniqUrl = 'SA_UNIQ_URL',
}

export type DateToFloatValue = {
  __typename?: 'DateToFloatValue';
  date: Scalars['Time']['output'];
  value: Scalars['Float']['output'];
};

export type DateToIntValue = {
  __typename?: 'DateToIntValue';
  date: Scalars['Time']['output'];
  value: Scalars['Int']['output'];
};

export enum Day {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DayAndTime = {
  __typename?: 'DayAndTime';
  day: Day;
  time: Scalars['String']['output'];
};

export enum DefaultChannelGroup {
  Affiliates = 'AFFILIATES',
  Audio = 'AUDIO',
  CrossNetwork = 'CROSS_NETWORK',
  Direct = 'DIRECT',
  Display = 'DISPLAY',
  Email = 'EMAIL',
  MobilePushNotifications = 'MOBILE_PUSH_NOTIFICATIONS',
  OrganicSearch = 'ORGANIC_SEARCH',
  OrganicShopping = 'ORGANIC_SHOPPING',
  OrganicSocial = 'ORGANIC_SOCIAL',
  OrganicVideo = 'ORGANIC_VIDEO',
  PaidOther = 'PAID_OTHER',
  PaidSearch = 'PAID_SEARCH',
  PaidShopping = 'PAID_SHOPPING',
  PaidSocial = 'PAID_SOCIAL',
  PaidVideo = 'PAID_VIDEO',
  Referral = 'REFERRAL',
  Sms = 'SMS',
}

export type DeleteDashboardInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDashboardItemInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDashboardItemPayload = {
  __typename?: 'DeleteDashboardItemPayload';
  id: Scalars['ID']['output'];
};

export type DeleteDashboardPayload = {
  __typename?: 'DeleteDashboardPayload';
  id: Scalars['ID']['output'];
};

export type DeleteIndexWorkerInput = {
  id: Scalars['ID']['input'];
};

export type DeleteIndexWorkerPayload = {
  __typename?: 'DeleteIndexWorkerPayload';
  id: Scalars['ID']['output'];
};

export type DeleteOrganizationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  id: Scalars['ID']['output'];
};

export type DeleteProjectInput = {
  id: Scalars['ID']['input'];
};

export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload';
  id: Scalars['ID']['output'];
};

export type DeleteQueryGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteQueryGroupPayload = {
  __typename?: 'DeleteQueryGroupPayload';
  id: Scalars['ID']['output'];
};

export type DeleteSearchAnalyticsInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSearchAnalyticsPayload = {
  __typename?: 'DeleteSearchAnalyticsPayload';
  id: Scalars['ID']['output'];
};

export type DeleteSearchAnalyticsViewInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSearchAnalyticsViewPayload = {
  __typename?: 'DeleteSearchAnalyticsViewPayload';
  id: Scalars['ID']['output'];
};

export type DeleteUrlGroupForUserAnalyticsInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUrlGroupForUserAnalyticsPayload = {
  __typename?: 'DeleteURLGroupForUserAnalyticsPayload';
  id: Scalars['ID']['output'];
};

export type DeleteUrlGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUrlGroupPayload = {
  __typename?: 'DeleteURLGroupPayload';
  id: Scalars['ID']['output'];
};

export type DeleteUserAnalyticsInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserAnalyticsViewInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserAnalyticsViewPayload = {
  __typename?: 'DeleteUserAnalyticsViewPayload';
  id: Scalars['ID']['output'];
};

export type Device = {
  __typename?: 'Device';
  brand?: Maybe<Scalars['String']['output']>;
  browser?: Maybe<Scalars['String']['output']>;
  browserVer?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  osVer?: Maybe<Scalars['String']['output']>;
};

export enum DeviceCategory {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
  Other = 'OTHER',
  Tablet = 'TABLET',
}

export type DuplicateProjectInput = {
  id: Scalars['ID']['input'];
};

export type DuplicateSearchAnalyticsProjectInput = {
  duplicateQueryGroups?: InputMaybe<Scalars['Boolean']['input']>;
  duplicateURLGroups?: InputMaybe<Scalars['Boolean']['input']>;
  duplicateViews?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type ExportSessionPerformanceDetailInput = {
  /** browsers to filter */
  browsers?: InputMaybe<Array<BrowserCategory>>;
  /** campaigns to filter */
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  /** campaigns filter type */
  campaignsFilterType?: InputMaybe<Array<UserAnalyticsFilter>>;
  dateRange: TimeRangeInput;
  /** default channel group */
  defaultChannelGroup?: InputMaybe<Array<DefaultChannelGroup>>;
  /** devices to filter */
  devices?: InputMaybe<Array<DeviceCategory>>;
  /** events to filter */
  events?: InputMaybe<Array<Scalars['String']['input']>>;
  exportType: UserAnalyticsExportType;
  /** url to filter */
  firstPageLocation?: InputMaybe<Scalars['String']['input']>;
  /** url filter type */
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  /** url group IDs to filter */
  firstPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** first page title to filter */
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  /** first page title filter type */
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  /** groupby */
  groupBy?: Array<SessionPerformanceDetailGroupBy>;
  /** is session with navigation */
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  /** medium to filter */
  mediums?: InputMaybe<Array<Scalars['String']['input']>>;
  /** orderby */
  orderBy?: SessionPerformanceDetailOrderByInput;
  /** os to filter */
  os?: InputMaybe<Array<OsCategory>>;
  /** pageLocationCount to filter */
  pageLocationCount?: InputMaybe<IntRangeInput>;
  /** 2nd url to filter */
  secondPageLocation?: InputMaybe<Scalars['String']['input']>;
  /** 2nd url filter type */
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  /** 2nd url group IDs to filter */
  secondPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 2nd page title to filter */
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  /** 2nd page title filter type */
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  /** source to filter */
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  userAnalyticsID: Scalars['ID']['input'];
  /** visited page to filter */
  visitedPage?: InputMaybe<Scalars['String']['input']>;
  /** visited page filter type */
  visitedPageFilterType?: InputMaybe<UserAnalyticsFilter>;
};

export type FloatRangeInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type Geography = {
  __typename?: 'Geography';
  city?: Maybe<Scalars['String']['output']>;
  continent?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export enum GettingStartedTour {
  IwCreateJob = 'IW_CREATE_JOB',
  IwCreateProject = 'IW_CREATE_PROJECT',
  IwRegisterProperty = 'IW_REGISTER_PROPERTY',
  SaCreateNavigationalQueryGroup = 'SA_CREATE_NAVIGATIONAL_QUERY_GROUP',
  SaCreateProject = 'SA_CREATE_PROJECT',
  SaCreateUrlGroup = 'SA_CREATE_URL_GROUP',
  SaCreateView = 'SA_CREATE_VIEW',
  SaSearchConsoleIntegration = 'SA_SEARCH_CONSOLE_INTEGRATION',
}

/** Search Console APIへのアクセスを定期的に行い、データを取得するためのプロパティ設定 */
export type IndexWorker = Node & {
  __typename?: 'IndexWorker';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** 無視するURLパラメータ */
  ignoreURLParameters: Array<Scalars['String']['output']>;
  jobs: IndexWorkerJobConnection;
  /** 識別するための名前 */
  name: Scalars['String']['output'];
  /** 説明文 */
  note: Scalars['String']['output'];
  organization: Organization;
  /** Search Console APIへのアクセスに必要なプロパティ */
  property:
    | RegisteredSearchConsoleServiceAccountProperty
    | RegisteredSearchConsoleUserProperty;
  /** 対応するプロパティのID */
  propertyID: Scalars['ID']['output'];
  /** URLTypeがRSSの場合のRSSフィードのURL */
  rssFeed?: Maybe<Scalars['String']['output']>;
  /** RSSフィードの場合、どの程度最近の記事を取得するか(日) */
  rssFeedSamplingPeriod?: Maybe<Scalars['Int']['output']>;
  /** 何件の記事を取得するか */
  samplingCount: Scalars['Int']['output'];
  /**
   * minute, hour, dowの3つのスケジュールをcron形式で指定
   * 0 0 * みたいな
   */
  schedule?: Maybe<Scalars['String']['output']>;
  /** SITEMAPの場合、sitemapのURL */
  sitemap?: Maybe<Scalars['String']['output']>;
  /** @deprecated 使用されていません */
  state: Scalars['String']['output'];
  /** rss/sitemapのとき、URLGroupに合うものだけを絞り込む */
  urlGroup?: Maybe<UrlGroup>;
  /** URLの取得方法 */
  urlType: IndexWorkerUrlType;
  /** MANUALの場合、取得対象URLの一覧 */
  urls?: Maybe<Array<Scalars['String']['output']>>;
};

/** Search Console APIへのアクセスを定期的に行い、データを取得するためのプロパティ設定 */
export type IndexWorkerJobsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  first?: Scalars['Int']['input'];
  orderby?: InputMaybe<JobOrder>;
  range?: InputMaybe<TimeRangeInput>;
};

export type IndexWorkerErrorUrl = {
  __typename?: 'IndexWorkerErrorURL';
  error: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/**
 * ある時間に実行されるSearch Console APIへのIndexWorker取得アクセス一式
 * 複数のURLにアクセスしていて、集計結果を持っている。
 * それぞれのURLごとのアクセス結果はtasksに持っている
 */
export type IndexWorkerJob = {
  __typename?: 'IndexWorkerJob';
  completedAt?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  /** スクレイピングに失敗した数 */
  failureCount: Scalars['Int']['output'];
  failureURLs: Array<IndexWorkerErrorUrl>;
  /** nodeではないが、内部処理のためにIDを持つ */
  id: Scalars['ID']['output'];
  indexWorker: IndexWorker;
  /** スクレイピングを試みた数 */
  requestCount: Scalars['Int']['output'];
  result?: Maybe<IndexWorkerResult>;
  /** スクレイピングに成功した数 */
  successCount: Scalars['Int']['output'];
  /**
   * このジョブで取得したURLごとのアクセス結果
   * 順番は固定で、変更できません
   */
  tasks: IndexWorkerTaskConnection;
};

/**
 * ある時間に実行されるSearch Console APIへのIndexWorker取得アクセス一式
 * 複数のURLにアクセスしていて、集計結果を持っている。
 * それぞれのURLごとのアクセス結果はtasksに持っている
 */
export type IndexWorkerJobTasksArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  first?: Scalars['Int']['input'];
};

export type IndexWorkerJobConnection = {
  __typename?: 'IndexWorkerJobConnection';
  edges: Array<IndexWorkerJobEdge>;
  pageInfo: PageInfo;
};

export type IndexWorkerJobEdge = {
  __typename?: 'IndexWorkerJobEdge';
  cursor: Scalars['String']['output'];
  node: IndexWorkerJob;
};

export type IndexWorkerProject = Node &
  Project & {
    __typename?: 'IndexWorkerProject';
    color: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    indexWorkers: Array<IndexWorker>;
    name: Scalars['String']['output'];
  };

export type IndexWorkerResult = {
  __typename?: 'IndexWorkerResult';
  /** canonical 先がインデックス済みのURL数 */
  canonicalIndexedCount: Scalars['Int']['output'];
  /** canonical 先がインデックス済みのURL数の割合 */
  canonicalIndexedRate: Scalars['Float']['output'];
  /** クロール済みのURL数 */
  crawledCount: Scalars['Int']['output'];
  /** クロール済みのURL数の割合 */
  crawledRate: Scalars['Float']['output'];
  /** インデックス済みのURL数 */
  indexedCount: Scalars['Int']['output'];
  /** インデックス済みのURL数の割合 */
  indexedRate: Scalars['Float']['output'];
  /** canonical 先インデックス率の7日間移動平均 */
  sevenDaysMovingAverageCanonicalIndexedRate: Scalars['Float']['output'];
  /** クロール率の7日間移動平均 */
  sevenDaysMovingAverageCrawledRate: Scalars['Float']['output'];
  /** インデックス率の7日間移動平均 */
  sevenDaysMovingAverageIndexedRate: Scalars['Float']['output'];
  /** 調査対象のURL数 */
  totalCount: Scalars['Int']['output'];
  /** canonical 先インデックス率の28日間移動平均 */
  twentyEightDaysMovingAverageCanonicalIndexedRate: Scalars['Float']['output'];
  /** クロール率の28日間移動平均 */
  twentyEightDaysMovingAverageCrawledRate: Scalars['Float']['output'];
  /** インデックス率の28日間移動平均 */
  twentyEightDaysMovingAverageIndexedRate: Scalars['Float']['output'];
};

/** URLごとのsearch console apiへのアクセス情報 */
export type IndexWorkerTask = {
  __typename?: 'IndexWorkerTask';
  /** クロール済みかどうか */
  crawled: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Time']['output']>;
  /** @deprecated 使用されていません */
  endedAt?: Maybe<Scalars['Time']['output']>;
  /** インデックス済みかどうか */
  indexed: Scalars['Boolean']['output'];
  /** search console apiから届いた生の結果 */
  result?: Maybe<IndexWorkerTaskResult>;
  /** 調査したURL */
  url: IndexWorkerUrl;
};

export type IndexWorkerTaskConnection = {
  __typename?: 'IndexWorkerTaskConnection';
  edges: Array<IndexWorkerTaskEdge>;
  pageInfo: PageInfo;
};

export type IndexWorkerTaskEdge = {
  __typename?: 'IndexWorkerTaskEdge';
  cursor: Scalars['String']['output'];
  node: IndexWorkerTask;
};

export type IndexWorkerTaskResult = {
  __typename?: 'IndexWorkerTaskResult';
  coverageState: Scalars['String']['output'];
  crawledAs: Scalars['String']['output'];
  forceSendFields: Array<Scalars['String']['output']>;
  googleCanonical: Scalars['String']['output'];
  indexingState: Scalars['String']['output'];
  lastCrawlTime: Scalars['String']['output'];
  nullFields: Array<Scalars['String']['output']>;
  pageFetchState: Scalars['String']['output'];
  refferingUrls: Array<Scalars['String']['output']>;
  robotsTxtState: Scalars['String']['output'];
  sitemap: Array<Scalars['String']['output']>;
  userCanonical: Scalars['String']['output'];
  verdict: Scalars['String']['output'];
};

export type IndexWorkerUrl = Node & {
  __typename?: 'IndexWorkerURL';
  id: Scalars['ID']['output'];
  indexWorker: IndexWorker;
  indexWorkerID: Scalars['ID']['output'];
  results: Array<IndexWorkerTask>;
  url: Scalars['String']['output'];
};

export type IndexWorkerUrlResultsArgs = {
  range?: InputMaybe<TimeRangeInput>;
};

export type IndexWorkerUrlGroupInput = {
  name: Scalars['String']['input'];
  op: LogicalOperator;
  patterns: Array<CreateUrlGroupPatternInput>;
};

export enum IndexWorkerUrlType {
  /** 手動でURLを指定する */
  Manual = 'MANUAL',
  /** RSSフィードを用いてURLを取得する */
  Rss = 'RSS',
  /** sitemap.xmlを用いてURLを取得する */
  Sitemap = 'SITEMAP',
}

export type IntRange = {
  __typename?: 'IntRange';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

export type IntRangeInput = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type JobOrder = {
  direction: OrderDirection;
  field: JobOrderField;
};

export enum JobOrderField {
  CreatedAt = 'CREATED_AT',
}

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR',
}

export type MakeSearchAnalyticsComparingExportDataInput = {
  /** 比較対象の日付範囲 */
  comparingDateRange: TimeRangeInput;
  /** 国でフィルタリング */
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 対象の日付範囲 */
  dateRange: TimeRangeInput;
  /** デバイスでフィルタリング */
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  exportType: SearchAnalyticsExportType;
  groupBy?: Array<SearchAnalyticsRawDataGroupBy>;
  /** 匿名化クエリでフィルタリング */
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  /** データのソート順 */
  orderBy?: SearchAnalyticsRawComparingDataOrderInput;
  /** Queryでフィルタリング */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Queryのフィルタリングタイプ */
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** QueryGroupでフィルタリング */
  queryGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchAnalyticsID: Scalars['ID']['input'];
  /** 検索タイプでフィルタリング */
  searchTypes?: InputMaybe<Array<SearchAnalyticsSearchType>>;
  /** URLでフィルタリング */
  url?: InputMaybe<Scalars['String']['input']>;
  /** URLのフィルタリングタイプ */
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** URLGroupでフィルタリング */
  urlGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MakeSearchAnalyticsExportDataInput = {
  /** 平均順位の範囲でフィルタリング */
  avgOfRankRange?: InputMaybe<FloatRangeInput>;
  /** Clickの範囲でフィルタリング */
  clicksRange?: InputMaybe<IntRangeInput>;
  /** 国でフィルタリング */
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  /** CTRの範囲でフィルタリング */
  ctrRange?: InputMaybe<FloatRangeInput>;
  /** 対象の日付範囲 */
  dateRange: TimeRangeInput;
  /** デバイスでフィルタリング */
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  /** distinctQueriesの範囲でフィルタリング */
  distinctQueriesRange?: InputMaybe<IntRangeInput>;
  /** distinctURLsの範囲でフィルタリング */
  distinctURLsRange?: InputMaybe<IntRangeInput>;
  exportType: SearchAnalyticsExportType;
  groupBy?: Array<SearchAnalyticsRawDataGroupBy>;
  /** Impressionsの範囲でフィルタリング */
  impressionsRange?: InputMaybe<IntRangeInput>;
  /** 匿名化クエリでフィルタリング */
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  /** データのソート順 */
  orderBy?: SearchAnalyticsRawDataOrderInput;
  /** Queryでフィルタリング */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Queryのフィルタリングタイプ */
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** QueryGroupでフィルタリング */
  queryGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchAnalyticsID: Scalars['ID']['input'];
  /** 検索タイプでフィルタリング */
  searchTypes?: InputMaybe<Array<SearchAnalyticsSearchType>>;
  /** URLでフィルタリング */
  url?: InputMaybe<Scalars['String']['input']>;
  /** URLのフィルタリングタイプ */
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** URLGroupでフィルタリング */
  urlGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MarkGettingStartedTourAsDoneInput = {
  organizationID: Scalars['ID']['input'];
  step: GettingStartedTour;
};

export type MoveIndexWorkerProjectInput = {
  id: Scalars['ID']['input'];
  targetProjectID: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrganizationToContract: Contract;
  archiveNotification: Notification;
  cancelInvitation: Scalars['Boolean']['output'];
  changeOrganizationRole: UserOrganization;
  createContract: Contract;
  createDashboard: Dashboard;
  createDashboardItem: DashboardItemDivider | DashboardItemMetricWithTimeSeries;
  createIndexWorker: IndexWorker;
  createOrganization: Organization;
  createProject:
    | IndexWorkerProject
    | SearchAnalyticsProject
    | UserAnalyticsProject;
  createQueryGroup: QueryGroup;
  createSearchAnalytics: SearchAnalytics;
  createSearchAnalyticsChat: SearchAnalyticsChat;
  createSearchAnalyticsManagedByJADE: SearchAnalytics;
  /** SearchAnalyticsViewの作成 */
  createSearchAnalyticsView: SearchAnalyticsView;
  createURLGroup: UrlGroup;
  createURLGroupForUserAnalytics: UrlGroup;
  createUserAnalytics: UserAnalytics;
  createUserAnalyticsView: UserAnalyticsView;
  deleteDashboard: DeleteDashboardPayload;
  deleteDashboardItem: DeleteDashboardItemPayload;
  deleteIndexWorker: DeleteIndexWorkerPayload;
  deleteOrganization: DeleteOrganizationPayload;
  deleteProject: DeleteProjectPayload;
  deleteQueryGroup: DeleteQueryGroupPayload;
  deleteSearchAnalytics: DeleteSearchAnalyticsPayload;
  /** SearchAnalyticsViewの削除 */
  deleteSearchAnalyticsView: DeleteSearchAnalyticsViewPayload;
  deleteURLGroup: DeleteUrlGroupPayload;
  deleteURLGroupForUserAnalytics: DeleteUrlGroupForUserAnalyticsPayload;
  deleteUserAnalytics: UserAnalytics;
  deleteUserAnalyticsView: DeleteUserAnalyticsViewPayload;
  duplicateProject:
    | IndexWorkerProject
    | SearchAnalyticsProject
    | UserAnalyticsProject;
  duplicateSearchAnalyticsProject: SearchAnalyticsProject;
  exportSessionPerformanceDetail: UserAnalyticsExportData;
  /** SearchAnalyticsのエクスポートを行う */
  makeSearchAnalyticsComparingExportData: Array<SearchAnalyticsExportData>;
  /** SearchAnalyticsのエクスポートを行う */
  makeSearchAnalyticsExportData: Array<SearchAnalyticsExportData>;
  markGettingStartedTourAsDone: Organization;
  moveIndexWorkerProject: IndexWorker;
  readNotification: Notification;
  registerProperty:
    | RegisteredSearchConsoleServiceAccountProperty
    | RegisteredSearchConsoleUserProperty;
  registerServiceAccount: ServiceAccount;
  removeScheduleFromIndexWorker: IndexWorker;
  removeUserFromOrganization: Scalars['Boolean']['output'];
  replySearchAnalyticsChat: SearchAnalyticsChat;
  runIndexWorker: IndexWorker;
  sendInvitation: UserOrganization;
  unarchiveNotification: Notification;
  unregisterProperty: UnregisteredSearchConsolePropertyPayload;
  /** 副作用として、そのServiceAccountで登録済みのプロパティが無効になります。 */
  unregisterServiceAccount: UnregisteredServiceAccountPayload;
  updateContract: Contract;
  updateDashboard: Dashboard;
  updateDashboardItem: DashboardItemDivider | DashboardItemMetricWithTimeSeries;
  updateDashboardItemsOrder: Array<
    DashboardItemDivider | DashboardItemMetricWithTimeSeries
  >;
  updateIndexWorker: IndexWorker;
  updateIndexWorkerViewOrder:
    | IndexWorkerProject
    | SearchAnalyticsProject
    | UserAnalyticsProject;
  updateNotificationSetting: Organization;
  updateOrganization: Organization;
  updateProject:
    | IndexWorkerProject
    | SearchAnalyticsProject
    | UserAnalyticsProject;
  updateProperty:
    | RegisteredSearchConsoleServiceAccountProperty
    | RegisteredSearchConsoleUserProperty;
  updateQueryGroup: QueryGroup;
  updateQueryGroupOrder: Array<QueryGroup>;
  updateSearchAnalytics: SearchAnalytics;
  /**
   * SearchAnalyticsViewの更新
   * 注意: 入力すべてをそのまま適用します。
   * nullにした場合、更新されてないのではなくnullになります。
   */
  updateSearchAnalyticsView: SearchAnalyticsView;
  /** SearchAnalyticsViewの並び順の更新 */
  updateSearchAnalyticsViewOrder: Array<SearchAnalyticsView>;
  /**
   * SearchAnalyticsViewのSpreadsheetIDの更新
   * 空文字列を送るとnullになります。
   */
  updateSearchAnalyticsViewSpreadsheetId: SearchAnalyticsView;
  updateURLGroup: UrlGroup;
  updateURLGroupForUserAnalytics: UrlGroup;
  updateURLGroupOrder: Array<UrlGroup>;
  updateURLGroupOrderForUserAnalytics: Array<UrlGroup>;
  updateUser: User;
  updateUserAnalytics: UserAnalytics;
  updateUserAnalyticsView: UserAnalyticsView;
  updateUserAnalyticsViewOrder: UserAnalytics;
};

export type MutationAddOrganizationToContractArgs = {
  input?: InputMaybe<AddOrganizationToContractInput>;
};

export type MutationArchiveNotificationArgs = {
  input?: InputMaybe<ArchiveNotificationInput>;
};

export type MutationCancelInvitationArgs = {
  input?: InputMaybe<CancelInvitationInput>;
};

export type MutationChangeOrganizationRoleArgs = {
  input?: InputMaybe<ChangeOrganizationRoleInput>;
};

export type MutationCreateContractArgs = {
  input?: InputMaybe<CreateContractInput>;
};

export type MutationCreateDashboardArgs = {
  input: CreateDashboardInput;
};

export type MutationCreateDashboardItemArgs = {
  input: CreateDashboardItemInput;
};

export type MutationCreateIndexWorkerArgs = {
  input?: InputMaybe<CreateIndexWorkerInput>;
};

export type MutationCreateOrganizationArgs = {
  input?: InputMaybe<CreateOrganizationInput>;
};

export type MutationCreateProjectArgs = {
  input?: InputMaybe<CreateProjectInput>;
};

export type MutationCreateQueryGroupArgs = {
  input: CreateQueryGroupInput;
};

export type MutationCreateSearchAnalyticsArgs = {
  input: CreateSearchAnalyticsInput;
};

export type MutationCreateSearchAnalyticsChatArgs = {
  input: CreateSearchAnalyticsChatInput;
};

export type MutationCreateSearchAnalyticsManagedByJadeArgs = {
  input: CreateSearchAnalyticsManagedByJadeInput;
};

export type MutationCreateSearchAnalyticsViewArgs = {
  input: CreateSearchAnalyticsViewInput;
};

export type MutationCreateUrlGroupArgs = {
  input: CreateUrlGroupInput;
};

export type MutationCreateUrlGroupForUserAnalyticsArgs = {
  input: CreateUrUserAnalyticsUrlGroupInput;
};

export type MutationCreateUserAnalyticsArgs = {
  input: CreateUserAnalyticsInput;
};

export type MutationCreateUserAnalyticsViewArgs = {
  input: CreateUserAnalyticsViewInput;
};

export type MutationDeleteDashboardArgs = {
  input: DeleteDashboardInput;
};

export type MutationDeleteDashboardItemArgs = {
  input: DeleteDashboardItemInput;
};

export type MutationDeleteIndexWorkerArgs = {
  input?: InputMaybe<DeleteIndexWorkerInput>;
};

export type MutationDeleteOrganizationArgs = {
  input?: InputMaybe<DeleteOrganizationInput>;
};

export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};

export type MutationDeleteQueryGroupArgs = {
  input: DeleteQueryGroupInput;
};

export type MutationDeleteSearchAnalyticsArgs = {
  input: DeleteSearchAnalyticsInput;
};

export type MutationDeleteSearchAnalyticsViewArgs = {
  input: DeleteSearchAnalyticsViewInput;
};

export type MutationDeleteUrlGroupArgs = {
  input: DeleteUrlGroupInput;
};

export type MutationDeleteUrlGroupForUserAnalyticsArgs = {
  input: DeleteUrlGroupForUserAnalyticsInput;
};

export type MutationDeleteUserAnalyticsArgs = {
  input: DeleteUserAnalyticsInput;
};

export type MutationDeleteUserAnalyticsViewArgs = {
  input: DeleteUserAnalyticsViewInput;
};

export type MutationDuplicateProjectArgs = {
  input?: InputMaybe<DuplicateProjectInput>;
};

export type MutationDuplicateSearchAnalyticsProjectArgs = {
  input?: InputMaybe<DuplicateSearchAnalyticsProjectInput>;
};

export type MutationExportSessionPerformanceDetailArgs = {
  input: ExportSessionPerformanceDetailInput;
};

export type MutationMakeSearchAnalyticsComparingExportDataArgs = {
  input: MakeSearchAnalyticsComparingExportDataInput;
};

export type MutationMakeSearchAnalyticsExportDataArgs = {
  input: MakeSearchAnalyticsExportDataInput;
};

export type MutationMarkGettingStartedTourAsDoneArgs = {
  input: MarkGettingStartedTourAsDoneInput;
};

export type MutationMoveIndexWorkerProjectArgs = {
  input?: InputMaybe<MoveIndexWorkerProjectInput>;
};

export type MutationReadNotificationArgs = {
  input?: InputMaybe<ReadNotificationInput>;
};

export type MutationRegisterPropertyArgs = {
  input?: InputMaybe<RegisterPropertyInput>;
};

export type MutationRegisterServiceAccountArgs = {
  input?: InputMaybe<RegisterServiceAccountInput>;
};

export type MutationRemoveScheduleFromIndexWorkerArgs = {
  input?: InputMaybe<RemoveScheduleFromIndexWorkerInput>;
};

export type MutationRemoveUserFromOrganizationArgs = {
  input?: InputMaybe<RemoveUserFromOrganizationInput>;
};

export type MutationReplySearchAnalyticsChatArgs = {
  input: ReplySearchAnalyticsChatInput;
};

export type MutationRunIndexWorkerArgs = {
  input?: InputMaybe<RunIndexWorkerInput>;
};

export type MutationSendInvitationArgs = {
  input?: InputMaybe<SendInvitationInput>;
};

export type MutationUnarchiveNotificationArgs = {
  input?: InputMaybe<UnarchiveNotificationInput>;
};

export type MutationUnregisterPropertyArgs = {
  input?: InputMaybe<UnregisterPropertyInput>;
};

export type MutationUnregisterServiceAccountArgs = {
  input?: InputMaybe<UnregisterServiceAccountInput>;
};

export type MutationUpdateContractArgs = {
  input?: InputMaybe<UpdateContractInput>;
};

export type MutationUpdateDashboardArgs = {
  input: UpdateDashboardInput;
};

export type MutationUpdateDashboardItemArgs = {
  input: UpdateDashboardItemInput;
};

export type MutationUpdateDashboardItemsOrderArgs = {
  input: UpdateDashboardItemsOrderInput;
};

export type MutationUpdateIndexWorkerArgs = {
  input?: InputMaybe<UpdateIndexWorkerInput>;
};

export type MutationUpdateIndexWorkerViewOrderArgs = {
  input: UpdateIndexWorkerViewOrderInput;
};

export type MutationUpdateNotificationSettingArgs = {
  input: UpdateNotificationSettingInput;
};

export type MutationUpdateOrganizationArgs = {
  input?: InputMaybe<UpdateOrganizationInput>;
};

export type MutationUpdateProjectArgs = {
  input?: InputMaybe<UpdateProjectInput>;
};

export type MutationUpdatePropertyArgs = {
  input?: InputMaybe<UpdatePropertyInput>;
};

export type MutationUpdateQueryGroupArgs = {
  input: UpdateQueryGroupInput;
};

export type MutationUpdateQueryGroupOrderArgs = {
  input: UpdateQueryGroupOrderInput;
};

export type MutationUpdateSearchAnalyticsArgs = {
  input: UpdateSearchAnalyticsInput;
};

export type MutationUpdateSearchAnalyticsViewArgs = {
  input: UpdateSearchAnalyticsViewInput;
};

export type MutationUpdateSearchAnalyticsViewOrderArgs = {
  input: UpdateSearchAnalyticsViewOrderInput;
};

export type MutationUpdateSearchAnalyticsViewSpreadsheetIdArgs = {
  input: UpdateSearchAnalyticsViewSpreadsheetId;
};

export type MutationUpdateUrlGroupArgs = {
  input: UpdateUrlGroupInput;
};

export type MutationUpdateUrlGroupForUserAnalyticsArgs = {
  input: UpdateUrlGroupForUserAnalyticsInput;
};

export type MutationUpdateUrlGroupOrderArgs = {
  input: UpdateUrlGroupOrderInput;
};

export type MutationUpdateUrlGroupOrderForUserAnalyticsArgs = {
  input: UpdateUrlGroupOrderForUserAnalyticsInput;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateUserAnalyticsArgs = {
  input: UpdateUserAnalyticsInput;
};

export type MutationUpdateUserAnalyticsViewArgs = {
  input: UpdateUserAnalyticsViewInput;
};

export type MutationUpdateUserAnalyticsViewOrderArgs = {
  input: UpdateUserAnalyticsViewOrderInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  archived: Scalars['Boolean']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  organizationID?: Maybe<Scalars['ID']['output']>;
  readAt?: Maybe<Scalars['Time']['output']>;
  severity: NotificationSeverity;
  title: Scalars['String']['output'];
  type: NotificationType;
  updatedAt: Scalars['Time']['output'];
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export enum NotificationSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Middle = 'MIDDLE',
}

export enum NotificationType {
  Announcement = 'ANNOUNCEMENT',
  AnomalyDetected = 'ANOMALY_DETECTED',
  Error = 'ERROR',
  NewData = 'NEW_DATA',
  Report = 'REPORT',
}

export enum OsCategory {
  Android = 'ANDROID',
  ChromeOs = 'CHROME_OS',
  Ios = 'IOS',
  Linux = 'LINUX',
  Macos = 'MACOS',
  Other = 'OTHER',
  Windows = 'WINDOWS',
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Organization = Node & {
  __typename?: 'Organization';
  contract?: Maybe<Contract>;
  createdAt: Scalars['Time']['output'];
  /** そのOrganizationに紐ついているダッシュボードです。 */
  dashboards: Array<Dashboard>;
  doNotNotifyTypes: Array<NotificationType>;
  doneGettingStartedTours: Array<GettingStartedTour>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projects: Array<
    IndexWorkerProject | SearchAnalyticsProject | UserAnalyticsProject
  >;
  /** そのorganizationで登録済みのプロパティです。 */
  registeredProperties: Array<
    | RegisteredSearchConsoleServiceAccountProperty
    | RegisteredSearchConsoleUserProperty
  >;
  serviceAccountEmail: Scalars['String']['output'];
  /** そのOrganizationに紐ついているServiceAccountです。 */
  serviceAccounts: Array<ServiceAccount>;
  updatedAt: Scalars['Time']['output'];
  users: Array<UserOrganization>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Plan = Node & {
  __typename?: 'Plan';
  guestCapacity: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  iwJobCapacity: Scalars['Int']['output'];
  iwProjectCapacity: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orgCapacity: Scalars['Int']['output'];
  propertyCapacity: Scalars['Int']['output'];
  queryGroupCapacity: Scalars['Int']['output'];
  scProjectCapacity: Scalars['Int']['output'];
  uaProjectCapacity: Scalars['Int']['output'];
  urlGroupCapacity: Scalars['Int']['output'];
  userCapacity: Scalars['Int']['output'];
  viewCapacity: Scalars['Int']['output'];
};

export type Project = {
  color: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum ProjectKind {
  IndexWorker = 'INDEX_WORKER',
  SearchAnalytics = 'SEARCH_ANALYTICS',
  UserAnalytics = 'USER_ANALYTICS',
}

export type Query = {
  __typename?: 'Query';
  availablePropertyTime: Scalars['String']['output'];
  getAllURLGroupsBySearchAnalytics: Array<AllUrlGroupsResult>;
  getRandomSession?: Maybe<SessionIDs>;
  node?: Maybe<
    | Contract
    | Dashboard
    | DashboardItemDivider
    | DashboardItemMetricWithTimeSeries
    | IndexWorker
    | IndexWorkerProject
    | IndexWorkerUrl
    | Notification
    | Organization
    | Plan
    | QueryGroup
    | RegisteredSearchConsoleServiceAccountProperty
    | RegisteredSearchConsoleUserProperty
    | SearchAnalytics
    | SearchAnalyticsChat
    | SearchAnalyticsProject
    | SearchAnalyticsView
    | ServiceAccount
    | SitemapIndex
    | UrlGroup
    | User
    | UserAnalytics
    | UserAnalyticsProject
    | UserAnalyticsView
  >;
  stripeCustomerPortalURL: Scalars['String']['output'];
  viewer?: Maybe<User>;
  viewerOrganizationRole: UserOrganizationRole;
};

export type QueryAvailablePropertyTimeArgs = {
  input: AvailablePropertyTimeInput;
};

export type QueryGetAllUrlGroupsBySearchAnalyticsArgs = {
  organizationID: Scalars['ID']['input'];
};

export type QueryGetRandomSessionArgs = {
  userAnalyticsID: Scalars['ID']['input'];
};

export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStripeCustomerPortalUrlArgs = {
  contractID: Scalars['ID']['input'];
};

export type QueryViewerOrganizationRoleArgs = {
  organizationID: Scalars['ID']['input'];
};

/**
 * クエリグループ
 * いずれの条件にも当てはまらなかった場合、collection: なし, ID: default, Name: defaultのものが出力されます。
 */
export type QueryGroup = Node & {
  __typename?: 'QueryGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  op: LogicalOperator;
  patterns: Array<Maybe<QueryGroupPattern>>;
};

export type QueryGroupPattern = {
  __typename?: 'QueryGroupPattern';
  pattern: Scalars['String']['output'];
  type: QueryGroupPatternType;
};

export enum QueryGroupPatternType {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Equals = 'EQUALS',
  /** @deprecated CONTAINSに直す */
  Includes = 'INCLUDES',
  NotContains = 'NOT_CONTAINS',
  /** @deprecated NOT_CONTAINSに直す */
  NotIncludes = 'NOT_INCLUDES',
  NotRegex = 'NOT_REGEX',
  Regex = 'REGEX',
  StartsWith = 'STARTS_WITH',
}

export type ReadNotificationInput = {
  id: Scalars['ID']['input'];
};

export type RecommendedQueryGroup = {
  __typename?: 'RecommendedQueryGroup';
  name: Scalars['String']['output'];
  op: LogicalOperator;
  patterns: Array<Maybe<QueryGroupPattern>>;
};

export type RecommendedUrlGroup = {
  __typename?: 'RecommendedURLGroup';
  coverage: Scalars['Float']['output'];
  op: LogicalOperator;
  patterns: Array<Maybe<UrlGroupPattern>>;
};

export type RegisterPropertyInput = {
  organizationID: Scalars['ID']['input'];
  property: Scalars['String']['input'];
  /** SERVICE ACCOUNTの場合のみ必須 */
  serviceAccountID?: InputMaybe<Scalars['ID']['input']>;
  /**
   * プロパティの所有者のタイプ
   * USERの場合は自身です
   */
  type: RegisterPropertyType;
};

export enum RegisterPropertyType {
  ServiceAccount = 'SERVICE_ACCOUNT',
  User = 'USER',
}

export type RegisterServiceAccountInput = {
  json: Scalars['Upload']['input'];
  organizationID: Scalars['ID']['input'];
};

export type RegisteredSearchConsoleProperty = {
  alerts: Array<SearchConsolePropertyAlert>;
  available: Scalars['Boolean']['output'];
  indexWorkers: Array<IndexWorker>;
  organization: Organization;
  property: Scalars['String']['output'];
  sitemaps: Array<SitemapLike>;
};

export type RegisteredSearchConsoleServiceAccountProperty = Node &
  RegisteredSearchConsoleProperty & {
    __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
    alerts: Array<SearchConsolePropertyAlert>;
    available: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    indexWorkers: Array<IndexWorker>;
    organization: Organization;
    property: Scalars['String']['output'];
    serviceAccount: ServiceAccount;
    sitemaps: Array<SitemapLike>;
  };

export type RegisteredSearchConsoleUserProperty = Node &
  RegisteredSearchConsoleProperty & {
    __typename?: 'RegisteredSearchConsoleUserProperty';
    alerts: Array<SearchConsolePropertyAlert>;
    available: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    indexWorkers: Array<IndexWorker>;
    organization: Organization;
    property: Scalars['String']['output'];
    sitemaps: Array<SitemapLike>;
    user: User;
  };

export type RemoveScheduleFromIndexWorkerInput = {
  id: Scalars['ID']['input'];
};

export type RemoveUserFromOrganizationInput = {
  email: Scalars['String']['input'];
  organizationID: Scalars['ID']['input'];
};

export type ReplySearchAnalyticsChatInput = {
  id: Scalars['ID']['input'];
  no: Scalars['Int']['input'];
};

export type Result = IndexWorkerResult;

export type RunIndexWorkerInput = {
  id: Scalars['ID']['input'];
};

export type SearchAnalytics = Node & {
  __typename?: 'SearchAnalytics';
  aggregatedData: Array<
    | SearchAnalyticsAggregatedDataByQueryGroup
    | SearchAnalyticsAggregatedDataByUrlGroup
    | SearchAnalyticsAggregatedDataNonGrouped
  >;
  chats: Array<SearchAnalyticsChat>;
  datasetName: Scalars['String']['output'];
  exportData: Array<SearchAnalyticsExportData>;
  googleCloudProjectID: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latestDataDate?: Maybe<Scalars['Time']['output']>;
  manageType: SearchAnalyticsManageType;
  queryGroups: Array<QueryGroup>;
  rawComparingData: SearchAnalyticsRawComparingDataConnection;
  rawData: SearchAnalyticsRawDataConnection;
  /** QueryGroupを推薦します。queryGroupIDやurlGroupIDの片方を指定することで、そのurlGroupIDかqueryGroupIDに紐づくrecommendedQueryGroupsを取得します。 */
  recommendedQueryGroups: Array<RecommendedQueryGroup>;
  recommendedURLGroups: Array<RecommendedUrlGroup>;
  spreadsheetID?: Maybe<Scalars['String']['output']>;
  urlGroups: Array<UrlGroup>;
  views: Array<SearchAnalyticsView>;
};

export type SearchAnalyticsAggregatedDataArgs = {
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  dateRange: TimeRangeInput;
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  groupBy?: InputMaybe<SearchAnalyticsGroupBy>;
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  queryGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchTypes?: InputMaybe<Array<SearchAnalyticsSearchType>>;
  url?: InputMaybe<Scalars['String']['input']>;
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  urlGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SearchAnalyticsRawComparingDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  comparingDateRange: TimeRangeInput;
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  dateRange: TimeRangeInput;
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: Scalars['Int']['input'];
  groupBy?: Array<SearchAnalyticsRawDataGroupBy>;
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: SearchAnalyticsRawComparingDataOrderInput;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  queryGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchTypes?: InputMaybe<Array<SearchAnalyticsSearchType>>;
  url?: InputMaybe<Scalars['String']['input']>;
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  urlGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SearchAnalyticsRawDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  avgOfRankRange?: InputMaybe<FloatRangeInput>;
  clicksRange?: InputMaybe<IntRangeInput>;
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  ctrRange?: InputMaybe<FloatRangeInput>;
  dateRange: TimeRangeInput;
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  distinctQueriesRange?: InputMaybe<IntRangeInput>;
  distinctURLsRange?: InputMaybe<IntRangeInput>;
  first?: Scalars['Int']['input'];
  groupBy?: Array<SearchAnalyticsRawDataGroupBy>;
  impressionsRange?: InputMaybe<IntRangeInput>;
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: SearchAnalyticsRawDataOrderInput;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  queryGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchTypes?: InputMaybe<Array<SearchAnalyticsSearchType>>;
  url?: InputMaybe<Scalars['String']['input']>;
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  urlGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SearchAnalyticsRecommendedQueryGroupsArgs = {
  queryGroupID?: InputMaybe<Scalars['ID']['input']>;
  urlGroupID?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchAnalyticsAggregatedData = {
  aor7d?: Maybe<Scalars['Float']['output']>;
  aor28d?: Maybe<Scalars['Float']['output']>;
  avgOfRank?: Maybe<Scalars['Float']['output']>;
  ctr: Scalars['Float']['output'];
  ctr7d?: Maybe<Scalars['Float']['output']>;
  ctr28d?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Time']['output'];
  distinctQueries: Scalars['Int']['output'];
  distinctURLs: Scalars['Int']['output'];
  dq7d?: Maybe<Scalars['Float']['output']>;
  dq28d?: Maybe<Scalars['Float']['output']>;
  du7d?: Maybe<Scalars['Float']['output']>;
  du28d?: Maybe<Scalars['Float']['output']>;
  soc7d?: Maybe<Scalars['Float']['output']>;
  soc28d?: Maybe<Scalars['Float']['output']>;
  soi7d?: Maybe<Scalars['Float']['output']>;
  soi28d?: Maybe<Scalars['Float']['output']>;
  sumOfClicks: Scalars['Int']['output'];
  sumOfImpressions: Scalars['Int']['output'];
  tableType: SearchAnalyticsTableType;
};

export type SearchAnalyticsAggregatedDataByQueryGroup =
  SearchAnalyticsAggregatedData & {
    __typename?: 'SearchAnalyticsAggregatedDataByQueryGroup';
    aor7d?: Maybe<Scalars['Float']['output']>;
    aor28d?: Maybe<Scalars['Float']['output']>;
    avgOfRank?: Maybe<Scalars['Float']['output']>;
    ctr: Scalars['Float']['output'];
    ctr7d?: Maybe<Scalars['Float']['output']>;
    ctr28d?: Maybe<Scalars['Float']['output']>;
    date: Scalars['Time']['output'];
    distinctQueries: Scalars['Int']['output'];
    distinctURLs: Scalars['Int']['output'];
    dq7d?: Maybe<Scalars['Float']['output']>;
    dq28d?: Maybe<Scalars['Float']['output']>;
    du7d?: Maybe<Scalars['Float']['output']>;
    du28d?: Maybe<Scalars['Float']['output']>;
    queryGroup: QueryGroup;
    soc7d?: Maybe<Scalars['Float']['output']>;
    soc28d?: Maybe<Scalars['Float']['output']>;
    soi7d?: Maybe<Scalars['Float']['output']>;
    soi28d?: Maybe<Scalars['Float']['output']>;
    sumOfClicks: Scalars['Int']['output'];
    sumOfImpressions: Scalars['Int']['output'];
    tableType: SearchAnalyticsTableType;
  };

export type SearchAnalyticsAggregatedDataByUrlGroup =
  SearchAnalyticsAggregatedData & {
    __typename?: 'SearchAnalyticsAggregatedDataByURLGroup';
    aor7d?: Maybe<Scalars['Float']['output']>;
    aor28d?: Maybe<Scalars['Float']['output']>;
    avgOfRank?: Maybe<Scalars['Float']['output']>;
    ctr: Scalars['Float']['output'];
    ctr7d?: Maybe<Scalars['Float']['output']>;
    ctr28d?: Maybe<Scalars['Float']['output']>;
    date: Scalars['Time']['output'];
    distinctQueries: Scalars['Int']['output'];
    distinctURLs: Scalars['Int']['output'];
    dq7d?: Maybe<Scalars['Float']['output']>;
    dq28d?: Maybe<Scalars['Float']['output']>;
    du7d?: Maybe<Scalars['Float']['output']>;
    du28d?: Maybe<Scalars['Float']['output']>;
    soc7d?: Maybe<Scalars['Float']['output']>;
    soc28d?: Maybe<Scalars['Float']['output']>;
    soi7d?: Maybe<Scalars['Float']['output']>;
    soi28d?: Maybe<Scalars['Float']['output']>;
    sumOfClicks: Scalars['Int']['output'];
    sumOfImpressions: Scalars['Int']['output'];
    tableType: SearchAnalyticsTableType;
    urlGroup: UrlGroup;
  };

export type SearchAnalyticsAggregatedDataNonGrouped =
  SearchAnalyticsAggregatedData & {
    __typename?: 'SearchAnalyticsAggregatedDataNonGrouped';
    aor7d?: Maybe<Scalars['Float']['output']>;
    aor28d?: Maybe<Scalars['Float']['output']>;
    avgOfRank?: Maybe<Scalars['Float']['output']>;
    ctr: Scalars['Float']['output'];
    ctr7d?: Maybe<Scalars['Float']['output']>;
    ctr28d?: Maybe<Scalars['Float']['output']>;
    date: Scalars['Time']['output'];
    distinctQueries: Scalars['Int']['output'];
    distinctURLs: Scalars['Int']['output'];
    dq7d?: Maybe<Scalars['Float']['output']>;
    dq28d?: Maybe<Scalars['Float']['output']>;
    du7d?: Maybe<Scalars['Float']['output']>;
    du28d?: Maybe<Scalars['Float']['output']>;
    soc7d?: Maybe<Scalars['Float']['output']>;
    soc28d?: Maybe<Scalars['Float']['output']>;
    soi7d?: Maybe<Scalars['Float']['output']>;
    soi28d?: Maybe<Scalars['Float']['output']>;
    sumOfClicks: Scalars['Int']['output'];
    sumOfImpressions: Scalars['Int']['output'];
    tableType: SearchAnalyticsTableType;
  };

export type SearchAnalyticsChat = Node & {
  __typename?: 'SearchAnalyticsChat';
  createdAt: Scalars['Time']['output'];
  dataToDate: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  messageSuggestions: Array<Scalars['String']['output']>;
  messages: Array<SearchAnalyticsChatMessage>;
  updatedAt: Scalars['Time']['output'];
};

export type SearchAnalyticsChatMessage = {
  __typename?: 'SearchAnalyticsChatMessage';
  content: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  role: SearchAnalyticsChatMessageRole;
};

export enum SearchAnalyticsChatMessageRole {
  Assistant = 'ASSISTANT',
  User = 'USER',
}

export enum SearchAnalyticsComparingOrderField {
  AverageOfRank = 'AVERAGE_OF_RANK',
  AverageOfRankDifference = 'AVERAGE_OF_RANK_DIFFERENCE',
  Clicks = 'CLICKS',
  ClicksDifference = 'CLICKS_DIFFERENCE',
  Ctr = 'CTR',
  CtrDifference = 'CTR_DIFFERENCE',
  DistinctQueries = 'DISTINCT_QUERIES',
  DistinctQueriesDifference = 'DISTINCT_QUERIES_DIFFERENCE',
  DistinctUrls = 'DISTINCT_URLS',
  DistinctUrlsDifference = 'DISTINCT_URLS_DIFFERENCE',
  Impressions = 'IMPRESSIONS',
  ImpressionsDifference = 'IMPRESSIONS_DIFFERENCE',
}

export type SearchAnalyticsDataDate = {
  __typename?: 'SearchAnalyticsDataDate';
  dateRange?: Maybe<TimeRange>;
  type: SearchAnalyticsDataDateType;
};

export type SearchAnalyticsDataDateInput = {
  dateRange?: InputMaybe<TimeRangeInput>;
  type: SearchAnalyticsDataDateType;
};

export enum SearchAnalyticsDataDateType {
  /** カスタム期間 */
  Custom = 'CUSTOM',
  /** 過去7日間 */
  Days_7 = 'DAYS_7',
  /** 過去28日間 */
  Days_28 = 'DAYS_28',
  /** 過去90日間 */
  Days_90 = 'DAYS_90',
  /** 過去180日間 */
  Days_180 = 'DAYS_180',
  /** 過去365日間 */
  Days_365 = 'DAYS_365',
  /** 過去480日間 */
  Days_480 = 'DAYS_480',
  /** 過去1日間 */
  Latest = 'LATEST',
  /** @deprecated DAYS_90を使用してください */
  Months_3 = 'MONTHS_3',
  /** @deprecated DAYS_180を使用してください */
  Months_6 = 'MONTHS_6',
  /** @deprecated DAYS_365を使用してください */
  Months_12 = 'MONTHS_12',
  /** @deprecated DAYS_480を使用してください */
  Months_16 = 'MONTHS_16',
}

export type SearchAnalyticsExportData = {
  __typename?: 'SearchAnalyticsExportData';
  expires?: Maybe<Scalars['Time']['output']>;
  exportType: SearchAnalyticsExportType;
  exportedAt: Scalars['Time']['output'];
  status: SearchAnalyticsExportStatus;
  urls?: Maybe<Array<Scalars['String']['output']>>;
};

export enum SearchAnalyticsExportStatus {
  Done = 'DONE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
}

export enum SearchAnalyticsExportType {
  Csv = 'CSV',
  Json = 'JSON',
}

export enum SearchAnalyticsFilter {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Equals = 'EQUALS',
  /** @deprecated CONTAINSに直す */
  Include = 'INCLUDE',
  NotContains = 'NOT_CONTAINS',
  NotRegex = 'NOT_REGEX',
  Regex = 'REGEX',
  StartsWith = 'STARTS_WITH',
}

export enum SearchAnalyticsGroupBy {
  QueryGroup = 'QUERY_GROUP',
  UrlGroup = 'URL_GROUP',
}

export enum SearchAnalyticsManageType {
  Jade = 'JADE',
  Self = 'SELF',
}

export enum SearchAnalyticsOrderField {
  AverageOfRank = 'AVERAGE_OF_RANK',
  Clicks = 'CLICKS',
  Ctr = 'CTR',
  Date = 'DATE',
  DistinctQueries = 'DISTINCT_QUERIES',
  DistinctUrls = 'DISTINCT_URLS',
  Impressions = 'IMPRESSIONS',
}

export type SearchAnalyticsProject = Node &
  Project & {
    __typename?: 'SearchAnalyticsProject';
    color: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    searchAnalytics?: Maybe<SearchAnalytics>;
  };

export type SearchAnalyticsRawComparingData = {
  __typename?: 'SearchAnalyticsRawComparingData';
  avgOfRank?: Maybe<Scalars['Float']['output']>;
  clicks: Scalars['Int']['output'];
  comparedAvgOfRank?: Maybe<Scalars['Float']['output']>;
  comparedClicks: Scalars['Int']['output'];
  comparedCtr: Scalars['Float']['output'];
  comparedDistinctQueries: Scalars['Int']['output'];
  comparedDistinctURLs: Scalars['Int']['output'];
  comparedImpressions: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  ctr: Scalars['Float']['output'];
  device?: Maybe<Scalars['String']['output']>;
  distinctQueries: Scalars['Int']['output'];
  distinctURLs: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  isAnonymizedDiscover?: Maybe<Scalars['Boolean']['output']>;
  isAnonymousQuery?: Maybe<Scalars['Boolean']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  queryGroup?: Maybe<QueryGroup>;
  searchType?: Maybe<SearchAnalyticsSearchType>;
  url?: Maybe<Scalars['String']['output']>;
  urlGroup?: Maybe<UrlGroup>;
};

export type SearchAnalyticsRawComparingDataConnection = {
  __typename?: 'SearchAnalyticsRawComparingDataConnection';
  edges: Array<SearchAnalyticsRawComparingDataEdge>;
  pageInfo: PageInfo;
};

export type SearchAnalyticsRawComparingDataEdge = {
  __typename?: 'SearchAnalyticsRawComparingDataEdge';
  cursor: Scalars['String']['output'];
  node: SearchAnalyticsRawComparingData;
};

export type SearchAnalyticsRawComparingDataOrder = {
  __typename?: 'SearchAnalyticsRawComparingDataOrder';
  direction: OrderDirection;
  field: SearchAnalyticsComparingOrderField;
};

export type SearchAnalyticsRawComparingDataOrderInput = {
  direction: OrderDirection;
  field: SearchAnalyticsComparingOrderField;
};

export type SearchAnalyticsRawData = {
  __typename?: 'SearchAnalyticsRawData';
  avgOfRank?: Maybe<Scalars['Float']['output']>;
  clicks: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  ctr: Scalars['Float']['output'];
  date?: Maybe<Scalars['Time']['output']>;
  device?: Maybe<Scalars['String']['output']>;
  distinctQueries: Scalars['Int']['output'];
  distinctURLs: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  isAnonymizedDiscover?: Maybe<Scalars['Boolean']['output']>;
  isAnonymousQuery?: Maybe<Scalars['Boolean']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  queryGroup?: Maybe<QueryGroup>;
  searchType?: Maybe<SearchAnalyticsSearchType>;
  url?: Maybe<Scalars['String']['output']>;
  urlGroup?: Maybe<UrlGroup>;
};

export type SearchAnalyticsRawDataConnection = {
  __typename?: 'SearchAnalyticsRawDataConnection';
  edges: Array<SearchAnalyticsRawDataEdge>;
  pageInfo: PageInfo;
};

export type SearchAnalyticsRawDataEdge = {
  __typename?: 'SearchAnalyticsRawDataEdge';
  cursor: Scalars['String']['output'];
  node: SearchAnalyticsRawData;
};

export enum SearchAnalyticsRawDataGroupBy {
  Country = 'COUNTRY',
  Date = 'DATE',
  Device = 'DEVICE',
  IsAnonymizedDiscover = 'IS_ANONYMIZED_DISCOVER',
  IsAnonymizedQuery = 'IS_ANONYMIZED_QUERY',
  Query = 'QUERY',
  QueryGroup = 'QUERY_GROUP',
  SearchType = 'SEARCH_TYPE',
  Url = 'URL',
  UrlGroup = 'URL_GROUP',
}

export enum SearchAnalyticsRawDataKind {
  Action = 'ACTION',
  AmpBlueLink = 'AMP_BLUE_LINK',
  AmpImageResult = 'AMP_IMAGE_RESULT',
  AmpStory = 'AMP_STORY',
  AmpTopStories = 'AMP_TOP_STORIES',
  EduQAndA = 'EDU_Q_AND_A',
  EventsDetails = 'EVENTS_DETAILS',
  EventsListing = 'EVENTS_LISTING',
  JobDetails = 'JOB_DETAILS',
  JobListing = 'JOB_LISTING',
  MathSolvers = 'MATH_SOLVERS',
  MerchantListings = 'MERCHANT_LISTINGS',
  OrganicShopping = 'ORGANIC_SHOPPING',
  PageExperience = 'PAGE_EXPERIENCE',
  PracticeProblems = 'PRACTICE_PROBLEMS',
  ProductSnippets = 'PRODUCT_SNIPPETS',
  RecipeFeature = 'RECIPE_FEATURE',
  RecipeRichSnippet = 'RECIPE_RICH_SNIPPET',
  ReviewSnippet = 'REVIEW_SNIPPET',
  SearchAppearance = 'SEARCH_APPEARANCE',
  SpecialAnnouncement = 'SPECIAL_ANNOUNCEMENT',
  SubscribedContent = 'SUBSCRIBED_CONTENT',
  TpfFaq = 'TPF_FAQ',
  TpfHowto = 'TPF_HOWTO',
  TpfQa = 'TPF_QA',
  TranslatedResult = 'TRANSLATED_RESULT',
  Video = 'VIDEO',
  Weblite = 'WEBLITE',
}

export type SearchAnalyticsRawDataOrder = {
  __typename?: 'SearchAnalyticsRawDataOrder';
  direction: OrderDirection;
  field: SearchAnalyticsOrderField;
};

export type SearchAnalyticsRawDataOrderInput = {
  direction: OrderDirection;
  field: SearchAnalyticsOrderField;
};

export enum SearchAnalyticsSearchType {
  Discover = 'DISCOVER',
  GoogleNews = 'GOOGLE_NEWS',
  Image = 'IMAGE',
  News = 'NEWS',
  Video = 'VIDEO',
  Web = 'WEB',
}

export enum SearchAnalyticsTableType {
  SiteImpression = 'SITE_IMPRESSION',
  UrlImpression = 'URL_IMPRESSION',
}

export type SearchAnalyticsView = Node & {
  __typename?: 'SearchAnalyticsView';
  /** 集計データを出力 */
  aggregatedData: Array<
    | SearchAnalyticsAggregatedDataByQueryGroup
    | SearchAnalyticsAggregatedDataByUrlGroup
    | SearchAnalyticsAggregatedDataNonGrouped
  >;
  /** 国でフィルタリング */
  countries?: Maybe<Array<Scalars['String']['output']>>;
  /** 対象の日付範囲 */
  dataDate: SearchAnalyticsDataDate;
  description?: Maybe<Scalars['String']['output']>;
  /** デバイスでフィルタリング */
  devices?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  /** 匿名化クエリでフィルタリング */
  isAnonymousQuery?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** Queryでフィルタリング */
  query?: Maybe<Scalars['String']['output']>;
  /** Queryのフィルタリングタイプ */
  queryFilterType?: Maybe<SearchAnalyticsFilter>;
  /** QueryGroupでフィルタリング */
  queryGroupIDs?: Maybe<Array<Scalars['ID']['output']>>;
  /** 集計時のグループ化の対象を指定 */
  rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
  /** 集計時のグループ化の並び順を指定 */
  rawDataOrderBy: SearchAnalyticsRawDataOrder;
  /** 検索タイプでフィルタリング */
  searchTypes?: Maybe<Array<SearchAnalyticsSearchType>>;
  /** 出力するspreadsheet */
  spreadsheetID?: Maybe<Scalars['String']['output']>;
  /** URLでフィルタリング */
  url?: Maybe<Scalars['String']['output']>;
  /** URLのフィルタリングタイプ */
  urlFilterType?: Maybe<SearchAnalyticsFilter>;
  /** URLGroupでフィルタリング */
  urlGroupIDs?: Maybe<Array<Scalars['ID']['output']>>;
};

export type SearchAnalyticsViewAggregatedDataArgs = {
  dateRange?: InputMaybe<TimeRangeInput>;
  groupBy?: InputMaybe<SearchAnalyticsGroupBy>;
  useSavedDateRange?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchConsoleProperty = {
  __typename?: 'SearchConsoleProperty';
  property: Scalars['String']['output'];
};

export type SearchConsolePropertyAlert = {
  __typename?: 'SearchConsolePropertyAlert';
  from: DayAndTime;
  indexWorkers: Array<IndexWorker>;
  to: DayAndTime;
  totalUrlCount: Scalars['Int']['output'];
};

export type SendInvitationInput = {
  email: Scalars['String']['input'];
  organizationID: Scalars['ID']['input'];
  role: UserOrganizationRole;
};

export type ServiceAccount = Node & {
  __typename?: 'ServiceAccount';
  availableProperties: Array<SearchConsoleProperty>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** そのServiceAccountで登録済みのプロパティです。 */
  registeredProperties: Array<RegisteredSearchConsoleServiceAccountProperty>;
};

export type Session = {
  __typename?: 'Session';
  campaign?: Maybe<Scalars['String']['output']>;
  device: Device;
  firstPageLocation: Scalars['String']['output'];
  firstPageLocationURLGroup: UrlGroup;
  firstPageTitle?: Maybe<Scalars['String']['output']>;
  gaSessionId: Scalars['Int']['output'];
  geography: Geography;
  medium?: Maybe<Scalars['String']['output']>;
  pageLocationCount: Scalars['Int']['output'];
  pageReferrer?: Maybe<Scalars['String']['output']>;
  secondPageLocation?: Maybe<Scalars['String']['output']>;
  secondPageLocationURLGroup?: Maybe<UrlGroup>;
  secondPageTitle?: Maybe<Scalars['String']['output']>;
  sessionDuration: Scalars['Int']['output'];
  sessionEndTime: Scalars['Time']['output'];
  sessionStartTime: Scalars['Time']['output'];
  source?: Maybe<Scalars['String']['output']>;
  userPseudoId: Scalars['String']['output'];
};

export type SessionDetail = {
  __typename?: 'SessionDetail';
  campaign?: Maybe<Scalars['String']['output']>;
  device: Device;
  events: Array<SessionEvent>;
  firstVisitCampaign?: Maybe<Scalars['String']['output']>;
  firstVisitMedium?: Maybe<Scalars['String']['output']>;
  firstVisitSource?: Maybe<Scalars['String']['output']>;
  gaSessionId: Scalars['String']['output'];
  gaSessionNumber: Scalars['Int']['output'];
  geography: Geography;
  medium?: Maybe<Scalars['String']['output']>;
  pageReferrer?: Maybe<Scalars['String']['output']>;
  sessionDuration: Scalars['Int']['output'];
  sessionEndTime: Scalars['Time']['output'];
  sessionStartTime: Scalars['Time']['output'];
  source?: Maybe<Scalars['String']['output']>;
  userFirstTouchTimestamp?: Maybe<Scalars['Time']['output']>;
  userPseudoId: Scalars['String']['output'];
};

export type SessionEvent = {
  __typename?: 'SessionEvent';
  contentGroup?: Maybe<Scalars['String']['output']>;
  eventName: Scalars['String']['output'];
  eventParams: Array<SessionEventParam>;
  eventTimestamp: Scalars['Time']['output'];
  pageLocation?: Maybe<Scalars['String']['output']>;
  pageLocationWithoutParameter?: Maybe<Scalars['String']['output']>;
  pageReferrer?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
};

export type SessionEventParam = {
  __typename?: 'SessionEventParam';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SessionIDs = {
  __typename?: 'SessionIDs';
  gaSessionId: Scalars['Int']['output'];
  userPseudoId: Scalars['String']['output'];
};

export type SessionPerformance = {
  __typename?: 'SessionPerformance';
  avgPageLocationCount: Scalars['Float']['output'];
  avgSessionDuration: Scalars['Float']['output'];
  conversionCount: Scalars['Int']['output'];
  conversionRate: Scalars['Float']['output'];
  date: Scalars['Time']['output'];
  engagementCount: Scalars['Int']['output'];
  engagementRate: Scalars['Float']['output'];
  firstPageLocationURLGroup?: Maybe<UrlGroup>;
  secondPageLocationURLGroup?: Maybe<UrlGroup>;
  /** 集計データ */
  sessionCount: Scalars['Int']['output'];
  /** ディメンションデータ */
  sourceMedium?: Maybe<TrafficSourceMedium>;
  surfCount: Scalars['Int']['output'];
  surfRate: Scalars['Float']['output'];
  uniqueUserCount: Scalars['Int']['output'];
};

export type SessionPerformanceDetail = {
  __typename?: 'SessionPerformanceDetail';
  avgPageLocationCount: Scalars['Float']['output'];
  avgSessionDuration: Scalars['Float']['output'];
  browser?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  conversionCount: Scalars['Int']['output'];
  conversionRate: Scalars['Float']['output'];
  date?: Maybe<Scalars['Time']['output']>;
  defaultChannelGroup?: Maybe<Scalars['String']['output']>;
  device?: Maybe<Scalars['String']['output']>;
  engagementCount: Scalars['Int']['output'];
  engagementRate: Scalars['Float']['output'];
  /** ディメンションデータ */
  firstPageLocation?: Maybe<Scalars['String']['output']>;
  firstPageLocationURLGroup?: Maybe<UrlGroup>;
  os?: Maybe<Scalars['String']['output']>;
  secondPageLocation?: Maybe<Scalars['String']['output']>;
  secondPageLocationURLGroup?: Maybe<UrlGroup>;
  /** 集計データ */
  sessionCount: Scalars['Int']['output'];
  sourceMedium?: Maybe<TrafficSourceMedium>;
  surfCount: Scalars['Int']['output'];
  surfRate: Scalars['Float']['output'];
  uniqueUserCount: Scalars['Int']['output'];
};

export enum SessionPerformanceDetailGroupBy {
  Browser = 'BROWSER',
  Campaign = 'CAMPAIGN',
  Date = 'DATE',
  DefaultChannelGroup = 'DEFAULT_CHANNEL_GROUP',
  Device = 'DEVICE',
  FirstPageLocation = 'FIRST_PAGE_LOCATION',
  FirstPageLocationUrlGroup = 'FIRST_PAGE_LOCATION_URL_GROUP',
  Os = 'OS',
  SecondPageLocation = 'SECOND_PAGE_LOCATION',
  SecondPageLocationUrlGroup = 'SECOND_PAGE_LOCATION_URL_GROUP',
  SourceMedium = 'SOURCE_MEDIUM',
}

export type SessionPerformanceDetailOrderBy = {
  __typename?: 'SessionPerformanceDetailOrderBy';
  direction: OrderDirection;
  field: SessionPerformanceDetailOrderByField;
};

export enum SessionPerformanceDetailOrderByField {
  AvgPageLocationCount = 'AVG_PAGE_LOCATION_COUNT',
  AvgSessionDuration = 'AVG_SESSION_DURATION',
  ConversionRate = 'CONVERSION_RATE',
  Date = 'DATE',
  EngagementCount = 'ENGAGEMENT_COUNT',
  EngagementRate = 'ENGAGEMENT_RATE',
  SessionCount = 'SESSION_COUNT',
  SurfRate = 'SURF_RATE',
  UniqueUserCount = 'UNIQUE_USER_COUNT',
}

export type SessionPerformanceDetailOrderByInput = {
  direction: OrderDirection;
  field: SessionPerformanceDetailOrderByField;
};

export enum SessionPerformanceGroupBy {
  FirstPageLocationUrlGroup = 'FIRST_PAGE_LOCATION_URL_GROUP',
  SecondPageLocationUrlGroup = 'SECOND_PAGE_LOCATION_URL_GROUP',
  SourceMedium = 'SOURCE_MEDIUM',
}

export type SetKeyEventsInput = {
  keyEvents: Array<Scalars['String']['input']>;
  userAnalyticsID: Scalars['ID']['input'];
};

export type Sitemap = {
  __typename?: 'Sitemap';
  url: Scalars['String']['output'];
};

export type SitemapIndex = Node & {
  __typename?: 'SitemapIndex';
  id: Scalars['ID']['output'];
  sitemaps: Array<Sitemap>;
  url: Scalars['String']['output'];
};

export type SitemapLike = Sitemap | SitemapIndex;

export enum StripeSubscriptionStatus {
  /** 有効 */
  Active = 'ACTIVE',
  /** キャンセル済み */
  Canceled = 'CANCELED',
  /** 契約時の決済失敗、24時間以内のリトライ可能期間中 */
  Incomplete = 'INCOMPLETE',
  /** 契約時の決済失敗、リトライ期限切れ */
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  /** 特になし */
  None = 'NONE',
  /** 更新失敗、リトライ中 */
  PastDue = 'PAST_DUE',
  /** おためし期間中 */
  Trialing = 'TRIALING',
  /** 更新失敗、リトライ失敗 */
  Unpaid = 'UNPAID',
}

export type TaskOrder = {
  direction: OrderDirection;
  field: TaskOrderField;
};

export enum TaskOrderField {
  /** @deprecated Field no longer supported */
  CreatedAt = 'CREATED_AT',
}

export type TimeRange = {
  __typename?: 'TimeRange';
  end: Scalars['Time']['output'];
  start: Scalars['Time']['output'];
};

export type TimeRangeInput = {
  end: Scalars['Time']['input'];
  start: Scalars['Time']['input'];
};

export type TrafficSourceMedium = {
  __typename?: 'TrafficSourceMedium';
  medium: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type TrafficSourceMediumInput = {
  medium: Scalars['String']['input'];
  source: Scalars['String']['input'];
};

/**
 * URLグループ
 * いずれの条件にも当てはまらなかった場合、collection: なし, ID: default, Name: defaultのものが出力されます。
 */
export type UrlGroup = Node & {
  __typename?: 'URLGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  op: LogicalOperator;
  patterns: Array<Maybe<UrlGroupPattern>>;
};

export type UrlGroupPattern = {
  __typename?: 'URLGroupPattern';
  pattern: Scalars['String']['output'];
  type: UrlGroupPatternType;
};

export enum UrlGroupPatternType {
  Contains = 'CONTAINS',
  Depth1 = 'DEPTH1',
  Depth2 = 'DEPTH2',
  Depth3 = 'DEPTH3',
  EndsWith = 'ENDS_WITH',
  Equals = 'EQUALS',
  /** @deprecated CONTAINSに直す */
  Includes = 'INCLUDES',
  NotContains = 'NOT_CONTAINS',
  /** @deprecated NOT_CONTAINSに直す */
  NotIncludes = 'NOT_INCLUDES',
  NotRegex = 'NOT_REGEX',
  Regex = 'REGEX',
  StartsWith = 'STARTS_WITH',
  SubDomain = 'SUB_DOMAIN',
}

export type UnarchiveNotificationInput = {
  id: Scalars['ID']['input'];
};

export type UnregisterPropertyInput = {
  id: Scalars['ID']['input'];
};

export type UnregisterServiceAccountInput = {
  id: Scalars['ID']['input'];
};

export type UnregisteredSearchConsolePropertyPayload = {
  __typename?: 'UnregisteredSearchConsolePropertyPayload';
  id: Scalars['ID']['output'];
};

export type UnregisteredServiceAccountPayload = {
  __typename?: 'UnregisteredServiceAccountPayload';
  id: Scalars['ID']['output'];
};

export type UpdateContractInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContractType>;
};

export type UpdateDashboardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDashboardItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  featureID?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDashboardItemsOrderInput = {
  dashboardID: Scalars['ID']['input'];
  items: Array<Scalars['ID']['input']>;
};

export type UpdateIndexWorkerInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  ignoreURLParameters?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  propertyID?: InputMaybe<Scalars['ID']['input']>;
  rssFeed?: InputMaybe<Scalars['String']['input']>;
  rssFeedSamplingPeriod?: InputMaybe<Scalars['Int']['input']>;
  samplingCount?: InputMaybe<Scalars['Int']['input']>;
  /**
   * minute, hour, dowの3つのスケジュールをcron形式で指定
   * 例: 0 0 *
   */
  schedule?: InputMaybe<Scalars['String']['input']>;
  sitemap?: InputMaybe<Scalars['String']['input']>;
  urlGroup?: InputMaybe<IndexWorkerUrlGroupInput>;
  urls?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateIndexWorkerViewOrderInput = {
  ids: Array<Scalars['ID']['input']>;
  projectID: Scalars['ID']['input'];
};

export type UpdateNotificationSettingInput = {
  isNotified: Scalars['Boolean']['input'];
  organizationID: Scalars['ID']['input'];
  type: NotificationType;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateProjectInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePropertyInput = {
  id: Scalars['ID']['input'];
  serviceAccountID?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<RegisterPropertyType>;
};

export type UpdateQueryGroupInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  op?: InputMaybe<LogicalOperator>;
  patterns?: InputMaybe<Array<UpdateQueryGroupPatternInput>>;
};

export type UpdateQueryGroupOrderInput = {
  ids: Array<Scalars['ID']['input']>;
  searchAnalyticsId: Scalars['ID']['input'];
};

export type UpdateQueryGroupPatternInput = {
  pattern: Scalars['String']['input'];
  type: QueryGroupPatternType;
};

export type UpdateSearchAnalyticsInput = {
  datasetName?: InputMaybe<Scalars['String']['input']>;
  googleCloudProjectID?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** nullにしたい場合は空文字列を送る */
  spreadsheetID?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSearchAnalyticsViewInput = {
  /** 国でフィルタリング */
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 対象の日付範囲 */
  dataDate: SearchAnalyticsDataDateInput;
  description?: InputMaybe<Scalars['String']['input']>;
  /** デバイスでフィルタリング */
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  /** 匿名化クエリでフィルタリング */
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Queryでフィルタリング */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Queryのフィルタリングタイプ */
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** QueryGroupでフィルタリング */
  queryGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 集計時のグループ化の対象を指定 */
  rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
  /** 集計時のグループ化の並び順を指定 */
  rawDataOrderBy: SearchAnalyticsRawDataOrderInput;
  /** 検索タイプでフィルタリング */
  searchTypes?: InputMaybe<Array<SearchAnalyticsSearchType>>;
  /** URLでフィルタリング */
  url?: InputMaybe<Scalars['String']['input']>;
  /** urlのフィルタリングタイプ */
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  /** URLGroupでフィルタリング */
  urlGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateSearchAnalyticsViewOrderInput = {
  ids: Array<Scalars['ID']['input']>;
  searchAnalyticsID: Scalars['ID']['input'];
};

export type UpdateSearchAnalyticsViewSpreadsheetId = {
  id: Scalars['ID']['input'];
  /** 空文字列を送るとnullになります。 */
  spreadsheetID: Scalars['String']['input'];
};

export type UpdateUrlGroupForUserAnalyticsInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  op?: InputMaybe<LogicalOperator>;
  patterns?: InputMaybe<Array<CreateUrlGroupPatternInput>>;
};

export type UpdateUrlGroupInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  op?: InputMaybe<LogicalOperator>;
  patterns?: InputMaybe<Array<UpdateUrlGroupPatternInput>>;
};

export type UpdateUrlGroupOrderForUserAnalyticsInput = {
  urlGroupIDs: Array<Scalars['ID']['input']>;
  userAnalyticsID: Scalars['ID']['input'];
};

export type UpdateUrlGroupOrderInput = {
  ids: Array<Scalars['ID']['input']>;
  searchAnalyticsId: Scalars['ID']['input'];
};

export type UpdateUrlGroupPatternInput = {
  pattern: Scalars['String']['input'];
  type: UrlGroupPatternType;
};

export type UpdateUserAnalyticsInput = {
  datasetName?: InputMaybe<Scalars['String']['input']>;
  googleCloudProjectID?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  keyEvents?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateUserAnalyticsViewInput = {
  browsers?: InputMaybe<Array<BrowserCategory>>;
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignsFilterType?: InputMaybe<Array<UserAnalyticsFilter>>;
  defaultChannelGroup?: InputMaybe<Array<DefaultChannelGroup>>;
  defaultDateRange?: InputMaybe<UserAnalyticsDataDateInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  detailGroupBy?: InputMaybe<Array<SessionPerformanceDetailGroupBy>>;
  detailOrderBy?: InputMaybe<SessionPerformanceDetailOrderByInput>;
  devices?: InputMaybe<Array<DeviceCategory>>;
  events?: InputMaybe<Array<Scalars['String']['input']>>;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageLocationURL?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  id: Scalars['ID']['input'];
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  mediums?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Array<OsCategory>>;
  pageLocationCount?: InputMaybe<IntRangeInput>;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocationURL?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  visitedPage?: InputMaybe<Scalars['String']['input']>;
  visitedPageFilterType?: InputMaybe<UserAnalyticsFilter>;
};

export type UpdateUserAnalyticsViewOrderInput = {
  id: Scalars['ID']['input'];
  viewOrder: Array<Scalars['ID']['input']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  isTutorialMovieViewed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  userID: Scalars['ID']['input'];
};

export type User = Node & {
  __typename?: 'User';
  /**
   * そのユーザがgoogle search consoleで利用可能なプロパティです。
   * organizationIdを指定すると、そのorganizationで登録済みのプロパティは除外されます。
   */
  availableProperties: Array<SearchConsoleProperty>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isCustomerSupport: Scalars['Boolean']['output'];
  /** @deprecated isCustomerSupportRepを使用してください */
  isCustomerSupportRep: Scalars['Boolean']['output'];
  isTutorialMovieViewed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notifications: NotificationConnection;
  organizations: Array<UserOrganization>;
  picture: Scalars['String']['output'];
  preferredLanguage: Scalars['String']['output'];
  /** そのユーザが持ち主である、amethystに登録済みのプロパティです。 */
  registeredProperties: Array<RegisteredSearchConsoleUserProperty>;
};

export type UserAvailablePropertiesArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserIsCustomerSupportArgs = {
  organizationID: Scalars['ID']['input'];
};

export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  first?: Scalars['Int']['input'];
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  severity?: InputMaybe<NotificationSeverity>;
  type?: InputMaybe<NotificationType>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserAnalytics = Node & {
  __typename?: 'UserAnalytics';
  datasetName: Scalars['String']['output'];
  earliestDataDate: Scalars['Time']['output'];
  eventNames?: Maybe<Array<Scalars['String']['output']>>;
  exportData: Array<UserAnalyticsExportData>;
  getSessionDetail?: Maybe<SessionDetail>;
  googleCloudProjectID: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Array<Scalars['String']['output']>>;
  latestDataDate: Scalars['Time']['output'];
  sessionPerformance?: Maybe<Array<SessionPerformance>>;
  sessionPerformanceDetail?: Maybe<Array<SessionPerformanceDetail>>;
  sessions?: Maybe<Array<Session>>;
  urlGroups?: Maybe<Array<UrlGroup>>;
  userSessions?: Maybe<Array<Session>>;
  views?: Maybe<Array<UserAnalyticsView>>;
};

export type UserAnalyticsGetSessionDetailArgs = {
  gaSessionId: Scalars['Int']['input'];
  userPseudoId: Scalars['String']['input'];
};

export type UserAnalyticsSessionPerformanceArgs = {
  browsers?: InputMaybe<Array<BrowserCategory>>;
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignsFilterType?: InputMaybe<Array<UserAnalyticsFilter>>;
  dateRange: TimeRangeInput;
  defaultChannelGroup?: InputMaybe<Array<DefaultChannelGroup>>;
  devices?: InputMaybe<Array<DeviceCategory>>;
  events?: InputMaybe<Array<Scalars['String']['input']>>;
  firstPageLocation?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  groupBy?: InputMaybe<SessionPerformanceGroupBy>;
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  mediums?: InputMaybe<Array<Scalars['String']['input']>>;
  os?: InputMaybe<Array<OsCategory>>;
  pageLocationCount?: InputMaybe<IntRangeInput>;
  secondPageLocation?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  visitedPage?: InputMaybe<Scalars['String']['input']>;
  visitedPageFilterType?: InputMaybe<UserAnalyticsFilter>;
};

export type UserAnalyticsSessionPerformanceDetailArgs = {
  browsers?: InputMaybe<Array<BrowserCategory>>;
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignsFilterType?: InputMaybe<Array<UserAnalyticsFilter>>;
  dateRange: TimeRangeInput;
  defaultChannelGroup?: InputMaybe<Array<DefaultChannelGroup>>;
  devices?: InputMaybe<Array<DeviceCategory>>;
  events?: InputMaybe<Array<Scalars['String']['input']>>;
  firstPageLocation?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  groupBy?: Array<SessionPerformanceDetailGroupBy>;
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  mediums?: InputMaybe<Array<Scalars['String']['input']>>;
  offset?: Scalars['Int']['input'];
  orderBy?: SessionPerformanceDetailOrderByInput;
  os?: InputMaybe<Array<OsCategory>>;
  pageLocationCount?: InputMaybe<IntRangeInput>;
  secondPageLocation?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  visitedPage?: InputMaybe<Scalars['String']['input']>;
  visitedPageFilterType?: InputMaybe<UserAnalyticsFilter>;
};

export type UserAnalyticsSessionsArgs = {
  browsers?: InputMaybe<Array<BrowserCategory>>;
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignsFilterType?: InputMaybe<Array<UserAnalyticsFilter>>;
  dateRange: TimeRangeInput;
  defaultChannelGroup?: InputMaybe<Array<DefaultChannelGroup>>;
  devices?: InputMaybe<Array<DeviceCategory>>;
  events?: InputMaybe<Array<Scalars['String']['input']>>;
  firstPageLocation?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  mediums?: InputMaybe<Array<Scalars['String']['input']>>;
  offset?: Scalars['Int']['input'];
  os?: InputMaybe<Array<OsCategory>>;
  pageLocationCount?: InputMaybe<IntRangeInput>;
  secondPageLocation?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocationURLGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  visitedPage?: InputMaybe<Scalars['String']['input']>;
  visitedPageFilterType?: InputMaybe<UserAnalyticsFilter>;
};

export type UserAnalyticsUserSessionsArgs = {
  dateRange: TimeRangeInput;
  userPseudoId: Scalars['String']['input'];
};

export type UserAnalyticsDataDate = {
  __typename?: 'UserAnalyticsDataDate';
  dateRange?: Maybe<TimeRange>;
  type: UserAnalyticsDataDateType;
};

export type UserAnalyticsDataDateInput = {
  dateRange?: InputMaybe<TimeRangeInput>;
  type: UserAnalyticsDataDateType;
};

export enum UserAnalyticsDataDateType {
  /** カスタム期間 */
  Custom = 'CUSTOM',
  /** 過去7日間 */
  Days_7 = 'DAYS_7',
  /** 過去28日間 */
  Days_28 = 'DAYS_28',
  /** 過去90日間 */
  Days_90 = 'DAYS_90',
  /** 過去180日間 */
  Days_180 = 'DAYS_180',
  /** 過去365日間 */
  Days_365 = 'DAYS_365',
  /** 過去480日間 */
  Days_480 = 'DAYS_480',
  /** 過去1日間 */
  Latest = 'LATEST',
  /** @deprecated DAYS_90を使用してください */
  Months_3 = 'MONTHS_3',
  /** @deprecated DAYS_180を使用してください */
  Months_6 = 'MONTHS_6',
  /** @deprecated DAYS_365を使用してください */
  Months_12 = 'MONTHS_12',
  /** @deprecated DAYS_480を使用してください */
  Months_16 = 'MONTHS_16',
}

export type UserAnalyticsExportData = {
  __typename?: 'UserAnalyticsExportData';
  expires?: Maybe<Scalars['Time']['output']>;
  exportType: UserAnalyticsExportType;
  exportedAt: Scalars['Time']['output'];
  status: UserAnalyticsExportStatus;
  urls?: Maybe<Array<Scalars['String']['output']>>;
};

export enum UserAnalyticsExportStatus {
  Done = 'DONE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
}

export enum UserAnalyticsExportType {
  Csv = 'CSV',
  Json = 'JSON',
}

export enum UserAnalyticsFilter {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Equals = 'EQUALS',
  NotContains = 'NOT_CONTAINS',
  NotRegex = 'NOT_REGEX',
  Regex = 'REGEX',
  StartsWith = 'STARTS_WITH',
}

export type UserAnalyticsProject = Node &
  Project & {
    __typename?: 'UserAnalyticsProject';
    color: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    userAnalytics?: Maybe<UserAnalytics>;
  };

export type UserAnalyticsView = Node & {
  __typename?: 'UserAnalyticsView';
  browsers?: Maybe<Array<BrowserCategory>>;
  campaigns?: Maybe<Array<Scalars['String']['output']>>;
  campaignsFilterType?: Maybe<Array<UserAnalyticsFilter>>;
  defaultChannelGroup?: Maybe<Array<DefaultChannelGroup>>;
  defaultDateRange: UserAnalyticsDataDate;
  description?: Maybe<Scalars['String']['output']>;
  detailGroupBy: Array<SessionPerformanceDetailGroupBy>;
  detailOrderBy: SessionPerformanceDetailOrderBy;
  devices?: Maybe<Array<DeviceCategory>>;
  events?: Maybe<Array<Scalars['String']['output']>>;
  firstPageLocationFilterType?: Maybe<UserAnalyticsFilter>;
  firstPageLocationURL?: Maybe<Scalars['String']['output']>;
  firstPageLocationURLGroupIDs?: Maybe<Array<Scalars['ID']['output']>>;
  firstPageTitle?: Maybe<Scalars['String']['output']>;
  firstPageTitleFilterType?: Maybe<UserAnalyticsFilter>;
  id: Scalars['ID']['output'];
  isSessionWithNavigation?: Maybe<Scalars['Boolean']['output']>;
  mediums?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  os?: Maybe<Array<OsCategory>>;
  pageLocationCount?: Maybe<IntRange>;
  secondPageLocationFilterType?: Maybe<UserAnalyticsFilter>;
  secondPageLocationURL?: Maybe<Scalars['String']['output']>;
  secondPageLocationURLGroupIDs?: Maybe<Array<Scalars['ID']['output']>>;
  secondPageTitle?: Maybe<Scalars['String']['output']>;
  secondPageTitleFilterType?: Maybe<UserAnalyticsFilter>;
  sessionPerformance?: Maybe<Array<SessionPerformance>>;
  sessionPerformanceDetail?: Maybe<Array<SessionPerformanceDetail>>;
  sessions?: Maybe<Array<Session>>;
  sources?: Maybe<Array<Scalars['String']['output']>>;
  visitedPage?: Maybe<Scalars['String']['output']>;
  visitedPageFilterType?: Maybe<UserAnalyticsFilter>;
};

export type UserAnalyticsViewSessionPerformanceArgs = {
  dateRange?: InputMaybe<TimeRangeInput>;
  groupBy?: InputMaybe<SessionPerformanceGroupBy>;
};

export type UserAnalyticsViewSessionPerformanceDetailArgs = {
  dateRange?: InputMaybe<TimeRangeInput>;
  groupBy?: InputMaybe<Array<SessionPerformanceDetailGroupBy>>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SessionPerformanceDetailOrderByInput>;
};

export type UserAnalyticsViewSessionsArgs = {
  dateRange?: InputMaybe<TimeRangeInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type UserOrganization = {
  __typename?: 'UserOrganization';
  organization: Organization;
  organizationID: Scalars['ID']['output'];
  role: UserOrganizationRole;
  status: UserOrganizationStatus;
  user: User;
  userID: Scalars['ID']['output'];
};

export enum UserOrganizationRole {
  Administrator = 'ADMINISTRATOR',
  FullMember = 'FULL_MEMBER',
  Guest = 'GUEST',
  Owner = 'OWNER',
  RestrictedMember = 'RESTRICTED_MEMBER',
}

export enum UserOrganizationStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export type AppLayoutQueryVariables = Exact<{
  organizationID: Scalars['ID']['input'];
  includeOrganization: Scalars['Boolean']['input'];
}>;

export type AppLayoutQuery = {
  __typename?: 'Query';
  viewer?: {__typename?: 'User'; id: string} | null;
  org?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        doneGettingStartedTours: Array<GettingStartedTour>;
        registeredProperties: Array<
          | {
              __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
              id: string;
              property: string;
              serviceAccount: {
                __typename?: 'ServiceAccount';
                id: string;
                email: string;
              };
              alerts: Array<{
                __typename?: 'SearchConsolePropertyAlert';
                totalUrlCount: number;
                from: {__typename?: 'DayAndTime'; day: Day; time: string};
                to: {__typename?: 'DayAndTime'; day: Day; time: string};
                indexWorkers: Array<{
                  __typename?: 'IndexWorker';
                  id: string;
                  name: string;
                  color: string;
                  urls?: Array<string> | null;
                  urlType: IndexWorkerUrlType;
                  samplingCount: number;
                  organization: {
                    __typename?: 'Organization';
                    id: string;
                    name: string;
                  };
                }>;
              }>;
            }
          | {
              __typename?: 'RegisteredSearchConsoleUserProperty';
              id: string;
              property: string;
              user: {__typename?: 'User'; id: string; email: string};
              alerts: Array<{
                __typename?: 'SearchConsolePropertyAlert';
                totalUrlCount: number;
                from: {__typename?: 'DayAndTime'; day: Day; time: string};
                to: {__typename?: 'DayAndTime'; day: Day; time: string};
                indexWorkers: Array<{
                  __typename?: 'IndexWorker';
                  id: string;
                  name: string;
                  color: string;
                  urls?: Array<string> | null;
                  urlType: IndexWorkerUrlType;
                  samplingCount: number;
                  organization: {
                    __typename?: 'Organization';
                    id: string;
                    name: string;
                  };
                }>;
              }>;
            }
        >;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          organizations: Array<{__typename?: 'Organization'; id: string}>;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            urlGroupCapacity: number;
            viewCapacity: number;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            uaProjectCapacity: number;
          } | null;
        } | null;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type OrgSwitcherViewerQueryFragment = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    organizations: Array<{
      __typename?: 'UserOrganization';
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            orgCapacity: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type SubscriptionStatusBoundaryQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type SubscriptionStatusBoundaryQuery = {
  __typename?: 'Query';
  contractOrg?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {__typename?: 'Plan'; id: string} | null;
        } | null;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SideNavigationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type SideNavigationQuery = {
  __typename?: 'Query';
  viewerOrganizationRole: UserOrganizationRole;
  organization?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        indexWorkerProjects: Array<
          | {
              __typename?: 'IndexWorkerProject';
              id: string;
              name: string;
              color: string;
              indexWorkers: Array<{
                __typename?: 'IndexWorker';
                id: string;
                name: string;
                color: string;
              }>;
            }
          | {__typename?: 'SearchAnalyticsProject'}
          | {__typename?: 'UserAnalyticsProject'}
        >;
        searchAnalyticsProjects: Array<
          | {__typename?: 'IndexWorkerProject'}
          | {
              __typename?: 'SearchAnalyticsProject';
              id: string;
              name: string;
              color: string;
              searchAnalytics?: {
                __typename?: 'SearchAnalytics';
                latestDataDate?: Date | null;
                views: Array<{
                  __typename?: 'SearchAnalyticsView';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {__typename?: 'UserAnalyticsProject'}
        >;
        userAnalyticsProjects: Array<
          | {__typename?: 'IndexWorkerProject'}
          | {__typename?: 'SearchAnalyticsProject'}
          | {
              __typename?: 'UserAnalyticsProject';
              id: string;
              name: string;
              color: string;
              userAnalytics?: {
                __typename?: 'UserAnalytics';
                id: string;
                views?: Array<{
                  __typename?: 'UserAnalyticsView';
                  id: string;
                  name: string;
                }> | null;
              } | null;
            }
        >;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
  viewer?: {
    __typename?: 'User';
    id: string;
    organizations: Array<{
      __typename?: 'UserOrganization';
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            orgCapacity: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type SideNavigationOrgFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  indexWorkerProjects: Array<
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          color: string;
        }>;
      }
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'UserAnalyticsProject'}
  >;
  searchAnalyticsProjects: Array<
    | {__typename?: 'IndexWorkerProject'}
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
        searchAnalytics?: {
          __typename?: 'SearchAnalytics';
          latestDataDate?: Date | null;
          views: Array<{
            __typename?: 'SearchAnalyticsView';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {__typename?: 'UserAnalyticsProject'}
  >;
  userAnalyticsProjects: Array<
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
        userAnalytics?: {
          __typename?: 'UserAnalytics';
          id: string;
          views?: Array<{
            __typename?: 'UserAnalyticsView';
            id: string;
            name: string;
          }> | null;
        } | null;
      }
  >;
};

export type ExportStatusIconQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type ExportStatusIconQuery = {
  __typename?: 'Query';
  organization?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        projects: Array<
          | {__typename?: 'IndexWorkerProject'}
          | {
              __typename?: 'SearchAnalyticsProject';
              id: string;
              name: string;
              color: string;
              searchAnalytics?: {
                __typename?: 'SearchAnalytics';
                id: string;
                exportData: Array<{
                  __typename?: 'SearchAnalyticsExportData';
                  urls?: Array<string> | null;
                  expires?: Date | null;
                  status: SearchAnalyticsExportStatus;
                  exportedAt: Date;
                  exportType: SearchAnalyticsExportType;
                }>;
              } | null;
            }
          | {
              __typename?: 'UserAnalyticsProject';
              id: string;
              name: string;
              color: string;
              userAnalytics?: {
                __typename?: 'UserAnalytics';
                id: string;
                exportData: Array<{
                  __typename?: 'UserAnalyticsExportData';
                  urls?: Array<string> | null;
                  expires?: Date | null;
                  status: UserAnalyticsExportStatus;
                  exportedAt: Date;
                  exportType: UserAnalyticsExportType;
                }>;
              } | null;
            }
        >;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type ExportStatusIconFragment = {
  __typename?: 'Organization';
  id: string;
  projects: Array<
    | {__typename?: 'IndexWorkerProject'}
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
        searchAnalytics?: {
          __typename?: 'SearchAnalytics';
          id: string;
          exportData: Array<{
            __typename?: 'SearchAnalyticsExportData';
            urls?: Array<string> | null;
            expires?: Date | null;
            status: SearchAnalyticsExportStatus;
            exportedAt: Date;
            exportType: SearchAnalyticsExportType;
          }>;
        } | null;
      }
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
        userAnalytics?: {
          __typename?: 'UserAnalytics';
          id: string;
          exportData: Array<{
            __typename?: 'UserAnalyticsExportData';
            urls?: Array<string> | null;
            expires?: Date | null;
            status: UserAnalyticsExportStatus;
            exportedAt: Date;
            exportType: UserAnalyticsExportType;
          }>;
        } | null;
      }
  >;
};

export type UsePlanOrgFragment = {
  __typename?: 'Organization';
  id: string;
  contract?: {
    __typename?: 'Contract';
    id: string;
    name: string;
    type: ContractType;
    stripeSubscriptionStatus: StripeSubscriptionStatus;
    organizations: Array<{__typename?: 'Organization'; id: string}>;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      name: string;
      urlGroupCapacity: number;
      viewCapacity: number;
      propertyCapacity: number;
      userCapacity: number;
      guestCapacity: number;
      orgCapacity: number;
      iwProjectCapacity: number;
      scProjectCapacity: number;
      queryGroupCapacity: number;
      uaProjectCapacity: number;
    } | null;
  } | null;
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject:
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
      }
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
      }
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
      };
};

export type ImportUrlGroupModal_SearchAnalyticsProjectFragment = {
  __typename?: 'SearchAnalyticsProject';
  id: string;
  name: string;
  searchAnalytics?: {
    __typename?: 'SearchAnalytics';
    id: string;
    urlGroups: Array<{
      __typename?: 'URLGroup';
      id: string;
      name: string;
      op: LogicalOperator;
      patterns: Array<{
        __typename?: 'URLGroupPattern';
        type: UrlGroupPatternType;
        pattern: string;
      } | null>;
    }>;
  } | null;
};

export type ImportUrlGroupModal_UserAnalyticsProjectFragment = {
  __typename?: 'UserAnalyticsProject';
  id: string;
  name: string;
  userAnalytics?: {
    __typename?: 'UserAnalytics';
    id: string;
    urlGroups?: Array<{
      __typename?: 'URLGroup';
      id: string;
      name: string;
      op: LogicalOperator;
      patterns: Array<{
        __typename?: 'URLGroupPattern';
        type: UrlGroupPatternType;
        pattern: string;
      } | null>;
    }> | null;
  } | null;
};

export type ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationVariables =
  Exact<{
    input: CreateUrlGroupInput;
  }>;

export type ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation = {
  __typename?: 'Mutation';
  createURLGroup: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationVariables =
  Exact<{
    input: CreateUrUserAnalyticsUrlGroupInput;
  }>;

export type ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation = {
  __typename?: 'Mutation';
  createURLGroupForUserAnalytics: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type DashboardFragment = {
  __typename?: 'Dashboard';
  id: string;
  description?: string | null;
  name: string;
  items: Array<
    | {__typename?: 'DashboardItemDivider'; id: string; name: string}
    | {
        __typename?: 'DashboardItemMetricWithTimeSeries';
        name: string;
        id: string;
        description?: string | null;
        type: DashboardItemType;
        featureID?: string | null;
        timeSeries: Array<{
          __typename?: 'DateToFloatValue';
          date: Date;
          value: number;
        }>;
      }
  >;
};

type DashboardItem_DashboardItemDivider_Fragment = {
  __typename?: 'DashboardItemDivider';
};

type DashboardItem_DashboardItemMetricWithTimeSeries_Fragment = {
  __typename?: 'DashboardItemMetricWithTimeSeries';
  id: string;
  name: string;
  description?: string | null;
  type: DashboardItemType;
  featureID?: string | null;
  timeSeries: Array<{
    __typename?: 'DateToFloatValue';
    date: Date;
    value: number;
  }>;
};

export type DashboardItemFragment =
  | DashboardItem_DashboardItemDivider_Fragment
  | DashboardItem_DashboardItemMetricWithTimeSeries_Fragment;

export type DashboardItemMetricWithTimeSeriesFragment = {
  __typename?: 'DashboardItemMetricWithTimeSeries';
  id: string;
  name: string;
  description?: string | null;
  type: DashboardItemType;
  featureID?: string | null;
  timeSeries: Array<{
    __typename?: 'DateToFloatValue';
    date: Date;
    value: number;
  }>;
};

export type FeatureDataQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  featureId: Scalars['ID']['input'];
}>;

export type FeatureDataQuery = {
  __typename?: 'Query';
  project?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
      }
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
      }
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
  feature?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'; id: string; name: string; color: string}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'; id: string; name: string}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SearchAnalyticsViewFeatureDataFragment = {
  __typename?: 'SearchAnalyticsView';
  id: string;
  name: string;
};

export type IndexWorkerFeatureDataFragment = {
  __typename?: 'IndexWorker';
  id: string;
  name: string;
  color: string;
};

export type DashboardPageQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  timeRange: TimeRangeInput;
}>;

export type DashboardPageQuery = {
  __typename?: 'Query';
  viewerOrganizationRole: UserOrganizationRole;
  viewer?: {__typename?: 'User'; id: string} | null;
  organization?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
          } | null;
        } | null;
        projects: Array<
          | {
              __typename?: 'IndexWorkerProject';
              id: string;
              name: string;
              color: string;
              indexWorkers: Array<{
                __typename?: 'IndexWorker';
                id: string;
                name: string;
                color: string;
              }>;
            }
          | {
              __typename?: 'SearchAnalyticsProject';
              id: string;
              name: string;
              color: string;
              searchAnalytics?: {
                __typename?: 'SearchAnalytics';
                id: string;
                latestDataDate?: Date | null;
                views: Array<{
                  __typename?: 'SearchAnalyticsView';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename?: 'UserAnalyticsProject';
              id: string;
              name: string;
              color: string;
            }
        >;
        dashboards: Array<{
          __typename?: 'Dashboard';
          id: string;
          description?: string | null;
          name: string;
          items: Array<
            | {__typename?: 'DashboardItemDivider'; id: string; name: string}
            | {
                __typename?: 'DashboardItemMetricWithTimeSeries';
                name: string;
                id: string;
                description?: string | null;
                type: DashboardItemType;
                featureID?: string | null;
                timeSeries: Array<{
                  __typename?: 'DateToFloatValue';
                  date: Date;
                  value: number;
                }>;
              }
          >;
        }>;
        registeredProperties: Array<
          | {
              __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
              id: string;
              property: string;
              serviceAccount: {
                __typename?: 'ServiceAccount';
                id: string;
                email: string;
              };
              alerts: Array<{
                __typename?: 'SearchConsolePropertyAlert';
                totalUrlCount: number;
                from: {__typename?: 'DayAndTime'; day: Day; time: string};
                to: {__typename?: 'DayAndTime'; day: Day; time: string};
                indexWorkers: Array<{
                  __typename?: 'IndexWorker';
                  id: string;
                  name: string;
                  color: string;
                  urls?: Array<string> | null;
                  urlType: IndexWorkerUrlType;
                  samplingCount: number;
                  organization: {
                    __typename?: 'Organization';
                    id: string;
                    name: string;
                  };
                }>;
              }>;
            }
          | {
              __typename?: 'RegisteredSearchConsoleUserProperty';
              id: string;
              property: string;
              user: {__typename?: 'User'; id: string; email: string};
              alerts: Array<{
                __typename?: 'SearchConsolePropertyAlert';
                totalUrlCount: number;
                from: {__typename?: 'DayAndTime'; day: Day; time: string};
                to: {__typename?: 'DayAndTime'; day: Day; time: string};
                indexWorkers: Array<{
                  __typename?: 'IndexWorker';
                  id: string;
                  name: string;
                  color: string;
                  urls?: Array<string> | null;
                  urlType: IndexWorkerUrlType;
                  samplingCount: number;
                  organization: {
                    __typename?: 'Organization';
                    id: string;
                    name: string;
                  };
                }>;
              }>;
            }
        >;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type DashboardTabsFragment = {
  __typename?: 'Organization';
  dashboards: Array<{__typename?: 'Dashboard'; id: string; name: string}>;
};

export type DashboardTabFragment = {
  __typename?: 'Dashboard';
  id: string;
  name: string;
};

export type IndexWorkerApiQuotaAlertFragment = {
  __typename?: 'Organization';
  id: string;
  registeredProperties: Array<
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        property: string;
        serviceAccount: {
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        };
        alerts: Array<{
          __typename?: 'SearchConsolePropertyAlert';
          totalUrlCount: number;
          from: {__typename?: 'DayAndTime'; day: Day; time: string};
          to: {__typename?: 'DayAndTime'; day: Day; time: string};
          indexWorkers: Array<{
            __typename?: 'IndexWorker';
            id: string;
            name: string;
            color: string;
            urls?: Array<string> | null;
            urlType: IndexWorkerUrlType;
            samplingCount: number;
            organization: {
              __typename?: 'Organization';
              id: string;
              name: string;
            };
          }>;
        }>;
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        property: string;
        user: {__typename?: 'User'; id: string; email: string};
        alerts: Array<{
          __typename?: 'SearchConsolePropertyAlert';
          totalUrlCount: number;
          from: {__typename?: 'DayAndTime'; day: Day; time: string};
          to: {__typename?: 'DayAndTime'; day: Day; time: string};
          indexWorkers: Array<{
            __typename?: 'IndexWorker';
            id: string;
            name: string;
            color: string;
            urls?: Array<string> | null;
            urlType: IndexWorkerUrlType;
            samplingCount: number;
            organization: {
              __typename?: 'Organization';
              id: string;
              name: string;
            };
          }>;
        }>;
      }
  >;
};

export type CreateDashboardMutationVariables = Exact<{
  input: CreateDashboardInput;
}>;

export type CreateDashboardMutation = {
  __typename?: 'Mutation';
  createDashboard: {__typename?: 'Dashboard'; id: string};
};

export type DeleteDashboardItemMutationVariables = Exact<{
  input: DeleteDashboardItemInput;
}>;

export type DeleteDashboardItemMutation = {
  __typename?: 'Mutation';
  deleteDashboardItem: {__typename?: 'DeleteDashboardItemPayload'; id: string};
};

export type CreateDashboardItemMutationVariables = Exact<{
  input: CreateDashboardItemInput;
}>;

export type CreateDashboardItemMutation = {
  __typename?: 'Mutation';
  createDashboardItem:
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string};
};

export type UpdateDashboardItemsOrderMutationVariables = Exact<{
  input: UpdateDashboardItemsOrderInput;
}>;

export type UpdateDashboardItemsOrderMutation = {
  __typename?: 'Mutation';
  updateDashboardItemsOrder: Array<
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
  >;
};

export type DashboardDescriptionTextareaFragment = {
  __typename?: 'Dashboard';
  id: string;
  description?: string | null;
};

export type UpdateDashboardDescriptionMutationVariables = Exact<{
  input: UpdateDashboardInput;
}>;

export type UpdateDashboardDescriptionMutation = {
  __typename?: 'Mutation';
  updateDashboard: {
    __typename?: 'Dashboard';
    id: string;
    description?: string | null;
  };
};

export type DashboardDrawerProjectSelectFragment = {
  __typename?: 'Organization';
  id: string;
  projects: Array<
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
        indexWorkers: Array<{__typename?: 'IndexWorker'; id: string}>;
      }
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
        searchAnalytics?: {
          __typename?: 'SearchAnalytics';
          id: string;
          latestDataDate?: Date | null;
        } | null;
      }
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
      }
  >;
};

export type DashboardDrawerProjectSelectIndexWorkerProjectFragment = {
  __typename?: 'IndexWorkerProject';
  indexWorkers: Array<{__typename?: 'IndexWorker'; id: string}>;
};

export type DashboardDrawerProjectSelectSearchAnalyticsProjectFragment = {
  __typename?: 'SearchAnalyticsProject';
  searchAnalytics?: {
    __typename?: 'SearchAnalytics';
    id: string;
    latestDataDate?: Date | null;
  } | null;
};

export type DashboardIndexWorkerSelectFragment = {
  __typename?: 'IndexWorkerProject';
  id: string;
  indexWorkers: Array<{
    __typename?: 'IndexWorker';
    id: string;
    name: string;
    color: string;
  }>;
};

export type DashboardNameInputFragment = {
  __typename?: 'Dashboard';
  id: string;
  name: string;
};

export type UpdateDashboardNameMutationVariables = Exact<{
  input: UpdateDashboardInput;
}>;

export type UpdateDashboardNameMutation = {
  __typename?: 'Mutation';
  updateDashboard: {__typename?: 'Dashboard'; id: string; name: string};
};

export type DashboardSaViewSelectFragment = {
  __typename?: 'SearchAnalyticsProject';
  id: string;
  searchAnalytics?: {
    __typename?: 'SearchAnalytics';
    id: string;
    views: Array<{
      __typename?: 'SearchAnalyticsView';
      id: string;
      name: string;
    }>;
  } | null;
};

export type DeleteDashboardMutationVariables = Exact<{
  input: DeleteDashboardInput;
}>;

export type DeleteDashboardMutation = {
  __typename?: 'Mutation';
  deleteDashboard: {__typename?: 'DeleteDashboardPayload'; id: string};
};

export type GettingStartedOrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  doneGettingStartedTours: Array<GettingStartedTour>;
};

export type MarkGettingStartedTourAsDoneMutationVariables = Exact<{
  input: MarkGettingStartedTourAsDoneInput;
}>;

export type MarkGettingStartedTourAsDoneMutation = {
  __typename?: 'Mutation';
  markGettingStartedTourAsDone: {
    __typename?: 'Organization';
    id: string;
    doneGettingStartedTours: Array<GettingStartedTour>;
  };
};

export type IwDetailApiQuotaAlertFragment = {
  __typename?: 'IndexWorker';
  id: string;
  property:
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        property: string;
        serviceAccount: {
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        };
        alerts: Array<{
          __typename?: 'SearchConsolePropertyAlert';
          totalUrlCount: number;
          from: {__typename?: 'DayAndTime'; day: Day; time: string};
          to: {__typename?: 'DayAndTime'; day: Day; time: string};
          indexWorkers: Array<{
            __typename?: 'IndexWorker';
            id: string;
            name: string;
            color: string;
            urls?: Array<string> | null;
            urlType: IndexWorkerUrlType;
            samplingCount: number;
            organization: {
              __typename?: 'Organization';
              id: string;
              name: string;
            };
          }>;
        }>;
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        property: string;
        user: {__typename?: 'User'; id: string; email: string};
        alerts: Array<{
          __typename?: 'SearchConsolePropertyAlert';
          totalUrlCount: number;
          from: {__typename?: 'DayAndTime'; day: Day; time: string};
          to: {__typename?: 'DayAndTime'; day: Day; time: string};
          indexWorkers: Array<{
            __typename?: 'IndexWorker';
            id: string;
            name: string;
            color: string;
            urls?: Array<string> | null;
            urlType: IndexWorkerUrlType;
            samplingCount: number;
            organization: {
              __typename?: 'Organization';
              id: string;
              name: string;
            };
          }>;
        }>;
      };
};

export type IndexWorkerHeadingFragment = {
  __typename?: 'IndexWorker';
  id: string;
  name: string;
  color: string;
  note: string;
  schedule?: string | null;
  urlType: IndexWorkerUrlType;
  rssFeed?: string | null;
  sitemap?: string | null;
  urls?: Array<string> | null;
  property:
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        property: string;
      }
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; property: string};
  urlGroup?: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  } | null;
  jobs: {
    __typename?: 'IndexWorkerJobConnection';
    edges: Array<{
      __typename?: 'IndexWorkerJobEdge';
      node: {
        __typename?: 'IndexWorkerJob';
        completedAt?: Date | null;
        id: string;
        tasks: {
          __typename?: 'IndexWorkerTaskConnection';
          edges: Array<{
            __typename?: 'IndexWorkerTaskEdge';
            node: {
              __typename?: 'IndexWorkerTask';
              url: {__typename?: 'IndexWorkerURL'; id: string};
            };
          }>;
        };
      };
    }>;
  };
};

export type IndexWorkerSummaryCardFragment = {
  __typename?: 'IndexWorker';
  id: string;
  name: string;
  color: string;
  note: string;
  schedule?: string | null;
  urlType: IndexWorkerUrlType;
  rssFeed?: string | null;
  sitemap?: string | null;
  urls?: Array<string> | null;
  samplingCount: number;
  property:
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        property: string;
      }
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; property: string};
  urlGroup?: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  } | null;
  jobs: {
    __typename?: 'IndexWorkerJobConnection';
    edges: Array<{
      __typename?: 'IndexWorkerJobEdge';
      node: {
        __typename?: 'IndexWorkerJob';
        completedAt?: Date | null;
        id: string;
        tasks: {
          __typename?: 'IndexWorkerTaskConnection';
          edges: Array<{
            __typename?: 'IndexWorkerTaskEdge';
            node: {
              __typename?: 'IndexWorkerTask';
              url: {__typename?: 'IndexWorkerURL'; id: string};
            };
          }>;
        };
      };
    }>;
  };
};

export type IndexWorkerChartQueryVariables = Exact<{
  indexWorkerId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  range?: InputMaybe<TimeRangeInput>;
}>;

export type IndexWorkerChartQuery = {
  __typename?: 'Query';
  indexWorker?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {
        __typename?: 'IndexWorker';
        id: string;
        jobs: {
          __typename?: 'IndexWorkerJobConnection';
          edges: Array<{
            __typename?: 'IndexWorkerJobEdge';
            node: {
              __typename?: 'IndexWorkerJob';
              id: string;
              completedAt?: Date | null;
              result?: {
                __typename?: 'IndexWorkerResult';
                crawledCount: number;
                indexedCount: number;
                canonicalIndexedCount: number;
                totalCount: number;
                crawledRate: number;
                indexedRate: number;
                canonicalIndexedRate: number;
                sevenDaysMovingAverageCrawledRate: number;
                sevenDaysMovingAverageIndexedRate: number;
                sevenDaysMovingAverageCanonicalIndexedRate: number;
                twentyEightDaysMovingAverageCrawledRate: number;
                twentyEightDaysMovingAverageIndexedRate: number;
                twentyEightDaysMovingAverageCanonicalIndexedRate: number;
              } | null;
            };
          }>;
        };
      }
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type IndexWorkerChartFragment = {
  __typename?: 'IndexWorker';
  jobs: {
    __typename?: 'IndexWorkerJobConnection';
    edges: Array<{
      __typename?: 'IndexWorkerJobEdge';
      node: {
        __typename?: 'IndexWorkerJob';
        id: string;
        completedAt?: Date | null;
        result?: {
          __typename?: 'IndexWorkerResult';
          crawledCount: number;
          indexedCount: number;
          canonicalIndexedCount: number;
          totalCount: number;
          crawledRate: number;
          indexedRate: number;
          canonicalIndexedRate: number;
          sevenDaysMovingAverageCrawledRate: number;
          sevenDaysMovingAverageIndexedRate: number;
          sevenDaysMovingAverageCanonicalIndexedRate: number;
          twentyEightDaysMovingAverageCrawledRate: number;
          twentyEightDaysMovingAverageIndexedRate: number;
          twentyEightDaysMovingAverageCanonicalIndexedRate: number;
        } | null;
      };
    }>;
  };
};

export type UrlsTableFragment = {
  __typename?: 'IndexWorker';
  id: string;
  name: string;
  latestJob: {
    __typename?: 'IndexWorkerJobConnection';
    edges: Array<{
      __typename?: 'IndexWorkerJobEdge';
      node: {
        __typename?: 'IndexWorkerJob';
        id: string;
        failureURLs: Array<{
          __typename?: 'IndexWorkerErrorURL';
          url: string;
          error: string;
        }>;
        tasks: {
          __typename?: 'IndexWorkerTaskConnection';
          edges: Array<{
            __typename?: 'IndexWorkerTaskEdge';
            node: {
              __typename?: 'IndexWorkerTask';
              crawled: boolean;
              indexed: boolean;
              createdAt?: Date | null;
              url: {__typename?: 'IndexWorkerURL'; id: string; url: string};
              result?: {
                __typename?: 'IndexWorkerTaskResult';
                coverageState: string;
                crawledAs: string;
                forceSendFields: Array<string>;
                googleCanonical: string;
                indexingState: string;
                lastCrawlTime: string;
                nullFields: Array<string>;
                pageFetchState: string;
                refferingUrls: Array<string>;
                robotsTxtState: string;
                sitemap: Array<string>;
                userCanonical: string;
              } | null;
            };
          }>;
        };
      };
    }>;
  };
};

export type StatusReportCardFragment = {
  __typename?: 'IndexWorker';
  id: string;
  latestJob: {
    __typename?: 'IndexWorkerJobConnection';
    edges: Array<{
      __typename?: 'IndexWorkerJobEdge';
      node: {
        __typename?: 'IndexWorkerJob';
        id: string;
        failureURLs: Array<{
          __typename?: 'IndexWorkerErrorURL';
          url: string;
          error: string;
        }>;
        tasks: {
          __typename?: 'IndexWorkerTaskConnection';
          edges: Array<{
            __typename?: 'IndexWorkerTaskEdge';
            node: {
              __typename?: 'IndexWorkerTask';
              crawled: boolean;
              indexed: boolean;
              createdAt?: Date | null;
              url: {__typename?: 'IndexWorkerURL'; id: string; url: string};
              result?: {
                __typename?: 'IndexWorkerTaskResult';
                coverageState: string;
              } | null;
            };
          }>;
        };
      };
    }>;
  };
};

export type CreateIndexWorkerMutationVariables = Exact<{
  input?: InputMaybe<CreateIndexWorkerInput>;
}>;

export type CreateIndexWorkerMutation = {
  __typename?: 'Mutation';
  createIndexWorker: {__typename?: 'IndexWorker'; id: string};
};

export type UpdateIndexWorkerMutationVariables = Exact<{
  input: UpdateIndexWorkerInput;
}>;

export type UpdateIndexWorkerMutation = {
  __typename?: 'Mutation';
  updateIndexWorker: {__typename?: 'IndexWorker'; id: string};
};

export type RemoveScheduleFromIndexWorkerMutationVariables = Exact<{
  input: RemoveScheduleFromIndexWorkerInput;
}>;

export type RemoveScheduleFromIndexWorkerMutation = {
  __typename?: 'Mutation';
  removeScheduleFromIndexWorker: {__typename?: 'IndexWorker'; id: string};
};

export type IndexWorkerFormDialogFragment = {
  __typename?: 'IndexWorker';
  id: string;
  name: string;
  note: string;
  urlType: IndexWorkerUrlType;
  propertyID: string;
  rssFeed?: string | null;
  rssFeedSamplingPeriod?: number | null;
  samplingCount: number;
  sitemap?: string | null;
  color: string;
  urls?: Array<string> | null;
  schedule?: string | null;
  ignoreURLParameters: Array<string>;
  urlGroup?: {
    __typename?: 'URLGroup';
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      pattern: string;
      type: UrlGroupPatternType;
    } | null>;
  } | null;
};

export type SuspendIndexWorkerModalFragment = {
  __typename?: 'IndexWorker';
  id: string;
  name: string;
  color: string;
  schedule?: string | null;
};

export type SuspendIndexWorkerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SuspendIndexWorkerMutation = {
  __typename?: 'Mutation';
  removeScheduleFromIndexWorker: {
    __typename?: 'IndexWorker';
    id: string;
    schedule?: string | null;
  };
};

export type IndexWorkerPageQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  indexWorkerId: Scalars['ID']['input'];
  range: TimeRangeInput;
}>;

export type IndexWorkerPageQuery = {
  __typename?: 'Query';
  organization?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; name: string; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
  project?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; name: string; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
  indexWorker?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {
        __typename?: 'IndexWorker';
        id: string;
        name: string;
        color: string;
        note: string;
        schedule?: string | null;
        urlType: IndexWorkerUrlType;
        rssFeed?: string | null;
        sitemap?: string | null;
        urls?: Array<string> | null;
        propertyID: string;
        rssFeedSamplingPeriod?: number | null;
        samplingCount: number;
        ignoreURLParameters: Array<string>;
        property:
          | {
              __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
              id: string;
              property: string;
              serviceAccount: {
                __typename?: 'ServiceAccount';
                id: string;
                email: string;
              };
              alerts: Array<{
                __typename?: 'SearchConsolePropertyAlert';
                totalUrlCount: number;
                from: {__typename?: 'DayAndTime'; day: Day; time: string};
                to: {__typename?: 'DayAndTime'; day: Day; time: string};
                indexWorkers: Array<{
                  __typename?: 'IndexWorker';
                  id: string;
                  name: string;
                  color: string;
                  urls?: Array<string> | null;
                  urlType: IndexWorkerUrlType;
                  samplingCount: number;
                  organization: {
                    __typename?: 'Organization';
                    id: string;
                    name: string;
                  };
                }>;
              }>;
            }
          | {
              __typename?: 'RegisteredSearchConsoleUserProperty';
              id: string;
              property: string;
              user: {__typename?: 'User'; id: string; email: string};
              alerts: Array<{
                __typename?: 'SearchConsolePropertyAlert';
                totalUrlCount: number;
                from: {__typename?: 'DayAndTime'; day: Day; time: string};
                to: {__typename?: 'DayAndTime'; day: Day; time: string};
                indexWorkers: Array<{
                  __typename?: 'IndexWorker';
                  id: string;
                  name: string;
                  color: string;
                  urls?: Array<string> | null;
                  urlType: IndexWorkerUrlType;
                  samplingCount: number;
                  organization: {
                    __typename?: 'Organization';
                    id: string;
                    name: string;
                  };
                }>;
              }>;
            };
        urlGroup?: {
          __typename?: 'URLGroup';
          id: string;
          name: string;
          op: LogicalOperator;
          patterns: Array<{
            __typename?: 'URLGroupPattern';
            type: UrlGroupPatternType;
            pattern: string;
          } | null>;
        } | null;
        jobs: {
          __typename?: 'IndexWorkerJobConnection';
          edges: Array<{
            __typename?: 'IndexWorkerJobEdge';
            node: {
              __typename?: 'IndexWorkerJob';
              completedAt?: Date | null;
              id: string;
              tasks: {
                __typename?: 'IndexWorkerTaskConnection';
                edges: Array<{
                  __typename?: 'IndexWorkerTaskEdge';
                  node: {
                    __typename?: 'IndexWorkerTask';
                    url: {__typename?: 'IndexWorkerURL'; id: string};
                  };
                }>;
              };
            };
          }>;
        };
        latestJob: {
          __typename?: 'IndexWorkerJobConnection';
          edges: Array<{
            __typename?: 'IndexWorkerJobEdge';
            node: {
              __typename?: 'IndexWorkerJob';
              id: string;
              failureURLs: Array<{
                __typename?: 'IndexWorkerErrorURL';
                url: string;
                error: string;
              }>;
              tasks: {
                __typename?: 'IndexWorkerTaskConnection';
                edges: Array<{
                  __typename?: 'IndexWorkerTaskEdge';
                  node: {
                    __typename?: 'IndexWorkerTask';
                    crawled: boolean;
                    indexed: boolean;
                    createdAt?: Date | null;
                    url: {
                      __typename?: 'IndexWorkerURL';
                      id: string;
                      url: string;
                    };
                    result?: {
                      __typename?: 'IndexWorkerTaskResult';
                      coverageState: string;
                      crawledAs: string;
                      forceSendFields: Array<string>;
                      googleCanonical: string;
                      indexingState: string;
                      lastCrawlTime: string;
                      nullFields: Array<string>;
                      pageFetchState: string;
                      refferingUrls: Array<string>;
                      robotsTxtState: string;
                      sitemap: Array<string>;
                      userCanonical: string;
                    } | null;
                  };
                }>;
              };
            };
          }>;
        };
      }
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type LatestDataDateQueryVariables = Exact<{
  indexWorkerId: Scalars['ID']['input'];
}>;

export type LatestDataDateQuery = {
  __typename?: 'Query';
  indexWorker?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {
        __typename?: 'IndexWorker';
        id: string;
        jobs: {
          __typename?: 'IndexWorkerJobConnection';
          edges: Array<{
            __typename?: 'IndexWorkerJobEdge';
            node: {
              __typename?: 'IndexWorkerJob';
              completedAt?: Date | null;
              id: string;
            };
          }>;
        };
      }
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type IndexWorkersPageQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type IndexWorkersPageQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {
        __typename?: 'IndexWorkerProject';
        color: string;
        name: string;
        description?: string | null;
        id: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          color: string;
          note: string;
          schedule?: string | null;
          urlType: IndexWorkerUrlType;
          rssFeed?: string | null;
          sitemap?: string | null;
          urls?: Array<string> | null;
          samplingCount: number;
          property:
            | {
                __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                property: string;
              }
            | {
                __typename?: 'RegisteredSearchConsoleUserProperty';
                property: string;
              };
          urlGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
            op: LogicalOperator;
            patterns: Array<{
              __typename?: 'URLGroupPattern';
              type: UrlGroupPatternType;
              pattern: string;
            } | null>;
          } | null;
          jobs: {
            __typename?: 'IndexWorkerJobConnection';
            edges: Array<{
              __typename?: 'IndexWorkerJobEdge';
              node: {
                __typename?: 'IndexWorkerJob';
                completedAt?: Date | null;
                id: string;
                tasks: {
                  __typename?: 'IndexWorkerTaskConnection';
                  edges: Array<{
                    __typename?: 'IndexWorkerTaskEdge';
                    node: {
                      __typename?: 'IndexWorkerTask';
                      url: {__typename?: 'IndexWorkerURL'; id: string};
                    };
                  }>;
                };
              };
            }>;
          };
        }>;
      }
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
  viewer?: {
    __typename?: 'User';
    id: string;
    organizations: Array<{
      __typename?: 'UserOrganization';
      role: UserOrganizationRole;
      organization: {__typename?: 'Organization'; id: string; name: string};
    }>;
  } | null;
};

export type UpdateNotificationSettingMutationVariables = Exact<{
  input: UpdateNotificationSettingInput;
}>;

export type UpdateNotificationSettingMutation = {
  __typename?: 'Mutation';
  updateNotificationSetting: {
    __typename?: 'Organization';
    id: string;
    name: string;
    doNotNotifyTypes: Array<NotificationType>;
  };
};

export type NotificationSettingsPageQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type NotificationSettingsPageQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        doNotNotifyTypes: Array<NotificationType>;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
  viewer?: {
    __typename?: 'User';
    id: string;
    organizations: Array<{
      __typename?: 'UserOrganization';
      role: UserOrganizationRole;
      organizationID: string;
    }>;
  } | null;
};

export type NotificationSettingFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  doNotNotifyTypes: Array<NotificationType>;
};

export type OnboardingRouteQueryVariables = Exact<{[key: string]: never}>;

export type OnboardingRouteQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    organizations: Array<{
      __typename?: 'UserOrganization';
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            orgCapacity: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type CreateContractInfoQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type CreateContractInfoQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          options: Array<ContractOption>;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
          } | null;
        } | null;
      }
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type RegisterPropertyInfoQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type RegisterPropertyInfoQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    email: string;
    availableProperties: Array<{
      __typename?: 'SearchConsoleProperty';
      property: string;
    }>;
  } | null;
  org?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {
        __typename?: 'Organization';
        id: string;
        contract?: {
          __typename?: 'Contract';
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
          } | null;
        } | null;
      }
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type PermissionContent_OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  users: Array<{
    __typename?: 'UserOrganization';
    userID: string;
    role: UserOrganizationRole;
    status: UserOrganizationStatus;
    user: {__typename?: 'User'; id: string; email: string};
  }>;
  serviceAccounts: Array<{
    __typename?: 'ServiceAccount';
    id: string;
    email: string;
  }>;
  contract?: {
    __typename?: 'Contract';
    id: string;
    options: Array<ContractOption>;
    name: string;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      userCapacity: number;
      guestCapacity: number;
    } | null;
  } | null;
};

export type PermissionContent_ViewerFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  isAdmin: boolean;
  isCustomerSupport: boolean;
};

export type PermissionPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type PermissionPageQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    email: string;
    isAdmin: boolean;
    isCustomerSupport: boolean;
  } | null;
  organization?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        users: Array<{
          __typename?: 'UserOrganization';
          userID: string;
          role: UserOrganizationRole;
          status: UserOrganizationStatus;
          user: {__typename?: 'User'; id: string; email: string};
        }>;
        serviceAccounts: Array<{
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        }>;
        contract?: {
          __typename?: 'Contract';
          id: string;
          options: Array<ContractOption>;
          name: string;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            userCapacity: number;
            guestCapacity: number;
          } | null;
        } | null;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type PermissionTable_OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  users: Array<{
    __typename?: 'UserOrganization';
    userID: string;
    role: UserOrganizationRole;
    status: UserOrganizationStatus;
    user: {__typename?: 'User'; id: string; email: string};
  }>;
  serviceAccounts: Array<{
    __typename?: 'ServiceAccount';
    id: string;
    email: string;
  }>;
  contract?: {
    __typename?: 'Contract';
    id: string;
    options: Array<ContractOption>;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      userCapacity: number;
      guestCapacity: number;
    } | null;
  } | null;
};

export type PermissionTable_ViewerFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  isAdmin: boolean;
  isCustomerSupport: boolean;
};

export type AddUserButton_OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  users: Array<{
    __typename?: 'UserOrganization';
    userID: string;
    role: UserOrganizationRole;
  }>;
  contract?: {
    __typename?: 'Contract';
    id: string;
    name: string;
    options: Array<ContractOption>;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      userCapacity: number;
      guestCapacity: number;
    } | null;
  } | null;
};

export type AddUserDialog_OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  users: Array<{
    __typename?: 'UserOrganization';
    userID: string;
    role: UserOrganizationRole;
  }>;
  contract?: {
    __typename?: 'Contract';
    id: string;
    name: string;
    options: Array<ContractOption>;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      userCapacity: number;
      guestCapacity: number;
    } | null;
  } | null;
};

export type AddUserDialogConfirm_OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
};

export type SendInvitationMutationVariables = Exact<{
  input: SendInvitationInput;
}>;

export type SendInvitationMutation = {
  __typename?: 'Mutation';
  sendInvitation: {
    __typename?: 'UserOrganization';
    userID: string;
    organizationID: string;
    role: UserOrganizationRole;
    status: UserOrganizationStatus;
    user: {__typename?: 'User'; id: string; email: string};
  };
};

export type AddUserDialogForm_OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  users: Array<{
    __typename?: 'UserOrganization';
    userID: string;
    role: UserOrganizationRole;
  }>;
  contract?: {
    __typename?: 'Contract';
    id: string;
    name: string;
    options: Array<ContractOption>;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      userCapacity: number;
      guestCapacity: number;
    } | null;
  } | null;
};

export type AddUserForm_OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  users: Array<{
    __typename?: 'UserOrganization';
    userID: string;
    role: UserOrganizationRole;
    status: UserOrganizationStatus;
    user: {__typename?: 'User'; id: string; email: string};
  }>;
  contract?: {
    __typename?: 'Contract';
    id: string;
    name: string;
    options: Array<ContractOption>;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      userCapacity: number;
      guestCapacity: number;
    } | null;
  } | null;
};

export type SearchAnalyticsTopPageQueryVariables = Exact<{
  organizationID: Scalars['ID']['input'];
  projectID: Scalars['ID']['input'];
}>;

export type SearchAnalyticsTopPageQuery = {
  __typename?: 'Query';
  viewer?: {__typename?: 'User'; id: string} | null;
  organization?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {
        __typename?: 'Organization';
        name: string;
        serviceAccountEmail: string;
        id: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
            uaProjectCapacity: number;
          } | null;
        } | null;
      }
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
  project?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {
        __typename?: 'SearchAnalyticsProject';
        name: string;
        color: string;
        description?: string | null;
        id: string;
        searchAnalytics?: {
          __typename?: 'SearchAnalytics';
          id: string;
          datasetName: string;
          googleCloudProjectID: string;
          manageType: SearchAnalyticsManageType;
          latestDataDate?: Date | null;
          views: Array<{
            __typename?: 'SearchAnalyticsView';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type SearchAnalyticsChatFragmentFragment = {
  __typename?: 'SearchAnalyticsChat';
  id: string;
  messageSuggestions: Array<string>;
  createdAt: Date;
  updatedAt: Date;
  dataToDate: Date;
  messages: Array<{
    __typename?: 'SearchAnalyticsChatMessage';
    role: SearchAnalyticsChatMessageRole;
    content: string;
    createdAt: Date;
  }>;
};

export type SearchAnalyticsChatsQueryVariables = Exact<{
  searchAnalyticsId: Scalars['ID']['input'];
}>;

export type SearchAnalyticsChatsQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {
        __typename?: 'SearchAnalytics';
        id: string;
        chats: Array<{
          __typename?: 'SearchAnalyticsChat';
          id: string;
          messageSuggestions: Array<string>;
          createdAt: Date;
          updatedAt: Date;
          dataToDate: Date;
          messages: Array<{
            __typename?: 'SearchAnalyticsChatMessage';
            role: SearchAnalyticsChatMessageRole;
            content: string;
            createdAt: Date;
          }>;
        }>;
      }
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type CreateSearchAnalyticsChatMutationVariables = Exact<{
  input: CreateSearchAnalyticsChatInput;
}>;

export type CreateSearchAnalyticsChatMutation = {
  __typename?: 'Mutation';
  createSearchAnalyticsChat: {
    __typename?: 'SearchAnalyticsChat';
    id: string;
    messageSuggestions: Array<string>;
    createdAt: Date;
    updatedAt: Date;
    dataToDate: Date;
    messages: Array<{
      __typename?: 'SearchAnalyticsChatMessage';
      role: SearchAnalyticsChatMessageRole;
      content: string;
      createdAt: Date;
    }>;
  };
};

export type ReplySearchAnalyticsChatMutationVariables = Exact<{
  input: ReplySearchAnalyticsChatInput;
}>;

export type ReplySearchAnalyticsChatMutation = {
  __typename?: 'Mutation';
  replySearchAnalyticsChat: {
    __typename?: 'SearchAnalyticsChat';
    id: string;
    messageSuggestions: Array<string>;
    createdAt: Date;
    updatedAt: Date;
    dataToDate: Date;
    messages: Array<{
      __typename?: 'SearchAnalyticsChatMessage';
      role: SearchAnalyticsChatMessageRole;
      content: string;
      createdAt: Date;
    }>;
  };
};

export type DuplicateSearchAnalytcisProjectMutationVariables = Exact<{
  input?: InputMaybe<DuplicateSearchAnalyticsProjectInput>;
}>;

export type DuplicateSearchAnalytcisProjectMutation = {
  __typename?: 'Mutation';
  duplicateSearchAnalyticsProject: {
    __typename?: 'SearchAnalyticsProject';
    id: string;
    name: string;
    color: string;
    searchAnalytics?: {
      __typename?: 'SearchAnalytics';
      id: string;
      views: Array<{
        __typename?: 'SearchAnalyticsView';
        id: string;
        name: string;
      }>;
    } | null;
  };
};

export type MakeSearchAnalyticsExportDataMutationVariables = Exact<{
  input: MakeSearchAnalyticsExportDataInput;
}>;

export type MakeSearchAnalyticsExportDataMutation = {
  __typename?: 'Mutation';
  exportData: Array<{
    __typename?: 'SearchAnalyticsExportData';
    urls?: Array<string> | null;
    expires?: Date | null;
    status: SearchAnalyticsExportStatus;
    exportedAt: Date;
    exportType: SearchAnalyticsExportType;
  }>;
};

export type MakeSearchAnalyticsComparingExportDataMutationVariables = Exact<{
  input: MakeSearchAnalyticsComparingExportDataInput;
}>;

export type MakeSearchAnalyticsComparingExportDataMutation = {
  __typename?: 'Mutation';
  exportData: Array<{
    __typename?: 'SearchAnalyticsExportData';
    urls?: Array<string> | null;
    expires?: Date | null;
    status: SearchAnalyticsExportStatus;
    exportedAt: Date;
    exportType: SearchAnalyticsExportType;
  }>;
};

export type SuggestQueryGroupDialog_QueryUrlGroupsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SuggestQueryGroupDialog_QueryUrlGroupsQuery = {
  __typename?: 'Query';
  searchAnalytics?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {
        __typename?: 'SearchAnalytics';
        id: string;
        queryGroups: Array<{
          __typename?: 'QueryGroup';
          id: string;
          name: string;
          op: LogicalOperator;
          patterns: Array<{
            __typename?: 'QueryGroupPattern';
            type: QueryGroupPatternType;
            pattern: string;
          } | null>;
        }>;
        urlGroups: Array<{
          __typename?: 'URLGroup';
          id: string;
          name: string;
          op: LogicalOperator;
          patterns: Array<{
            __typename?: 'URLGroupPattern';
            type: UrlGroupPatternType;
            pattern: string;
          } | null>;
        }>;
      }
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SuggestQueryGroupDialog_RecommendedQueryGroupsQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
    queryGroupId?: InputMaybe<Scalars['ID']['input']>;
    urlGroupId?: InputMaybe<Scalars['ID']['input']>;
  }>;

export type SuggestQueryGroupDialog_RecommendedQueryGroupsQuery = {
  __typename?: 'Query';
  searchAnalytics?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {
        __typename?: 'SearchAnalytics';
        recommendedQueryGroups: Array<{
          __typename?: 'RecommendedQueryGroup';
          name: string;
          op: LogicalOperator;
          patterns: Array<{
            __typename?: 'QueryGroupPattern';
            type: QueryGroupPatternType;
            pattern: string;
          } | null>;
        }>;
      }
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SuggestQueryGroupDialog_CreateQueryGroupMutationVariables = Exact<{
  input: CreateQueryGroupInput;
}>;

export type SuggestQueryGroupDialog_CreateQueryGroupMutation = {
  __typename?: 'Mutation';
  createQueryGroup: {
    __typename?: 'QueryGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'QueryGroupPattern';
      type: QueryGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type ImportUrlGroupButtonQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type ImportUrlGroupButtonQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        projects: Array<
          | {__typename?: 'IndexWorkerProject'}
          | {__typename?: 'SearchAnalyticsProject'}
          | {
              __typename?: 'UserAnalyticsProject';
              id: string;
              name: string;
              userAnalytics?: {
                __typename?: 'UserAnalytics';
                id: string;
                urlGroups?: Array<{
                  __typename?: 'URLGroup';
                  id: string;
                  name: string;
                  op: LogicalOperator;
                  patterns: Array<{
                    __typename?: 'URLGroupPattern';
                    type: UrlGroupPatternType;
                    pattern: string;
                  } | null>;
                }> | null;
              } | null;
            }
        >;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SuggestUrlGroupQueryVariables = Exact<{
  searchAnalyticsId: Scalars['ID']['input'];
}>;

export type SuggestUrlGroupQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {
        __typename?: 'SearchAnalytics';
        id: string;
        recommendedURLGroups: Array<{
          __typename?: 'RecommendedURLGroup';
          op: LogicalOperator;
          coverage: number;
          patterns: Array<{
            __typename?: 'URLGroupPattern';
            type: UrlGroupPatternType;
            pattern: string;
          } | null>;
        }>;
      }
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SuggestUrlGroupDialogFragment = {
  __typename?: 'SearchAnalytics';
  id: string;
  recommendedURLGroups: Array<{
    __typename?: 'RecommendedURLGroup';
    op: LogicalOperator;
    coverage: number;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  }>;
};

export type CreateSuggestedUrlGroupMutationVariables = Exact<{
  input: CreateUrlGroupInput;
}>;

export type CreateSuggestedUrlGroupMutation = {
  __typename?: 'Mutation';
  createURLGroup: {__typename?: 'URLGroup'; id: string};
};

export type SelfManagedFragment = {
  __typename?: 'Organization';
  id: string;
  serviceAccountEmail: string;
};

export type UpdateSearchAnalyticsSpreadsheetIdMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  spreadsheetId: Scalars['String']['input'];
}>;

export type UpdateSearchAnalyticsSpreadsheetIdMutation = {
  __typename?: 'Mutation';
  updateSearchAnalytics: {
    __typename?: 'SearchAnalytics';
    id: string;
    spreadsheetID?: string | null;
  };
};

export type UpdateViewSpreadsheetIdMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  spreadsheetId: Scalars['String']['input'];
}>;

export type UpdateViewSpreadsheetIdMutation = {
  __typename?: 'Mutation';
  updateSearchAnalyticsViewSpreadsheetId: {
    __typename?: 'SearchAnalyticsView';
    id: string;
    spreadsheetID?: string | null;
  };
};

export type DeletePropertyButton_UnregisterPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePropertyButton_UnregisterPropertyMutation = {
  __typename?: 'Mutation';
  unregisterProperty: {
    __typename?: 'UnregisteredSearchConsolePropertyPayload';
    id: string;
  };
};

export type DeletePropertyButton_IndexWorkersQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type DeletePropertyButton_IndexWorkersQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
        }>;
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
        }>;
      }
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type SessionDetailFragment = {
  __typename?: 'SessionDetail';
  gaSessionId: string;
  userPseudoId: string;
  events: Array<{
    __typename?: 'SessionEvent';
    eventName: string;
    eventTimestamp: Date;
    pageReferrer?: string | null;
    pageLocation?: string | null;
    pageLocationWithoutParameter?: string | null;
    pageTitle?: string | null;
    contentGroup?: string | null;
    eventParams: Array<{
      __typename?: 'SessionEventParam';
      key: string;
      value: string;
    }>;
  }>;
};

export type GetRandomSessionQueryVariables = Exact<{
  userAnalyticsId: Scalars['ID']['input'];
}>;

export type GetRandomSessionQuery = {
  __typename?: 'Query';
  getRandomSession?: {
    __typename?: 'SessionIDs';
    gaSessionId: number;
    userPseudoId: string;
  } | null;
};

export type SessionExplorerPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
}>;

export type SessionExplorerPageQuery = {
  __typename?: 'Query';
  project?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        userAnalytics?: {__typename?: 'UserAnalytics'; id: string} | null;
      }
    | {__typename?: 'UserAnalyticsView'}
    | null;
  org?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'; id: string; name: string}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type GetSessionQueryVariables = Exact<{
  userAnalyticsId: Scalars['ID']['input'];
  gaSessionId: Scalars['Int']['input'];
  userPseudoId: Scalars['String']['input'];
}>;

export type GetSessionQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {
        __typename?: 'UserAnalytics';
        id: string;
        getSessionDetail?: {
          __typename?: 'SessionDetail';
          gaSessionId: string;
          userPseudoId: string;
          gaSessionNumber: number;
          sessionDuration: number;
          sessionStartTime: Date;
          sessionEndTime: Date;
          userFirstTouchTimestamp?: Date | null;
          source?: string | null;
          medium?: string | null;
          campaign?: string | null;
          firstVisitSource?: string | null;
          firstVisitMedium?: string | null;
          firstVisitCampaign?: string | null;
          pageReferrer?: string | null;
          geography: {
            __typename?: 'Geography';
            continent?: string | null;
            country?: string | null;
            region?: string | null;
            city?: string | null;
          };
          device: {
            __typename?: 'Device';
            category?: string | null;
            brand?: string | null;
            model?: string | null;
            os?: string | null;
            osVer?: string | null;
            language?: string | null;
            browser?: string | null;
            browserVer?: string | null;
          };
          events: Array<{
            __typename?: 'SessionEvent';
            eventName: string;
            eventTimestamp: Date;
            pageReferrer?: string | null;
            pageLocation?: string | null;
            pageLocationWithoutParameter?: string | null;
            pageTitle?: string | null;
            contentGroup?: string | null;
            eventParams: Array<{
              __typename?: 'SessionEventParam';
              key: string;
              value: string;
            }>;
          }>;
        } | null;
      }
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SessionSummaryFragment = {
  __typename?: 'SessionDetail';
  gaSessionId: string;
  userPseudoId: string;
  gaSessionNumber: number;
  sessionDuration: number;
  sessionStartTime: Date;
  sessionEndTime: Date;
  userFirstTouchTimestamp?: Date | null;
  source?: string | null;
  medium?: string | null;
  campaign?: string | null;
  firstVisitSource?: string | null;
  firstVisitMedium?: string | null;
  firstVisitCampaign?: string | null;
  pageReferrer?: string | null;
  geography: {
    __typename?: 'Geography';
    continent?: string | null;
    country?: string | null;
    region?: string | null;
    city?: string | null;
  };
  device: {
    __typename?: 'Device';
    category?: string | null;
    brand?: string | null;
    model?: string | null;
    os?: string | null;
    osVer?: string | null;
    language?: string | null;
    browser?: string | null;
    browserVer?: string | null;
  };
};

export type UserSessionsQueryVariables = Exact<{
  userAnalyticsID: Scalars['ID']['input'];
  userPseudoID: Scalars['String']['input'];
  dateRange: TimeRangeInput;
}>;

export type UserSessionsQuery = {
  __typename?: 'Query';
  userAnalytics?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {
        __typename?: 'UserAnalytics';
        userSessions?: Array<{
          __typename?: 'Session';
          gaSessionId: number;
          userPseudoId: string;
          sessionStartTime: Date;
          sessionDuration: number;
          pageLocationCount: number;
          source?: string | null;
          medium?: string | null;
          campaign?: string | null;
          pageReferrer?: string | null;
          device: {
            __typename?: 'Device';
            category?: string | null;
            os?: string | null;
            browser?: string | null;
          };
          geography: {
            __typename?: 'Geography';
            country?: string | null;
            region?: string | null;
            city?: string | null;
          };
        }> | null;
      }
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type UserAnalyticsBreadcrumbsOrgFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
};

export type UserAnalyticsBreadcrumbsProjectFragment = {
  __typename?: 'UserAnalyticsProject';
  id: string;
  name: string;
};

export type SessionsCard_SessionFragment = {
  __typename?: 'Session';
  gaSessionId: number;
  userPseudoId: string;
  sessionStartTime: Date;
  sessionDuration: number;
  pageLocationCount: number;
  source?: string | null;
  medium?: string | null;
  campaign?: string | null;
  pageReferrer?: string | null;
  device: {
    __typename?: 'Device';
    category?: string | null;
    os?: string | null;
    browser?: string | null;
  };
  geography: {
    __typename?: 'Geography';
    country?: string | null;
    region?: string | null;
    city?: string | null;
  };
};

export type SessionsCardWrapper_SessionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dateRange: TimeRangeInput;
  os: Array<OsCategory> | OsCategory;
  devices: Array<DeviceCategory> | DeviceCategory;
  browsers: Array<BrowserCategory> | BrowserCategory;
  sources: Array<Scalars['String']['input']> | Scalars['String']['input'];
  mediums: Array<Scalars['String']['input']> | Scalars['String']['input'];
  campaigns: Array<Scalars['String']['input']> | Scalars['String']['input'];
  campaignsFilterType: Array<UserAnalyticsFilter> | UserAnalyticsFilter;
  events: Array<Scalars['String']['input']> | Scalars['String']['input'];
  firstPageLocation?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationURLGroupIDs?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocation?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationURLGroupIDs?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  sessionsLimit?: InputMaybe<Scalars['Int']['input']>;
  sessionsOffset?: InputMaybe<Scalars['Int']['input']>;
  defaultChannelGroup?: InputMaybe<
    Array<DefaultChannelGroup> | DefaultChannelGroup
  >;
  pageLocationCount?: InputMaybe<IntRangeInput>;
}>;

export type SessionsCardWrapper_SessionsQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {
        __typename?: 'UserAnalytics';
        id: string;
        sessions?: Array<{
          __typename?: 'Session';
          gaSessionId: number;
          userPseudoId: string;
          sessionStartTime: Date;
          sessionDuration: number;
          pageLocationCount: number;
          source?: string | null;
          medium?: string | null;
          campaign?: string | null;
          pageReferrer?: string | null;
          device: {
            __typename?: 'Device';
            category?: string | null;
            os?: string | null;
            browser?: string | null;
          };
          geography: {
            __typename?: 'Geography';
            country?: string | null;
            region?: string | null;
            city?: string | null;
          };
        }> | null;
      }
    | {__typename?: 'UserAnalyticsProject'}
    | {
        __typename?: 'UserAnalyticsView';
        id: string;
        sessions?: Array<{
          __typename?: 'Session';
          gaSessionId: number;
          userPseudoId: string;
          sessionStartTime: Date;
          sessionDuration: number;
          pageLocationCount: number;
          source?: string | null;
          medium?: string | null;
          campaign?: string | null;
          pageReferrer?: string | null;
          device: {
            __typename?: 'Device';
            category?: string | null;
            os?: string | null;
            browser?: string | null;
          };
          geography: {
            __typename?: 'Geography';
            country?: string | null;
            region?: string | null;
            city?: string | null;
          };
        }> | null;
      }
    | null;
};

export type UserAnalyticsDetail_ProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  organizationID: Scalars['ID']['input'];
}>;

export type UserAnalyticsDetail_ProjectQuery = {
  __typename?: 'Query';
  project?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {
        __typename?: 'UserAnalyticsProject';
        name: string;
        userAnalytics?: {
          __typename?: 'UserAnalytics';
          id: string;
          keyEvents?: Array<string> | null;
          eventNames?: Array<string> | null;
          urlGroups?: Array<{
            __typename?: 'URLGroup';
            id: string;
            name: string;
          }> | null;
        } | null;
      }
    | {__typename?: 'UserAnalyticsView'}
    | null;
  viewer?: {
    __typename?: 'User';
    id: string;
    isAdmin: boolean;
    isCustomerSupport: boolean;
    organizations: Array<{
      __typename?: 'UserOrganization';
      role: UserOrganizationRole;
      organizationID: string;
      organization: {__typename?: 'Organization'; id: string; name: string};
    }>;
  } | null;
};

export type UserAnalyticsDetail_UserAnalyticsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dateRange: TimeRangeInput;
  os: Array<OsCategory> | OsCategory;
  devices: Array<DeviceCategory> | DeviceCategory;
  browsers: Array<BrowserCategory> | BrowserCategory;
  sources: Array<Scalars['String']['input']> | Scalars['String']['input'];
  mediums: Array<Scalars['String']['input']> | Scalars['String']['input'];
  campaigns: Array<Scalars['String']['input']> | Scalars['String']['input'];
  campaignsFilterType: Array<UserAnalyticsFilter> | UserAnalyticsFilter;
  events: Array<Scalars['String']['input']> | Scalars['String']['input'];
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageLocation?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationURLGroupIDs?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocation?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationURLGroupIDs?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  sessionPerformanceGroupBy?: InputMaybe<SessionPerformanceGroupBy>;
  sessionPerformanceDetailLimit?: InputMaybe<Scalars['Int']['input']>;
  sessionPerformanceDetailOffset?: InputMaybe<Scalars['Int']['input']>;
  sessionPerformanceDetailGroupBy?: InputMaybe<
    Array<SessionPerformanceDetailGroupBy> | SessionPerformanceDetailGroupBy
  >;
  sessionPerformanceDetailOrderBy?: InputMaybe<SessionPerformanceDetailOrderByInput>;
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  defaultChannelGroup?: InputMaybe<
    Array<DefaultChannelGroup> | DefaultChannelGroup
  >;
  pageLocationCount?: InputMaybe<IntRangeInput>;
}>;

export type UserAnalyticsDetail_UserAnalyticsQuery = {
  __typename?: 'Query';
  userAnalytics?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {
        __typename?: 'UserAnalytics';
        id: string;
        urlGroups?: Array<{
          __typename?: 'URLGroup';
          id: string;
          name: string;
        }> | null;
        sessionPerformance?: Array<{
          __typename?: 'SessionPerformance';
          date: Date;
          sessionCount: number;
          uniqueUserCount: number;
          conversionRate: number;
          conversionCount: number;
          surfRate: number;
          surfCount: number;
          engagementRate: number;
          engagementCount: number;
          sourceMedium?: {
            __typename?: 'TrafficSourceMedium';
            source: string;
            medium: string;
          } | null;
          firstPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
          secondPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
        }> | null;
        sessionPerformanceDetail?: Array<{
          __typename?: 'SessionPerformanceDetail';
          date?: Date | null;
          firstPageLocation?: string | null;
          secondPageLocation?: string | null;
          device?: string | null;
          os?: string | null;
          browser?: string | null;
          campaign?: string | null;
          defaultChannelGroup?: string | null;
          sessionCount: number;
          uniqueUserCount: number;
          conversionRate: number;
          surfRate: number;
          avgSessionDuration: number;
          engagementRate: number;
          engagementCount: number;
          avgPageLocationCount: number;
          firstPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
          secondPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
          sourceMedium?: {
            __typename?: 'TrafficSourceMedium';
            source: string;
            medium: string;
          } | null;
        }> | null;
      }
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type UserAnalyticsDetail_ViewConditionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserAnalyticsDetail_ViewConditionsQuery = {
  __typename?: 'Query';
  viewConditions?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {
        __typename?: 'UserAnalyticsView';
        id: string;
        os?: Array<OsCategory> | null;
        devices?: Array<DeviceCategory> | null;
        browsers?: Array<BrowserCategory> | null;
        sources?: Array<string> | null;
        mediums?: Array<string> | null;
        campaigns?: Array<string> | null;
        campaignsFilterType?: Array<UserAnalyticsFilter> | null;
        events?: Array<string> | null;
        name: string;
        description?: string | null;
        firstPageTitle?: string | null;
        firstPageTitleFilterType?: UserAnalyticsFilter | null;
        secondPageTitle?: string | null;
        secondPageTitleFilterType?: UserAnalyticsFilter | null;
        firstPageLocationURLGroupIDs?: Array<string> | null;
        secondPageLocationURLGroupIDs?: Array<string> | null;
        firstPageLocationFilterType?: UserAnalyticsFilter | null;
        secondPageLocationFilterType?: UserAnalyticsFilter | null;
        firstPageLocationURL?: string | null;
        secondPageLocationURL?: string | null;
        isSessionWithNavigation?: boolean | null;
        defaultChannelGroup?: Array<DefaultChannelGroup> | null;
        detailGroupBy: Array<SessionPerformanceDetailGroupBy>;
        defaultDateRange: {
          __typename?: 'UserAnalyticsDataDate';
          type: UserAnalyticsDataDateType;
          dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
        };
        detailOrderBy: {
          __typename?: 'SessionPerformanceDetailOrderBy';
          field: SessionPerformanceDetailOrderByField;
          direction: OrderDirection;
        };
        pageLocationCount?: {
          __typename?: 'IntRange';
          min?: number | null;
          max?: number | null;
        } | null;
      }
    | null;
};

export type UserAnalyticsDetail_ViewDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dateRange: TimeRangeInput;
  sessionPerformanceGroupBy?: InputMaybe<SessionPerformanceGroupBy>;
  sessionPerformanceDetailGroupBy?: InputMaybe<
    Array<SessionPerformanceDetailGroupBy> | SessionPerformanceDetailGroupBy
  >;
  sessionPerformanceDetailLimit?: InputMaybe<Scalars['Int']['input']>;
  sessionPerformanceDetailOffset?: InputMaybe<Scalars['Int']['input']>;
  sessionPerformanceDetailOrderBy?: InputMaybe<SessionPerformanceDetailOrderByInput>;
}>;

export type UserAnalyticsDetail_ViewDetailQuery = {
  __typename?: 'Query';
  viewDetail?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {
        __typename?: 'UserAnalyticsView';
        id: string;
        name: string;
        description?: string | null;
        os?: Array<OsCategory> | null;
        devices?: Array<DeviceCategory> | null;
        browsers?: Array<BrowserCategory> | null;
        sources?: Array<string> | null;
        mediums?: Array<string> | null;
        campaigns?: Array<string> | null;
        campaignsFilterType?: Array<UserAnalyticsFilter> | null;
        events?: Array<string> | null;
        firstPageTitle?: string | null;
        firstPageTitleFilterType?: UserAnalyticsFilter | null;
        firstPageLocationURLGroupIDs?: Array<string> | null;
        firstPageLocationURL?: string | null;
        firstPageLocationFilterType?: UserAnalyticsFilter | null;
        secondPageTitle?: string | null;
        secondPageTitleFilterType?: UserAnalyticsFilter | null;
        secondPageLocationURLGroupIDs?: Array<string> | null;
        secondPageLocationURL?: string | null;
        secondPageLocationFilterType?: UserAnalyticsFilter | null;
        isSessionWithNavigation?: boolean | null;
        defaultChannelGroup?: Array<DefaultChannelGroup> | null;
        defaultDateRange: {
          __typename?: 'UserAnalyticsDataDate';
          type: UserAnalyticsDataDateType;
          dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
        };
        sessionPerformance?: Array<{
          __typename?: 'SessionPerformance';
          date: Date;
          sessionCount: number;
          uniqueUserCount: number;
          conversionRate: number;
          conversionCount: number;
          surfRate: number;
          surfCount: number;
          engagementRate: number;
          engagementCount: number;
          sourceMedium?: {
            __typename?: 'TrafficSourceMedium';
            source: string;
            medium: string;
          } | null;
          firstPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
          secondPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
        }> | null;
        sessionPerformanceDetail?: Array<{
          __typename?: 'SessionPerformanceDetail';
          date?: Date | null;
          firstPageLocation?: string | null;
          secondPageLocation?: string | null;
          device?: string | null;
          os?: string | null;
          browser?: string | null;
          campaign?: string | null;
          defaultChannelGroup?: string | null;
          sessionCount: number;
          uniqueUserCount: number;
          conversionRate: number;
          surfRate: number;
          avgSessionDuration: number;
          engagementRate: number;
          engagementCount: number;
          avgPageLocationCount: number;
          firstPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
          secondPageLocationURLGroup?: {
            __typename?: 'URLGroup';
            id: string;
            name: string;
          } | null;
          sourceMedium?: {
            __typename?: 'TrafficSourceMedium';
            source: string;
            medium: string;
          } | null;
        }> | null;
      }
    | null;
};

export type UrlGroupIdsDropdown_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
};

export type DetailHeader_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
};

export type UseFilterChips_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
};

export type MetricDetailsCard_UserAnalyticsFragment = {
  __typename?: 'UserAnalytics';
  sessionPerformanceDetail?: Array<{
    __typename?: 'SessionPerformanceDetail';
    date?: Date | null;
    firstPageLocation?: string | null;
    secondPageLocation?: string | null;
    device?: string | null;
    os?: string | null;
    browser?: string | null;
    campaign?: string | null;
    defaultChannelGroup?: string | null;
    sessionCount: number;
    uniqueUserCount: number;
    conversionRate: number;
    surfRate: number;
    avgSessionDuration: number;
    engagementRate: number;
    engagementCount: number;
    avgPageLocationCount: number;
    firstPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
    secondPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
    sourceMedium?: {
      __typename?: 'TrafficSourceMedium';
      source: string;
      medium: string;
    } | null;
  }> | null;
};

export type MetricDetailsCard_UserAnalyticsViewFragment = {
  __typename?: 'UserAnalyticsView';
  sessionPerformanceDetail?: Array<{
    __typename?: 'SessionPerformanceDetail';
    date?: Date | null;
    firstPageLocation?: string | null;
    secondPageLocation?: string | null;
    device?: string | null;
    os?: string | null;
    browser?: string | null;
    campaign?: string | null;
    defaultChannelGroup?: string | null;
    sessionCount: number;
    uniqueUserCount: number;
    conversionRate: number;
    surfRate: number;
    avgSessionDuration: number;
    engagementRate: number;
    engagementCount: number;
    avgPageLocationCount: number;
    firstPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
    secondPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
    sourceMedium?: {
      __typename?: 'TrafficSourceMedium';
      source: string;
      medium: string;
    } | null;
  }> | null;
};

export type TableRow_SessionPerformanceDetailFragment = {
  __typename?: 'SessionPerformanceDetail';
  date?: Date | null;
  firstPageLocation?: string | null;
  secondPageLocation?: string | null;
  device?: string | null;
  os?: string | null;
  browser?: string | null;
  campaign?: string | null;
  defaultChannelGroup?: string | null;
  sessionCount: number;
  uniqueUserCount: number;
  conversionRate: number;
  surfRate: number;
  avgSessionDuration: number;
  engagementRate: number;
  engagementCount: number;
  avgPageLocationCount: number;
  firstPageLocationURLGroup?: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
  } | null;
  secondPageLocationURLGroup?: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
  } | null;
  sourceMedium?: {
    __typename?: 'TrafficSourceMedium';
    source: string;
    medium: string;
  } | null;
};

export type UaExportButton_ExportSessionPerformanceDetailMutationVariables =
  Exact<{
    input: ExportSessionPerformanceDetailInput;
  }>;

export type UaExportButton_ExportSessionPerformanceDetailMutation = {
  __typename?: 'Mutation';
  exportSessionPerformanceDetail: {
    __typename?: 'UserAnalyticsExportData';
    urls?: Array<string> | null;
    expires?: Date | null;
    status: UserAnalyticsExportStatus;
    exportType: UserAnalyticsExportType;
    exportedAt: Date;
  };
};

export type UserAnalyticsListQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  organizationID: Scalars['ID']['input'];
  dateRange: TimeRangeInput;
  os?: InputMaybe<Array<OsCategory> | OsCategory>;
  devices?: InputMaybe<Array<DeviceCategory> | DeviceCategory>;
  browsers?: InputMaybe<Array<BrowserCategory> | BrowserCategory>;
  sources?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  mediums?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  campaigns?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  campaignsFilterType?: InputMaybe<
    Array<UserAnalyticsFilter> | UserAnalyticsFilter
  >;
  events?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  firstPageTitle?: InputMaybe<Scalars['String']['input']>;
  firstPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageTitle?: InputMaybe<Scalars['String']['input']>;
  secondPageTitleFilterType?: InputMaybe<UserAnalyticsFilter>;
  firstPageLocation?: InputMaybe<Scalars['String']['input']>;
  firstPageLocationURLGroupIDs?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  firstPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  secondPageLocation?: InputMaybe<Scalars['String']['input']>;
  secondPageLocationURLGroupIDs?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  secondPageLocationFilterType?: InputMaybe<UserAnalyticsFilter>;
  sessionPerformanceGroupBy?: InputMaybe<SessionPerformanceGroupBy>;
  isSessionWithNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  defaultChannelGroup?: InputMaybe<
    Array<DefaultChannelGroup> | DefaultChannelGroup
  >;
  pageLocationCount?: InputMaybe<IntRangeInput>;
}>;

export type UserAnalyticsListQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        description?: string | null;
        color: string;
        userAnalytics?: {
          __typename?: 'UserAnalytics';
          id: string;
          googleCloudProjectID: string;
          datasetName: string;
          keyEvents?: Array<string> | null;
          eventNames?: Array<string> | null;
          views?: Array<{
            __typename?: 'UserAnalyticsView';
            id: string;
            name: string;
            description?: string | null;
            os?: Array<OsCategory> | null;
            devices?: Array<DeviceCategory> | null;
            browsers?: Array<BrowserCategory> | null;
            sources?: Array<string> | null;
            mediums?: Array<string> | null;
            campaigns?: Array<string> | null;
            campaignsFilterType?: Array<UserAnalyticsFilter> | null;
            events?: Array<string> | null;
            firstPageTitle?: string | null;
            firstPageTitleFilterType?: UserAnalyticsFilter | null;
            firstPageLocationURLGroupIDs?: Array<string> | null;
            firstPageLocationURL?: string | null;
            firstPageLocationFilterType?: UserAnalyticsFilter | null;
            secondPageTitle?: string | null;
            secondPageTitleFilterType?: UserAnalyticsFilter | null;
            secondPageLocationURLGroupIDs?: Array<string> | null;
            secondPageLocationURL?: string | null;
            secondPageLocationFilterType?: UserAnalyticsFilter | null;
            isSessionWithNavigation?: boolean | null;
            defaultChannelGroup?: Array<DefaultChannelGroup> | null;
            defaultDateRange: {
              __typename?: 'UserAnalyticsDataDate';
              type: UserAnalyticsDataDateType;
              dateRange?: {
                __typename?: 'TimeRange';
                start: Date;
                end: Date;
              } | null;
            };
            sessionPerformance?: Array<{
              __typename?: 'SessionPerformance';
              date: Date;
              sessionCount: number;
              uniqueUserCount: number;
              conversionRate: number;
              conversionCount: number;
              surfRate: number;
              surfCount: number;
              engagementRate: number;
              engagementCount: number;
              sourceMedium?: {
                __typename?: 'TrafficSourceMedium';
                source: string;
                medium: string;
              } | null;
              firstPageLocationURLGroup?: {
                __typename?: 'URLGroup';
                id: string;
                name: string;
              } | null;
              secondPageLocationURLGroup?: {
                __typename?: 'URLGroup';
                id: string;
                name: string;
              } | null;
            }> | null;
          }> | null;
          sessionPerformance?: Array<{
            __typename?: 'SessionPerformance';
            date: Date;
            sessionCount: number;
            uniqueUserCount: number;
            conversionRate: number;
            conversionCount: number;
            surfRate: number;
            surfCount: number;
            engagementRate: number;
            engagementCount: number;
            sourceMedium?: {
              __typename?: 'TrafficSourceMedium';
              source: string;
              medium: string;
            } | null;
            firstPageLocationURLGroup?: {
              __typename?: 'URLGroup';
              id: string;
              name: string;
            } | null;
            secondPageLocationURLGroup?: {
              __typename?: 'URLGroup';
              id: string;
              name: string;
            } | null;
          }> | null;
        } | null;
      }
    | {__typename?: 'UserAnalyticsView'}
    | null;
  viewer?: {
    __typename?: 'User';
    id: string;
    isAdmin: boolean;
    isCustomerSupport: boolean;
    organizations: Array<{
      __typename?: 'UserOrganization';
      role: UserOrganizationRole;
      organizationID: string;
    }>;
  } | null;
  organization?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'; id: string; serviceAccountEmail: string}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type UpdateUserAnalyticsKeyEventsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  keyEvents: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type UpdateUserAnalyticsKeyEventsMutation = {
  __typename?: 'Mutation';
  updateUserAnalytics: {
    __typename?: 'UserAnalytics';
    id: string;
    keyEvents?: Array<string> | null;
    eventNames?: Array<string> | null;
  };
};

export type ConversionEventsDialog_UserAnalyticsFragment = {
  __typename?: 'UserAnalytics';
  id: string;
  keyEvents?: Array<string> | null;
  eventNames?: Array<string> | null;
};

export type DeleteUserAnalyticsProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;

export type DeleteUserAnalyticsProjectMutation = {
  __typename?: 'Mutation';
  deleteProject: {__typename?: 'DeleteProjectPayload'; id: string};
};

export type ProjectMenuFragment = {
  __typename?: 'UserAnalyticsProject';
  id: string;
  name: string;
  color: string;
  description?: string | null;
  userAnalytics?: {
    __typename?: 'UserAnalytics';
    id: string;
    googleCloudProjectID: string;
    datasetName: string;
    keyEvents?: Array<string> | null;
    eventNames?: Array<string> | null;
  } | null;
};

export type UpdateUserAnalyticsProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateUserAnalyticsProjectMutation = {
  __typename?: 'Mutation';
  updateProject:
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
      }
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
      }
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
      };
};

export type UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationVariables =
  Exact<{
    input: UpdateUserAnalyticsInput;
  }>;

export type UpdateUserAnalyticsModal_UpdateUserAnalyticsMutation = {
  __typename?: 'Mutation';
  updateUserAnalytics: {
    __typename?: 'UserAnalytics';
    id: string;
    googleCloudProjectID: string;
    datasetName: string;
  };
};

export type CreateUserAnalyticsMutationVariables = Exact<{
  input: CreateUserAnalyticsInput;
}>;

export type CreateUserAnalyticsMutation = {
  __typename?: 'Mutation';
  createUserAnalytics: {
    __typename?: 'UserAnalytics';
    id: string;
    googleCloudProjectID: string;
    datasetName: string;
    keyEvents?: Array<string> | null;
    eventNames?: Array<string> | null;
  };
};

export type ViewList_UserAnalyticsFragment = {
  __typename?: 'UserAnalytics';
  id: string;
  googleCloudProjectID: string;
  datasetName: string;
  keyEvents?: Array<string> | null;
  views?: Array<{
    __typename?: 'UserAnalyticsView';
    id: string;
    name: string;
    description?: string | null;
    os?: Array<OsCategory> | null;
    devices?: Array<DeviceCategory> | null;
    browsers?: Array<BrowserCategory> | null;
    sources?: Array<string> | null;
    mediums?: Array<string> | null;
    campaigns?: Array<string> | null;
    campaignsFilterType?: Array<UserAnalyticsFilter> | null;
    events?: Array<string> | null;
    firstPageTitle?: string | null;
    firstPageTitleFilterType?: UserAnalyticsFilter | null;
    firstPageLocationURLGroupIDs?: Array<string> | null;
    firstPageLocationURL?: string | null;
    firstPageLocationFilterType?: UserAnalyticsFilter | null;
    secondPageTitle?: string | null;
    secondPageTitleFilterType?: UserAnalyticsFilter | null;
    secondPageLocationURLGroupIDs?: Array<string> | null;
    secondPageLocationURL?: string | null;
    secondPageLocationFilterType?: UserAnalyticsFilter | null;
    isSessionWithNavigation?: boolean | null;
    defaultChannelGroup?: Array<DefaultChannelGroup> | null;
    defaultDateRange: {
      __typename?: 'UserAnalyticsDataDate';
      type: UserAnalyticsDataDateType;
      dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
    };
    sessionPerformance?: Array<{
      __typename?: 'SessionPerformance';
      date: Date;
      sessionCount: number;
      uniqueUserCount: number;
      conversionRate: number;
      conversionCount: number;
      surfRate: number;
      surfCount: number;
      engagementRate: number;
      engagementCount: number;
      sourceMedium?: {
        __typename?: 'TrafficSourceMedium';
        source: string;
        medium: string;
      } | null;
      firstPageLocationURLGroup?: {
        __typename?: 'URLGroup';
        id: string;
        name: string;
      } | null;
      secondPageLocationURLGroup?: {
        __typename?: 'URLGroup';
        id: string;
        name: string;
      } | null;
    }> | null;
  }> | null;
  sessionPerformance?: Array<{
    __typename?: 'SessionPerformance';
    date: Date;
    sessionCount: number;
    uniqueUserCount: number;
    conversionRate: number;
    conversionCount: number;
    surfRate: number;
    surfCount: number;
    engagementRate: number;
    engagementCount: number;
    sourceMedium?: {
      __typename?: 'TrafficSourceMedium';
      source: string;
      medium: string;
    } | null;
    firstPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
    secondPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
  }> | null;
};

export type ViewList_UpdateUserAnalyticsViewOrderMutationVariables = Exact<{
  input: UpdateUserAnalyticsViewOrderInput;
}>;

export type ViewList_UpdateUserAnalyticsViewOrderMutation = {
  __typename?: 'Mutation';
  updateUserAnalyticsViewOrder: {
    __typename?: 'UserAnalytics';
    views?: Array<{__typename?: 'UserAnalyticsView'; id: string}> | null;
  };
};

export type Chart_SessionPerformanceFragment = {
  __typename?: 'SessionPerformance';
  date: Date;
  sessionCount: number;
  uniqueUserCount: number;
  conversionRate: number;
  conversionCount: number;
  surfRate: number;
  surfCount: number;
  engagementRate: number;
  engagementCount: number;
  sourceMedium?: {
    __typename?: 'TrafficSourceMedium';
    source: string;
    medium: string;
  } | null;
  firstPageLocationURLGroup?: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
  } | null;
  secondPageLocationURLGroup?: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
  } | null;
};

export type Chart_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
};

export type ChartCard_UserAnalyticsFragment = {
  __typename?: 'UserAnalytics';
  id: string;
  sessionPerformance?: Array<{
    __typename?: 'SessionPerformance';
    date: Date;
    sessionCount: number;
    uniqueUserCount: number;
    conversionRate: number;
    conversionCount: number;
    surfRate: number;
    surfCount: number;
    engagementRate: number;
    engagementCount: number;
    sourceMedium?: {
      __typename?: 'TrafficSourceMedium';
      source: string;
      medium: string;
    } | null;
    firstPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
    secondPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
  }> | null;
};

export type ChartCard_UserAnalyticsViewFragment = {
  __typename?: 'UserAnalyticsView';
  id: string;
  name: string;
  description?: string | null;
  os?: Array<OsCategory> | null;
  devices?: Array<DeviceCategory> | null;
  browsers?: Array<BrowserCategory> | null;
  sources?: Array<string> | null;
  mediums?: Array<string> | null;
  campaigns?: Array<string> | null;
  campaignsFilterType?: Array<UserAnalyticsFilter> | null;
  events?: Array<string> | null;
  firstPageTitle?: string | null;
  firstPageTitleFilterType?: UserAnalyticsFilter | null;
  firstPageLocationURLGroupIDs?: Array<string> | null;
  firstPageLocationURL?: string | null;
  firstPageLocationFilterType?: UserAnalyticsFilter | null;
  secondPageTitle?: string | null;
  secondPageTitleFilterType?: UserAnalyticsFilter | null;
  secondPageLocationURLGroupIDs?: Array<string> | null;
  secondPageLocationURL?: string | null;
  secondPageLocationFilterType?: UserAnalyticsFilter | null;
  isSessionWithNavigation?: boolean | null;
  defaultChannelGroup?: Array<DefaultChannelGroup> | null;
  defaultDateRange: {
    __typename?: 'UserAnalyticsDataDate';
    type: UserAnalyticsDataDateType;
    dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
  };
  sessionPerformance?: Array<{
    __typename?: 'SessionPerformance';
    date: Date;
    sessionCount: number;
    uniqueUserCount: number;
    conversionRate: number;
    conversionCount: number;
    surfRate: number;
    surfCount: number;
    engagementRate: number;
    engagementCount: number;
    sourceMedium?: {
      __typename?: 'TrafficSourceMedium';
      source: string;
      medium: string;
    } | null;
    firstPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
    secondPageLocationURLGroup?: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
    } | null;
  }> | null;
};

export type DeleteViewDialog_DeleteViewMutationVariables = Exact<{
  input: DeleteUserAnalyticsViewInput;
}>;

export type DeleteViewDialog_DeleteViewMutation = {
  __typename?: 'Mutation';
  deleteUserAnalyticsView: {
    __typename?: 'DeleteUserAnalyticsViewPayload';
    id: string;
  };
};

export type CreateViewDialog_CreateUserAnalyticsViewMutationVariables = Exact<{
  input: CreateUserAnalyticsViewInput;
}>;

export type CreateViewDialog_CreateUserAnalyticsViewMutation = {
  __typename?: 'Mutation';
  createUserAnalyticsView: {
    __typename?: 'UserAnalyticsView';
    id: string;
    os?: Array<OsCategory> | null;
    devices?: Array<DeviceCategory> | null;
    browsers?: Array<BrowserCategory> | null;
    sources?: Array<string> | null;
    mediums?: Array<string> | null;
    campaigns?: Array<string> | null;
    detailGroupBy: Array<SessionPerformanceDetailGroupBy>;
    name: string;
    description?: string | null;
    firstPageLocationURLGroupIDs?: Array<string> | null;
    firstPageLocationURL?: string | null;
    firstPageLocationFilterType?: UserAnalyticsFilter | null;
    firstPageTitle?: string | null;
    firstPageTitleFilterType?: UserAnalyticsFilter | null;
    secondPageLocationURLGroupIDs?: Array<string> | null;
    secondPageLocationURL?: string | null;
    secondPageLocationFilterType?: UserAnalyticsFilter | null;
    secondPageTitle?: string | null;
    secondPageTitleFilterType?: UserAnalyticsFilter | null;
    isSessionWithNavigation?: boolean | null;
    defaultChannelGroup?: Array<DefaultChannelGroup> | null;
    detailOrderBy: {
      __typename?: 'SessionPerformanceDetailOrderBy';
      field: SessionPerformanceDetailOrderByField;
      direction: OrderDirection;
    };
    defaultDateRange: {
      __typename?: 'UserAnalyticsDataDate';
      type: UserAnalyticsDataDateType;
      dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
    };
  };
};

export type UpdateViewDialog_UpdateUserAnalyticsViewMutationVariables = Exact<{
  input: UpdateUserAnalyticsViewInput;
}>;

export type UpdateViewDialog_UpdateUserAnalyticsViewMutation = {
  __typename?: 'Mutation';
  updateUserAnalyticsView: {
    __typename?: 'UserAnalyticsView';
    id: string;
    os?: Array<OsCategory> | null;
    devices?: Array<DeviceCategory> | null;
    browsers?: Array<BrowserCategory> | null;
    sources?: Array<string> | null;
    mediums?: Array<string> | null;
    campaigns?: Array<string> | null;
    detailGroupBy: Array<SessionPerformanceDetailGroupBy>;
    name: string;
    description?: string | null;
    firstPageLocationURLGroupIDs?: Array<string> | null;
    firstPageLocationURL?: string | null;
    firstPageLocationFilterType?: UserAnalyticsFilter | null;
    firstPageTitle?: string | null;
    firstPageTitleFilterType?: UserAnalyticsFilter | null;
    secondPageLocationURLGroupIDs?: Array<string> | null;
    secondPageLocationURL?: string | null;
    secondPageLocationFilterType?: UserAnalyticsFilter | null;
    secondPageTitle?: string | null;
    secondPageTitleFilterType?: UserAnalyticsFilter | null;
    isSessionWithNavigation?: boolean | null;
    defaultChannelGroup?: Array<DefaultChannelGroup> | null;
    detailOrderBy: {
      __typename?: 'SessionPerformanceDetailOrderBy';
      field: SessionPerformanceDetailOrderByField;
      direction: OrderDirection;
    };
    defaultDateRange: {
      __typename?: 'UserAnalyticsDataDate';
      type: UserAnalyticsDataDateType;
      dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
    };
  };
};

export type UpdateViewDialog_UserAnalyticsViewFragment = {
  __typename?: 'UserAnalyticsView';
  id: string;
  os?: Array<OsCategory> | null;
  devices?: Array<DeviceCategory> | null;
  browsers?: Array<BrowserCategory> | null;
  sources?: Array<string> | null;
  mediums?: Array<string> | null;
  campaigns?: Array<string> | null;
  detailGroupBy: Array<SessionPerformanceDetailGroupBy>;
  name: string;
  description?: string | null;
  firstPageLocationURLGroupIDs?: Array<string> | null;
  firstPageLocationURL?: string | null;
  firstPageLocationFilterType?: UserAnalyticsFilter | null;
  firstPageTitle?: string | null;
  firstPageTitleFilterType?: UserAnalyticsFilter | null;
  secondPageLocationURLGroupIDs?: Array<string> | null;
  secondPageLocationURL?: string | null;
  secondPageLocationFilterType?: UserAnalyticsFilter | null;
  secondPageTitle?: string | null;
  secondPageTitleFilterType?: UserAnalyticsFilter | null;
  isSessionWithNavigation?: boolean | null;
  defaultChannelGroup?: Array<DefaultChannelGroup> | null;
  detailOrderBy: {
    __typename?: 'SessionPerformanceDetailOrderBy';
    field: SessionPerformanceDetailOrderByField;
    direction: OrderDirection;
  };
  defaultDateRange: {
    __typename?: 'UserAnalyticsDataDate';
    type: UserAnalyticsDataDateType;
    dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
  };
};

export type UrlGroup_UserAnalyticsProjectQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
}>;

export type UrlGroup_UserAnalyticsProjectQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    isAdmin: boolean;
    isCustomerSupport: boolean;
    organizations: Array<{
      __typename?: 'UserOrganization';
      organizationID: string;
      role: UserOrganizationRole;
    }>;
  } | null;
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        userAnalytics?: {
          __typename?: 'UserAnalytics';
          id: string;
          urlGroups?: Array<{
            __typename?: 'URLGroup';
            id: string;
            name: string;
            op: LogicalOperator;
            patterns: Array<{
              __typename?: 'URLGroupPattern';
              type: UrlGroupPatternType;
              pattern: string;
            } | null>;
          }> | null;
        } | null;
      }
    | {__typename?: 'UserAnalyticsView'}
    | null;
  organization?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        projects: Array<
          | {__typename?: 'IndexWorkerProject'}
          | {
              __typename?: 'SearchAnalyticsProject';
              id: string;
              name: string;
              searchAnalytics?: {
                __typename?: 'SearchAnalytics';
                id: string;
                urlGroups: Array<{
                  __typename?: 'URLGroup';
                  id: string;
                  name: string;
                  op: LogicalOperator;
                  patterns: Array<{
                    __typename?: 'URLGroupPattern';
                    type: UrlGroupPatternType;
                    pattern: string;
                  } | null>;
                }>;
              } | null;
            }
          | {
              __typename?: 'UserAnalyticsProject';
              id: string;
              name: string;
              userAnalytics?: {
                __typename?: 'UserAnalytics';
                id: string;
                urlGroups?: Array<{
                  __typename?: 'URLGroup';
                  id: string;
                  name: string;
                  op: LogicalOperator;
                  patterns: Array<{
                    __typename?: 'URLGroupPattern';
                    type: UrlGroupPatternType;
                    pattern: string;
                  } | null>;
                }> | null;
              } | null;
            }
        >;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type UrlGroupDialog_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
  op: LogicalOperator;
  patterns: Array<{
    __typename?: 'URLGroupPattern';
    type: UrlGroupPatternType;
    pattern: string;
  } | null>;
};

export type UrlGroupDialog_UrlGroupPatternFragment = {
  __typename?: 'URLGroupPattern';
  type: UrlGroupPatternType;
  pattern: string;
};

export type UrlGroupTable_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
  op: LogicalOperator;
  patterns: Array<{
    __typename?: 'URLGroupPattern';
    type: UrlGroupPatternType;
    pattern: string;
  } | null>;
};

export type UrlGroupTable_UpdateUrlGroupOrderMutationVariables = Exact<{
  input: UpdateUrlGroupOrderForUserAnalyticsInput;
}>;

export type UrlGroupTable_UpdateUrlGroupOrderMutation = {
  __typename?: 'Mutation';
  updateURLGroupOrderForUserAnalytics: Array<{
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  }>;
};

export type CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationVariables =
  Exact<{
    input: CreateUrUserAnalyticsUrlGroupInput;
  }>;

export type CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation = {
  __typename?: 'Mutation';
  createURLGroupForUserAnalytics: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationVariables =
  Exact<{
    input: DeleteUrlGroupForUserAnalyticsInput;
  }>;

export type DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation = {
  __typename?: 'Mutation';
  deleteURLGroupForUserAnalytics: {
    __typename?: 'DeleteURLGroupForUserAnalyticsPayload';
    id: string;
  };
};

export type DeleteUrlGroupModal_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
};

export type UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationVariables =
  Exact<{
    input: UpdateUrlGroupForUserAnalyticsInput;
  }>;

export type UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation = {
  __typename?: 'Mutation';
  updateURLGroupForUserAnalytics: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type UpdateUrlGroupDialog_UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
  op: LogicalOperator;
  patterns: Array<{
    __typename?: 'URLGroupPattern';
    type: UrlGroupPatternType;
    pattern: string;
  } | null>;
};

export type ContractFragment = {
  __typename?: 'Contract';
  id: string;
  name: string;
  type: ContractType;
  stripeSubscriptionStatus: StripeSubscriptionStatus;
  subscriptionPlan?: {
    __typename?: 'Plan';
    id: string;
    name: string;
    propertyCapacity: number;
    userCapacity: number;
    guestCapacity: number;
    orgCapacity: number;
    iwProjectCapacity: number;
    iwJobCapacity: number;
    scProjectCapacity: number;
    queryGroupCapacity: number;
    urlGroupCapacity: number;
    viewCapacity: number;
    uaProjectCapacity: number;
  } | null;
};

export type PlanFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  propertyCapacity: number;
  userCapacity: number;
  guestCapacity: number;
  orgCapacity: number;
  iwProjectCapacity: number;
  iwJobCapacity: number;
  scProjectCapacity: number;
  queryGroupCapacity: number;
  urlGroupCapacity: number;
  viewCapacity: number;
  uaProjectCapacity: number;
};

export type StripeCustomerPortalUrlQueryVariables = Exact<{
  contractId: Scalars['ID']['input'];
}>;

export type StripeCustomerPortalUrlQuery = {
  __typename?: 'Query';
  stripeCustomerPortalURL: string;
};

export type CreateContractMutationVariables = Exact<{
  input: CreateContractInput;
}>;

export type CreateContractMutation = {
  __typename?: 'Mutation';
  createContract: {
    __typename?: 'Contract';
    id: string;
    name: string;
    type: ContractType;
    stripeSubscriptionStatus: StripeSubscriptionStatus;
  };
};

export type UpdateContractMutationVariables = Exact<{
  input: UpdateContractInput;
}>;

export type UpdateContractMutation = {
  __typename?: 'Mutation';
  updateContract: {
    __typename?: 'Contract';
    id: string;
    name: string;
    type: ContractType;
    stripeSubscriptionStatus: StripeSubscriptionStatus;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      name: string;
      propertyCapacity: number;
      userCapacity: number;
      guestCapacity: number;
      orgCapacity: number;
      iwProjectCapacity: number;
      iwJobCapacity: number;
      scProjectCapacity: number;
      queryGroupCapacity: number;
      urlGroupCapacity: number;
      viewCapacity: number;
      uaProjectCapacity: number;
    } | null;
  };
};

export type AddOrganizationToContractMutationVariables = Exact<{
  input: AddOrganizationToContractInput;
}>;

export type AddOrganizationToContractMutation = {
  __typename?: 'Mutation';
  addOrganizationToContract: {
    __typename?: 'Contract';
    id: string;
    name: string;
    type: ContractType;
    stripeSubscriptionStatus: StripeSubscriptionStatus;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      name: string;
      propertyCapacity: number;
      userCapacity: number;
      guestCapacity: number;
      orgCapacity: number;
      iwProjectCapacity: number;
      iwJobCapacity: number;
      scProjectCapacity: number;
      queryGroupCapacity: number;
      urlGroupCapacity: number;
      viewCapacity: number;
      uaProjectCapacity: number;
    } | null;
  };
};

export type PageInfoFragment = {
  __typename?: 'PageInfo';
  endCursor?: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string | null;
};

export type ViewerQueryVariables = Exact<{[key: string]: never}>;

export type ViewerQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    preferredLanguage: string;
    isTutorialMovieViewed: boolean;
    id: string;
    name: string;
    picture: string;
    email: string;
    registeredProperties: Array<{
      __typename?: 'RegisteredSearchConsoleUserProperty';
      id: string;
      property: string;
      user: {__typename?: 'User'; id: string; name: string; email: string};
    }>;
    organizations: Array<{
      __typename?: 'UserOrganization';
      organizationID: string;
      role: UserOrganizationRole;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
            uaProjectCapacity: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type IndexWorkerFragment = {
  __typename?: 'IndexWorker';
  id: string;
  name: string;
  note: string;
  urlType: IndexWorkerUrlType;
  rssFeed?: string | null;
  rssFeedSamplingPeriod?: number | null;
  samplingCount: number;
  sitemap?: string | null;
  color: string;
  urls?: Array<string> | null;
  schedule?: string | null;
  ignoreURLParameters: Array<string>;
  property:
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        property: string;
        serviceAccount: {
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        };
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        property: string;
        user: {__typename?: 'User'; id: string; name: string; email: string};
      };
  urlGroup?: {
    __typename?: 'URLGroup';
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  } | null;
};

export type IndexWorkerJobFragment = {
  __typename?: 'IndexWorkerJob';
  id: string;
  completedAt?: Date | null;
  result?: {
    __typename?: 'IndexWorkerResult';
    crawledCount: number;
    indexedCount: number;
    canonicalIndexedCount: number;
    totalCount: number;
    crawledRate: number;
    indexedRate: number;
    canonicalIndexedRate: number;
    sevenDaysMovingAverageCrawledRate: number;
    sevenDaysMovingAverageIndexedRate: number;
    sevenDaysMovingAverageCanonicalIndexedRate: number;
    twentyEightDaysMovingAverageCrawledRate: number;
    twentyEightDaysMovingAverageIndexedRate: number;
    twentyEightDaysMovingAverageCanonicalIndexedRate: number;
  } | null;
};

export type IndexWorkerResultFragment = {
  __typename?: 'IndexWorkerResult';
  crawledCount: number;
  indexedCount: number;
  canonicalIndexedCount: number;
  totalCount: number;
  crawledRate: number;
  indexedRate: number;
  canonicalIndexedRate: number;
  sevenDaysMovingAverageCrawledRate: number;
  sevenDaysMovingAverageIndexedRate: number;
  sevenDaysMovingAverageCanonicalIndexedRate: number;
  twentyEightDaysMovingAverageCrawledRate: number;
  twentyEightDaysMovingAverageIndexedRate: number;
  twentyEightDaysMovingAverageCanonicalIndexedRate: number;
};

export type IndexWorkerTaskFragment = {
  __typename?: 'IndexWorkerTask';
  crawled: boolean;
  indexed: boolean;
  createdAt?: Date | null;
  url: {__typename?: 'IndexWorkerURL'; id: string; url: string};
  result?: {
    __typename?: 'IndexWorkerTaskResult';
    coverageState: string;
    crawledAs: string;
    forceSendFields: Array<string>;
    googleCanonical: string;
    indexingState: string;
    lastCrawlTime: string;
    nullFields: Array<string>;
    pageFetchState: string;
    refferingUrls: Array<string>;
    robotsTxtState: string;
    sitemap: Array<string>;
    userCanonical: string;
  } | null;
};

export type IndexWorkerTaskResultFragment = {
  __typename?: 'IndexWorkerTaskResult';
  coverageState: string;
  crawledAs: string;
  forceSendFields: Array<string>;
  googleCanonical: string;
  indexingState: string;
  lastCrawlTime: string;
  nullFields: Array<string>;
  pageFetchState: string;
  refferingUrls: Array<string>;
  robotsTxtState: string;
  sitemap: Array<string>;
  userCanonical: string;
};

export type IndexWorkerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IndexWorkerQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {
        __typename?: 'IndexWorker';
        id: string;
        name: string;
        note: string;
        urlType: IndexWorkerUrlType;
        rssFeed?: string | null;
        rssFeedSamplingPeriod?: number | null;
        samplingCount: number;
        sitemap?: string | null;
        color: string;
        urls?: Array<string> | null;
        schedule?: string | null;
        ignoreURLParameters: Array<string>;
        jobs: {
          __typename?: 'IndexWorkerJobConnection';
          edges: Array<{
            __typename?: 'IndexWorkerJobEdge';
            node: {__typename?: 'IndexWorkerJob'; id: string};
          }>;
        };
        property:
          | {
              __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
              id: string;
              property: string;
              serviceAccount: {
                __typename?: 'ServiceAccount';
                id: string;
                email: string;
              };
            }
          | {
              __typename?: 'RegisteredSearchConsoleUserProperty';
              id: string;
              property: string;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                email: string;
              };
            };
        urlGroup?: {
          __typename?: 'URLGroup';
          name: string;
          op: LogicalOperator;
          patterns: Array<{
            __typename?: 'URLGroupPattern';
            type: UrlGroupPatternType;
            pattern: string;
          } | null>;
        } | null;
      }
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type IndexWorkerLatestJobQueryVariables = Exact<{
  indexWorkerId: Scalars['ID']['input'];
  range?: InputMaybe<TimeRangeInput>;
}>;

export type IndexWorkerLatestJobQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {
        __typename?: 'IndexWorker';
        name: string;
        urlType: IndexWorkerUrlType;
        schedule?: string | null;
        id: string;
        jobs: {
          __typename?: 'IndexWorkerJobConnection';
          edges: Array<{
            __typename?: 'IndexWorkerJobEdge';
            node: {
              __typename?: 'IndexWorkerJob';
              id: string;
              completedAt?: Date | null;
              tasks: {
                __typename?: 'IndexWorkerTaskConnection';
                edges: Array<{
                  __typename?: 'IndexWorkerTaskEdge';
                  node: {
                    __typename?: 'IndexWorkerTask';
                    crawled: boolean;
                    indexed: boolean;
                    createdAt?: Date | null;
                    url: {
                      __typename?: 'IndexWorkerURL';
                      id: string;
                      url: string;
                    };
                    result?: {
                      __typename?: 'IndexWorkerTaskResult';
                      coverageState: string;
                      crawledAs: string;
                      forceSendFields: Array<string>;
                      googleCanonical: string;
                      indexingState: string;
                      lastCrawlTime: string;
                      nullFields: Array<string>;
                      pageFetchState: string;
                      refferingUrls: Array<string>;
                      robotsTxtState: string;
                      sitemap: Array<string>;
                      userCanonical: string;
                    } | null;
                  };
                }>;
              };
              result?: {
                __typename?: 'IndexWorkerResult';
                crawledCount: number;
                indexedCount: number;
                canonicalIndexedCount: number;
                totalCount: number;
                crawledRate: number;
                indexedRate: number;
                canonicalIndexedRate: number;
                sevenDaysMovingAverageCrawledRate: number;
                sevenDaysMovingAverageIndexedRate: number;
                sevenDaysMovingAverageCanonicalIndexedRate: number;
                twentyEightDaysMovingAverageCrawledRate: number;
                twentyEightDaysMovingAverageIndexedRate: number;
                twentyEightDaysMovingAverageCanonicalIndexedRate: number;
              } | null;
            };
          }>;
        };
      }
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type IndexWorkerJobsQueryVariables = Exact<{
  indexWorkerId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  range?: InputMaybe<TimeRangeInput>;
}>;

export type IndexWorkerJobsQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {
        __typename?: 'IndexWorker';
        samplingCount: number;
        urlType: IndexWorkerUrlType;
        id: string;
        jobs: {
          __typename?: 'IndexWorkerJobConnection';
          edges: Array<{
            __typename?: 'IndexWorkerJobEdge';
            node: {
              __typename?: 'IndexWorkerJob';
              id: string;
              completedAt?: Date | null;
              result?: {
                __typename?: 'IndexWorkerResult';
                crawledCount: number;
                indexedCount: number;
                canonicalIndexedCount: number;
                totalCount: number;
                crawledRate: number;
                indexedRate: number;
                canonicalIndexedRate: number;
                sevenDaysMovingAverageCrawledRate: number;
                sevenDaysMovingAverageIndexedRate: number;
                sevenDaysMovingAverageCanonicalIndexedRate: number;
                twentyEightDaysMovingAverageCrawledRate: number;
                twentyEightDaysMovingAverageIndexedRate: number;
                twentyEightDaysMovingAverageCanonicalIndexedRate: number;
              } | null;
            };
          }>;
        };
      }
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type IndexWorkersJobsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
}>;

export type IndexWorkersJobsQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {
        __typename?: 'IndexWorkerProject';
        color: string;
        name: string;
        id: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          note: string;
          urlType: IndexWorkerUrlType;
          rssFeed?: string | null;
          rssFeedSamplingPeriod?: number | null;
          samplingCount: number;
          sitemap?: string | null;
          color: string;
          urls?: Array<string> | null;
          schedule?: string | null;
          ignoreURLParameters: Array<string>;
          jobs: {
            __typename?: 'IndexWorkerJobConnection';
            edges: Array<{
              __typename?: 'IndexWorkerJobEdge';
              node: {
                __typename?: 'IndexWorkerJob';
                id: string;
                completedAt?: Date | null;
                result?: {
                  __typename?: 'IndexWorkerResult';
                  crawledCount: number;
                  indexedCount: number;
                  canonicalIndexedCount: number;
                  totalCount: number;
                  crawledRate: number;
                  indexedRate: number;
                  canonicalIndexedRate: number;
                  sevenDaysMovingAverageCrawledRate: number;
                  sevenDaysMovingAverageIndexedRate: number;
                  sevenDaysMovingAverageCanonicalIndexedRate: number;
                  twentyEightDaysMovingAverageCrawledRate: number;
                  twentyEightDaysMovingAverageIndexedRate: number;
                  twentyEightDaysMovingAverageCanonicalIndexedRate: number;
                } | null;
              };
            }>;
          };
          property:
            | {
                __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                id: string;
                property: string;
                serviceAccount: {
                  __typename?: 'ServiceAccount';
                  id: string;
                  email: string;
                };
              }
            | {
                __typename?: 'RegisteredSearchConsoleUserProperty';
                id: string;
                property: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  email: string;
                };
              };
          urlGroup?: {
            __typename?: 'URLGroup';
            name: string;
            op: LogicalOperator;
            patterns: Array<{
              __typename?: 'URLGroupPattern';
              type: UrlGroupPatternType;
              pattern: string;
            } | null>;
          } | null;
        }>;
      }
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type GetAvailablePropertyTimeQueryVariables = Exact<{
  jobsToAdd: Scalars['Int']['input'];
  property: Scalars['String']['input'];
  days: Scalars['String']['input'];
}>;

export type GetAvailablePropertyTimeQuery = {
  __typename?: 'Query';
  availablePropertyTime: string;
};

export type UrlHistoryQueryVariables = Exact<{
  urlId: Scalars['ID']['input'];
  range?: InputMaybe<TimeRangeInput>;
}>;

export type UrlHistoryQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {
        __typename?: 'IndexWorkerURL';
        url: string;
        id: string;
        results: Array<{
          __typename?: 'IndexWorkerTask';
          crawled: boolean;
          indexed: boolean;
          createdAt?: Date | null;
          url: {__typename?: 'IndexWorkerURL'; id: string; url: string};
          result?: {
            __typename?: 'IndexWorkerTaskResult';
            coverageState: string;
            crawledAs: string;
            forceSendFields: Array<string>;
            googleCanonical: string;
            indexingState: string;
            lastCrawlTime: string;
            nullFields: Array<string>;
            pageFetchState: string;
            refferingUrls: Array<string>;
            robotsTxtState: string;
            sitemap: Array<string>;
            userCanonical: string;
          } | null;
        }>;
      }
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type DeleteIndexWorkerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteIndexWorkerMutation = {
  __typename?: 'Mutation';
  deleteIndexWorker: {__typename?: 'DeleteIndexWorkerPayload'; id: string};
};

export type MoveIndexWorkerProjectMutationVariables = Exact<{
  input: MoveIndexWorkerProjectInput;
}>;

export type MoveIndexWorkerProjectMutation = {
  __typename?: 'Mutation';
  moveIndexWorkerProject: {
    __typename?: 'IndexWorker';
    id: string;
    name: string;
    note: string;
    urlType: IndexWorkerUrlType;
    rssFeed?: string | null;
    rssFeedSamplingPeriod?: number | null;
    samplingCount: number;
    sitemap?: string | null;
    color: string;
    urls?: Array<string> | null;
    schedule?: string | null;
    ignoreURLParameters: Array<string>;
    property:
      | {
          __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
          id: string;
          property: string;
          serviceAccount: {
            __typename?: 'ServiceAccount';
            id: string;
            email: string;
          };
        }
      | {
          __typename?: 'RegisteredSearchConsoleUserProperty';
          id: string;
          property: string;
          user: {__typename?: 'User'; id: string; name: string; email: string};
        };
    urlGroup?: {
      __typename?: 'URLGroup';
      name: string;
      op: LogicalOperator;
      patterns: Array<{
        __typename?: 'URLGroupPattern';
        type: UrlGroupPatternType;
        pattern: string;
      } | null>;
    } | null;
  };
};

export type UpdateIndexWorkersViewOrderMutationVariables = Exact<{
  input: UpdateIndexWorkerViewOrderInput;
}>;

export type UpdateIndexWorkersViewOrderMutation = {
  __typename?: 'Mutation';
  updateIndexWorkerViewOrder:
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          note: string;
          urlType: IndexWorkerUrlType;
          rssFeed?: string | null;
          rssFeedSamplingPeriod?: number | null;
          samplingCount: number;
          sitemap?: string | null;
          color: string;
          urls?: Array<string> | null;
          schedule?: string | null;
          ignoreURLParameters: Array<string>;
          property:
            | {
                __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                id: string;
                property: string;
                serviceAccount: {
                  __typename?: 'ServiceAccount';
                  id: string;
                  email: string;
                };
              }
            | {
                __typename?: 'RegisteredSearchConsoleUserProperty';
                id: string;
                property: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  email: string;
                };
              };
          urlGroup?: {
            __typename?: 'URLGroup';
            name: string;
            op: LogicalOperator;
            patterns: Array<{
              __typename?: 'URLGroupPattern';
              type: UrlGroupPatternType;
              pattern: string;
            } | null>;
          } | null;
        }>;
      }
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'UserAnalyticsProject'};
};

export type RunIndexWorkerMutationVariables = Exact<{
  input: RunIndexWorkerInput;
}>;

export type RunIndexWorkerMutation = {
  __typename?: 'Mutation';
  runIndexWorker: {
    __typename?: 'IndexWorker';
    id: string;
    name: string;
    note: string;
    urlType: IndexWorkerUrlType;
    rssFeed?: string | null;
    rssFeedSamplingPeriod?: number | null;
    samplingCount: number;
    sitemap?: string | null;
    color: string;
    urls?: Array<string> | null;
    schedule?: string | null;
    ignoreURLParameters: Array<string>;
    property:
      | {
          __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
          id: string;
          property: string;
          serviceAccount: {
            __typename?: 'ServiceAccount';
            id: string;
            email: string;
          };
        }
      | {
          __typename?: 'RegisteredSearchConsoleUserProperty';
          id: string;
          property: string;
          user: {__typename?: 'User'; id: string; name: string; email: string};
        };
    urlGroup?: {
      __typename?: 'URLGroup';
      name: string;
      op: LogicalOperator;
      patterns: Array<{
        __typename?: 'URLGroupPattern';
        type: UrlGroupPatternType;
        pattern: string;
      } | null>;
    } | null;
  };
};

export type NotificationFragment = {
  __typename?: 'Notification';
  body: string;
  id: string;
  title: string;
  type: NotificationType;
  readAt?: Date | null;
  createdAt: Date;
  updatedAt: Date;
  severity: NotificationSeverity;
};

export type NotificationSummaryFragment = {
  __typename?: 'Notification';
  id: string;
  title: string;
  type: NotificationType;
  readAt?: Date | null;
  createdAt: Date;
  updatedAt: Date;
  severity: NotificationSeverity;
};

export type NotificationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<NotificationType>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  severity?: InputMaybe<NotificationSeverity>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    email: string;
    notifications: {
      __typename?: 'NotificationConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor?: string | null;
      };
      edges: Array<{
        __typename?: 'NotificationEdge';
        node: {
          __typename?: 'Notification';
          id: string;
          title: string;
          type: NotificationType;
          readAt?: Date | null;
          createdAt: Date;
          updatedAt: Date;
          severity: NotificationSeverity;
        };
      }>;
    };
  } | null;
};

export type NotificationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type NotificationQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {
        __typename?: 'Notification';
        body: string;
        id: string;
        title: string;
        type: NotificationType;
        readAt?: Date | null;
        createdAt: Date;
        updatedAt: Date;
        severity: NotificationSeverity;
      }
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type ArchiveNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ArchiveNotificationMutation = {
  __typename?: 'Mutation';
  archiveNotification: {
    __typename?: 'Notification';
    id: string;
    title: string;
    type: NotificationType;
    readAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
    severity: NotificationSeverity;
  };
};

export type UnarchiveNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UnarchiveNotificationMutation = {
  __typename?: 'Mutation';
  unarchiveNotification: {
    __typename?: 'Notification';
    id: string;
    title: string;
    type: NotificationType;
    readAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
    severity: NotificationSeverity;
  };
};

export type MarkAsReadNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MarkAsReadNotificationMutation = {
  __typename?: 'Mutation';
  readNotification: {
    __typename?: 'Notification';
    body: string;
    id: string;
    title: string;
    type: NotificationType;
    readAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
    severity: NotificationSeverity;
  };
};

export type OrganizationBasicInfoFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  contract?: {
    __typename?: 'Contract';
    id: string;
    name: string;
    type: ContractType;
    stripeSubscriptionStatus: StripeSubscriptionStatus;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      name: string;
      propertyCapacity: number;
      userCapacity: number;
      guestCapacity: number;
      orgCapacity: number;
      iwProjectCapacity: number;
      iwJobCapacity: number;
      scProjectCapacity: number;
      queryGroupCapacity: number;
      urlGroupCapacity: number;
      viewCapacity: number;
      uaProjectCapacity: number;
    } | null;
  } | null;
};

export type OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  registeredProperties: Array<
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        property: string;
        serviceAccount: {
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        };
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        property: string;
        user: {__typename?: 'User'; id: string; name: string; email: string};
      }
  >;
  projects: Array<
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          color: string;
          property:
            | {
                __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                id: string;
                property: string;
                serviceAccount: {
                  __typename?: 'ServiceAccount';
                  id: string;
                  email: string;
                };
              }
            | {
                __typename?: 'RegisteredSearchConsoleUserProperty';
                id: string;
                property: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  email: string;
                };
              };
        }>;
      }
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
      }
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
      }
  >;
  users: Array<{
    __typename?: 'UserOrganization';
    userID: string;
    role: UserOrganizationRole;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      name: string;
      picture: string;
    };
  }>;
  serviceAccounts: Array<{
    __typename?: 'ServiceAccount';
    id: string;
    email: string;
  }>;
  contract?: {
    __typename?: 'Contract';
    id: string;
    name: string;
    type: ContractType;
    stripeSubscriptionStatus: StripeSubscriptionStatus;
    subscriptionPlan?: {
      __typename?: 'Plan';
      id: string;
      name: string;
      propertyCapacity: number;
      userCapacity: number;
      guestCapacity: number;
      orgCapacity: number;
      iwProjectCapacity: number;
      iwJobCapacity: number;
      scProjectCapacity: number;
      queryGroupCapacity: number;
      urlGroupCapacity: number;
      viewCapacity: number;
      uaProjectCapacity: number;
    } | null;
  } | null;
};

export type UserOrganizationFragment = {
  __typename?: 'UserOrganization';
  userID: string;
  organizationID: string;
  role: UserOrganizationRole;
  user: {__typename?: 'User'; id: string; email: string};
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    contract?: {
      __typename?: 'Contract';
      id: string;
      name: string;
      type: ContractType;
      stripeSubscriptionStatus: StripeSubscriptionStatus;
      subscriptionPlan?: {
        __typename?: 'Plan';
        id: string;
        name: string;
        propertyCapacity: number;
        userCapacity: number;
        guestCapacity: number;
        orgCapacity: number;
        iwProjectCapacity: number;
        iwJobCapacity: number;
        scProjectCapacity: number;
        queryGroupCapacity: number;
        urlGroupCapacity: number;
        viewCapacity: number;
        uaProjectCapacity: number;
      } | null;
    } | null;
  };
};

export type OrganizationBasicInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrganizationBasicInfoQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
            uaProjectCapacity: number;
          } | null;
        } | null;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        registeredProperties: Array<
          | {
              __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
              id: string;
              property: string;
              serviceAccount: {
                __typename?: 'ServiceAccount';
                id: string;
                email: string;
              };
            }
          | {
              __typename?: 'RegisteredSearchConsoleUserProperty';
              id: string;
              property: string;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                email: string;
              };
            }
        >;
        projects: Array<
          | {
              __typename?: 'IndexWorkerProject';
              id: string;
              name: string;
              color: string;
              indexWorkers: Array<{
                __typename?: 'IndexWorker';
                id: string;
                name: string;
                color: string;
                property:
                  | {
                      __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                      id: string;
                      property: string;
                      serviceAccount: {
                        __typename?: 'ServiceAccount';
                        id: string;
                        email: string;
                      };
                    }
                  | {
                      __typename?: 'RegisteredSearchConsoleUserProperty';
                      id: string;
                      property: string;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        email: string;
                      };
                    };
              }>;
            }
          | {
              __typename?: 'SearchAnalyticsProject';
              id: string;
              name: string;
              color: string;
            }
          | {
              __typename?: 'UserAnalyticsProject';
              id: string;
              name: string;
              color: string;
            }
        >;
        users: Array<{
          __typename?: 'UserOrganization';
          userID: string;
          role: UserOrganizationRole;
          user: {
            __typename?: 'User';
            id: string;
            email: string;
            name: string;
            picture: string;
          };
        }>;
        serviceAccounts: Array<{
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        }>;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
            uaProjectCapacity: number;
          } | null;
        } | null;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type OrganizationsQueryVariables = Exact<{
  organizationID: Scalars['ID']['input'];
}>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    email: string;
    isAdmin: boolean;
    isCustomerSupport: boolean;
    organizations: Array<{
      __typename?: 'UserOrganization';
      userID: string;
      organizationID: string;
      role: UserOrganizationRole;
      user: {__typename?: 'User'; id: string; email: string};
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
            uaProjectCapacity: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type AllGroupsBySearchAnalyticsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AllGroupsBySearchAnalyticsQuery = {
  __typename?: 'Query';
  getAllURLGroupsBySearchAnalytics: Array<{
    __typename?: 'AllURLGroupsResult';
    searchAnalyticsName: string;
    urlGroup: {
      __typename?: 'URLGroup';
      id: string;
      name: string;
      op: LogicalOperator;
      patterns: Array<{
        __typename?: 'URLGroupPattern';
        type: UrlGroupPatternType;
        pattern: string;
      } | null>;
    };
  }>;
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    contract?: {
      __typename?: 'Contract';
      id: string;
      name: string;
      type: ContractType;
      stripeSubscriptionStatus: StripeSubscriptionStatus;
      subscriptionPlan?: {
        __typename?: 'Plan';
        id: string;
        name: string;
        propertyCapacity: number;
        userCapacity: number;
        guestCapacity: number;
        orgCapacity: number;
        iwProjectCapacity: number;
        iwJobCapacity: number;
        scProjectCapacity: number;
        queryGroupCapacity: number;
        urlGroupCapacity: number;
        viewCapacity: number;
        uaProjectCapacity: number;
      } | null;
    } | null;
  };
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {__typename?: 'Organization'; id: string; name: string};
};

export type DeleteOrganizationMutationVariables = Exact<{
  input: DeleteOrganizationInput;
}>;

export type DeleteOrganizationMutation = {
  __typename?: 'Mutation';
  deleteOrganization: {__typename?: 'DeleteOrganizationPayload'; id: string};
};

type Project_IndexWorkerProject_Fragment = {
  __typename?: 'IndexWorkerProject';
  id: string;
  name: string;
  color: string;
  indexWorkers: Array<{
    __typename?: 'IndexWorker';
    id: string;
    name: string;
    note: string;
    urlType: IndexWorkerUrlType;
    rssFeed?: string | null;
    rssFeedSamplingPeriod?: number | null;
    samplingCount: number;
    sitemap?: string | null;
    color: string;
    urls?: Array<string> | null;
    schedule?: string | null;
    ignoreURLParameters: Array<string>;
    property:
      | {
          __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
          id: string;
          property: string;
          serviceAccount: {
            __typename?: 'ServiceAccount';
            id: string;
            email: string;
          };
        }
      | {
          __typename?: 'RegisteredSearchConsoleUserProperty';
          id: string;
          property: string;
          user: {__typename?: 'User'; id: string; name: string; email: string};
        };
    urlGroup?: {
      __typename?: 'URLGroup';
      name: string;
      op: LogicalOperator;
      patterns: Array<{
        __typename?: 'URLGroupPattern';
        type: UrlGroupPatternType;
        pattern: string;
      } | null>;
    } | null;
  }>;
};

type Project_SearchAnalyticsProject_Fragment = {
  __typename?: 'SearchAnalyticsProject';
  id: string;
  name: string;
  color: string;
  searchAnalytics?: {
    __typename?: 'SearchAnalytics';
    id: string;
    datasetName: string;
    googleCloudProjectID: string;
    manageType: SearchAnalyticsManageType;
    views: Array<{
      __typename?: 'SearchAnalyticsView';
      id: string;
      name: string;
    }>;
  } | null;
};

type Project_UserAnalyticsProject_Fragment = {
  __typename?: 'UserAnalyticsProject';
  id: string;
  name: string;
  color: string;
};

export type ProjectFragment =
  | Project_IndexWorkerProject_Fragment
  | Project_SearchAnalyticsProject_Fragment
  | Project_UserAnalyticsProject_Fragment;

export type IndexWorkerProjectFragment = {
  __typename?: 'IndexWorkerProject';
  id: string;
  name: string;
  color: string;
  description?: string | null;
  indexWorkers: Array<{
    __typename?: 'IndexWorker';
    id: string;
    name: string;
    note: string;
    urlType: IndexWorkerUrlType;
    rssFeed?: string | null;
    rssFeedSamplingPeriod?: number | null;
    samplingCount: number;
    sitemap?: string | null;
    color: string;
    urls?: Array<string> | null;
    schedule?: string | null;
    ignoreURLParameters: Array<string>;
    property:
      | {
          __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
          id: string;
          property: string;
          serviceAccount: {
            __typename?: 'ServiceAccount';
            id: string;
            email: string;
          };
        }
      | {
          __typename?: 'RegisteredSearchConsoleUserProperty';
          id: string;
          property: string;
          user: {__typename?: 'User'; id: string; name: string; email: string};
        };
    urlGroup?: {
      __typename?: 'URLGroup';
      name: string;
      op: LogicalOperator;
      patterns: Array<{
        __typename?: 'URLGroupPattern';
        type: UrlGroupPatternType;
        pattern: string;
      } | null>;
    } | null;
  }>;
};

export type SearchAnalyticsProjectFragment = {
  __typename?: 'SearchAnalyticsProject';
  id: string;
  name: string;
  color: string;
  description?: string | null;
  searchAnalytics?: {
    __typename?: 'SearchAnalytics';
    id: string;
    datasetName: string;
    googleCloudProjectID: string;
    manageType: SearchAnalyticsManageType;
    views: Array<{
      __typename?: 'SearchAnalyticsView';
      id: string;
      name: string;
    }>;
  } | null;
};

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          note: string;
          urlType: IndexWorkerUrlType;
          rssFeed?: string | null;
          rssFeedSamplingPeriod?: number | null;
          samplingCount: number;
          sitemap?: string | null;
          color: string;
          urls?: Array<string> | null;
          schedule?: string | null;
          ignoreURLParameters: Array<string>;
          property:
            | {
                __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                id: string;
                property: string;
                serviceAccount: {
                  __typename?: 'ServiceAccount';
                  id: string;
                  email: string;
                };
              }
            | {
                __typename?: 'RegisteredSearchConsoleUserProperty';
                id: string;
                property: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  email: string;
                };
              };
          urlGroup?: {
            __typename?: 'URLGroup';
            name: string;
            op: LogicalOperator;
            patterns: Array<{
              __typename?: 'URLGroupPattern';
              type: UrlGroupPatternType;
              pattern: string;
            } | null>;
          } | null;
        }>;
      }
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        searchAnalytics?: {
          __typename?: 'SearchAnalytics';
          latestDataDate?: Date | null;
          id: string;
          datasetName: string;
          googleCloudProjectID: string;
          manageType: SearchAnalyticsManageType;
          views: Array<{
            __typename?: 'SearchAnalyticsView';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type ProjectsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type ProjectsQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {
        __typename?: 'Organization';
        name: string;
        id: string;
        projects: Array<
          | {
              __typename?: 'IndexWorkerProject';
              id: string;
              name: string;
              color: string;
              description?: string | null;
              indexWorkers: Array<{
                __typename?: 'IndexWorker';
                id: string;
                name: string;
                note: string;
                urlType: IndexWorkerUrlType;
                rssFeed?: string | null;
                rssFeedSamplingPeriod?: number | null;
                samplingCount: number;
                sitemap?: string | null;
                color: string;
                urls?: Array<string> | null;
                schedule?: string | null;
                ignoreURLParameters: Array<string>;
                property:
                  | {
                      __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                      id: string;
                      property: string;
                      serviceAccount: {
                        __typename?: 'ServiceAccount';
                        id: string;
                        email: string;
                      };
                    }
                  | {
                      __typename?: 'RegisteredSearchConsoleUserProperty';
                      id: string;
                      property: string;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        email: string;
                      };
                    };
                urlGroup?: {
                  __typename?: 'URLGroup';
                  name: string;
                  op: LogicalOperator;
                  patterns: Array<{
                    __typename?: 'URLGroupPattern';
                    type: UrlGroupPatternType;
                    pattern: string;
                  } | null>;
                } | null;
              }>;
            }
          | {
              __typename?: 'SearchAnalyticsProject';
              id: string;
              name: string;
              color: string;
              description?: string | null;
              searchAnalytics?: {
                __typename?: 'SearchAnalytics';
                id: string;
                datasetName: string;
                googleCloudProjectID: string;
                manageType: SearchAnalyticsManageType;
                views: Array<{
                  __typename?: 'SearchAnalyticsView';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {__typename?: 'UserAnalyticsProject'}
        >;
      }
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type CreateProjectMutationVariables = Exact<{
  input?: InputMaybe<CreateProjectInput>;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject:
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          note: string;
          urlType: IndexWorkerUrlType;
          rssFeed?: string | null;
          rssFeedSamplingPeriod?: number | null;
          samplingCount: number;
          sitemap?: string | null;
          color: string;
          urls?: Array<string> | null;
          schedule?: string | null;
          ignoreURLParameters: Array<string>;
          property:
            | {
                __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                id: string;
                property: string;
                serviceAccount: {
                  __typename?: 'ServiceAccount';
                  id: string;
                  email: string;
                };
              }
            | {
                __typename?: 'RegisteredSearchConsoleUserProperty';
                id: string;
                property: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  email: string;
                };
              };
          urlGroup?: {
            __typename?: 'URLGroup';
            name: string;
            op: LogicalOperator;
            patterns: Array<{
              __typename?: 'URLGroupPattern';
              type: UrlGroupPatternType;
              pattern: string;
            } | null>;
          } | null;
        }>;
      }
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
        searchAnalytics?: {
          __typename?: 'SearchAnalytics';
          id: string;
          datasetName: string;
          googleCloudProjectID: string;
          manageType: SearchAnalyticsManageType;
          views: Array<{
            __typename?: 'SearchAnalyticsView';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
      };
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject: {__typename?: 'DeleteProjectPayload'; id: string};
};

export type DuplicateProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DuplicateProjectMutation = {
  __typename?: 'Mutation';
  duplicateProject:
    | {
        __typename?: 'IndexWorkerProject';
        id: string;
        name: string;
        color: string;
        indexWorkers: Array<{
          __typename?: 'IndexWorker';
          id: string;
          name: string;
          note: string;
          urlType: IndexWorkerUrlType;
          rssFeed?: string | null;
          rssFeedSamplingPeriod?: number | null;
          samplingCount: number;
          sitemap?: string | null;
          color: string;
          urls?: Array<string> | null;
          schedule?: string | null;
          ignoreURLParameters: Array<string>;
          property:
            | {
                __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
                id: string;
                property: string;
                serviceAccount: {
                  __typename?: 'ServiceAccount';
                  id: string;
                  email: string;
                };
              }
            | {
                __typename?: 'RegisteredSearchConsoleUserProperty';
                id: string;
                property: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  email: string;
                };
              };
          urlGroup?: {
            __typename?: 'URLGroup';
            name: string;
            op: LogicalOperator;
            patterns: Array<{
              __typename?: 'URLGroupPattern';
              type: UrlGroupPatternType;
              pattern: string;
            } | null>;
          } | null;
        }>;
      }
    | {
        __typename?: 'SearchAnalyticsProject';
        id: string;
        name: string;
        color: string;
        searchAnalytics?: {
          __typename?: 'SearchAnalytics';
          id: string;
          datasetName: string;
          googleCloudProjectID: string;
          manageType: SearchAnalyticsManageType;
          views: Array<{
            __typename?: 'SearchAnalyticsView';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'UserAnalyticsProject';
        id: string;
        name: string;
        color: string;
      };
};

export type RegisteredSearchConsoleUserPropertyFragment = {
  __typename?: 'RegisteredSearchConsoleUserProperty';
  id: string;
  property: string;
  user: {__typename?: 'User'; id: string; name: string; email: string};
};

export type RegisteredSearchConsoleServiceAccountPropertyFragment = {
  __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
  id: string;
  property: string;
  serviceAccount: {__typename?: 'ServiceAccount'; id: string; email: string};
};

export type RegisteredSeachConsolePropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RegisteredSeachConsolePropertyQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        property: string;
        serviceAccount: {
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        };
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        property: string;
        user: {__typename?: 'User'; id: string; name: string; email: string};
      }
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type RegisteredPropertiesByOrgIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RegisteredPropertiesByOrgIdQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        id: string;
        registeredProperties: Array<
          | {
              __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
              id: string;
              property: string;
              serviceAccount: {
                __typename?: 'ServiceAccount';
                id: string;
                email: string;
              };
            }
          | {
              __typename?: 'RegisteredSearchConsoleUserProperty';
              id: string;
              property: string;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                email: string;
              };
            }
        >;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type PropertySitemapsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PropertySitemapsQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        sitemaps: Array<
          | {__typename?: 'Sitemap'; url: string}
          | {__typename?: 'SitemapIndex'; id: string; url: string}
        >;
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        sitemaps: Array<
          | {__typename?: 'Sitemap'; url: string}
          | {__typename?: 'SitemapIndex'; id: string; url: string}
        >;
      }
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type OnboardingAvailablePropertiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OnboardingAvailablePropertiesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    name: string;
    picture: string;
    email: string;
    availableProperties: Array<{
      __typename?: 'SearchConsoleProperty';
      property: string;
    }>;
    organizations: Array<{
      __typename?: 'UserOrganization';
      organizationID: string;
      role: UserOrganizationRole;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contract?: {
          __typename?: 'Contract';
          id: string;
          name: string;
          type: ContractType;
          stripeSubscriptionStatus: StripeSubscriptionStatus;
          subscriptionPlan?: {
            __typename?: 'Plan';
            id: string;
            name: string;
            propertyCapacity: number;
            userCapacity: number;
            guestCapacity: number;
            orgCapacity: number;
            iwProjectCapacity: number;
            iwJobCapacity: number;
            scProjectCapacity: number;
            queryGroupCapacity: number;
            urlGroupCapacity: number;
            viewCapacity: number;
            uaProjectCapacity: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type AvailablePropertiesByOrgIdQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type AvailablePropertiesByOrgIdQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    email: string;
    availableProperties: Array<{
      __typename?: 'SearchConsoleProperty';
      property: string;
    }>;
  } | null;
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Organization';
        serviceAccounts: Array<{
          __typename?: 'ServiceAccount';
          id: string;
          availableProperties: Array<{
            __typename?: 'SearchConsoleProperty';
            property: string;
          }>;
        }>;
      }
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type RegisterPropertyMutationVariables = Exact<{
  input?: InputMaybe<RegisterPropertyInput>;
}>;

export type RegisterPropertyMutation = {
  __typename?: 'Mutation';
  registerProperty:
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        property: string;
        user: {__typename?: 'User'; id: string; name: string; email: string};
      };
};

export type UpdatePropertyMutationVariables = Exact<{
  input?: InputMaybe<UpdatePropertyInput>;
}>;

export type UpdatePropertyMutation = {
  __typename?: 'Mutation';
  updateProperty:
    | {
        __typename?: 'RegisteredSearchConsoleServiceAccountProperty';
        id: string;
        property: string;
        serviceAccount: {
          __typename?: 'ServiceAccount';
          id: string;
          email: string;
        };
      }
    | {
        __typename?: 'RegisteredSearchConsoleUserProperty';
        id: string;
        property: string;
        user: {__typename?: 'User'; id: string; name: string; email: string};
      };
};

export type SitemapIndexQueryVariables = Exact<{
  sitemapId: Scalars['ID']['input'];
}>;

export type SitemapIndexQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {__typename?: 'SearchAnalytics'}
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {
        __typename?: 'SitemapIndex';
        id: string;
        url: string;
        sitemaps: Array<{__typename?: 'Sitemap'; url: string}>;
      }
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SearchAnalyticsBasicInfoFragment = {
  __typename?: 'SearchAnalytics';
  id: string;
  datasetName: string;
  googleCloudProjectID: string;
  manageType: SearchAnalyticsManageType;
  views: Array<{__typename?: 'SearchAnalyticsView'; id: string; name: string}>;
};

export type SearchAnalyticsViewBasicInfoFragment = {
  __typename?: 'SearchAnalyticsView';
  id: string;
  name: string;
};

export type SearchAnalyticsViewFragment = {
  __typename?: 'SearchAnalyticsView';
  id: string;
  name: string;
  description?: string | null;
  query?: string | null;
  queryFilterType?: SearchAnalyticsFilter | null;
  queryGroupIDs?: Array<string> | null;
  url?: string | null;
  urlFilterType?: SearchAnalyticsFilter | null;
  urlGroupIDs?: Array<string> | null;
  devices?: Array<string> | null;
  searchTypes?: Array<SearchAnalyticsSearchType> | null;
  countries?: Array<string> | null;
  isAnonymousQuery?: boolean | null;
  rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
  spreadsheetID?: string | null;
  dataDate: {
    __typename?: 'SearchAnalyticsDataDate';
    type: SearchAnalyticsDataDateType;
    dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
  };
  rawDataOrderBy: {
    __typename?: 'SearchAnalyticsRawDataOrder';
    field: SearchAnalyticsOrderField;
    direction: OrderDirection;
  };
};

export type SearchAnalyticsQueryUrlGroupFragment = {
  __typename?: 'SearchAnalytics';
  id: string;
  queryGroups: Array<{
    __typename?: 'QueryGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'QueryGroupPattern';
      type: QueryGroupPatternType;
      pattern: string;
    } | null>;
  }>;
  urlGroups: Array<{
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  }>;
};

export type QueryGroupFragment = {
  __typename?: 'QueryGroup';
  id: string;
  name: string;
  op: LogicalOperator;
  patterns: Array<{
    __typename?: 'QueryGroupPattern';
    type: QueryGroupPatternType;
    pattern: string;
  } | null>;
};

export type QueryGroupPatternFragment = {
  __typename?: 'QueryGroupPattern';
  type: QueryGroupPatternType;
  pattern: string;
};

export type UrlGroupFragment = {
  __typename?: 'URLGroup';
  id: string;
  name: string;
  op: LogicalOperator;
  patterns: Array<{
    __typename?: 'URLGroupPattern';
    type: UrlGroupPatternType;
    pattern: string;
  } | null>;
};

export type UrlGroupPatternFragment = {
  __typename?: 'URLGroupPattern';
  type: UrlGroupPatternType;
  pattern: string;
};

export type AggregatedDataNonGroupedFragment = {
  __typename?: 'SearchAnalyticsAggregatedDataNonGrouped';
  date: Date;
  tableType: SearchAnalyticsTableType;
  sumOfClicks: number;
  sumOfImpressions: number;
  ctr: number;
  avgOfRank?: number | null;
  distinctQueries: number;
  distinctURLs: number;
  soc7d?: number | null;
  soc28d?: number | null;
  soi7d?: number | null;
  soi28d?: number | null;
  ctr7d?: number | null;
  ctr28d?: number | null;
  aor7d?: number | null;
  aor28d?: number | null;
  dq7d?: number | null;
  dq28d?: number | null;
  du7d?: number | null;
  du28d?: number | null;
};

export type AggregatedDataByQueryGroupFragment = {
  __typename?: 'SearchAnalyticsAggregatedDataByQueryGroup';
  date: Date;
  tableType: SearchAnalyticsTableType;
  sumOfClicks: number;
  sumOfImpressions: number;
  ctr: number;
  avgOfRank?: number | null;
  distinctQueries: number;
  distinctURLs: number;
  soc7d?: number | null;
  soc28d?: number | null;
  soi7d?: number | null;
  soi28d?: number | null;
  ctr7d?: number | null;
  ctr28d?: number | null;
  aor7d?: number | null;
  aor28d?: number | null;
  dq7d?: number | null;
  dq28d?: number | null;
  du7d?: number | null;
  du28d?: number | null;
  queryGroup: {
    __typename?: 'QueryGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'QueryGroupPattern';
      type: QueryGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type AggregatedDataByUrlGroupFragment = {
  __typename?: 'SearchAnalyticsAggregatedDataByURLGroup';
  date: Date;
  tableType: SearchAnalyticsTableType;
  sumOfClicks: number;
  sumOfImpressions: number;
  ctr: number;
  avgOfRank?: number | null;
  distinctQueries: number;
  distinctURLs: number;
  soc7d?: number | null;
  soc28d?: number | null;
  soi7d?: number | null;
  soi28d?: number | null;
  ctr7d?: number | null;
  ctr28d?: number | null;
  aor7d?: number | null;
  aor28d?: number | null;
  dq7d?: number | null;
  dq28d?: number | null;
  du7d?: number | null;
  du28d?: number | null;
  urlGroup: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type SearchAnalyticsRawDataConnectionFragment = {
  __typename?: 'SearchAnalyticsRawDataConnection';
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
  };
  edges: Array<{
    __typename?: 'SearchAnalyticsRawDataEdge';
    cursor: string;
    node: {
      __typename?: 'SearchAnalyticsRawData';
      date?: Date | null;
      query?: string | null;
      device?: string | null;
      country?: string | null;
      searchType?: SearchAnalyticsSearchType | null;
      isAnonymousQuery?: boolean | null;
      isAnonymizedDiscover?: boolean | null;
      url?: string | null;
      clicks: number;
      impressions: number;
      ctr: number;
      avgOfRank?: number | null;
      distinctQueries: number;
      distinctURLs: number;
      queryGroup?: {__typename?: 'QueryGroup'; id: string; name: string} | null;
      urlGroup?: {__typename?: 'URLGroup'; id: string; name: string} | null;
    };
  }>;
};

export type SearchAnalyticsRawDataFragment = {
  __typename?: 'SearchAnalyticsRawData';
  date?: Date | null;
  query?: string | null;
  device?: string | null;
  country?: string | null;
  searchType?: SearchAnalyticsSearchType | null;
  isAnonymousQuery?: boolean | null;
  isAnonymizedDiscover?: boolean | null;
  url?: string | null;
  clicks: number;
  impressions: number;
  ctr: number;
  avgOfRank?: number | null;
  distinctQueries: number;
  distinctURLs: number;
  queryGroup?: {__typename?: 'QueryGroup'; id: string; name: string} | null;
  urlGroup?: {__typename?: 'URLGroup'; id: string; name: string} | null;
};

export type SearchAnalyticsRawComparingDataConnectionFragment = {
  __typename?: 'SearchAnalyticsRawComparingDataConnection';
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
  };
  edges: Array<{
    __typename?: 'SearchAnalyticsRawComparingDataEdge';
    cursor: string;
    node: {
      __typename?: 'SearchAnalyticsRawComparingData';
      query?: string | null;
      device?: string | null;
      country?: string | null;
      searchType?: SearchAnalyticsSearchType | null;
      isAnonymousQuery?: boolean | null;
      isAnonymizedDiscover?: boolean | null;
      url?: string | null;
      clicks: number;
      impressions: number;
      ctr: number;
      avgOfRank?: number | null;
      distinctQueries: number;
      distinctURLs: number;
      comparedClicks: number;
      comparedImpressions: number;
      comparedCtr: number;
      comparedAvgOfRank?: number | null;
      comparedDistinctQueries: number;
      comparedDistinctURLs: number;
      queryGroup?: {__typename?: 'QueryGroup'; id: string; name: string} | null;
      urlGroup?: {__typename?: 'URLGroup'; id: string; name: string} | null;
    };
  }>;
};

export type SearchAnalyticsRawComparingDataFragment = {
  __typename?: 'SearchAnalyticsRawComparingData';
  query?: string | null;
  device?: string | null;
  country?: string | null;
  searchType?: SearchAnalyticsSearchType | null;
  isAnonymousQuery?: boolean | null;
  isAnonymizedDiscover?: boolean | null;
  url?: string | null;
  clicks: number;
  impressions: number;
  ctr: number;
  avgOfRank?: number | null;
  distinctQueries: number;
  distinctURLs: number;
  comparedClicks: number;
  comparedImpressions: number;
  comparedCtr: number;
  comparedAvgOfRank?: number | null;
  comparedDistinctQueries: number;
  comparedDistinctURLs: number;
  queryGroup?: {__typename?: 'QueryGroup'; id: string; name: string} | null;
  urlGroup?: {__typename?: 'URLGroup'; id: string; name: string} | null;
};

export type SearchAnalyticsViewListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SearchAnalyticsViewListQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'}
    | {__typename?: 'Dashboard'}
    | {__typename?: 'DashboardItemDivider'}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'}
    | {__typename?: 'IndexWorker'}
    | {__typename?: 'IndexWorkerProject'}
    | {__typename?: 'IndexWorkerURL'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Organization'}
    | {__typename?: 'Plan'}
    | {__typename?: 'QueryGroup'}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'}
    | {
        __typename?: 'SearchAnalytics';
        id: string;
        views: Array<{
          __typename?: 'SearchAnalyticsView';
          id: string;
          name: string;
          description?: string | null;
          query?: string | null;
          queryFilterType?: SearchAnalyticsFilter | null;
          queryGroupIDs?: Array<string> | null;
          url?: string | null;
          urlFilterType?: SearchAnalyticsFilter | null;
          urlGroupIDs?: Array<string> | null;
          devices?: Array<string> | null;
          searchTypes?: Array<SearchAnalyticsSearchType> | null;
          countries?: Array<string> | null;
          isAnonymousQuery?: boolean | null;
          rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
          spreadsheetID?: string | null;
          dataDate: {
            __typename?: 'SearchAnalyticsDataDate';
            type: SearchAnalyticsDataDateType;
            dateRange?: {
              __typename?: 'TimeRange';
              start: Date;
              end: Date;
            } | null;
          };
          rawDataOrderBy: {
            __typename?: 'SearchAnalyticsRawDataOrder';
            field: SearchAnalyticsOrderField;
            direction: OrderDirection;
          };
        }>;
      }
    | {__typename?: 'SearchAnalyticsChat'}
    | {__typename?: 'SearchAnalyticsProject'}
    | {__typename?: 'SearchAnalyticsView'}
    | {__typename?: 'ServiceAccount'}
    | {__typename?: 'SitemapIndex'}
    | {__typename?: 'URLGroup'}
    | {__typename?: 'User'}
    | {__typename?: 'UserAnalytics'}
    | {__typename?: 'UserAnalyticsProject'}
    | {__typename?: 'UserAnalyticsView'}
    | null;
};

export type SearchAnalyticsViewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SearchAnalyticsViewQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {__typename?: 'SearchAnalytics'; id: string}
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {
        __typename?: 'SearchAnalyticsView';
        id: string;
        name: string;
        description?: string | null;
        query?: string | null;
        queryFilterType?: SearchAnalyticsFilter | null;
        queryGroupIDs?: Array<string> | null;
        url?: string | null;
        urlFilterType?: SearchAnalyticsFilter | null;
        urlGroupIDs?: Array<string> | null;
        devices?: Array<string> | null;
        searchTypes?: Array<SearchAnalyticsSearchType> | null;
        countries?: Array<string> | null;
        isAnonymousQuery?: boolean | null;
        rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
        spreadsheetID?: string | null;
        dataDate: {
          __typename?: 'SearchAnalyticsDataDate';
          type: SearchAnalyticsDataDateType;
          dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
        };
        rawDataOrderBy: {
          __typename?: 'SearchAnalyticsRawDataOrder';
          field: SearchAnalyticsOrderField;
          direction: OrderDirection;
        };
      }
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type SearchAnalyticsViewAggregatedDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dateRange: TimeRangeInput;
  groupBy?: InputMaybe<SearchAnalyticsGroupBy>;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  queryGroupIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  url?: InputMaybe<Scalars['String']['input']>;
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  urlGroupIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  devices?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  searchTypes?: InputMaybe<
    Array<SearchAnalyticsSearchType> | SearchAnalyticsSearchType
  >;
  countries?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  useSavedDateRange?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchAnalyticsViewAggregatedDataQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {
        __typename?: 'SearchAnalytics';
        spreadsheetID?: string | null;
        id: string;
        aggregatedData: Array<
          | {
              __typename?: 'SearchAnalyticsAggregatedDataByQueryGroup';
              date: Date;
              tableType: SearchAnalyticsTableType;
              sumOfClicks: number;
              sumOfImpressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              soc7d?: number | null;
              soc28d?: number | null;
              soi7d?: number | null;
              soi28d?: number | null;
              ctr7d?: number | null;
              ctr28d?: number | null;
              aor7d?: number | null;
              aor28d?: number | null;
              dq7d?: number | null;
              dq28d?: number | null;
              du7d?: number | null;
              du28d?: number | null;
              queryGroup: {
                __typename?: 'QueryGroup';
                id: string;
                name: string;
                op: LogicalOperator;
                patterns: Array<{
                  __typename?: 'QueryGroupPattern';
                  type: QueryGroupPatternType;
                  pattern: string;
                } | null>;
              };
            }
          | {
              __typename?: 'SearchAnalyticsAggregatedDataByURLGroup';
              date: Date;
              tableType: SearchAnalyticsTableType;
              sumOfClicks: number;
              sumOfImpressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              soc7d?: number | null;
              soc28d?: number | null;
              soi7d?: number | null;
              soi28d?: number | null;
              ctr7d?: number | null;
              ctr28d?: number | null;
              aor7d?: number | null;
              aor28d?: number | null;
              dq7d?: number | null;
              dq28d?: number | null;
              du7d?: number | null;
              du28d?: number | null;
              urlGroup: {
                __typename?: 'URLGroup';
                id: string;
                name: string;
                op: LogicalOperator;
                patterns: Array<{
                  __typename?: 'URLGroupPattern';
                  type: UrlGroupPatternType;
                  pattern: string;
                } | null>;
              };
            }
          | {
              __typename?: 'SearchAnalyticsAggregatedDataNonGrouped';
              date: Date;
              tableType: SearchAnalyticsTableType;
              sumOfClicks: number;
              sumOfImpressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              soc7d?: number | null;
              soc28d?: number | null;
              soi7d?: number | null;
              soi28d?: number | null;
              ctr7d?: number | null;
              ctr28d?: number | null;
              aor7d?: number | null;
              aor28d?: number | null;
              dq7d?: number | null;
              dq28d?: number | null;
              du7d?: number | null;
              du28d?: number | null;
            }
        >;
      }
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {
        __typename?: 'SearchAnalyticsView';
        id: string;
        name: string;
        description?: string | null;
        query?: string | null;
        queryFilterType?: SearchAnalyticsFilter | null;
        queryGroupIDs?: Array<string> | null;
        url?: string | null;
        urlFilterType?: SearchAnalyticsFilter | null;
        urlGroupIDs?: Array<string> | null;
        devices?: Array<string> | null;
        searchTypes?: Array<SearchAnalyticsSearchType> | null;
        countries?: Array<string> | null;
        isAnonymousQuery?: boolean | null;
        rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
        spreadsheetID?: string | null;
        aggregatedData: Array<
          | {
              __typename?: 'SearchAnalyticsAggregatedDataByQueryGroup';
              date: Date;
              tableType: SearchAnalyticsTableType;
              sumOfClicks: number;
              sumOfImpressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              soc7d?: number | null;
              soc28d?: number | null;
              soi7d?: number | null;
              soi28d?: number | null;
              ctr7d?: number | null;
              ctr28d?: number | null;
              aor7d?: number | null;
              aor28d?: number | null;
              dq7d?: number | null;
              dq28d?: number | null;
              du7d?: number | null;
              du28d?: number | null;
              queryGroup: {
                __typename?: 'QueryGroup';
                id: string;
                name: string;
                op: LogicalOperator;
                patterns: Array<{
                  __typename?: 'QueryGroupPattern';
                  type: QueryGroupPatternType;
                  pattern: string;
                } | null>;
              };
            }
          | {
              __typename?: 'SearchAnalyticsAggregatedDataByURLGroup';
              date: Date;
              tableType: SearchAnalyticsTableType;
              sumOfClicks: number;
              sumOfImpressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              soc7d?: number | null;
              soc28d?: number | null;
              soi7d?: number | null;
              soi28d?: number | null;
              ctr7d?: number | null;
              ctr28d?: number | null;
              aor7d?: number | null;
              aor28d?: number | null;
              dq7d?: number | null;
              dq28d?: number | null;
              du7d?: number | null;
              du28d?: number | null;
              urlGroup: {
                __typename?: 'URLGroup';
                id: string;
                name: string;
                op: LogicalOperator;
                patterns: Array<{
                  __typename?: 'URLGroupPattern';
                  type: UrlGroupPatternType;
                  pattern: string;
                } | null>;
              };
            }
          | {
              __typename?: 'SearchAnalyticsAggregatedDataNonGrouped';
              date: Date;
              tableType: SearchAnalyticsTableType;
              sumOfClicks: number;
              sumOfImpressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              soc7d?: number | null;
              soc28d?: number | null;
              soi7d?: number | null;
              soi28d?: number | null;
              ctr7d?: number | null;
              ctr28d?: number | null;
              aor7d?: number | null;
              aor28d?: number | null;
              dq7d?: number | null;
              dq28d?: number | null;
              du7d?: number | null;
              du28d?: number | null;
            }
        >;
        dataDate: {
          __typename?: 'SearchAnalyticsDataDate';
          type: SearchAnalyticsDataDateType;
          dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
        };
        rawDataOrderBy: {
          __typename?: 'SearchAnalyticsRawDataOrder';
          field: SearchAnalyticsOrderField;
          direction: OrderDirection;
        };
      }
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type SearchAnalyticsRawDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  dateRange: TimeRangeInput;
  compareDateRange: TimeRangeInput;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFilterType?: InputMaybe<SearchAnalyticsFilter>;
  queryGroupIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  url?: InputMaybe<Scalars['String']['input']>;
  urlFilterType?: InputMaybe<SearchAnalyticsFilter>;
  urlGroupIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  devices?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  searchTypes?: InputMaybe<
    Array<SearchAnalyticsSearchType> | SearchAnalyticsSearchType
  >;
  countries?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  isAnonymousQuery?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy: SearchAnalyticsRawDataOrderInput;
  compareOrderBy: SearchAnalyticsRawComparingDataOrderInput;
  groupBy: Array<SearchAnalyticsRawDataGroupBy> | SearchAnalyticsRawDataGroupBy;
  isComparing: Scalars['Boolean']['input'];
  clicksRange?: InputMaybe<IntRangeInput>;
  impressionsRange?: InputMaybe<IntRangeInput>;
  ctrRange?: InputMaybe<FloatRangeInput>;
  avgOfRankRange?: InputMaybe<FloatRangeInput>;
  distinctQueriesRange?: InputMaybe<IntRangeInput>;
  distinctURLsRange?: InputMaybe<IntRangeInput>;
}>;

export type SearchAnalyticsRawDataQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {
        __typename?: 'SearchAnalytics';
        id: string;
        rawData?: {
          __typename?: 'SearchAnalyticsRawDataConnection';
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string | null;
          };
          edges: Array<{
            __typename?: 'SearchAnalyticsRawDataEdge';
            cursor: string;
            node: {
              __typename?: 'SearchAnalyticsRawData';
              date?: Date | null;
              query?: string | null;
              device?: string | null;
              country?: string | null;
              searchType?: SearchAnalyticsSearchType | null;
              isAnonymousQuery?: boolean | null;
              isAnonymizedDiscover?: boolean | null;
              url?: string | null;
              clicks: number;
              impressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              queryGroup?: {
                __typename?: 'QueryGroup';
                id: string;
                name: string;
              } | null;
              urlGroup?: {
                __typename?: 'URLGroup';
                id: string;
                name: string;
              } | null;
            };
          }>;
        };
        rawComparingData?: {
          __typename?: 'SearchAnalyticsRawComparingDataConnection';
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string | null;
          };
          edges: Array<{
            __typename?: 'SearchAnalyticsRawComparingDataEdge';
            cursor: string;
            node: {
              __typename?: 'SearchAnalyticsRawComparingData';
              query?: string | null;
              device?: string | null;
              country?: string | null;
              searchType?: SearchAnalyticsSearchType | null;
              isAnonymousQuery?: boolean | null;
              isAnonymizedDiscover?: boolean | null;
              url?: string | null;
              clicks: number;
              impressions: number;
              ctr: number;
              avgOfRank?: number | null;
              distinctQueries: number;
              distinctURLs: number;
              comparedClicks: number;
              comparedImpressions: number;
              comparedCtr: number;
              comparedAvgOfRank?: number | null;
              comparedDistinctQueries: number;
              comparedDistinctURLs: number;
              queryGroup?: {
                __typename?: 'QueryGroup';
                id: string;
                name: string;
              } | null;
              urlGroup?: {
                __typename?: 'URLGroup';
                id: string;
                name: string;
              } | null;
            };
          }>;
        };
      }
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type QueryUrlGroupsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type QueryUrlGroupsQuery = {
  __typename?: 'Query';
  node?:
    | {__typename?: 'Contract'; id: string}
    | {__typename?: 'Dashboard'; id: string}
    | {__typename?: 'DashboardItemDivider'; id: string}
    | {__typename?: 'DashboardItemMetricWithTimeSeries'; id: string}
    | {__typename?: 'IndexWorker'; id: string}
    | {__typename?: 'IndexWorkerProject'; id: string}
    | {__typename?: 'IndexWorkerURL'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Organization'; id: string}
    | {__typename?: 'Plan'; id: string}
    | {__typename?: 'QueryGroup'; id: string}
    | {__typename?: 'RegisteredSearchConsoleServiceAccountProperty'; id: string}
    | {__typename?: 'RegisteredSearchConsoleUserProperty'; id: string}
    | {
        __typename?: 'SearchAnalytics';
        id: string;
        queryGroups: Array<{
          __typename?: 'QueryGroup';
          id: string;
          name: string;
          op: LogicalOperator;
          patterns: Array<{
            __typename?: 'QueryGroupPattern';
            type: QueryGroupPatternType;
            pattern: string;
          } | null>;
        }>;
        urlGroups: Array<{
          __typename?: 'URLGroup';
          id: string;
          name: string;
          op: LogicalOperator;
          patterns: Array<{
            __typename?: 'URLGroupPattern';
            type: UrlGroupPatternType;
            pattern: string;
          } | null>;
        }>;
      }
    | {__typename?: 'SearchAnalyticsChat'; id: string}
    | {__typename?: 'SearchAnalyticsProject'; id: string}
    | {__typename?: 'SearchAnalyticsView'; id: string}
    | {__typename?: 'ServiceAccount'; id: string}
    | {__typename?: 'SitemapIndex'; id: string}
    | {__typename?: 'URLGroup'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'UserAnalytics'; id: string}
    | {__typename?: 'UserAnalyticsProject'; id: string}
    | {__typename?: 'UserAnalyticsView'; id: string}
    | null;
};

export type CreateSearchAnalyticsMutationVariables = Exact<{
  input: CreateSearchAnalyticsInput;
}>;

export type CreateSearchAnalyticsMutation = {
  __typename?: 'Mutation';
  createSearchAnalytics: {
    __typename?: 'SearchAnalytics';
    id: string;
    datasetName: string;
    googleCloudProjectID: string;
    manageType: SearchAnalyticsManageType;
    views: Array<{
      __typename?: 'SearchAnalyticsView';
      id: string;
      name: string;
    }>;
  };
};

export type CreateSearchAnalyticsManagedByJadeMutationVariables = Exact<{
  input: CreateSearchAnalyticsManagedByJadeInput;
}>;

export type CreateSearchAnalyticsManagedByJadeMutation = {
  __typename?: 'Mutation';
  createSearchAnalyticsManagedByJADE: {
    __typename?: 'SearchAnalytics';
    id: string;
    datasetName: string;
    googleCloudProjectID: string;
    manageType: SearchAnalyticsManageType;
    views: Array<{
      __typename?: 'SearchAnalyticsView';
      id: string;
      name: string;
    }>;
  };
};

export type DeleteSearchAnalyticsMutationVariables = Exact<{
  input: DeleteSearchAnalyticsInput;
}>;

export type DeleteSearchAnalyticsMutation = {
  __typename?: 'Mutation';
  deleteSearchAnalytics: {
    __typename?: 'DeleteSearchAnalyticsPayload';
    id: string;
  };
};

export type UpdateSearchAnalyticsMutationVariables = Exact<{
  input: UpdateSearchAnalyticsInput;
}>;

export type UpdateSearchAnalyticsMutation = {
  __typename?: 'Mutation';
  updateSearchAnalytics: {
    __typename?: 'SearchAnalytics';
    id: string;
    datasetName: string;
    googleCloudProjectID: string;
    manageType: SearchAnalyticsManageType;
    views: Array<{
      __typename?: 'SearchAnalyticsView';
      id: string;
      name: string;
    }>;
  };
};

export type CreateSearchAnalyticsViewMutationVariables = Exact<{
  input: CreateSearchAnalyticsViewInput;
}>;

export type CreateSearchAnalyticsViewMutation = {
  __typename?: 'Mutation';
  createSearchAnalyticsView: {
    __typename?: 'SearchAnalyticsView';
    id: string;
    name: string;
    description?: string | null;
    query?: string | null;
    queryFilterType?: SearchAnalyticsFilter | null;
    queryGroupIDs?: Array<string> | null;
    url?: string | null;
    urlFilterType?: SearchAnalyticsFilter | null;
    urlGroupIDs?: Array<string> | null;
    devices?: Array<string> | null;
    searchTypes?: Array<SearchAnalyticsSearchType> | null;
    countries?: Array<string> | null;
    isAnonymousQuery?: boolean | null;
    rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
    spreadsheetID?: string | null;
    dataDate: {
      __typename?: 'SearchAnalyticsDataDate';
      type: SearchAnalyticsDataDateType;
      dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
    };
    rawDataOrderBy: {
      __typename?: 'SearchAnalyticsRawDataOrder';
      field: SearchAnalyticsOrderField;
      direction: OrderDirection;
    };
  };
};

export type UpdateSearchAnalyticsViewMutationVariables = Exact<{
  input: UpdateSearchAnalyticsViewInput;
}>;

export type UpdateSearchAnalyticsViewMutation = {
  __typename?: 'Mutation';
  updateSearchAnalyticsView: {
    __typename?: 'SearchAnalyticsView';
    id: string;
    name: string;
    description?: string | null;
    query?: string | null;
    queryFilterType?: SearchAnalyticsFilter | null;
    queryGroupIDs?: Array<string> | null;
    url?: string | null;
    urlFilterType?: SearchAnalyticsFilter | null;
    urlGroupIDs?: Array<string> | null;
    devices?: Array<string> | null;
    searchTypes?: Array<SearchAnalyticsSearchType> | null;
    countries?: Array<string> | null;
    isAnonymousQuery?: boolean | null;
    rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
    spreadsheetID?: string | null;
    dataDate: {
      __typename?: 'SearchAnalyticsDataDate';
      type: SearchAnalyticsDataDateType;
      dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
    };
    rawDataOrderBy: {
      __typename?: 'SearchAnalyticsRawDataOrder';
      field: SearchAnalyticsOrderField;
      direction: OrderDirection;
    };
  };
};

export type UpdateSearchAnalyticsViewOrderMutationVariables = Exact<{
  input: UpdateSearchAnalyticsViewOrderInput;
}>;

export type UpdateSearchAnalyticsViewOrderMutation = {
  __typename?: 'Mutation';
  updateSearchAnalyticsViewOrder: Array<{
    __typename?: 'SearchAnalyticsView';
    id: string;
    name: string;
    description?: string | null;
    query?: string | null;
    queryFilterType?: SearchAnalyticsFilter | null;
    queryGroupIDs?: Array<string> | null;
    url?: string | null;
    urlFilterType?: SearchAnalyticsFilter | null;
    urlGroupIDs?: Array<string> | null;
    devices?: Array<string> | null;
    searchTypes?: Array<SearchAnalyticsSearchType> | null;
    countries?: Array<string> | null;
    isAnonymousQuery?: boolean | null;
    rawDataGroupBy: Array<SearchAnalyticsRawDataGroupBy>;
    spreadsheetID?: string | null;
    dataDate: {
      __typename?: 'SearchAnalyticsDataDate';
      type: SearchAnalyticsDataDateType;
      dateRange?: {__typename?: 'TimeRange'; start: Date; end: Date} | null;
    };
    rawDataOrderBy: {
      __typename?: 'SearchAnalyticsRawDataOrder';
      field: SearchAnalyticsOrderField;
      direction: OrderDirection;
    };
  }>;
};

export type DeleteSearchAnalyticsViewMutationVariables = Exact<{
  input: DeleteSearchAnalyticsViewInput;
}>;

export type DeleteSearchAnalyticsViewMutation = {
  __typename?: 'Mutation';
  deleteSearchAnalyticsView: {
    __typename?: 'DeleteSearchAnalyticsViewPayload';
    id: string;
  };
};

export type CreateQueryGroupMutationVariables = Exact<{
  input: CreateQueryGroupInput;
}>;

export type CreateQueryGroupMutation = {
  __typename?: 'Mutation';
  createQueryGroup: {
    __typename?: 'QueryGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'QueryGroupPattern';
      type: QueryGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type UpdateQueryGroupMutationVariables = Exact<{
  input: UpdateQueryGroupInput;
}>;

export type UpdateQueryGroupMutation = {
  __typename?: 'Mutation';
  updateQueryGroup: {
    __typename?: 'QueryGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'QueryGroupPattern';
      type: QueryGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type UpdateQueryGroupOrderMutationVariables = Exact<{
  input: UpdateQueryGroupOrderInput;
}>;

export type UpdateQueryGroupOrderMutation = {
  __typename?: 'Mutation';
  updateQueryGroupOrder: Array<{
    __typename?: 'QueryGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'QueryGroupPattern';
      type: QueryGroupPatternType;
      pattern: string;
    } | null>;
  }>;
};

export type DeleteQueryGroupMutationVariables = Exact<{
  input: DeleteQueryGroupInput;
}>;

export type DeleteQueryGroupMutation = {
  __typename?: 'Mutation';
  deleteQueryGroup: {__typename?: 'DeleteQueryGroupPayload'; id: string};
};

export type CreateUrlGroupMutationVariables = Exact<{
  input: CreateUrlGroupInput;
}>;

export type CreateUrlGroupMutation = {
  __typename?: 'Mutation';
  createURLGroup: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type UpdateUrlGroupMutationVariables = Exact<{
  input: UpdateUrlGroupInput;
}>;

export type UpdateUrlGroupMutation = {
  __typename?: 'Mutation';
  updateURLGroup: {
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  };
};

export type UpdateUrlGroupOrderMutationVariables = Exact<{
  input: UpdateUrlGroupOrderInput;
}>;

export type UpdateUrlGroupOrderMutation = {
  __typename?: 'Mutation';
  updateURLGroupOrder: Array<{
    __typename?: 'URLGroup';
    id: string;
    name: string;
    op: LogicalOperator;
    patterns: Array<{
      __typename?: 'URLGroupPattern';
      type: UrlGroupPatternType;
      pattern: string;
    } | null>;
  }>;
};

export type DeleteUrlGroupMutationVariables = Exact<{
  input: DeleteUrlGroupInput;
}>;

export type DeleteUrlGroupMutation = {
  __typename?: 'Mutation';
  deleteURLGroup: {__typename?: 'DeleteURLGroupPayload'; id: string};
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  picture: string;
  email: string;
  organizations: Array<{
    __typename?: 'UserOrganization';
    organizationID: string;
    role: UserOrganizationRole;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      contract?: {
        __typename?: 'Contract';
        id: string;
        name: string;
        type: ContractType;
        stripeSubscriptionStatus: StripeSubscriptionStatus;
        subscriptionPlan?: {
          __typename?: 'Plan';
          id: string;
          name: string;
          propertyCapacity: number;
          userCapacity: number;
          guestCapacity: number;
          orgCapacity: number;
          iwProjectCapacity: number;
          iwJobCapacity: number;
          scProjectCapacity: number;
          queryGroupCapacity: number;
          urlGroupCapacity: number;
          viewCapacity: number;
          uaProjectCapacity: number;
        } | null;
      } | null;
    };
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    preferredLanguage: string;
    isTutorialMovieViewed: boolean;
  };
};

export type RegisterServiceAccountMutationVariables = Exact<{
  input: RegisterServiceAccountInput;
}>;

export type RegisterServiceAccountMutation = {
  __typename?: 'Mutation';
  registerServiceAccount: {__typename?: 'ServiceAccount'; email: string};
};

export type UnregisterServiceAccountMutationVariables = Exact<{
  input: UnregisterServiceAccountInput;
}>;

export type UnregisterServiceAccountMutation = {
  __typename?: 'Mutation';
  unregisterServiceAccount: {
    __typename?: 'UnregisteredServiceAccountPayload';
    id: string;
  };
};

export type ChangeOrganizationRoleMutationVariables = Exact<{
  input?: InputMaybe<ChangeOrganizationRoleInput>;
}>;

export type ChangeOrganizationRoleMutation = {
  __typename?: 'Mutation';
  changeOrganizationRole: {
    __typename?: 'UserOrganization';
    user: {__typename?: 'User'; id: string; email: string};
    organization: {
      __typename?: 'Organization';
      id: string;
      users: Array<{
        __typename?: 'UserOrganization';
        role: UserOrganizationRole;
      }>;
    };
  };
};

export type RemoveUserFromOrganizationMutationVariables = Exact<{
  input: RemoveUserFromOrganizationInput;
}>;

export type RemoveUserFromOrganizationMutation = {
  __typename?: 'Mutation';
  removeUserFromOrganization: boolean;
};

export const OrgSwitcherViewerQueryFragmentDoc = gql`
  fragment OrgSwitcherViewerQuery on Query {
    viewer {
      id
      organizations {
        organization {
          id
          name
          contract {
            id
            subscriptionPlan {
              id
              orgCapacity
            }
          }
        }
      }
    }
  }
`;
export const SideNavigationOrgFragmentDoc = gql`
  fragment SideNavigationOrg on Organization {
    id
    name
    indexWorkerProjects: projects {
      ... on IndexWorkerProject {
        id
        name
        color
        indexWorkers {
          id
          name
          color
        }
      }
    }
    searchAnalyticsProjects: projects {
      ... on SearchAnalyticsProject {
        id
        name
        color
        searchAnalytics {
          latestDataDate
          views {
            id
            name
          }
        }
      }
    }
    userAnalyticsProjects: projects {
      ... on UserAnalyticsProject {
        id
        name
        color
        userAnalytics {
          id
          views {
            id
            name
          }
        }
      }
    }
  }
`;
export const ExportStatusIconFragmentDoc = gql`
  fragment ExportStatusIcon on Organization {
    id
    projects {
      ... on SearchAnalyticsProject {
        id
        name
        color
        searchAnalytics {
          id
          exportData {
            urls
            expires
            status
            exportedAt
            exportType
          }
        }
      }
      ... on UserAnalyticsProject {
        id
        name
        color
        userAnalytics {
          id
          exportData {
            urls
            expires
            status
            exportedAt
            exportType
          }
        }
      }
    }
  }
`;
export const UsePlanOrgFragmentDoc = gql`
  fragment UsePlanOrg on Organization {
    id
    contract {
      id
      name
      type
      organizations {
        id
      }
      subscriptionPlan {
        id
        name
        urlGroupCapacity
        viewCapacity
        propertyCapacity
        userCapacity
        guestCapacity
        orgCapacity
        iwProjectCapacity
        scProjectCapacity
        queryGroupCapacity
        uaProjectCapacity
      }
      stripeSubscriptionStatus
    }
  }
`;
export const ImportUrlGroupModal_SearchAnalyticsProjectFragmentDoc = gql`
  fragment ImportUrlGroupModal_SearchAnalyticsProject on SearchAnalyticsProject {
    id
    name
    searchAnalytics {
      id
      urlGroups {
        id
        name
        op
        patterns {
          type
          pattern
        }
      }
    }
  }
`;
export const ImportUrlGroupModal_UserAnalyticsProjectFragmentDoc = gql`
  fragment ImportUrlGroupModal_UserAnalyticsProject on UserAnalyticsProject {
    id
    name
    userAnalytics {
      id
      urlGroups {
        id
        name
        op
        patterns {
          type
          pattern
        }
      }
    }
  }
`;
export const DashboardNameInputFragmentDoc = gql`
  fragment DashboardNameInput on Dashboard {
    id
    name
  }
`;
export const DashboardDescriptionTextareaFragmentDoc = gql`
  fragment DashboardDescriptionTextarea on Dashboard {
    id
    description
  }
`;
export const DashboardItemMetricWithTimeSeriesFragmentDoc = gql`
  fragment DashboardItemMetricWithTimeSeries on DashboardItemMetricWithTimeSeries {
    id
    name
    description
    type
    featureID
    timeSeries(timeRange: $timeRange) {
      date
      value
    }
  }
`;
export const DashboardFragmentDoc = gql`
  fragment Dashboard on Dashboard {
    id
    description
    ...DashboardNameInput
    ...DashboardDescriptionTextarea
    items {
      name
      ... on DashboardItemMetricWithTimeSeries {
        ...DashboardItemMetricWithTimeSeries
      }
      ... on DashboardItemDivider {
        id
      }
    }
  }
  ${DashboardNameInputFragmentDoc}
  ${DashboardDescriptionTextareaFragmentDoc}
  ${DashboardItemMetricWithTimeSeriesFragmentDoc}
`;
export const DashboardItemFragmentDoc = gql`
  fragment DashboardItem on DashboardItem {
    ... on DashboardItemMetricWithTimeSeries {
      ...DashboardItemMetricWithTimeSeries
    }
  }
  ${DashboardItemMetricWithTimeSeriesFragmentDoc}
`;
export const SearchAnalyticsViewFeatureDataFragmentDoc = gql`
  fragment SearchAnalyticsViewFeatureData on SearchAnalyticsView {
    id
    name
  }
`;
export const IndexWorkerFeatureDataFragmentDoc = gql`
  fragment IndexWorkerFeatureData on IndexWorker {
    id
    name
    color
  }
`;
export const DashboardTabFragmentDoc = gql`
  fragment DashboardTab on Dashboard {
    id
    name
  }
`;
export const DashboardTabsFragmentDoc = gql`
  fragment DashboardTabs on Organization {
    dashboards {
      ...DashboardTab
    }
  }
  ${DashboardTabFragmentDoc}
`;
export const IndexWorkerApiQuotaAlertFragmentDoc = gql`
  fragment IndexWorkerApiQuotaAlert on Organization {
    id
    registeredProperties {
      property
      alerts {
        totalUrlCount
        from {
          day
          time
        }
        to {
          day
          time
        }
        indexWorkers {
          id
          name
          color
          urls
          urlType
          samplingCount
          organization {
            id
            name
          }
        }
      }
      ... on RegisteredSearchConsoleUserProperty {
        id
        user {
          id
          email
        }
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        id
        serviceAccount {
          id
          email
        }
      }
    }
  }
`;
export const DashboardDrawerProjectSelectIndexWorkerProjectFragmentDoc = gql`
  fragment DashboardDrawerProjectSelectIndexWorkerProject on IndexWorkerProject {
    indexWorkers {
      id
    }
  }
`;
export const DashboardDrawerProjectSelectSearchAnalyticsProjectFragmentDoc = gql`
  fragment DashboardDrawerProjectSelectSearchAnalyticsProject on SearchAnalyticsProject {
    searchAnalytics {
      id
      latestDataDate
    }
  }
`;
export const DashboardDrawerProjectSelectFragmentDoc = gql`
  fragment DashboardDrawerProjectSelect on Organization {
    id
    projects {
      id
      name
      color
      ... on IndexWorkerProject {
        ...DashboardDrawerProjectSelectIndexWorkerProject
      }
      ... on SearchAnalyticsProject {
        ...DashboardDrawerProjectSelectSearchAnalyticsProject
      }
    }
  }
  ${DashboardDrawerProjectSelectIndexWorkerProjectFragmentDoc}
  ${DashboardDrawerProjectSelectSearchAnalyticsProjectFragmentDoc}
`;
export const DashboardIndexWorkerSelectFragmentDoc = gql`
  fragment DashboardIndexWorkerSelect on IndexWorkerProject {
    id
    indexWorkers {
      id
      name
      color
    }
  }
`;
export const DashboardSaViewSelectFragmentDoc = gql`
  fragment DashboardSaViewSelect on SearchAnalyticsProject {
    id
    searchAnalytics {
      id
      views {
        id
        name
      }
    }
  }
`;
export const GettingStartedOrganizationFragmentDoc = gql`
  fragment GettingStartedOrganization on Organization {
    id
    doneGettingStartedTours
  }
`;
export const IwDetailApiQuotaAlertFragmentDoc = gql`
  fragment IwDetailApiQuotaAlert on IndexWorker {
    id
    property {
      property
      alerts {
        totalUrlCount
        from {
          day
          time
        }
        to {
          day
          time
        }
        indexWorkers {
          id
          name
          color
          urls
          urlType
          samplingCount
          organization {
            id
            name
          }
        }
      }
      ... on RegisteredSearchConsoleUserProperty {
        id
        user {
          id
          email
        }
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        id
        serviceAccount {
          id
          email
        }
      }
    }
  }
`;
export const IndexWorkerHeadingFragmentDoc = gql`
  fragment IndexWorkerHeading on IndexWorker {
    id
    name
    color
    note
    schedule
    urlType
    property {
      property
    }
    urlGroup {
      id
      name
      patterns {
        type
        pattern
      }
      op
    }
    rssFeed
    sitemap
    urls
    jobs(first: 1) {
      edges {
        node {
          completedAt
          id
          tasks(first: 2000) {
            edges {
              node {
                url {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const IndexWorkerSummaryCardFragmentDoc = gql`
  fragment IndexWorkerSummaryCard on IndexWorker {
    id
    name
    color
    note
    schedule
    urlType
    property {
      property
    }
    rssFeed
    sitemap
    urls
    samplingCount
    urlGroup {
      id
      name
      patterns {
        type
        pattern
      }
      op
    }
    jobs(first: 1) {
      edges {
        node {
          completedAt
          id
          tasks(first: 2000) {
            edges {
              node {
                url {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const IndexWorkerChartFragmentDoc = gql`
  fragment IndexWorkerChart on IndexWorker {
    jobs(first: $first, range: $range) {
      edges {
        node {
          id
          completedAt
          result {
            crawledCount
            indexedCount
            canonicalIndexedCount
            totalCount
            crawledRate
            indexedRate
            canonicalIndexedRate
            sevenDaysMovingAverageCrawledRate
            sevenDaysMovingAverageIndexedRate
            sevenDaysMovingAverageCanonicalIndexedRate
            twentyEightDaysMovingAverageCrawledRate
            twentyEightDaysMovingAverageIndexedRate
            twentyEightDaysMovingAverageCanonicalIndexedRate
          }
        }
      }
    }
  }
`;
export const UrlsTableFragmentDoc = gql`
  fragment UrlsTable on IndexWorker {
    id
    name
    latestJob: jobs(first: 1, range: $range) {
      edges {
        node {
          id
          failureURLs {
            url
            error
          }
          tasks(first: 2000) {
            edges {
              node {
                url {
                  id
                  url
                }
                crawled
                indexed
                result {
                  coverageState
                  crawledAs
                  forceSendFields
                  googleCanonical
                  indexingState
                  lastCrawlTime
                  nullFields
                  pageFetchState
                  refferingUrls
                  robotsTxtState
                  sitemap
                  userCanonical
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;
export const StatusReportCardFragmentDoc = gql`
  fragment StatusReportCard on IndexWorker {
    id
    latestJob: jobs(first: 1, range: $range) {
      edges {
        node {
          id
          failureURLs {
            url
            error
          }
          tasks(first: 2000) {
            edges {
              node {
                url {
                  id
                  url
                }
                crawled
                indexed
                result {
                  coverageState
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;
export const IndexWorkerFormDialogFragmentDoc = gql`
  fragment IndexWorkerFormDialog on IndexWorker {
    id
    name
    note
    urlType
    propertyID
    rssFeed
    rssFeedSamplingPeriod
    samplingCount
    sitemap
    urlGroup {
      op
      patterns {
        pattern
        type
      }
    }
    color
    urls
    schedule
    ignoreURLParameters
  }
`;
export const SuspendIndexWorkerModalFragmentDoc = gql`
  fragment SuspendIndexWorkerModal on IndexWorker {
    id
    name
    color
    schedule
  }
`;
export const NotificationSettingFragmentDoc = gql`
  fragment NotificationSetting on Organization {
    id
    name
    doNotNotifyTypes
  }
`;
export const PermissionTable_OrganizationFragmentDoc = gql`
  fragment PermissionTable_Organization on Organization {
    id
    users {
      userID
      user {
        id
        email
      }
      role
      status
    }
    serviceAccounts {
      id
      email
    }
    contract {
      id
      subscriptionPlan {
        id
        userCapacity
        guestCapacity
      }
      options
    }
  }
`;
export const AddUserDialogForm_OrganizationFragmentDoc = gql`
  fragment AddUserDialogForm_Organization on Organization {
    id
    name
    users {
      userID
      role
    }
    contract {
      id
      name
      subscriptionPlan {
        id
        userCapacity
        guestCapacity
      }
      options
    }
  }
`;
export const AddUserDialogConfirm_OrganizationFragmentDoc = gql`
  fragment AddUserDialogConfirm_Organization on Organization {
    id
    name
  }
`;
export const AddUserDialog_OrganizationFragmentDoc = gql`
  fragment AddUserDialog_Organization on Organization {
    id
    ...AddUserDialogForm_Organization
    ...AddUserDialogConfirm_Organization
  }
  ${AddUserDialogForm_OrganizationFragmentDoc}
  ${AddUserDialogConfirm_OrganizationFragmentDoc}
`;
export const AddUserButton_OrganizationFragmentDoc = gql`
  fragment AddUserButton_Organization on Organization {
    id
    ...AddUserDialog_Organization
  }
  ${AddUserDialog_OrganizationFragmentDoc}
`;
export const AddUserForm_OrganizationFragmentDoc = gql`
  fragment AddUserForm_Organization on Organization {
    id
    users {
      userID
      user {
        id
        email
      }
      role
      status
    }
    ...AddUserButton_Organization
  }
  ${AddUserButton_OrganizationFragmentDoc}
`;
export const PermissionContent_OrganizationFragmentDoc = gql`
  fragment PermissionContent_Organization on Organization {
    id
    ...PermissionTable_Organization
    ...AddUserForm_Organization
  }
  ${PermissionTable_OrganizationFragmentDoc}
  ${AddUserForm_OrganizationFragmentDoc}
`;
export const PermissionTable_ViewerFragmentDoc = gql`
  fragment PermissionTable_Viewer on User {
    id
    email
    isAdmin
    isCustomerSupport(organizationID: $orgId)
  }
`;
export const PermissionContent_ViewerFragmentDoc = gql`
  fragment PermissionContent_Viewer on User {
    id
    ...PermissionTable_Viewer
  }
  ${PermissionTable_ViewerFragmentDoc}
`;
export const SearchAnalyticsChatFragmentFragmentDoc = gql`
  fragment SearchAnalyticsChatFragment on SearchAnalyticsChat {
    id
    messages {
      role
      content
      createdAt
    }
    messageSuggestions
    createdAt
    updatedAt
    dataToDate
  }
`;
export const SuggestUrlGroupDialogFragmentDoc = gql`
  fragment SuggestUrlGroupDialog on SearchAnalytics {
    id
    recommendedURLGroups {
      patterns {
        type
        pattern
      }
      op
      coverage
    }
  }
`;
export const SelfManagedFragmentDoc = gql`
  fragment SelfManaged on Organization {
    id
    serviceAccountEmail
  }
`;
export const SessionDetailFragmentDoc = gql`
  fragment SessionDetail on SessionDetail {
    gaSessionId
    userPseudoId
    events {
      eventName
      eventTimestamp
      pageReferrer
      pageLocation
      pageLocationWithoutParameter
      pageTitle
      contentGroup
      eventParams {
        key
        value
      }
    }
  }
`;
export const SessionSummaryFragmentDoc = gql`
  fragment SessionSummary on SessionDetail {
    gaSessionId
    userPseudoId
    gaSessionNumber
    sessionDuration
    sessionStartTime
    sessionEndTime
    userFirstTouchTimestamp
    source
    medium
    campaign
    firstVisitSource
    firstVisitMedium
    firstVisitCampaign
    geography {
      continent
      country
      region
      city
    }
    pageReferrer
    device {
      category
      brand
      model
      os
      osVer
      language
      browser
      browserVer
    }
  }
`;
export const UserAnalyticsBreadcrumbsOrgFragmentDoc = gql`
  fragment UserAnalyticsBreadcrumbsOrg on Organization {
    id
    name
  }
`;
export const UserAnalyticsBreadcrumbsProjectFragmentDoc = gql`
  fragment UserAnalyticsBreadcrumbsProject on UserAnalyticsProject {
    id
    name
  }
`;
export const SessionsCard_SessionFragmentDoc = gql`
  fragment SessionsCard_Session on Session {
    gaSessionId
    userPseudoId
    sessionStartTime
    sessionDuration
    pageLocationCount
    device {
      category
      os
      browser
    }
    source
    medium
    campaign
    pageReferrer
    geography {
      country
      region
      city
    }
  }
`;
export const UrlGroupIdsDropdown_UrlGroupFragmentDoc = gql`
  fragment UrlGroupIdsDropdown_URLGroup on URLGroup {
    id
    name
  }
`;
export const UseFilterChips_UrlGroupFragmentDoc = gql`
  fragment UseFilterChips_URLGroup on URLGroup {
    id
    name
  }
`;
export const DetailHeader_UrlGroupFragmentDoc = gql`
  fragment DetailHeader_URLGroup on URLGroup {
    ...UseFilterChips_URLGroup
  }
  ${UseFilterChips_UrlGroupFragmentDoc}
`;
export const TableRow_SessionPerformanceDetailFragmentDoc = gql`
  fragment TableRow_SessionPerformanceDetail on SessionPerformanceDetail {
    date
    firstPageLocation
    firstPageLocationURLGroup {
      id
      name
    }
    secondPageLocation
    secondPageLocationURLGroup {
      id
      name
    }
    device
    os
    browser
    sourceMedium {
      source
      medium
    }
    campaign
    defaultChannelGroup
    sessionCount
    uniqueUserCount
    conversionRate
    surfRate
    avgSessionDuration
    engagementRate
    engagementCount
    avgPageLocationCount
  }
`;
export const MetricDetailsCard_UserAnalyticsFragmentDoc = gql`
  fragment MetricDetailsCard_UserAnalytics on UserAnalytics {
    sessionPerformanceDetail(
      dateRange: $dateRange
      os: $os
      devices: $devices
      browsers: $browsers
      sources: $sources
      mediums: $mediums
      campaigns: $campaigns
      campaignsFilterType: $campaignsFilterType
      events: $events
      firstPageLocation: $firstPageLocation
      firstPageLocationURLGroupIDs: $firstPageLocationURLGroupIDs
      firstPageLocationFilterType: $firstPageLocationFilterType
      firstPageTitle: $firstPageTitle
      firstPageTitleFilterType: $firstPageTitleFilterType
      secondPageLocation: $secondPageLocation
      secondPageLocationURLGroupIDs: $secondPageLocationURLGroupIDs
      secondPageLocationFilterType: $secondPageLocationFilterType
      secondPageTitle: $secondPageTitle
      secondPageTitleFilterType: $secondPageTitleFilterType
      limit: $sessionPerformanceDetailLimit
      offset: $sessionPerformanceDetailOffset
      groupBy: $sessionPerformanceDetailGroupBy
      orderBy: $sessionPerformanceDetailOrderBy
      isSessionWithNavigation: $isSessionWithNavigation
      defaultChannelGroup: $defaultChannelGroup
      pageLocationCount: $pageLocationCount
    ) {
      ...TableRow_SessionPerformanceDetail
    }
  }
  ${TableRow_SessionPerformanceDetailFragmentDoc}
`;
export const MetricDetailsCard_UserAnalyticsViewFragmentDoc = gql`
  fragment MetricDetailsCard_UserAnalyticsView on UserAnalyticsView {
    sessionPerformanceDetail(
      dateRange: $dateRange
      groupBy: $sessionPerformanceDetailGroupBy
      limit: $sessionPerformanceDetailLimit
      offset: $sessionPerformanceDetailOffset
      orderBy: $sessionPerformanceDetailOrderBy
    ) {
      ...TableRow_SessionPerformanceDetail
    }
  }
  ${TableRow_SessionPerformanceDetailFragmentDoc}
`;
export const ConversionEventsDialog_UserAnalyticsFragmentDoc = gql`
  fragment ConversionEventsDialog_UserAnalytics on UserAnalytics {
    id
    keyEvents
    eventNames
  }
`;
export const ProjectMenuFragmentDoc = gql`
  fragment ProjectMenu on UserAnalyticsProject {
    id
    name
    color
    description
    userAnalytics {
      id
      googleCloudProjectID
      datasetName
      keyEvents
      ...ConversionEventsDialog_UserAnalytics
    }
  }
  ${ConversionEventsDialog_UserAnalyticsFragmentDoc}
`;
export const Chart_SessionPerformanceFragmentDoc = gql`
  fragment Chart_SessionPerformance on SessionPerformance {
    date
    sourceMedium {
      source
      medium
    }
    firstPageLocationURLGroup {
      id
      name
    }
    secondPageLocationURLGroup {
      id
      name
    }
    sessionCount
    uniqueUserCount
    conversionRate
    conversionCount
    surfRate
    surfCount
    engagementRate
    engagementCount
  }
`;
export const ChartCard_UserAnalyticsViewFragmentDoc = gql`
  fragment ChartCard_UserAnalyticsView on UserAnalyticsView {
    id
    name
    description
    os
    devices
    browsers
    sources
    mediums
    campaigns
    campaignsFilterType
    events
    firstPageTitle
    firstPageTitleFilterType
    firstPageLocationURLGroupIDs
    firstPageLocationURL
    firstPageLocationFilterType
    secondPageTitle
    secondPageTitleFilterType
    secondPageLocationURLGroupIDs
    secondPageLocationURL
    secondPageLocationFilterType
    isSessionWithNavigation
    defaultChannelGroup
    defaultDateRange {
      type
      dateRange {
        start
        end
      }
    }
    sessionPerformance(
      dateRange: $dateRange
      groupBy: $sessionPerformanceGroupBy
    ) {
      ...Chart_SessionPerformance
    }
  }
  ${Chart_SessionPerformanceFragmentDoc}
`;
export const ChartCard_UserAnalyticsFragmentDoc = gql`
  fragment ChartCard_UserAnalytics on UserAnalytics {
    id
    sessionPerformance(
      dateRange: $dateRange
      os: $os
      devices: $devices
      browsers: $browsers
      sources: $sources
      mediums: $mediums
      campaigns: $campaigns
      campaignsFilterType: $campaignsFilterType
      events: $events
      firstPageTitle: $firstPageTitle
      firstPageTitleFilterType: $firstPageTitleFilterType
      firstPageLocation: $firstPageLocation
      firstPageLocationURLGroupIDs: $firstPageLocationURLGroupIDs
      firstPageLocationFilterType: $firstPageLocationFilterType
      secondPageTitle: $secondPageTitle
      secondPageTitleFilterType: $secondPageTitleFilterType
      secondPageLocation: $secondPageLocation
      secondPageLocationURLGroupIDs: $secondPageLocationURLGroupIDs
      secondPageLocationFilterType: $secondPageLocationFilterType
      groupBy: $sessionPerformanceGroupBy
      isSessionWithNavigation: $isSessionWithNavigation
      defaultChannelGroup: $defaultChannelGroup
      pageLocationCount: $pageLocationCount
    ) {
      ...Chart_SessionPerformance
    }
  }
  ${Chart_SessionPerformanceFragmentDoc}
`;
export const ViewList_UserAnalyticsFragmentDoc = gql`
  fragment ViewList_UserAnalytics on UserAnalytics {
    id
    googleCloudProjectID
    datasetName
    keyEvents
    views {
      ...ChartCard_UserAnalyticsView
    }
    ...ChartCard_UserAnalytics
  }
  ${ChartCard_UserAnalyticsViewFragmentDoc}
  ${ChartCard_UserAnalyticsFragmentDoc}
`;
export const Chart_UrlGroupFragmentDoc = gql`
  fragment Chart_URLGroup on URLGroup {
    id
    name
  }
`;
export const UpdateViewDialog_UserAnalyticsViewFragmentDoc = gql`
  fragment UpdateViewDialog_UserAnalyticsView on UserAnalyticsView {
    id
    os
    devices
    browsers
    sources
    mediums
    campaigns
    detailGroupBy
    detailOrderBy {
      field
      direction
    }
    name
    description
    defaultDateRange {
      type
      dateRange {
        start
        end
      }
    }
    firstPageLocationURLGroupIDs
    firstPageLocationURL
    firstPageLocationFilterType
    firstPageTitle
    firstPageTitleFilterType
    secondPageLocationURLGroupIDs
    secondPageLocationURL
    secondPageLocationFilterType
    secondPageTitle
    secondPageTitleFilterType
    isSessionWithNavigation
    defaultChannelGroup
  }
`;
export const UrlGroupDialog_UrlGroupPatternFragmentDoc = gql`
  fragment UrlGroupDialog_URLGroupPattern on URLGroupPattern {
    type
    pattern
  }
`;
export const UrlGroupDialog_UrlGroupFragmentDoc = gql`
  fragment UrlGroupDialog_URLGroup on URLGroup {
    id
    name
    op
    patterns {
      ...UrlGroupDialog_URLGroupPattern
    }
  }
  ${UrlGroupDialog_UrlGroupPatternFragmentDoc}
`;
export const UrlGroupTable_UrlGroupFragmentDoc = gql`
  fragment UrlGroupTable_URLGroup on URLGroup {
    ...UrlGroupDialog_URLGroup
  }
  ${UrlGroupDialog_UrlGroupFragmentDoc}
`;
export const DeleteUrlGroupModal_UrlGroupFragmentDoc = gql`
  fragment DeleteUrlGroupModal_URLGroup on URLGroup {
    id
    name
  }
`;
export const UpdateUrlGroupDialog_UrlGroupFragmentDoc = gql`
  fragment UpdateUrlGroupDialog_URLGroup on URLGroup {
    id
    name
    op
    patterns {
      type
      pattern
    }
  }
`;
export const IndexWorkerResultFragmentDoc = gql`
  fragment IndexWorkerResult on IndexWorkerResult {
    crawledCount
    indexedCount
    canonicalIndexedCount
    totalCount
    crawledRate
    indexedRate
    canonicalIndexedRate
    sevenDaysMovingAverageCrawledRate
    sevenDaysMovingAverageIndexedRate
    sevenDaysMovingAverageCanonicalIndexedRate
    twentyEightDaysMovingAverageCrawledRate
    twentyEightDaysMovingAverageIndexedRate
    twentyEightDaysMovingAverageCanonicalIndexedRate
  }
`;
export const IndexWorkerJobFragmentDoc = gql`
  fragment IndexWorkerJob on IndexWorkerJob {
    id
    completedAt
    result {
      ...IndexWorkerResult
    }
  }
  ${IndexWorkerResultFragmentDoc}
`;
export const IndexWorkerTaskResultFragmentDoc = gql`
  fragment IndexWorkerTaskResult on IndexWorkerTaskResult {
    coverageState
    crawledAs
    forceSendFields
    googleCanonical
    indexingState
    lastCrawlTime
    nullFields
    pageFetchState
    refferingUrls
    robotsTxtState
    sitemap
    userCanonical
  }
`;
export const IndexWorkerTaskFragmentDoc = gql`
  fragment IndexWorkerTask on IndexWorkerTask {
    url {
      id
      url
    }
    crawled
    indexed
    result {
      ...IndexWorkerTaskResult
    }
    createdAt
  }
  ${IndexWorkerTaskResultFragmentDoc}
`;
export const NotificationSummaryFragmentDoc = gql`
  fragment NotificationSummary on Notification {
    id
    title
    type
    readAt
    createdAt
    updatedAt
    severity
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    ...NotificationSummary
    body
  }
  ${NotificationSummaryFragmentDoc}
`;
export const RegisteredSearchConsoleUserPropertyFragmentDoc = gql`
  fragment RegisteredSearchConsoleUserProperty on RegisteredSearchConsoleUserProperty {
    id
    property
    user {
      id
      name
      email
    }
  }
`;
export const RegisteredSearchConsoleServiceAccountPropertyFragmentDoc = gql`
  fragment RegisteredSearchConsoleServiceAccountProperty on RegisteredSearchConsoleServiceAccountProperty {
    id
    property
    serviceAccount {
      id
      email
    }
  }
`;
export const PlanFragmentDoc = gql`
  fragment Plan on Plan {
    id
    name
    propertyCapacity
    userCapacity
    guestCapacity
    orgCapacity
    iwProjectCapacity
    iwJobCapacity
    scProjectCapacity
    queryGroupCapacity
    urlGroupCapacity
    viewCapacity
    uaProjectCapacity
  }
`;
export const ContractFragmentDoc = gql`
  fragment Contract on Contract {
    id
    name
    type
    subscriptionPlan {
      ...Plan
    }
    stripeSubscriptionStatus
  }
  ${PlanFragmentDoc}
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    registeredProperties {
      ... on RegisteredSearchConsoleUserProperty {
        ...RegisteredSearchConsoleUserProperty
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        ...RegisteredSearchConsoleServiceAccountProperty
      }
    }
    projects {
      id
      name
      color
      ... on IndexWorkerProject {
        indexWorkers {
          id
          name
          color
          property {
            ... on RegisteredSearchConsoleUserProperty {
              ...RegisteredSearchConsoleUserProperty
            }
            ... on RegisteredSearchConsoleServiceAccountProperty {
              ...RegisteredSearchConsoleServiceAccountProperty
            }
          }
        }
      }
    }
    users {
      userID
      user {
        id
        email
        name
        picture
      }
      role
    }
    serviceAccounts {
      id
      email
    }
    contract {
      ...Contract
    }
  }
  ${RegisteredSearchConsoleUserPropertyFragmentDoc}
  ${RegisteredSearchConsoleServiceAccountPropertyFragmentDoc}
  ${ContractFragmentDoc}
`;
export const OrganizationBasicInfoFragmentDoc = gql`
  fragment OrganizationBasicInfo on Organization {
    id
    name
    contract {
      ...Contract
    }
  }
  ${ContractFragmentDoc}
`;
export const UserOrganizationFragmentDoc = gql`
  fragment UserOrganization on UserOrganization {
    userID
    user {
      id
      email
    }
    organizationID
    organization {
      ...OrganizationBasicInfo
    }
    role
  }
  ${OrganizationBasicInfoFragmentDoc}
`;
export const UrlGroupPatternFragmentDoc = gql`
  fragment URLGroupPattern on URLGroupPattern {
    type
    pattern
  }
`;
export const IndexWorkerFragmentDoc = gql`
  fragment IndexWorker on IndexWorker {
    id
    name
    note
    urlType
    property {
      ... on RegisteredSearchConsoleUserProperty {
        ...RegisteredSearchConsoleUserProperty
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        ...RegisteredSearchConsoleServiceAccountProperty
      }
    }
    rssFeed
    rssFeedSamplingPeriod
    urlGroup {
      name
      patterns {
        ...URLGroupPattern
      }
      op
    }
    samplingCount
    sitemap
    color
    urls
    schedule
    ignoreURLParameters
  }
  ${RegisteredSearchConsoleUserPropertyFragmentDoc}
  ${RegisteredSearchConsoleServiceAccountPropertyFragmentDoc}
  ${UrlGroupPatternFragmentDoc}
`;
export const SearchAnalyticsViewBasicInfoFragmentDoc = gql`
  fragment SearchAnalyticsViewBasicInfo on SearchAnalyticsView {
    id
    name
  }
`;
export const SearchAnalyticsBasicInfoFragmentDoc = gql`
  fragment SearchAnalyticsBasicInfo on SearchAnalytics {
    id
    datasetName
    googleCloudProjectID
    manageType
    views {
      ...SearchAnalyticsViewBasicInfo
    }
  }
  ${SearchAnalyticsViewBasicInfoFragmentDoc}
`;
export const ProjectFragmentDoc = gql`
  fragment Project on Project {
    id
    name
    color
    ... on IndexWorkerProject {
      indexWorkers {
        ...IndexWorker
      }
    }
    ... on SearchAnalyticsProject {
      searchAnalytics {
        ...SearchAnalyticsBasicInfo
      }
    }
  }
  ${IndexWorkerFragmentDoc}
  ${SearchAnalyticsBasicInfoFragmentDoc}
`;
export const IndexWorkerProjectFragmentDoc = gql`
  fragment IndexWorkerProject on IndexWorkerProject {
    id
    name
    color
    description
    indexWorkers {
      ...IndexWorker
    }
  }
  ${IndexWorkerFragmentDoc}
`;
export const SearchAnalyticsProjectFragmentDoc = gql`
  fragment SearchAnalyticsProject on SearchAnalyticsProject {
    id
    name
    color
    description
    searchAnalytics {
      ...SearchAnalyticsBasicInfo
    }
  }
  ${SearchAnalyticsBasicInfoFragmentDoc}
`;
export const SearchAnalyticsViewFragmentDoc = gql`
  fragment SearchAnalyticsView on SearchAnalyticsView {
    id
    name
    description
    query
    queryFilterType
    queryGroupIDs
    url
    urlFilterType
    urlGroupIDs
    devices
    searchTypes
    countries
    isAnonymousQuery
    dataDate {
      type
      dateRange {
        start
        end
      }
    }
    rawDataGroupBy
    rawDataOrderBy {
      field
      direction
    }
    spreadsheetID
  }
`;
export const QueryGroupPatternFragmentDoc = gql`
  fragment QueryGroupPattern on QueryGroupPattern {
    type
    pattern
  }
`;
export const QueryGroupFragmentDoc = gql`
  fragment QueryGroup on QueryGroup {
    id
    name
    patterns {
      ...QueryGroupPattern
    }
    op
  }
  ${QueryGroupPatternFragmentDoc}
`;
export const UrlGroupFragmentDoc = gql`
  fragment URLGroup on URLGroup {
    id
    name
    patterns {
      ...URLGroupPattern
    }
    op
  }
  ${UrlGroupPatternFragmentDoc}
`;
export const SearchAnalyticsQueryUrlGroupFragmentDoc = gql`
  fragment SearchAnalyticsQueryUrlGroup on SearchAnalytics {
    id
    queryGroups {
      ...QueryGroup
    }
    urlGroups {
      ...URLGroup
    }
  }
  ${QueryGroupFragmentDoc}
  ${UrlGroupFragmentDoc}
`;
export const AggregatedDataNonGroupedFragmentDoc = gql`
  fragment AggregatedDataNonGrouped on SearchAnalyticsAggregatedDataNonGrouped {
    date
    tableType
    sumOfClicks
    sumOfImpressions
    ctr
    avgOfRank
    distinctQueries
    distinctURLs
    soc7d
    soc28d
    soi7d
    soi28d
    ctr7d
    ctr28d
    aor7d
    aor28d
    dq7d
    dq28d
    du7d
    du28d
  }
`;
export const AggregatedDataByQueryGroupFragmentDoc = gql`
  fragment AggregatedDataByQueryGroup on SearchAnalyticsAggregatedDataByQueryGroup {
    date
    tableType
    sumOfClicks
    sumOfImpressions
    ctr
    avgOfRank
    distinctQueries
    distinctURLs
    soc7d
    soc28d
    soi7d
    soi28d
    ctr7d
    ctr28d
    aor7d
    aor28d
    dq7d
    dq28d
    du7d
    du28d
    queryGroup {
      ...QueryGroup
    }
  }
  ${QueryGroupFragmentDoc}
`;
export const AggregatedDataByUrlGroupFragmentDoc = gql`
  fragment AggregatedDataByUrlGroup on SearchAnalyticsAggregatedDataByURLGroup {
    date
    tableType
    sumOfClicks
    sumOfImpressions
    ctr
    avgOfRank
    distinctQueries
    distinctURLs
    soc7d
    soc28d
    soi7d
    soi28d
    ctr7d
    ctr28d
    aor7d
    aor28d
    dq7d
    dq28d
    du7d
    du28d
    urlGroup {
      ...URLGroup
    }
  }
  ${UrlGroupFragmentDoc}
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
export const SearchAnalyticsRawDataFragmentDoc = gql`
  fragment SearchAnalyticsRawData on SearchAnalyticsRawData {
    date
    query
    device
    country
    searchType
    isAnonymousQuery
    isAnonymizedDiscover
    url
    clicks
    impressions
    ctr
    avgOfRank
    distinctQueries
    distinctURLs
    queryGroup {
      id
      name
    }
    urlGroup {
      id
      name
    }
  }
`;
export const SearchAnalyticsRawDataConnectionFragmentDoc = gql`
  fragment SearchAnalyticsRawDataConnection on SearchAnalyticsRawDataConnection {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...SearchAnalyticsRawData
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${SearchAnalyticsRawDataFragmentDoc}
`;
export const SearchAnalyticsRawComparingDataFragmentDoc = gql`
  fragment SearchAnalyticsRawComparingData on SearchAnalyticsRawComparingData {
    query
    device
    country
    searchType
    isAnonymousQuery
    isAnonymizedDiscover
    url
    clicks
    impressions
    ctr
    avgOfRank
    distinctQueries
    distinctURLs
    queryGroup {
      id
      name
    }
    urlGroup {
      id
      name
    }
    comparedClicks
    comparedImpressions
    comparedCtr
    comparedAvgOfRank
    comparedDistinctQueries
    comparedDistinctURLs
  }
`;
export const SearchAnalyticsRawComparingDataConnectionFragmentDoc = gql`
  fragment SearchAnalyticsRawComparingDataConnection on SearchAnalyticsRawComparingDataConnection {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...SearchAnalyticsRawComparingData
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${SearchAnalyticsRawComparingDataFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    name
    picture
    email
    organizations {
      organizationID
      role
      organization {
        ...OrganizationBasicInfo
      }
    }
  }
  ${OrganizationBasicInfoFragmentDoc}
`;
export const AppLayoutDocument = gql`
  query AppLayout($organizationID: ID!, $includeOrganization: Boolean!) {
    viewer {
      id
    }
    org: node(id: $organizationID) @include(if: $includeOrganization) {
      ... on Organization {
        id
        doneGettingStartedTours
        ...IndexWorkerApiQuotaAlert
        ...UsePlanOrg
      }
    }
  }
  ${IndexWorkerApiQuotaAlertFragmentDoc}
  ${UsePlanOrgFragmentDoc}
`;

/**
 * __useAppLayoutQuery__
 *
 * To run a query within a React component, call `useAppLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppLayoutQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      includeOrganization: // value for 'includeOrganization'
 *   },
 * });
 */
export function useAppLayoutQuery(
  baseOptions: Apollo.QueryHookOptions<AppLayoutQuery, AppLayoutQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<AppLayoutQuery, AppLayoutQueryVariables>(
    AppLayoutDocument,
    options
  );
}
export function useAppLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppLayoutQuery,
    AppLayoutQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<AppLayoutQuery, AppLayoutQueryVariables>(
    AppLayoutDocument,
    options
  );
}
export type AppLayoutQueryHookResult = ReturnType<typeof useAppLayoutQuery>;
export type AppLayoutLazyQueryHookResult = ReturnType<
  typeof useAppLayoutLazyQuery
>;
export type AppLayoutQueryResult = Apollo.QueryResult<
  AppLayoutQuery,
  AppLayoutQueryVariables
>;
export const SubscriptionStatusBoundaryDocument = gql`
  query SubscriptionStatusBoundary($orgId: ID!) {
    contractOrg: node(id: $orgId) {
      ... on Organization {
        id
        contract {
          id
          subscriptionPlan {
            id
          }
          stripeSubscriptionStatus
        }
      }
    }
  }
`;

/**
 * __useSubscriptionStatusBoundaryQuery__
 *
 * To run a query within a React component, call `useSubscriptionStatusBoundaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionStatusBoundaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionStatusBoundaryQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useSubscriptionStatusBoundaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionStatusBoundaryQuery,
    SubscriptionStatusBoundaryQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SubscriptionStatusBoundaryQuery,
    SubscriptionStatusBoundaryQueryVariables
  >(SubscriptionStatusBoundaryDocument, options);
}
export function useSubscriptionStatusBoundaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionStatusBoundaryQuery,
    SubscriptionStatusBoundaryQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SubscriptionStatusBoundaryQuery,
    SubscriptionStatusBoundaryQueryVariables
  >(SubscriptionStatusBoundaryDocument, options);
}
export type SubscriptionStatusBoundaryQueryHookResult = ReturnType<
  typeof useSubscriptionStatusBoundaryQuery
>;
export type SubscriptionStatusBoundaryLazyQueryHookResult = ReturnType<
  typeof useSubscriptionStatusBoundaryLazyQuery
>;
export type SubscriptionStatusBoundaryQueryResult = Apollo.QueryResult<
  SubscriptionStatusBoundaryQuery,
  SubscriptionStatusBoundaryQueryVariables
>;
export const SideNavigationDocument = gql`
  query SideNavigation($organizationId: ID!) {
    viewerOrganizationRole(organizationID: $organizationId)
    organization: node(id: $organizationId) {
      ... on Organization {
        ...SideNavigationOrg
      }
    }
    ...OrgSwitcherViewerQuery
  }
  ${SideNavigationOrgFragmentDoc}
  ${OrgSwitcherViewerQueryFragmentDoc}
`;

/**
 * __useSideNavigationQuery__
 *
 * To run a query within a React component, call `useSideNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSideNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSideNavigationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSideNavigationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SideNavigationQuery,
    SideNavigationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<SideNavigationQuery, SideNavigationQueryVariables>(
    SideNavigationDocument,
    options
  );
}
export function useSideNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SideNavigationQuery,
    SideNavigationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<SideNavigationQuery, SideNavigationQueryVariables>(
    SideNavigationDocument,
    options
  );
}
export type SideNavigationQueryHookResult = ReturnType<
  typeof useSideNavigationQuery
>;
export type SideNavigationLazyQueryHookResult = ReturnType<
  typeof useSideNavigationLazyQuery
>;
export type SideNavigationQueryResult = Apollo.QueryResult<
  SideNavigationQuery,
  SideNavigationQueryVariables
>;
export const ExportStatusIconDocument = gql`
  query ExportStatusIcon($organizationId: ID!) {
    organization: node(id: $organizationId) {
      ... on Organization {
        ...ExportStatusIcon
      }
    }
  }
  ${ExportStatusIconFragmentDoc}
`;

/**
 * __useExportStatusIconQuery__
 *
 * To run a query within a React component, call `useExportStatusIconQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportStatusIconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportStatusIconQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useExportStatusIconQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportStatusIconQuery,
    ExportStatusIconQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<ExportStatusIconQuery, ExportStatusIconQueryVariables>(
    ExportStatusIconDocument,
    options
  );
}
export function useExportStatusIconLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportStatusIconQuery,
    ExportStatusIconQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    ExportStatusIconQuery,
    ExportStatusIconQueryVariables
  >(ExportStatusIconDocument, options);
}
export type ExportStatusIconQueryHookResult = ReturnType<
  typeof useExportStatusIconQuery
>;
export type ExportStatusIconLazyQueryHookResult = ReturnType<
  typeof useExportStatusIconLazyQuery
>;
export type ExportStatusIconQueryResult = Apollo.QueryResult<
  ExportStatusIconQuery,
  ExportStatusIconQueryVariables
>;
export const UpdateProjectDocument = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      name
      color
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, options);
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>;
export type UpdateProjectMutationResult =
  Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsDocument = gql`
  mutation ImportUrlGroupModal_CreateURLGroupForSearchAnalytics(
    $input: CreateURLGroupInput!
  ) {
    createURLGroup(input: $input) {
      id
      name
      patterns {
        type
        pattern
      }
      op
    }
  }
`;
export type ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationFn =
  Apollo.MutationFunction<
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationVariables
  >;

/**
 * __useImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation__
 *
 * To run a mutation, you first call `useImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUrlGroupModalCreateUrlGroupForSearchAnalyticsMutation, { data, loading, error }] = useImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationVariables
  >(ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsDocument, options);
}
export type ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationHookResult =
  ReturnType<
    typeof useImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation
  >;
export type ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationResult =
  Apollo.MutationResult<ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation>;
export type ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationOptions =
  Apollo.BaseMutationOptions<
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForSearchAnalyticsMutationVariables
  >;
export const ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsDocument = gql`
  mutation ImportUrlGroupModal_CreateURLGroupForUserAnalytics(
    $input: CreateURUserAnalyticsURLGroupInput!
  ) {
    createURLGroupForUserAnalytics(input: $input) {
      id
      name
      patterns {
        type
        pattern
      }
      op
    }
  }
`;
export type ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationFn =
  Apollo.MutationFunction<
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationVariables
  >;

/**
 * __useImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation__
 *
 * To run a mutation, you first call `useImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUrlGroupModalCreateUrlGroupForUserAnalyticsMutation, { data, loading, error }] = useImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationVariables
  >(ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsDocument, options);
}
export type ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationHookResult =
  ReturnType<
    typeof useImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation
  >;
export type ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationResult =
  Apollo.MutationResult<ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation>;
export type ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationOptions =
  Apollo.BaseMutationOptions<
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutation,
    ImportUrlGroupModal_CreateUrlGroupForUserAnalyticsMutationVariables
  >;
export const FeatureDataDocument = gql`
  query FeatureData($projectId: ID!, $featureId: ID!) {
    project: node(id: $projectId) {
      ... on IndexWorkerProject {
        id
        name
        color
      }
      ... on SearchAnalyticsProject {
        id
        name
        color
      }
    }
    feature: node(id: $featureId) {
      ... on SearchAnalyticsView {
        ...SearchAnalyticsViewFeatureData
      }
      ... on IndexWorker {
        ...IndexWorkerFeatureData
      }
    }
  }
  ${SearchAnalyticsViewFeatureDataFragmentDoc}
  ${IndexWorkerFeatureDataFragmentDoc}
`;

/**
 * __useFeatureDataQuery__
 *
 * To run a query within a React component, call `useFeatureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useFeatureDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureDataQuery,
    FeatureDataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<FeatureDataQuery, FeatureDataQueryVariables>(
    FeatureDataDocument,
    options
  );
}
export function useFeatureDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureDataQuery,
    FeatureDataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<FeatureDataQuery, FeatureDataQueryVariables>(
    FeatureDataDocument,
    options
  );
}
export type FeatureDataQueryHookResult = ReturnType<typeof useFeatureDataQuery>;
export type FeatureDataLazyQueryHookResult = ReturnType<
  typeof useFeatureDataLazyQuery
>;
export type FeatureDataQueryResult = Apollo.QueryResult<
  FeatureDataQuery,
  FeatureDataQueryVariables
>;
export const DashboardPageDocument = gql`
  query DashboardPage($organizationId: ID!, $timeRange: TimeRangeInput!) {
    viewer {
      id
    }
    viewerOrganizationRole(organizationID: $organizationId)
    organization: node(id: $organizationId) {
      ... on Organization {
        id
        name
        contract {
          id
          subscriptionPlan {
            id
            name
          }
        }
        projects {
          id
          ... on IndexWorkerProject {
            ...DashboardIndexWorkerSelect
          }
          ... on SearchAnalyticsProject {
            ...DashboardSaViewSelect
          }
        }
        dashboards {
          ...Dashboard
        }
        ...DashboardDrawerProjectSelect
        ...DashboardTabs
        ...IndexWorkerApiQuotaAlert
      }
    }
  }
  ${DashboardIndexWorkerSelectFragmentDoc}
  ${DashboardSaViewSelectFragmentDoc}
  ${DashboardFragmentDoc}
  ${DashboardDrawerProjectSelectFragmentDoc}
  ${DashboardTabsFragmentDoc}
  ${IndexWorkerApiQuotaAlertFragmentDoc}
`;

/**
 * __useDashboardPageQuery__
 *
 * To run a query within a React component, call `useDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useDashboardPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardPageQuery,
    DashboardPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<DashboardPageQuery, DashboardPageQueryVariables>(
    DashboardPageDocument,
    options
  );
}
export function useDashboardPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardPageQuery,
    DashboardPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<DashboardPageQuery, DashboardPageQueryVariables>(
    DashboardPageDocument,
    options
  );
}
export type DashboardPageQueryHookResult = ReturnType<
  typeof useDashboardPageQuery
>;
export type DashboardPageLazyQueryHookResult = ReturnType<
  typeof useDashboardPageLazyQuery
>;
export type DashboardPageQueryResult = Apollo.QueryResult<
  DashboardPageQuery,
  DashboardPageQueryVariables
>;
export const CreateDashboardDocument = gql`
  mutation CreateDashboard($input: CreateDashboardInput!) {
    createDashboard(input: $input) {
      id
    }
  }
`;
export type CreateDashboardMutationFn = Apollo.MutationFunction<
  CreateDashboardMutation,
  CreateDashboardMutationVariables
>;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDashboardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDashboardMutation,
    CreateDashboardMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateDashboardMutation,
    CreateDashboardMutationVariables
  >(CreateDashboardDocument, options);
}
export type CreateDashboardMutationHookResult = ReturnType<
  typeof useCreateDashboardMutation
>;
export type CreateDashboardMutationResult =
  Apollo.MutationResult<CreateDashboardMutation>;
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<
  CreateDashboardMutation,
  CreateDashboardMutationVariables
>;
export const DeleteDashboardItemDocument = gql`
  mutation DeleteDashboardItem($input: DeleteDashboardItemInput!) {
    deleteDashboardItem(input: $input) {
      id
    }
  }
`;
export type DeleteDashboardItemMutationFn = Apollo.MutationFunction<
  DeleteDashboardItemMutation,
  DeleteDashboardItemMutationVariables
>;

/**
 * __useDeleteDashboardItemMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardItemMutation, { data, loading, error }] = useDeleteDashboardItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDashboardItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDashboardItemMutation,
    DeleteDashboardItemMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteDashboardItemMutation,
    DeleteDashboardItemMutationVariables
  >(DeleteDashboardItemDocument, options);
}
export type DeleteDashboardItemMutationHookResult = ReturnType<
  typeof useDeleteDashboardItemMutation
>;
export type DeleteDashboardItemMutationResult =
  Apollo.MutationResult<DeleteDashboardItemMutation>;
export type DeleteDashboardItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteDashboardItemMutation,
  DeleteDashboardItemMutationVariables
>;
export const CreateDashboardItemDocument = gql`
  mutation CreateDashboardItem($input: CreateDashboardItemInput!) {
    createDashboardItem(input: $input) {
      ... on DashboardItemMetricWithTimeSeries {
        id
      }
      ... on DashboardItemDivider {
        id
      }
    }
  }
`;
export type CreateDashboardItemMutationFn = Apollo.MutationFunction<
  CreateDashboardItemMutation,
  CreateDashboardItemMutationVariables
>;

/**
 * __useCreateDashboardItemMutation__
 *
 * To run a mutation, you first call `useCreateDashboardItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardItemMutation, { data, loading, error }] = useCreateDashboardItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDashboardItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDashboardItemMutation,
    CreateDashboardItemMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateDashboardItemMutation,
    CreateDashboardItemMutationVariables
  >(CreateDashboardItemDocument, options);
}
export type CreateDashboardItemMutationHookResult = ReturnType<
  typeof useCreateDashboardItemMutation
>;
export type CreateDashboardItemMutationResult =
  Apollo.MutationResult<CreateDashboardItemMutation>;
export type CreateDashboardItemMutationOptions = Apollo.BaseMutationOptions<
  CreateDashboardItemMutation,
  CreateDashboardItemMutationVariables
>;
export const UpdateDashboardItemsOrderDocument = gql`
  mutation UpdateDashboardItemsOrder($input: UpdateDashboardItemsOrderInput!) {
    updateDashboardItemsOrder(input: $input) {
      ... on Node {
        id
      }
    }
  }
`;
export type UpdateDashboardItemsOrderMutationFn = Apollo.MutationFunction<
  UpdateDashboardItemsOrderMutation,
  UpdateDashboardItemsOrderMutationVariables
>;

/**
 * __useUpdateDashboardItemsOrderMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardItemsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardItemsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardItemsOrderMutation, { data, loading, error }] = useUpdateDashboardItemsOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardItemsOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDashboardItemsOrderMutation,
    UpdateDashboardItemsOrderMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateDashboardItemsOrderMutation,
    UpdateDashboardItemsOrderMutationVariables
  >(UpdateDashboardItemsOrderDocument, options);
}
export type UpdateDashboardItemsOrderMutationHookResult = ReturnType<
  typeof useUpdateDashboardItemsOrderMutation
>;
export type UpdateDashboardItemsOrderMutationResult =
  Apollo.MutationResult<UpdateDashboardItemsOrderMutation>;
export type UpdateDashboardItemsOrderMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDashboardItemsOrderMutation,
    UpdateDashboardItemsOrderMutationVariables
  >;
export const UpdateDashboardDescriptionDocument = gql`
  mutation UpdateDashboardDescription($input: UpdateDashboardInput!) {
    updateDashboard(input: $input) {
      ...DashboardDescriptionTextarea
    }
  }
  ${DashboardDescriptionTextareaFragmentDoc}
`;
export type UpdateDashboardDescriptionMutationFn = Apollo.MutationFunction<
  UpdateDashboardDescriptionMutation,
  UpdateDashboardDescriptionMutationVariables
>;

/**
 * __useUpdateDashboardDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardDescriptionMutation, { data, loading, error }] = useUpdateDashboardDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDashboardDescriptionMutation,
    UpdateDashboardDescriptionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateDashboardDescriptionMutation,
    UpdateDashboardDescriptionMutationVariables
  >(UpdateDashboardDescriptionDocument, options);
}
export type UpdateDashboardDescriptionMutationHookResult = ReturnType<
  typeof useUpdateDashboardDescriptionMutation
>;
export type UpdateDashboardDescriptionMutationResult =
  Apollo.MutationResult<UpdateDashboardDescriptionMutation>;
export type UpdateDashboardDescriptionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDashboardDescriptionMutation,
    UpdateDashboardDescriptionMutationVariables
  >;
export const UpdateDashboardNameDocument = gql`
  mutation UpdateDashboardName($input: UpdateDashboardInput!) {
    updateDashboard(input: $input) {
      ...DashboardNameInput
    }
  }
  ${DashboardNameInputFragmentDoc}
`;
export type UpdateDashboardNameMutationFn = Apollo.MutationFunction<
  UpdateDashboardNameMutation,
  UpdateDashboardNameMutationVariables
>;

/**
 * __useUpdateDashboardNameMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardNameMutation, { data, loading, error }] = useUpdateDashboardNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDashboardNameMutation,
    UpdateDashboardNameMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateDashboardNameMutation,
    UpdateDashboardNameMutationVariables
  >(UpdateDashboardNameDocument, options);
}
export type UpdateDashboardNameMutationHookResult = ReturnType<
  typeof useUpdateDashboardNameMutation
>;
export type UpdateDashboardNameMutationResult =
  Apollo.MutationResult<UpdateDashboardNameMutation>;
export type UpdateDashboardNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateDashboardNameMutation,
  UpdateDashboardNameMutationVariables
>;
export const DeleteDashboardDocument = gql`
  mutation DeleteDashboard($input: DeleteDashboardInput!) {
    deleteDashboard(input: $input) {
      id
    }
  }
`;
export type DeleteDashboardMutationFn = Apollo.MutationFunction<
  DeleteDashboardMutation,
  DeleteDashboardMutationVariables
>;

/**
 * __useDeleteDashboardMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardMutation, { data, loading, error }] = useDeleteDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDashboardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDashboardMutation,
    DeleteDashboardMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteDashboardMutation,
    DeleteDashboardMutationVariables
  >(DeleteDashboardDocument, options);
}
export type DeleteDashboardMutationHookResult = ReturnType<
  typeof useDeleteDashboardMutation
>;
export type DeleteDashboardMutationResult =
  Apollo.MutationResult<DeleteDashboardMutation>;
export type DeleteDashboardMutationOptions = Apollo.BaseMutationOptions<
  DeleteDashboardMutation,
  DeleteDashboardMutationVariables
>;
export const MarkGettingStartedTourAsDoneDocument = gql`
  mutation MarkGettingStartedTourAsDone(
    $input: MarkGettingStartedTourAsDoneInput!
  ) {
    markGettingStartedTourAsDone(input: $input) {
      ...GettingStartedOrganization
    }
  }
  ${GettingStartedOrganizationFragmentDoc}
`;
export type MarkGettingStartedTourAsDoneMutationFn = Apollo.MutationFunction<
  MarkGettingStartedTourAsDoneMutation,
  MarkGettingStartedTourAsDoneMutationVariables
>;

/**
 * __useMarkGettingStartedTourAsDoneMutation__
 *
 * To run a mutation, you first call `useMarkGettingStartedTourAsDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkGettingStartedTourAsDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markGettingStartedTourAsDoneMutation, { data, loading, error }] = useMarkGettingStartedTourAsDoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkGettingStartedTourAsDoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkGettingStartedTourAsDoneMutation,
    MarkGettingStartedTourAsDoneMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    MarkGettingStartedTourAsDoneMutation,
    MarkGettingStartedTourAsDoneMutationVariables
  >(MarkGettingStartedTourAsDoneDocument, options);
}
export type MarkGettingStartedTourAsDoneMutationHookResult = ReturnType<
  typeof useMarkGettingStartedTourAsDoneMutation
>;
export type MarkGettingStartedTourAsDoneMutationResult =
  Apollo.MutationResult<MarkGettingStartedTourAsDoneMutation>;
export type MarkGettingStartedTourAsDoneMutationOptions =
  Apollo.BaseMutationOptions<
    MarkGettingStartedTourAsDoneMutation,
    MarkGettingStartedTourAsDoneMutationVariables
  >;
export const IndexWorkerChartDocument = gql`
  query IndexWorkerChart(
    $indexWorkerId: ID!
    $first: Int!
    $range: TimeRangeInput
  ) {
    indexWorker: node(id: $indexWorkerId) {
      id
      ...IndexWorkerChart
    }
  }
  ${IndexWorkerChartFragmentDoc}
`;

/**
 * __useIndexWorkerChartQuery__
 *
 * To run a query within a React component, call `useIndexWorkerChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexWorkerChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexWorkerChartQuery({
 *   variables: {
 *      indexWorkerId: // value for 'indexWorkerId'
 *      first: // value for 'first'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useIndexWorkerChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexWorkerChartQuery,
    IndexWorkerChartQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<IndexWorkerChartQuery, IndexWorkerChartQueryVariables>(
    IndexWorkerChartDocument,
    options
  );
}
export function useIndexWorkerChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexWorkerChartQuery,
    IndexWorkerChartQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    IndexWorkerChartQuery,
    IndexWorkerChartQueryVariables
  >(IndexWorkerChartDocument, options);
}
export type IndexWorkerChartQueryHookResult = ReturnType<
  typeof useIndexWorkerChartQuery
>;
export type IndexWorkerChartLazyQueryHookResult = ReturnType<
  typeof useIndexWorkerChartLazyQuery
>;
export type IndexWorkerChartQueryResult = Apollo.QueryResult<
  IndexWorkerChartQuery,
  IndexWorkerChartQueryVariables
>;
export const CreateIndexWorkerDocument = gql`
  mutation CreateIndexWorker($input: CreateIndexWorkerInput) {
    createIndexWorker(input: $input) {
      id
    }
  }
`;
export type CreateIndexWorkerMutationFn = Apollo.MutationFunction<
  CreateIndexWorkerMutation,
  CreateIndexWorkerMutationVariables
>;

/**
 * __useCreateIndexWorkerMutation__
 *
 * To run a mutation, you first call `useCreateIndexWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndexWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndexWorkerMutation, { data, loading, error }] = useCreateIndexWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndexWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIndexWorkerMutation,
    CreateIndexWorkerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateIndexWorkerMutation,
    CreateIndexWorkerMutationVariables
  >(CreateIndexWorkerDocument, options);
}
export type CreateIndexWorkerMutationHookResult = ReturnType<
  typeof useCreateIndexWorkerMutation
>;
export type CreateIndexWorkerMutationResult =
  Apollo.MutationResult<CreateIndexWorkerMutation>;
export type CreateIndexWorkerMutationOptions = Apollo.BaseMutationOptions<
  CreateIndexWorkerMutation,
  CreateIndexWorkerMutationVariables
>;
export const UpdateIndexWorkerDocument = gql`
  mutation UpdateIndexWorker($input: UpdateIndexWorkerInput!) {
    updateIndexWorker(input: $input) {
      id
    }
  }
`;
export type UpdateIndexWorkerMutationFn = Apollo.MutationFunction<
  UpdateIndexWorkerMutation,
  UpdateIndexWorkerMutationVariables
>;

/**
 * __useUpdateIndexWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateIndexWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexWorkerMutation, { data, loading, error }] = useUpdateIndexWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIndexWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIndexWorkerMutation,
    UpdateIndexWorkerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateIndexWorkerMutation,
    UpdateIndexWorkerMutationVariables
  >(UpdateIndexWorkerDocument, options);
}
export type UpdateIndexWorkerMutationHookResult = ReturnType<
  typeof useUpdateIndexWorkerMutation
>;
export type UpdateIndexWorkerMutationResult =
  Apollo.MutationResult<UpdateIndexWorkerMutation>;
export type UpdateIndexWorkerMutationOptions = Apollo.BaseMutationOptions<
  UpdateIndexWorkerMutation,
  UpdateIndexWorkerMutationVariables
>;
export const RemoveScheduleFromIndexWorkerDocument = gql`
  mutation RemoveScheduleFromIndexWorker(
    $input: RemoveScheduleFromIndexWorkerInput!
  ) {
    removeScheduleFromIndexWorker(input: $input) {
      id
    }
  }
`;
export type RemoveScheduleFromIndexWorkerMutationFn = Apollo.MutationFunction<
  RemoveScheduleFromIndexWorkerMutation,
  RemoveScheduleFromIndexWorkerMutationVariables
>;

/**
 * __useRemoveScheduleFromIndexWorkerMutation__
 *
 * To run a mutation, you first call `useRemoveScheduleFromIndexWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScheduleFromIndexWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScheduleFromIndexWorkerMutation, { data, loading, error }] = useRemoveScheduleFromIndexWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveScheduleFromIndexWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveScheduleFromIndexWorkerMutation,
    RemoveScheduleFromIndexWorkerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RemoveScheduleFromIndexWorkerMutation,
    RemoveScheduleFromIndexWorkerMutationVariables
  >(RemoveScheduleFromIndexWorkerDocument, options);
}
export type RemoveScheduleFromIndexWorkerMutationHookResult = ReturnType<
  typeof useRemoveScheduleFromIndexWorkerMutation
>;
export type RemoveScheduleFromIndexWorkerMutationResult =
  Apollo.MutationResult<RemoveScheduleFromIndexWorkerMutation>;
export type RemoveScheduleFromIndexWorkerMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveScheduleFromIndexWorkerMutation,
    RemoveScheduleFromIndexWorkerMutationVariables
  >;
export const SuspendIndexWorkerDocument = gql`
  mutation SuspendIndexWorker($id: ID!) {
    removeScheduleFromIndexWorker(input: {id: $id}) {
      id
      schedule
    }
  }
`;
export type SuspendIndexWorkerMutationFn = Apollo.MutationFunction<
  SuspendIndexWorkerMutation,
  SuspendIndexWorkerMutationVariables
>;

/**
 * __useSuspendIndexWorkerMutation__
 *
 * To run a mutation, you first call `useSuspendIndexWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendIndexWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendIndexWorkerMutation, { data, loading, error }] = useSuspendIndexWorkerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSuspendIndexWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SuspendIndexWorkerMutation,
    SuspendIndexWorkerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SuspendIndexWorkerMutation,
    SuspendIndexWorkerMutationVariables
  >(SuspendIndexWorkerDocument, options);
}
export type SuspendIndexWorkerMutationHookResult = ReturnType<
  typeof useSuspendIndexWorkerMutation
>;
export type SuspendIndexWorkerMutationResult =
  Apollo.MutationResult<SuspendIndexWorkerMutation>;
export type SuspendIndexWorkerMutationOptions = Apollo.BaseMutationOptions<
  SuspendIndexWorkerMutation,
  SuspendIndexWorkerMutationVariables
>;
export const IndexWorkerPageDocument = gql`
  query IndexWorkerPage(
    $organizationId: ID!
    $projectId: ID!
    $indexWorkerId: ID!
    $range: TimeRangeInput!
  ) {
    organization: node(id: $organizationId) {
      id
      ... on Organization {
        name
      }
    }
    project: node(id: $projectId) {
      id
      ... on IndexWorkerProject {
        name
      }
    }
    indexWorker: node(id: $indexWorkerId) {
      id
      ... on IndexWorker {
        ...IndexWorkerHeading
        ...SuspendIndexWorkerModal
        ...IndexWorkerFormDialog
        ...UrlsTable
        ...StatusReportCard
        ...IwDetailApiQuotaAlert
      }
    }
  }
  ${IndexWorkerHeadingFragmentDoc}
  ${SuspendIndexWorkerModalFragmentDoc}
  ${IndexWorkerFormDialogFragmentDoc}
  ${UrlsTableFragmentDoc}
  ${StatusReportCardFragmentDoc}
  ${IwDetailApiQuotaAlertFragmentDoc}
`;

/**
 * __useIndexWorkerPageQuery__
 *
 * To run a query within a React component, call `useIndexWorkerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexWorkerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexWorkerPageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *      indexWorkerId: // value for 'indexWorkerId'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useIndexWorkerPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexWorkerPageQuery,
    IndexWorkerPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<IndexWorkerPageQuery, IndexWorkerPageQueryVariables>(
    IndexWorkerPageDocument,
    options
  );
}
export function useIndexWorkerPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexWorkerPageQuery,
    IndexWorkerPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    IndexWorkerPageQuery,
    IndexWorkerPageQueryVariables
  >(IndexWorkerPageDocument, options);
}
export type IndexWorkerPageQueryHookResult = ReturnType<
  typeof useIndexWorkerPageQuery
>;
export type IndexWorkerPageLazyQueryHookResult = ReturnType<
  typeof useIndexWorkerPageLazyQuery
>;
export type IndexWorkerPageQueryResult = Apollo.QueryResult<
  IndexWorkerPageQuery,
  IndexWorkerPageQueryVariables
>;
export const LatestDataDateDocument = gql`
  query LatestDataDate($indexWorkerId: ID!) {
    indexWorker: node(id: $indexWorkerId) {
      id
      ... on IndexWorker {
        jobs(first: 1) {
          edges {
            node {
              completedAt
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useLatestDataDateQuery__
 *
 * To run a query within a React component, call `useLatestDataDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestDataDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestDataDateQuery({
 *   variables: {
 *      indexWorkerId: // value for 'indexWorkerId'
 *   },
 * });
 */
export function useLatestDataDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestDataDateQuery,
    LatestDataDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<LatestDataDateQuery, LatestDataDateQueryVariables>(
    LatestDataDateDocument,
    options
  );
}
export function useLatestDataDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestDataDateQuery,
    LatestDataDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<LatestDataDateQuery, LatestDataDateQueryVariables>(
    LatestDataDateDocument,
    options
  );
}
export type LatestDataDateQueryHookResult = ReturnType<
  typeof useLatestDataDateQuery
>;
export type LatestDataDateLazyQueryHookResult = ReturnType<
  typeof useLatestDataDateLazyQuery
>;
export type LatestDataDateQueryResult = Apollo.QueryResult<
  LatestDataDateQuery,
  LatestDataDateQueryVariables
>;
export const IndexWorkersPageDocument = gql`
  query IndexWorkersPage($projectId: ID!) {
    node(id: $projectId) {
      id
      ... on IndexWorkerProject {
        color
        name
        description
        indexWorkers {
          ...IndexWorkerSummaryCard
        }
      }
    }
    viewer {
      id
      organizations {
        organization {
          id
          name
        }
        role
      }
    }
  }
  ${IndexWorkerSummaryCardFragmentDoc}
`;

/**
 * __useIndexWorkersPageQuery__
 *
 * To run a query within a React component, call `useIndexWorkersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexWorkersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexWorkersPageQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useIndexWorkersPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexWorkersPageQuery,
    IndexWorkersPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<IndexWorkersPageQuery, IndexWorkersPageQueryVariables>(
    IndexWorkersPageDocument,
    options
  );
}
export function useIndexWorkersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexWorkersPageQuery,
    IndexWorkersPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    IndexWorkersPageQuery,
    IndexWorkersPageQueryVariables
  >(IndexWorkersPageDocument, options);
}
export type IndexWorkersPageQueryHookResult = ReturnType<
  typeof useIndexWorkersPageQuery
>;
export type IndexWorkersPageLazyQueryHookResult = ReturnType<
  typeof useIndexWorkersPageLazyQuery
>;
export type IndexWorkersPageQueryResult = Apollo.QueryResult<
  IndexWorkersPageQuery,
  IndexWorkersPageQueryVariables
>;
export const UpdateNotificationSettingDocument = gql`
  mutation UpdateNotificationSetting($input: UpdateNotificationSettingInput!) {
    updateNotificationSetting(input: $input) {
      ...NotificationSetting
    }
  }
  ${NotificationSettingFragmentDoc}
`;
export type UpdateNotificationSettingMutationFn = Apollo.MutationFunction<
  UpdateNotificationSettingMutation,
  UpdateNotificationSettingMutationVariables
>;

/**
 * __useUpdateNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingMutation, { data, loading, error }] = useUpdateNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationSettingMutation,
    UpdateNotificationSettingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateNotificationSettingMutation,
    UpdateNotificationSettingMutationVariables
  >(UpdateNotificationSettingDocument, options);
}
export type UpdateNotificationSettingMutationHookResult = ReturnType<
  typeof useUpdateNotificationSettingMutation
>;
export type UpdateNotificationSettingMutationResult =
  Apollo.MutationResult<UpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNotificationSettingMutation,
    UpdateNotificationSettingMutationVariables
  >;
export const NotificationSettingsPageDocument = gql`
  query NotificationSettingsPage($organizationId: ID!) {
    node(id: $organizationId) {
      ... on Organization {
        ...NotificationSetting
      }
    }
    viewer {
      id
      organizations {
        role
        organizationID
      }
    }
  }
  ${NotificationSettingFragmentDoc}
`;

/**
 * __useNotificationSettingsPageQuery__
 *
 * To run a query within a React component, call `useNotificationSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingsPageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useNotificationSettingsPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotificationSettingsPageQuery,
    NotificationSettingsPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    NotificationSettingsPageQuery,
    NotificationSettingsPageQueryVariables
  >(NotificationSettingsPageDocument, options);
}
export function useNotificationSettingsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationSettingsPageQuery,
    NotificationSettingsPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    NotificationSettingsPageQuery,
    NotificationSettingsPageQueryVariables
  >(NotificationSettingsPageDocument, options);
}
export type NotificationSettingsPageQueryHookResult = ReturnType<
  typeof useNotificationSettingsPageQuery
>;
export type NotificationSettingsPageLazyQueryHookResult = ReturnType<
  typeof useNotificationSettingsPageLazyQuery
>;
export type NotificationSettingsPageQueryResult = Apollo.QueryResult<
  NotificationSettingsPageQuery,
  NotificationSettingsPageQueryVariables
>;
export const OnboardingRouteDocument = gql`
  query OnboardingRoute {
    ...OrgSwitcherViewerQuery
  }
  ${OrgSwitcherViewerQueryFragmentDoc}
`;

/**
 * __useOnboardingRouteQuery__
 *
 * To run a query within a React component, call `useOnboardingRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingRouteQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingRouteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OnboardingRouteQuery,
    OnboardingRouteQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<OnboardingRouteQuery, OnboardingRouteQueryVariables>(
    OnboardingRouteDocument,
    options
  );
}
export function useOnboardingRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingRouteQuery,
    OnboardingRouteQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    OnboardingRouteQuery,
    OnboardingRouteQueryVariables
  >(OnboardingRouteDocument, options);
}
export type OnboardingRouteQueryHookResult = ReturnType<
  typeof useOnboardingRouteQuery
>;
export type OnboardingRouteLazyQueryHookResult = ReturnType<
  typeof useOnboardingRouteLazyQuery
>;
export type OnboardingRouteQueryResult = Apollo.QueryResult<
  OnboardingRouteQuery,
  OnboardingRouteQueryVariables
>;
export const CreateContractInfoDocument = gql`
  query CreateContractInfo($organizationId: ID!) {
    node(id: $organizationId) {
      id
      ... on Organization {
        id
        name
        contract {
          id
          name
          type
          stripeSubscriptionStatus
          options
          subscriptionPlan {
            id
            name
            propertyCapacity
            userCapacity
            guestCapacity
            orgCapacity
            iwProjectCapacity
            iwJobCapacity
            scProjectCapacity
            queryGroupCapacity
            urlGroupCapacity
            viewCapacity
          }
        }
      }
    }
  }
`;

/**
 * __useCreateContractInfoQuery__
 *
 * To run a query within a React component, call `useCreateContractInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateContractInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateContractInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateContractInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateContractInfoQuery,
    CreateContractInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    CreateContractInfoQuery,
    CreateContractInfoQueryVariables
  >(CreateContractInfoDocument, options);
}
export function useCreateContractInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateContractInfoQuery,
    CreateContractInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    CreateContractInfoQuery,
    CreateContractInfoQueryVariables
  >(CreateContractInfoDocument, options);
}
export type CreateContractInfoQueryHookResult = ReturnType<
  typeof useCreateContractInfoQuery
>;
export type CreateContractInfoLazyQueryHookResult = ReturnType<
  typeof useCreateContractInfoLazyQuery
>;
export type CreateContractInfoQueryResult = Apollo.QueryResult<
  CreateContractInfoQuery,
  CreateContractInfoQueryVariables
>;
export const RegisterPropertyInfoDocument = gql`
  query RegisterPropertyInfo($orgId: ID!) {
    viewer {
      email
      availableProperties {
        property
      }
    }
    org: node(id: $orgId) {
      id
      ... on Organization {
        contract {
          stripeSubscriptionStatus
          subscriptionPlan {
            id
            name
            propertyCapacity
            userCapacity
            guestCapacity
            orgCapacity
            iwProjectCapacity
            iwJobCapacity
            scProjectCapacity
            queryGroupCapacity
            urlGroupCapacity
            viewCapacity
          }
        }
      }
    }
  }
`;

/**
 * __useRegisterPropertyInfoQuery__
 *
 * To run a query within a React component, call `useRegisterPropertyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPropertyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPropertyInfoQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useRegisterPropertyInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisterPropertyInfoQuery,
    RegisterPropertyInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    RegisterPropertyInfoQuery,
    RegisterPropertyInfoQueryVariables
  >(RegisterPropertyInfoDocument, options);
}
export function useRegisterPropertyInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterPropertyInfoQuery,
    RegisterPropertyInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    RegisterPropertyInfoQuery,
    RegisterPropertyInfoQueryVariables
  >(RegisterPropertyInfoDocument, options);
}
export type RegisterPropertyInfoQueryHookResult = ReturnType<
  typeof useRegisterPropertyInfoQuery
>;
export type RegisterPropertyInfoLazyQueryHookResult = ReturnType<
  typeof useRegisterPropertyInfoLazyQuery
>;
export type RegisterPropertyInfoQueryResult = Apollo.QueryResult<
  RegisterPropertyInfoQuery,
  RegisterPropertyInfoQueryVariables
>;
export const PermissionPageDocument = gql`
  query PermissionPage($orgId: ID!) {
    viewer {
      id
      ...PermissionTable_Viewer
    }
    organization: node(id: $orgId) {
      ... on Organization {
        id
        ...PermissionContent_Organization
      }
    }
  }
  ${PermissionTable_ViewerFragmentDoc}
  ${PermissionContent_OrganizationFragmentDoc}
`;

/**
 * __usePermissionPageQuery__
 *
 * To run a query within a React component, call `usePermissionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionPageQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function usePermissionPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    PermissionPageQuery,
    PermissionPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PermissionPageQuery, PermissionPageQueryVariables>(
    PermissionPageDocument,
    options
  );
}
export function usePermissionPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PermissionPageQuery,
    PermissionPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PermissionPageQuery, PermissionPageQueryVariables>(
    PermissionPageDocument,
    options
  );
}
export type PermissionPageQueryHookResult = ReturnType<
  typeof usePermissionPageQuery
>;
export type PermissionPageLazyQueryHookResult = ReturnType<
  typeof usePermissionPageLazyQuery
>;
export type PermissionPageQueryResult = Apollo.QueryResult<
  PermissionPageQuery,
  PermissionPageQueryVariables
>;
export const SendInvitationDocument = gql`
  mutation SendInvitation($input: SendInvitationInput!) {
    sendInvitation(input: $input) {
      userID
      user {
        id
        email
      }
      organizationID
      role
      status
    }
  }
`;
export type SendInvitationMutationFn = Apollo.MutationFunction<
  SendInvitationMutation,
  SendInvitationMutationVariables
>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInvitationMutation,
    SendInvitationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SendInvitationMutation,
    SendInvitationMutationVariables
  >(SendInvitationDocument, options);
}
export type SendInvitationMutationHookResult = ReturnType<
  typeof useSendInvitationMutation
>;
export type SendInvitationMutationResult =
  Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<
  SendInvitationMutation,
  SendInvitationMutationVariables
>;
export const SearchAnalyticsTopPageDocument = gql`
  query SearchAnalyticsTopPage($organizationID: ID!, $projectID: ID!) {
    viewer {
      id
    }
    organization: node(id: $organizationID) {
      id
      ... on Organization {
        name
        serviceAccountEmail
        contract {
          ...Contract
        }
        ...SelfManaged
      }
    }
    project: node(id: $projectID) {
      id
      ... on SearchAnalyticsProject {
        name
        color
        description
        searchAnalytics {
          id
          datasetName
          googleCloudProjectID
          manageType
          latestDataDate
          views {
            id
            name
          }
        }
      }
    }
  }
  ${ContractFragmentDoc}
  ${SelfManagedFragmentDoc}
`;

/**
 * __useSearchAnalyticsTopPageQuery__
 *
 * To run a query within a React component, call `useSearchAnalyticsTopPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnalyticsTopPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnalyticsTopPageQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useSearchAnalyticsTopPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAnalyticsTopPageQuery,
    SearchAnalyticsTopPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SearchAnalyticsTopPageQuery,
    SearchAnalyticsTopPageQueryVariables
  >(SearchAnalyticsTopPageDocument, options);
}
export function useSearchAnalyticsTopPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAnalyticsTopPageQuery,
    SearchAnalyticsTopPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SearchAnalyticsTopPageQuery,
    SearchAnalyticsTopPageQueryVariables
  >(SearchAnalyticsTopPageDocument, options);
}
export type SearchAnalyticsTopPageQueryHookResult = ReturnType<
  typeof useSearchAnalyticsTopPageQuery
>;
export type SearchAnalyticsTopPageLazyQueryHookResult = ReturnType<
  typeof useSearchAnalyticsTopPageLazyQuery
>;
export type SearchAnalyticsTopPageQueryResult = Apollo.QueryResult<
  SearchAnalyticsTopPageQuery,
  SearchAnalyticsTopPageQueryVariables
>;
export const SearchAnalyticsChatsDocument = gql`
  query SearchAnalyticsChats($searchAnalyticsId: ID!) {
    node(id: $searchAnalyticsId) {
      ... on SearchAnalytics {
        id
        chats {
          ...SearchAnalyticsChatFragment
        }
      }
    }
  }
  ${SearchAnalyticsChatFragmentFragmentDoc}
`;

/**
 * __useSearchAnalyticsChatsQuery__
 *
 * To run a query within a React component, call `useSearchAnalyticsChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnalyticsChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnalyticsChatsQuery({
 *   variables: {
 *      searchAnalyticsId: // value for 'searchAnalyticsId'
 *   },
 * });
 */
export function useSearchAnalyticsChatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAnalyticsChatsQuery,
    SearchAnalyticsChatsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SearchAnalyticsChatsQuery,
    SearchAnalyticsChatsQueryVariables
  >(SearchAnalyticsChatsDocument, options);
}
export function useSearchAnalyticsChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAnalyticsChatsQuery,
    SearchAnalyticsChatsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SearchAnalyticsChatsQuery,
    SearchAnalyticsChatsQueryVariables
  >(SearchAnalyticsChatsDocument, options);
}
export type SearchAnalyticsChatsQueryHookResult = ReturnType<
  typeof useSearchAnalyticsChatsQuery
>;
export type SearchAnalyticsChatsLazyQueryHookResult = ReturnType<
  typeof useSearchAnalyticsChatsLazyQuery
>;
export type SearchAnalyticsChatsQueryResult = Apollo.QueryResult<
  SearchAnalyticsChatsQuery,
  SearchAnalyticsChatsQueryVariables
>;
export const CreateSearchAnalyticsChatDocument = gql`
  mutation CreateSearchAnalyticsChat($input: CreateSearchAnalyticsChatInput!) {
    createSearchAnalyticsChat(input: $input) {
      ...SearchAnalyticsChatFragment
    }
  }
  ${SearchAnalyticsChatFragmentFragmentDoc}
`;
export type CreateSearchAnalyticsChatMutationFn = Apollo.MutationFunction<
  CreateSearchAnalyticsChatMutation,
  CreateSearchAnalyticsChatMutationVariables
>;

/**
 * __useCreateSearchAnalyticsChatMutation__
 *
 * To run a mutation, you first call `useCreateSearchAnalyticsChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchAnalyticsChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchAnalyticsChatMutation, { data, loading, error }] = useCreateSearchAnalyticsChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSearchAnalyticsChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSearchAnalyticsChatMutation,
    CreateSearchAnalyticsChatMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateSearchAnalyticsChatMutation,
    CreateSearchAnalyticsChatMutationVariables
  >(CreateSearchAnalyticsChatDocument, options);
}
export type CreateSearchAnalyticsChatMutationHookResult = ReturnType<
  typeof useCreateSearchAnalyticsChatMutation
>;
export type CreateSearchAnalyticsChatMutationResult =
  Apollo.MutationResult<CreateSearchAnalyticsChatMutation>;
export type CreateSearchAnalyticsChatMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSearchAnalyticsChatMutation,
    CreateSearchAnalyticsChatMutationVariables
  >;
export const ReplySearchAnalyticsChatDocument = gql`
  mutation ReplySearchAnalyticsChat($input: ReplySearchAnalyticsChatInput!) {
    replySearchAnalyticsChat(input: $input) {
      ...SearchAnalyticsChatFragment
    }
  }
  ${SearchAnalyticsChatFragmentFragmentDoc}
`;
export type ReplySearchAnalyticsChatMutationFn = Apollo.MutationFunction<
  ReplySearchAnalyticsChatMutation,
  ReplySearchAnalyticsChatMutationVariables
>;

/**
 * __useReplySearchAnalyticsChatMutation__
 *
 * To run a mutation, you first call `useReplySearchAnalyticsChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplySearchAnalyticsChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replySearchAnalyticsChatMutation, { data, loading, error }] = useReplySearchAnalyticsChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplySearchAnalyticsChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplySearchAnalyticsChatMutation,
    ReplySearchAnalyticsChatMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ReplySearchAnalyticsChatMutation,
    ReplySearchAnalyticsChatMutationVariables
  >(ReplySearchAnalyticsChatDocument, options);
}
export type ReplySearchAnalyticsChatMutationHookResult = ReturnType<
  typeof useReplySearchAnalyticsChatMutation
>;
export type ReplySearchAnalyticsChatMutationResult =
  Apollo.MutationResult<ReplySearchAnalyticsChatMutation>;
export type ReplySearchAnalyticsChatMutationOptions =
  Apollo.BaseMutationOptions<
    ReplySearchAnalyticsChatMutation,
    ReplySearchAnalyticsChatMutationVariables
  >;
export const DuplicateSearchAnalytcisProjectDocument = gql`
  mutation DuplicateSearchAnalytcisProject(
    $input: DuplicateSearchAnalyticsProjectInput
  ) {
    duplicateSearchAnalyticsProject(input: $input) {
      id
      name
      color
      searchAnalytics {
        id
        views {
          id
          name
        }
      }
    }
  }
`;
export type DuplicateSearchAnalytcisProjectMutationFn = Apollo.MutationFunction<
  DuplicateSearchAnalytcisProjectMutation,
  DuplicateSearchAnalytcisProjectMutationVariables
>;

/**
 * __useDuplicateSearchAnalytcisProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateSearchAnalytcisProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateSearchAnalytcisProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateSearchAnalytcisProjectMutation, { data, loading, error }] = useDuplicateSearchAnalytcisProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateSearchAnalytcisProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateSearchAnalytcisProjectMutation,
    DuplicateSearchAnalytcisProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DuplicateSearchAnalytcisProjectMutation,
    DuplicateSearchAnalytcisProjectMutationVariables
  >(DuplicateSearchAnalytcisProjectDocument, options);
}
export type DuplicateSearchAnalytcisProjectMutationHookResult = ReturnType<
  typeof useDuplicateSearchAnalytcisProjectMutation
>;
export type DuplicateSearchAnalytcisProjectMutationResult =
  Apollo.MutationResult<DuplicateSearchAnalytcisProjectMutation>;
export type DuplicateSearchAnalytcisProjectMutationOptions =
  Apollo.BaseMutationOptions<
    DuplicateSearchAnalytcisProjectMutation,
    DuplicateSearchAnalytcisProjectMutationVariables
  >;
export const MakeSearchAnalyticsExportDataDocument = gql`
  mutation MakeSearchAnalyticsExportData(
    $input: MakeSearchAnalyticsExportDataInput!
  ) {
    exportData: makeSearchAnalyticsExportData(input: $input) {
      urls
      expires
      status
      exportedAt
      exportType
    }
  }
`;
export type MakeSearchAnalyticsExportDataMutationFn = Apollo.MutationFunction<
  MakeSearchAnalyticsExportDataMutation,
  MakeSearchAnalyticsExportDataMutationVariables
>;

/**
 * __useMakeSearchAnalyticsExportDataMutation__
 *
 * To run a mutation, you first call `useMakeSearchAnalyticsExportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSearchAnalyticsExportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSearchAnalyticsExportDataMutation, { data, loading, error }] = useMakeSearchAnalyticsExportDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeSearchAnalyticsExportDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeSearchAnalyticsExportDataMutation,
    MakeSearchAnalyticsExportDataMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    MakeSearchAnalyticsExportDataMutation,
    MakeSearchAnalyticsExportDataMutationVariables
  >(MakeSearchAnalyticsExportDataDocument, options);
}
export type MakeSearchAnalyticsExportDataMutationHookResult = ReturnType<
  typeof useMakeSearchAnalyticsExportDataMutation
>;
export type MakeSearchAnalyticsExportDataMutationResult =
  Apollo.MutationResult<MakeSearchAnalyticsExportDataMutation>;
export type MakeSearchAnalyticsExportDataMutationOptions =
  Apollo.BaseMutationOptions<
    MakeSearchAnalyticsExportDataMutation,
    MakeSearchAnalyticsExportDataMutationVariables
  >;
export const MakeSearchAnalyticsComparingExportDataDocument = gql`
  mutation MakeSearchAnalyticsComparingExportData(
    $input: MakeSearchAnalyticsComparingExportDataInput!
  ) {
    exportData: makeSearchAnalyticsComparingExportData(input: $input) {
      urls
      expires
      status
      exportedAt
      exportType
    }
  }
`;
export type MakeSearchAnalyticsComparingExportDataMutationFn =
  Apollo.MutationFunction<
    MakeSearchAnalyticsComparingExportDataMutation,
    MakeSearchAnalyticsComparingExportDataMutationVariables
  >;

/**
 * __useMakeSearchAnalyticsComparingExportDataMutation__
 *
 * To run a mutation, you first call `useMakeSearchAnalyticsComparingExportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSearchAnalyticsComparingExportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSearchAnalyticsComparingExportDataMutation, { data, loading, error }] = useMakeSearchAnalyticsComparingExportDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeSearchAnalyticsComparingExportDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeSearchAnalyticsComparingExportDataMutation,
    MakeSearchAnalyticsComparingExportDataMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    MakeSearchAnalyticsComparingExportDataMutation,
    MakeSearchAnalyticsComparingExportDataMutationVariables
  >(MakeSearchAnalyticsComparingExportDataDocument, options);
}
export type MakeSearchAnalyticsComparingExportDataMutationHookResult =
  ReturnType<typeof useMakeSearchAnalyticsComparingExportDataMutation>;
export type MakeSearchAnalyticsComparingExportDataMutationResult =
  Apollo.MutationResult<MakeSearchAnalyticsComparingExportDataMutation>;
export type MakeSearchAnalyticsComparingExportDataMutationOptions =
  Apollo.BaseMutationOptions<
    MakeSearchAnalyticsComparingExportDataMutation,
    MakeSearchAnalyticsComparingExportDataMutationVariables
  >;
export const SuggestQueryGroupDialog_QueryUrlGroupsDocument = gql`
  query SuggestQueryGroupDialog_QueryUrlGroups($id: ID!) {
    searchAnalytics: node(id: $id) {
      ... on SearchAnalytics {
        id
        queryGroups {
          id
          name
          op
          patterns {
            type
            pattern
          }
        }
        urlGroups {
          id
          name
          op
          patterns {
            type
            pattern
          }
        }
      }
    }
  }
`;

/**
 * __useSuggestQueryGroupDialog_QueryUrlGroupsQuery__
 *
 * To run a query within a React component, call `useSuggestQueryGroupDialog_QueryUrlGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestQueryGroupDialog_QueryUrlGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestQueryGroupDialog_QueryUrlGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSuggestQueryGroupDialog_QueryUrlGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuggestQueryGroupDialog_QueryUrlGroupsQuery,
    SuggestQueryGroupDialog_QueryUrlGroupsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SuggestQueryGroupDialog_QueryUrlGroupsQuery,
    SuggestQueryGroupDialog_QueryUrlGroupsQueryVariables
  >(SuggestQueryGroupDialog_QueryUrlGroupsDocument, options);
}
export function useSuggestQueryGroupDialog_QueryUrlGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestQueryGroupDialog_QueryUrlGroupsQuery,
    SuggestQueryGroupDialog_QueryUrlGroupsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SuggestQueryGroupDialog_QueryUrlGroupsQuery,
    SuggestQueryGroupDialog_QueryUrlGroupsQueryVariables
  >(SuggestQueryGroupDialog_QueryUrlGroupsDocument, options);
}
export type SuggestQueryGroupDialog_QueryUrlGroupsQueryHookResult = ReturnType<
  typeof useSuggestQueryGroupDialog_QueryUrlGroupsQuery
>;
export type SuggestQueryGroupDialog_QueryUrlGroupsLazyQueryHookResult =
  ReturnType<typeof useSuggestQueryGroupDialog_QueryUrlGroupsLazyQuery>;
export type SuggestQueryGroupDialog_QueryUrlGroupsQueryResult =
  Apollo.QueryResult<
    SuggestQueryGroupDialog_QueryUrlGroupsQuery,
    SuggestQueryGroupDialog_QueryUrlGroupsQueryVariables
  >;
export const SuggestQueryGroupDialog_RecommendedQueryGroupsDocument = gql`
  query SuggestQueryGroupDialog_RecommendedQueryGroups(
    $id: ID!
    $queryGroupId: ID
    $urlGroupId: ID
  ) {
    searchAnalytics: node(id: $id) {
      ... on SearchAnalytics {
        recommendedQueryGroups(
          queryGroupID: $queryGroupId
          urlGroupID: $urlGroupId
        ) {
          name
          patterns {
            type
            pattern
          }
          op
        }
      }
    }
  }
`;

/**
 * __useSuggestQueryGroupDialog_RecommendedQueryGroupsQuery__
 *
 * To run a query within a React component, call `useSuggestQueryGroupDialog_RecommendedQueryGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestQueryGroupDialog_RecommendedQueryGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestQueryGroupDialog_RecommendedQueryGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      queryGroupId: // value for 'queryGroupId'
 *      urlGroupId: // value for 'urlGroupId'
 *   },
 * });
 */
export function useSuggestQueryGroupDialog_RecommendedQueryGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuggestQueryGroupDialog_RecommendedQueryGroupsQuery,
    SuggestQueryGroupDialog_RecommendedQueryGroupsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SuggestQueryGroupDialog_RecommendedQueryGroupsQuery,
    SuggestQueryGroupDialog_RecommendedQueryGroupsQueryVariables
  >(SuggestQueryGroupDialog_RecommendedQueryGroupsDocument, options);
}
export function useSuggestQueryGroupDialog_RecommendedQueryGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestQueryGroupDialog_RecommendedQueryGroupsQuery,
    SuggestQueryGroupDialog_RecommendedQueryGroupsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SuggestQueryGroupDialog_RecommendedQueryGroupsQuery,
    SuggestQueryGroupDialog_RecommendedQueryGroupsQueryVariables
  >(SuggestQueryGroupDialog_RecommendedQueryGroupsDocument, options);
}
export type SuggestQueryGroupDialog_RecommendedQueryGroupsQueryHookResult =
  ReturnType<typeof useSuggestQueryGroupDialog_RecommendedQueryGroupsQuery>;
export type SuggestQueryGroupDialog_RecommendedQueryGroupsLazyQueryHookResult =
  ReturnType<typeof useSuggestQueryGroupDialog_RecommendedQueryGroupsLazyQuery>;
export type SuggestQueryGroupDialog_RecommendedQueryGroupsQueryResult =
  Apollo.QueryResult<
    SuggestQueryGroupDialog_RecommendedQueryGroupsQuery,
    SuggestQueryGroupDialog_RecommendedQueryGroupsQueryVariables
  >;
export const SuggestQueryGroupDialog_CreateQueryGroupDocument = gql`
  mutation SuggestQueryGroupDialog_CreateQueryGroup(
    $input: CreateQueryGroupInput!
  ) {
    createQueryGroup(input: $input) {
      id
      name
      op
      patterns {
        type
        pattern
      }
    }
  }
`;
export type SuggestQueryGroupDialog_CreateQueryGroupMutationFn =
  Apollo.MutationFunction<
    SuggestQueryGroupDialog_CreateQueryGroupMutation,
    SuggestQueryGroupDialog_CreateQueryGroupMutationVariables
  >;

/**
 * __useSuggestQueryGroupDialog_CreateQueryGroupMutation__
 *
 * To run a mutation, you first call `useSuggestQueryGroupDialog_CreateQueryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestQueryGroupDialog_CreateQueryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestQueryGroupDialogCreateQueryGroupMutation, { data, loading, error }] = useSuggestQueryGroupDialog_CreateQueryGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestQueryGroupDialog_CreateQueryGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SuggestQueryGroupDialog_CreateQueryGroupMutation,
    SuggestQueryGroupDialog_CreateQueryGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SuggestQueryGroupDialog_CreateQueryGroupMutation,
    SuggestQueryGroupDialog_CreateQueryGroupMutationVariables
  >(SuggestQueryGroupDialog_CreateQueryGroupDocument, options);
}
export type SuggestQueryGroupDialog_CreateQueryGroupMutationHookResult =
  ReturnType<typeof useSuggestQueryGroupDialog_CreateQueryGroupMutation>;
export type SuggestQueryGroupDialog_CreateQueryGroupMutationResult =
  Apollo.MutationResult<SuggestQueryGroupDialog_CreateQueryGroupMutation>;
export type SuggestQueryGroupDialog_CreateQueryGroupMutationOptions =
  Apollo.BaseMutationOptions<
    SuggestQueryGroupDialog_CreateQueryGroupMutation,
    SuggestQueryGroupDialog_CreateQueryGroupMutationVariables
  >;
export const ImportUrlGroupButtonDocument = gql`
  query ImportUrlGroupButton($organizationId: ID!) {
    node(id: $organizationId) {
      ... on Organization {
        id
        name
        projects {
          ...ImportUrlGroupModal_UserAnalyticsProject
        }
      }
    }
  }
  ${ImportUrlGroupModal_UserAnalyticsProjectFragmentDoc}
`;

/**
 * __useImportUrlGroupButtonQuery__
 *
 * To run a query within a React component, call `useImportUrlGroupButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportUrlGroupButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportUrlGroupButtonQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useImportUrlGroupButtonQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImportUrlGroupButtonQuery,
    ImportUrlGroupButtonQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    ImportUrlGroupButtonQuery,
    ImportUrlGroupButtonQueryVariables
  >(ImportUrlGroupButtonDocument, options);
}
export function useImportUrlGroupButtonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImportUrlGroupButtonQuery,
    ImportUrlGroupButtonQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    ImportUrlGroupButtonQuery,
    ImportUrlGroupButtonQueryVariables
  >(ImportUrlGroupButtonDocument, options);
}
export type ImportUrlGroupButtonQueryHookResult = ReturnType<
  typeof useImportUrlGroupButtonQuery
>;
export type ImportUrlGroupButtonLazyQueryHookResult = ReturnType<
  typeof useImportUrlGroupButtonLazyQuery
>;
export type ImportUrlGroupButtonQueryResult = Apollo.QueryResult<
  ImportUrlGroupButtonQuery,
  ImportUrlGroupButtonQueryVariables
>;
export const SuggestUrlGroupDocument = gql`
  query SuggestUrlGroup($searchAnalyticsId: ID!) {
    node(id: $searchAnalyticsId) {
      ... on SearchAnalytics {
        ...SuggestUrlGroupDialog
      }
    }
  }
  ${SuggestUrlGroupDialogFragmentDoc}
`;

/**
 * __useSuggestUrlGroupQuery__
 *
 * To run a query within a React component, call `useSuggestUrlGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestUrlGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestUrlGroupQuery({
 *   variables: {
 *      searchAnalyticsId: // value for 'searchAnalyticsId'
 *   },
 * });
 */
export function useSuggestUrlGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuggestUrlGroupQuery,
    SuggestUrlGroupQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<SuggestUrlGroupQuery, SuggestUrlGroupQueryVariables>(
    SuggestUrlGroupDocument,
    options
  );
}
export function useSuggestUrlGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestUrlGroupQuery,
    SuggestUrlGroupQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SuggestUrlGroupQuery,
    SuggestUrlGroupQueryVariables
  >(SuggestUrlGroupDocument, options);
}
export type SuggestUrlGroupQueryHookResult = ReturnType<
  typeof useSuggestUrlGroupQuery
>;
export type SuggestUrlGroupLazyQueryHookResult = ReturnType<
  typeof useSuggestUrlGroupLazyQuery
>;
export type SuggestUrlGroupQueryResult = Apollo.QueryResult<
  SuggestUrlGroupQuery,
  SuggestUrlGroupQueryVariables
>;
export const CreateSuggestedUrlGroupDocument = gql`
  mutation CreateSuggestedUrlGroup($input: CreateURLGroupInput!) {
    createURLGroup(input: $input) {
      id
    }
  }
`;
export type CreateSuggestedUrlGroupMutationFn = Apollo.MutationFunction<
  CreateSuggestedUrlGroupMutation,
  CreateSuggestedUrlGroupMutationVariables
>;

/**
 * __useCreateSuggestedUrlGroupMutation__
 *
 * To run a mutation, you first call `useCreateSuggestedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestedUrlGroupMutation, { data, loading, error }] = useCreateSuggestedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSuggestedUrlGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSuggestedUrlGroupMutation,
    CreateSuggestedUrlGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateSuggestedUrlGroupMutation,
    CreateSuggestedUrlGroupMutationVariables
  >(CreateSuggestedUrlGroupDocument, options);
}
export type CreateSuggestedUrlGroupMutationHookResult = ReturnType<
  typeof useCreateSuggestedUrlGroupMutation
>;
export type CreateSuggestedUrlGroupMutationResult =
  Apollo.MutationResult<CreateSuggestedUrlGroupMutation>;
export type CreateSuggestedUrlGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateSuggestedUrlGroupMutation,
  CreateSuggestedUrlGroupMutationVariables
>;
export const UpdateSearchAnalyticsSpreadsheetIdDocument = gql`
  mutation UpdateSearchAnalyticsSpreadsheetId(
    $id: ID!
    $spreadsheetId: String!
  ) {
    updateSearchAnalytics(input: {id: $id, spreadsheetID: $spreadsheetId}) {
      id
      spreadsheetID
    }
  }
`;
export type UpdateSearchAnalyticsSpreadsheetIdMutationFn =
  Apollo.MutationFunction<
    UpdateSearchAnalyticsSpreadsheetIdMutation,
    UpdateSearchAnalyticsSpreadsheetIdMutationVariables
  >;

/**
 * __useUpdateSearchAnalyticsSpreadsheetIdMutation__
 *
 * To run a mutation, you first call `useUpdateSearchAnalyticsSpreadsheetIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchAnalyticsSpreadsheetIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchAnalyticsSpreadsheetIdMutation, { data, loading, error }] = useUpdateSearchAnalyticsSpreadsheetIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      spreadsheetId: // value for 'spreadsheetId'
 *   },
 * });
 */
export function useUpdateSearchAnalyticsSpreadsheetIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSearchAnalyticsSpreadsheetIdMutation,
    UpdateSearchAnalyticsSpreadsheetIdMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateSearchAnalyticsSpreadsheetIdMutation,
    UpdateSearchAnalyticsSpreadsheetIdMutationVariables
  >(UpdateSearchAnalyticsSpreadsheetIdDocument, options);
}
export type UpdateSearchAnalyticsSpreadsheetIdMutationHookResult = ReturnType<
  typeof useUpdateSearchAnalyticsSpreadsheetIdMutation
>;
export type UpdateSearchAnalyticsSpreadsheetIdMutationResult =
  Apollo.MutationResult<UpdateSearchAnalyticsSpreadsheetIdMutation>;
export type UpdateSearchAnalyticsSpreadsheetIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSearchAnalyticsSpreadsheetIdMutation,
    UpdateSearchAnalyticsSpreadsheetIdMutationVariables
  >;
export const UpdateViewSpreadsheetIdDocument = gql`
  mutation UpdateViewSpreadsheetId($id: ID!, $spreadsheetId: String!) {
    updateSearchAnalyticsViewSpreadsheetId(
      input: {id: $id, spreadsheetID: $spreadsheetId}
    ) {
      id
      spreadsheetID
    }
  }
`;
export type UpdateViewSpreadsheetIdMutationFn = Apollo.MutationFunction<
  UpdateViewSpreadsheetIdMutation,
  UpdateViewSpreadsheetIdMutationVariables
>;

/**
 * __useUpdateViewSpreadsheetIdMutation__
 *
 * To run a mutation, you first call `useUpdateViewSpreadsheetIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewSpreadsheetIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewSpreadsheetIdMutation, { data, loading, error }] = useUpdateViewSpreadsheetIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      spreadsheetId: // value for 'spreadsheetId'
 *   },
 * });
 */
export function useUpdateViewSpreadsheetIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateViewSpreadsheetIdMutation,
    UpdateViewSpreadsheetIdMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateViewSpreadsheetIdMutation,
    UpdateViewSpreadsheetIdMutationVariables
  >(UpdateViewSpreadsheetIdDocument, options);
}
export type UpdateViewSpreadsheetIdMutationHookResult = ReturnType<
  typeof useUpdateViewSpreadsheetIdMutation
>;
export type UpdateViewSpreadsheetIdMutationResult =
  Apollo.MutationResult<UpdateViewSpreadsheetIdMutation>;
export type UpdateViewSpreadsheetIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateViewSpreadsheetIdMutation,
  UpdateViewSpreadsheetIdMutationVariables
>;
export const DeletePropertyButton_UnregisterPropertyDocument = gql`
  mutation DeletePropertyButton_UnregisterProperty($id: ID!) {
    unregisterProperty(input: {id: $id}) {
      id
    }
  }
`;
export type DeletePropertyButton_UnregisterPropertyMutationFn =
  Apollo.MutationFunction<
    DeletePropertyButton_UnregisterPropertyMutation,
    DeletePropertyButton_UnregisterPropertyMutationVariables
  >;

/**
 * __useDeletePropertyButton_UnregisterPropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyButton_UnregisterPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyButton_UnregisterPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyButtonUnregisterPropertyMutation, { data, loading, error }] = useDeletePropertyButton_UnregisterPropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyButton_UnregisterPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyButton_UnregisterPropertyMutation,
    DeletePropertyButton_UnregisterPropertyMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeletePropertyButton_UnregisterPropertyMutation,
    DeletePropertyButton_UnregisterPropertyMutationVariables
  >(DeletePropertyButton_UnregisterPropertyDocument, options);
}
export type DeletePropertyButton_UnregisterPropertyMutationHookResult =
  ReturnType<typeof useDeletePropertyButton_UnregisterPropertyMutation>;
export type DeletePropertyButton_UnregisterPropertyMutationResult =
  Apollo.MutationResult<DeletePropertyButton_UnregisterPropertyMutation>;
export type DeletePropertyButton_UnregisterPropertyMutationOptions =
  Apollo.BaseMutationOptions<
    DeletePropertyButton_UnregisterPropertyMutation,
    DeletePropertyButton_UnregisterPropertyMutationVariables
  >;
export const DeletePropertyButton_IndexWorkersDocument = gql`
  query DeletePropertyButton_IndexWorkers($propertyId: ID!) {
    node(id: $propertyId) {
      id
      ... on RegisteredSearchConsoleUserProperty {
        indexWorkers {
          id
          name
        }
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        indexWorkers {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useDeletePropertyButton_IndexWorkersQuery__
 *
 * To run a query within a React component, call `useDeletePropertyButton_IndexWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyButton_IndexWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletePropertyButton_IndexWorkersQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useDeletePropertyButton_IndexWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeletePropertyButton_IndexWorkersQuery,
    DeletePropertyButton_IndexWorkersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    DeletePropertyButton_IndexWorkersQuery,
    DeletePropertyButton_IndexWorkersQueryVariables
  >(DeletePropertyButton_IndexWorkersDocument, options);
}
export function useDeletePropertyButton_IndexWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeletePropertyButton_IndexWorkersQuery,
    DeletePropertyButton_IndexWorkersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    DeletePropertyButton_IndexWorkersQuery,
    DeletePropertyButton_IndexWorkersQueryVariables
  >(DeletePropertyButton_IndexWorkersDocument, options);
}
export type DeletePropertyButton_IndexWorkersQueryHookResult = ReturnType<
  typeof useDeletePropertyButton_IndexWorkersQuery
>;
export type DeletePropertyButton_IndexWorkersLazyQueryHookResult = ReturnType<
  typeof useDeletePropertyButton_IndexWorkersLazyQuery
>;
export type DeletePropertyButton_IndexWorkersQueryResult = Apollo.QueryResult<
  DeletePropertyButton_IndexWorkersQuery,
  DeletePropertyButton_IndexWorkersQueryVariables
>;
export const GetRandomSessionDocument = gql`
  query GetRandomSession($userAnalyticsId: ID!) {
    getRandomSession(userAnalyticsID: $userAnalyticsId) {
      gaSessionId
      userPseudoId
    }
  }
`;

/**
 * __useGetRandomSessionQuery__
 *
 * To run a query within a React component, call `useGetRandomSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomSessionQuery({
 *   variables: {
 *      userAnalyticsId: // value for 'userAnalyticsId'
 *   },
 * });
 */
export function useGetRandomSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRandomSessionQuery,
    GetRandomSessionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetRandomSessionQuery, GetRandomSessionQueryVariables>(
    GetRandomSessionDocument,
    options
  );
}
export function useGetRandomSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRandomSessionQuery,
    GetRandomSessionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetRandomSessionQuery,
    GetRandomSessionQueryVariables
  >(GetRandomSessionDocument, options);
}
export type GetRandomSessionQueryHookResult = ReturnType<
  typeof useGetRandomSessionQuery
>;
export type GetRandomSessionLazyQueryHookResult = ReturnType<
  typeof useGetRandomSessionLazyQuery
>;
export type GetRandomSessionQueryResult = Apollo.QueryResult<
  GetRandomSessionQuery,
  GetRandomSessionQueryVariables
>;
export const SessionExplorerPageDocument = gql`
  query SessionExplorerPage($orgId: ID!, $projectId: ID!) {
    project: node(id: $projectId) {
      ... on UserAnalyticsProject {
        ...UserAnalyticsBreadcrumbsProject
        userAnalytics {
          id
        }
      }
    }
    org: node(id: $orgId) {
      ... on Organization {
        ...UserAnalyticsBreadcrumbsOrg
      }
    }
  }
  ${UserAnalyticsBreadcrumbsProjectFragmentDoc}
  ${UserAnalyticsBreadcrumbsOrgFragmentDoc}
`;

/**
 * __useSessionExplorerPageQuery__
 *
 * To run a query within a React component, call `useSessionExplorerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionExplorerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionExplorerPageQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSessionExplorerPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionExplorerPageQuery,
    SessionExplorerPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SessionExplorerPageQuery,
    SessionExplorerPageQueryVariables
  >(SessionExplorerPageDocument, options);
}
export function useSessionExplorerPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionExplorerPageQuery,
    SessionExplorerPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SessionExplorerPageQuery,
    SessionExplorerPageQueryVariables
  >(SessionExplorerPageDocument, options);
}
export type SessionExplorerPageQueryHookResult = ReturnType<
  typeof useSessionExplorerPageQuery
>;
export type SessionExplorerPageLazyQueryHookResult = ReturnType<
  typeof useSessionExplorerPageLazyQuery
>;
export type SessionExplorerPageQueryResult = Apollo.QueryResult<
  SessionExplorerPageQuery,
  SessionExplorerPageQueryVariables
>;
export const GetSessionDocument = gql`
  query GetSession(
    $userAnalyticsId: ID!
    $gaSessionId: Int!
    $userPseudoId: String!
  ) {
    node(id: $userAnalyticsId) {
      ... on UserAnalytics {
        id
        getSessionDetail(
          gaSessionId: $gaSessionId
          userPseudoId: $userPseudoId
        ) {
          ...SessionSummary
          ...SessionDetail
        }
      }
    }
  }
  ${SessionSummaryFragmentDoc}
  ${SessionDetailFragmentDoc}
`;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      userAnalyticsId: // value for 'userAnalyticsId'
 *      gaSessionId: // value for 'gaSessionId'
 *      userPseudoId: // value for 'userPseudoId'
 *   },
 * });
 */
export function useGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<
  typeof useGetSessionLazyQuery
>;
export type GetSessionQueryResult = Apollo.QueryResult<
  GetSessionQuery,
  GetSessionQueryVariables
>;
export const UserSessionsDocument = gql`
  query UserSessions(
    $userAnalyticsID: ID!
    $userPseudoID: String!
    $dateRange: TimeRangeInput!
  ) {
    userAnalytics: node(id: $userAnalyticsID) {
      ... on UserAnalytics {
        userSessions(userPseudoId: $userPseudoID, dateRange: $dateRange) {
          ...SessionsCard_Session
        }
      }
    }
  }
  ${SessionsCard_SessionFragmentDoc}
`;

/**
 * __useUserSessionsQuery__
 *
 * To run a query within a React component, call `useUserSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSessionsQuery({
 *   variables: {
 *      userAnalyticsID: // value for 'userAnalyticsID'
 *      userPseudoID: // value for 'userPseudoID'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useUserSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSessionsQuery,
    UserSessionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<UserSessionsQuery, UserSessionsQueryVariables>(
    UserSessionsDocument,
    options
  );
}
export function useUserSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSessionsQuery,
    UserSessionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<UserSessionsQuery, UserSessionsQueryVariables>(
    UserSessionsDocument,
    options
  );
}
export type UserSessionsQueryHookResult = ReturnType<
  typeof useUserSessionsQuery
>;
export type UserSessionsLazyQueryHookResult = ReturnType<
  typeof useUserSessionsLazyQuery
>;
export type UserSessionsQueryResult = Apollo.QueryResult<
  UserSessionsQuery,
  UserSessionsQueryVariables
>;
export const SessionsCardWrapper_SessionsDocument = gql`
  query SessionsCardWrapper_Sessions(
    $id: ID!
    $dateRange: TimeRangeInput!
    $os: [OSCategory!]!
    $devices: [DeviceCategory!]!
    $browsers: [BrowserCategory!]!
    $sources: [String!]!
    $mediums: [String!]!
    $campaigns: [String!]!
    $campaignsFilterType: [UserAnalyticsFilter!]!
    $events: [String!]!
    $firstPageLocation: String
    $firstPageLocationURLGroupIDs: [ID!]
    $firstPageLocationFilterType: UserAnalyticsFilter
    $firstPageTitle: String
    $firstPageTitleFilterType: UserAnalyticsFilter
    $secondPageLocation: String
    $secondPageLocationURLGroupIDs: [ID!]
    $secondPageLocationFilterType: UserAnalyticsFilter
    $secondPageTitle: String
    $secondPageTitleFilterType: UserAnalyticsFilter
    $isSessionWithNavigation: Boolean
    $sessionsLimit: Int = 20
    $sessionsOffset: Int = 0
    $defaultChannelGroup: [DefaultChannelGroup!]
    $pageLocationCount: IntRangeInput
  ) {
    node(id: $id) {
      ... on UserAnalytics {
        id
        sessions(
          dateRange: $dateRange
          browsers: $browsers
          devices: $devices
          os: $os
          sources: $sources
          mediums: $mediums
          campaigns: $campaigns
          campaignsFilterType: $campaignsFilterType
          events: $events
          firstPageLocation: $firstPageLocation
          firstPageLocationURLGroupIDs: $firstPageLocationURLGroupIDs
          firstPageLocationFilterType: $firstPageLocationFilterType
          firstPageTitle: $firstPageTitle
          firstPageTitleFilterType: $firstPageTitleFilterType
          secondPageLocation: $secondPageLocation
          secondPageLocationURLGroupIDs: $secondPageLocationURLGroupIDs
          secondPageLocationFilterType: $secondPageLocationFilterType
          secondPageTitle: $secondPageTitle
          secondPageTitleFilterType: $secondPageTitleFilterType
          isSessionWithNavigation: $isSessionWithNavigation
          limit: $sessionsLimit
          offset: $sessionsOffset
          defaultChannelGroup: $defaultChannelGroup
          pageLocationCount: $pageLocationCount
        ) {
          ...SessionsCard_Session
        }
      }
      ... on UserAnalyticsView {
        id
        sessions(
          dateRange: $dateRange
          limit: $sessionsLimit
          offset: $sessionsOffset
        ) {
          ...SessionsCard_Session
        }
      }
    }
  }
  ${SessionsCard_SessionFragmentDoc}
`;

/**
 * __useSessionsCardWrapper_SessionsQuery__
 *
 * To run a query within a React component, call `useSessionsCardWrapper_SessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsCardWrapper_SessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsCardWrapper_SessionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateRange: // value for 'dateRange'
 *      os: // value for 'os'
 *      devices: // value for 'devices'
 *      browsers: // value for 'browsers'
 *      sources: // value for 'sources'
 *      mediums: // value for 'mediums'
 *      campaigns: // value for 'campaigns'
 *      campaignsFilterType: // value for 'campaignsFilterType'
 *      events: // value for 'events'
 *      firstPageLocation: // value for 'firstPageLocation'
 *      firstPageLocationURLGroupIDs: // value for 'firstPageLocationURLGroupIDs'
 *      firstPageLocationFilterType: // value for 'firstPageLocationFilterType'
 *      firstPageTitle: // value for 'firstPageTitle'
 *      firstPageTitleFilterType: // value for 'firstPageTitleFilterType'
 *      secondPageLocation: // value for 'secondPageLocation'
 *      secondPageLocationURLGroupIDs: // value for 'secondPageLocationURLGroupIDs'
 *      secondPageLocationFilterType: // value for 'secondPageLocationFilterType'
 *      secondPageTitle: // value for 'secondPageTitle'
 *      secondPageTitleFilterType: // value for 'secondPageTitleFilterType'
 *      isSessionWithNavigation: // value for 'isSessionWithNavigation'
 *      sessionsLimit: // value for 'sessionsLimit'
 *      sessionsOffset: // value for 'sessionsOffset'
 *      defaultChannelGroup: // value for 'defaultChannelGroup'
 *      pageLocationCount: // value for 'pageLocationCount'
 *   },
 * });
 */
export function useSessionsCardWrapper_SessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionsCardWrapper_SessionsQuery,
    SessionsCardWrapper_SessionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SessionsCardWrapper_SessionsQuery,
    SessionsCardWrapper_SessionsQueryVariables
  >(SessionsCardWrapper_SessionsDocument, options);
}
export function useSessionsCardWrapper_SessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionsCardWrapper_SessionsQuery,
    SessionsCardWrapper_SessionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SessionsCardWrapper_SessionsQuery,
    SessionsCardWrapper_SessionsQueryVariables
  >(SessionsCardWrapper_SessionsDocument, options);
}
export type SessionsCardWrapper_SessionsQueryHookResult = ReturnType<
  typeof useSessionsCardWrapper_SessionsQuery
>;
export type SessionsCardWrapper_SessionsLazyQueryHookResult = ReturnType<
  typeof useSessionsCardWrapper_SessionsLazyQuery
>;
export type SessionsCardWrapper_SessionsQueryResult = Apollo.QueryResult<
  SessionsCardWrapper_SessionsQuery,
  SessionsCardWrapper_SessionsQueryVariables
>;
export const UserAnalyticsDetail_ProjectDocument = gql`
  query UserAnalyticsDetail_Project($id: ID!, $organizationID: ID!) {
    project: node(id: $id) {
      ... on UserAnalyticsProject {
        name
        userAnalytics {
          id
          keyEvents
          eventNames
          urlGroups {
            ...Chart_URLGroup
          }
          eventNames
        }
      }
    }
    viewer {
      id
      isAdmin
      isCustomerSupport(organizationID: $organizationID)
      organizations {
        role
        organizationID
        organization {
          id
          name
        }
      }
    }
  }
  ${Chart_UrlGroupFragmentDoc}
`;

/**
 * __useUserAnalyticsDetail_ProjectQuery__
 *
 * To run a query within a React component, call `useUserAnalyticsDetail_ProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnalyticsDetail_ProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnalyticsDetail_ProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useUserAnalyticsDetail_ProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAnalyticsDetail_ProjectQuery,
    UserAnalyticsDetail_ProjectQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    UserAnalyticsDetail_ProjectQuery,
    UserAnalyticsDetail_ProjectQueryVariables
  >(UserAnalyticsDetail_ProjectDocument, options);
}
export function useUserAnalyticsDetail_ProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAnalyticsDetail_ProjectQuery,
    UserAnalyticsDetail_ProjectQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    UserAnalyticsDetail_ProjectQuery,
    UserAnalyticsDetail_ProjectQueryVariables
  >(UserAnalyticsDetail_ProjectDocument, options);
}
export type UserAnalyticsDetail_ProjectQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_ProjectQuery
>;
export type UserAnalyticsDetail_ProjectLazyQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_ProjectLazyQuery
>;
export type UserAnalyticsDetail_ProjectQueryResult = Apollo.QueryResult<
  UserAnalyticsDetail_ProjectQuery,
  UserAnalyticsDetail_ProjectQueryVariables
>;
export const UserAnalyticsDetail_UserAnalyticsDocument = gql`
  query UserAnalyticsDetail_UserAnalytics(
    $id: ID!
    $dateRange: TimeRangeInput!
    $os: [OSCategory!]!
    $devices: [DeviceCategory!]!
    $browsers: [BrowserCategory!]!
    $sources: [String!]!
    $mediums: [String!]!
    $campaigns: [String!]!
    $campaignsFilterType: [UserAnalyticsFilter!]!
    $events: [String!]!
    $firstPageTitle: String
    $firstPageTitleFilterType: UserAnalyticsFilter
    $secondPageTitle: String
    $secondPageTitleFilterType: UserAnalyticsFilter
    $firstPageLocation: String
    $firstPageLocationURLGroupIDs: [ID!]
    $firstPageLocationFilterType: UserAnalyticsFilter
    $secondPageLocation: String
    $secondPageLocationURLGroupIDs: [ID!]
    $secondPageLocationFilterType: UserAnalyticsFilter
    $sessionPerformanceGroupBy: SessionPerformanceGroupBy
    $sessionPerformanceDetailLimit: Int = 20
    $sessionPerformanceDetailOffset: Int = 0
    $sessionPerformanceDetailGroupBy: [SessionPerformanceDetailGroupBy!] = []
    $sessionPerformanceDetailOrderBy: SessionPerformanceDetailOrderByInput = {
      field: SESSION_COUNT
      direction: DESC
    }
    $isSessionWithNavigation: Boolean
    $defaultChannelGroup: [DefaultChannelGroup!]
    $pageLocationCount: IntRangeInput
  ) {
    userAnalytics: node(id: $id) {
      ... on UserAnalytics {
        ...ChartCard_UserAnalytics
        ...MetricDetailsCard_UserAnalytics
        urlGroups {
          ...DetailHeader_URLGroup
        }
      }
    }
  }
  ${ChartCard_UserAnalyticsFragmentDoc}
  ${MetricDetailsCard_UserAnalyticsFragmentDoc}
  ${DetailHeader_UrlGroupFragmentDoc}
`;

/**
 * __useUserAnalyticsDetail_UserAnalyticsQuery__
 *
 * To run a query within a React component, call `useUserAnalyticsDetail_UserAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnalyticsDetail_UserAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnalyticsDetail_UserAnalyticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateRange: // value for 'dateRange'
 *      os: // value for 'os'
 *      devices: // value for 'devices'
 *      browsers: // value for 'browsers'
 *      sources: // value for 'sources'
 *      mediums: // value for 'mediums'
 *      campaigns: // value for 'campaigns'
 *      campaignsFilterType: // value for 'campaignsFilterType'
 *      events: // value for 'events'
 *      firstPageTitle: // value for 'firstPageTitle'
 *      firstPageTitleFilterType: // value for 'firstPageTitleFilterType'
 *      secondPageTitle: // value for 'secondPageTitle'
 *      secondPageTitleFilterType: // value for 'secondPageTitleFilterType'
 *      firstPageLocation: // value for 'firstPageLocation'
 *      firstPageLocationURLGroupIDs: // value for 'firstPageLocationURLGroupIDs'
 *      firstPageLocationFilterType: // value for 'firstPageLocationFilterType'
 *      secondPageLocation: // value for 'secondPageLocation'
 *      secondPageLocationURLGroupIDs: // value for 'secondPageLocationURLGroupIDs'
 *      secondPageLocationFilterType: // value for 'secondPageLocationFilterType'
 *      sessionPerformanceGroupBy: // value for 'sessionPerformanceGroupBy'
 *      sessionPerformanceDetailLimit: // value for 'sessionPerformanceDetailLimit'
 *      sessionPerformanceDetailOffset: // value for 'sessionPerformanceDetailOffset'
 *      sessionPerformanceDetailGroupBy: // value for 'sessionPerformanceDetailGroupBy'
 *      sessionPerformanceDetailOrderBy: // value for 'sessionPerformanceDetailOrderBy'
 *      isSessionWithNavigation: // value for 'isSessionWithNavigation'
 *      defaultChannelGroup: // value for 'defaultChannelGroup'
 *      pageLocationCount: // value for 'pageLocationCount'
 *   },
 * });
 */
export function useUserAnalyticsDetail_UserAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAnalyticsDetail_UserAnalyticsQuery,
    UserAnalyticsDetail_UserAnalyticsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    UserAnalyticsDetail_UserAnalyticsQuery,
    UserAnalyticsDetail_UserAnalyticsQueryVariables
  >(UserAnalyticsDetail_UserAnalyticsDocument, options);
}
export function useUserAnalyticsDetail_UserAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAnalyticsDetail_UserAnalyticsQuery,
    UserAnalyticsDetail_UserAnalyticsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    UserAnalyticsDetail_UserAnalyticsQuery,
    UserAnalyticsDetail_UserAnalyticsQueryVariables
  >(UserAnalyticsDetail_UserAnalyticsDocument, options);
}
export type UserAnalyticsDetail_UserAnalyticsQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_UserAnalyticsQuery
>;
export type UserAnalyticsDetail_UserAnalyticsLazyQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_UserAnalyticsLazyQuery
>;
export type UserAnalyticsDetail_UserAnalyticsQueryResult = Apollo.QueryResult<
  UserAnalyticsDetail_UserAnalyticsQuery,
  UserAnalyticsDetail_UserAnalyticsQueryVariables
>;
export const UserAnalyticsDetail_ViewConditionsDocument = gql`
  query UserAnalyticsDetail_ViewConditions($id: ID!) {
    viewConditions: node(id: $id) {
      ... on UserAnalyticsView {
        id
        os
        devices
        browsers
        sources
        mediums
        campaigns
        campaignsFilterType
        events
        name
        description
        defaultDateRange {
          type
          dateRange {
            start
            end
          }
        }
        firstPageTitle
        firstPageTitleFilterType
        secondPageTitle
        secondPageTitleFilterType
        firstPageLocationURLGroupIDs
        secondPageLocationURLGroupIDs
        firstPageLocationFilterType
        secondPageLocationFilterType
        firstPageLocationURL
        secondPageLocationURL
        isSessionWithNavigation
        defaultChannelGroup
        detailGroupBy
        detailOrderBy {
          field
          direction
        }
        pageLocationCount {
          min
          max
        }
      }
    }
  }
`;

/**
 * __useUserAnalyticsDetail_ViewConditionsQuery__
 *
 * To run a query within a React component, call `useUserAnalyticsDetail_ViewConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnalyticsDetail_ViewConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnalyticsDetail_ViewConditionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAnalyticsDetail_ViewConditionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAnalyticsDetail_ViewConditionsQuery,
    UserAnalyticsDetail_ViewConditionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    UserAnalyticsDetail_ViewConditionsQuery,
    UserAnalyticsDetail_ViewConditionsQueryVariables
  >(UserAnalyticsDetail_ViewConditionsDocument, options);
}
export function useUserAnalyticsDetail_ViewConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAnalyticsDetail_ViewConditionsQuery,
    UserAnalyticsDetail_ViewConditionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    UserAnalyticsDetail_ViewConditionsQuery,
    UserAnalyticsDetail_ViewConditionsQueryVariables
  >(UserAnalyticsDetail_ViewConditionsDocument, options);
}
export type UserAnalyticsDetail_ViewConditionsQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_ViewConditionsQuery
>;
export type UserAnalyticsDetail_ViewConditionsLazyQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_ViewConditionsLazyQuery
>;
export type UserAnalyticsDetail_ViewConditionsQueryResult = Apollo.QueryResult<
  UserAnalyticsDetail_ViewConditionsQuery,
  UserAnalyticsDetail_ViewConditionsQueryVariables
>;
export const UserAnalyticsDetail_ViewDetailDocument = gql`
  query UserAnalyticsDetail_ViewDetail(
    $id: ID!
    $dateRange: TimeRangeInput!
    $sessionPerformanceGroupBy: SessionPerformanceGroupBy
    $sessionPerformanceDetailGroupBy: [SessionPerformanceDetailGroupBy!] = []
    $sessionPerformanceDetailLimit: Int = 20
    $sessionPerformanceDetailOffset: Int = 0
    $sessionPerformanceDetailOrderBy: SessionPerformanceDetailOrderByInput = {
      field: SESSION_COUNT
      direction: DESC
    }
  ) {
    viewDetail: node(id: $id) {
      ... on UserAnalyticsView {
        ...ChartCard_UserAnalyticsView
        ...MetricDetailsCard_UserAnalyticsView
      }
    }
  }
  ${ChartCard_UserAnalyticsViewFragmentDoc}
  ${MetricDetailsCard_UserAnalyticsViewFragmentDoc}
`;

/**
 * __useUserAnalyticsDetail_ViewDetailQuery__
 *
 * To run a query within a React component, call `useUserAnalyticsDetail_ViewDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnalyticsDetail_ViewDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnalyticsDetail_ViewDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateRange: // value for 'dateRange'
 *      sessionPerformanceGroupBy: // value for 'sessionPerformanceGroupBy'
 *      sessionPerformanceDetailGroupBy: // value for 'sessionPerformanceDetailGroupBy'
 *      sessionPerformanceDetailLimit: // value for 'sessionPerformanceDetailLimit'
 *      sessionPerformanceDetailOffset: // value for 'sessionPerformanceDetailOffset'
 *      sessionPerformanceDetailOrderBy: // value for 'sessionPerformanceDetailOrderBy'
 *   },
 * });
 */
export function useUserAnalyticsDetail_ViewDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAnalyticsDetail_ViewDetailQuery,
    UserAnalyticsDetail_ViewDetailQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    UserAnalyticsDetail_ViewDetailQuery,
    UserAnalyticsDetail_ViewDetailQueryVariables
  >(UserAnalyticsDetail_ViewDetailDocument, options);
}
export function useUserAnalyticsDetail_ViewDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAnalyticsDetail_ViewDetailQuery,
    UserAnalyticsDetail_ViewDetailQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    UserAnalyticsDetail_ViewDetailQuery,
    UserAnalyticsDetail_ViewDetailQueryVariables
  >(UserAnalyticsDetail_ViewDetailDocument, options);
}
export type UserAnalyticsDetail_ViewDetailQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_ViewDetailQuery
>;
export type UserAnalyticsDetail_ViewDetailLazyQueryHookResult = ReturnType<
  typeof useUserAnalyticsDetail_ViewDetailLazyQuery
>;
export type UserAnalyticsDetail_ViewDetailQueryResult = Apollo.QueryResult<
  UserAnalyticsDetail_ViewDetailQuery,
  UserAnalyticsDetail_ViewDetailQueryVariables
>;
export const UaExportButton_ExportSessionPerformanceDetailDocument = gql`
  mutation UaExportButton_ExportSessionPerformanceDetail(
    $input: ExportSessionPerformanceDetailInput!
  ) {
    exportSessionPerformanceDetail(input: $input) {
      urls
      expires
      status
      exportType
      exportedAt
    }
  }
`;
export type UaExportButton_ExportSessionPerformanceDetailMutationFn =
  Apollo.MutationFunction<
    UaExportButton_ExportSessionPerformanceDetailMutation,
    UaExportButton_ExportSessionPerformanceDetailMutationVariables
  >;

/**
 * __useUaExportButton_ExportSessionPerformanceDetailMutation__
 *
 * To run a mutation, you first call `useUaExportButton_ExportSessionPerformanceDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUaExportButton_ExportSessionPerformanceDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uaExportButtonExportSessionPerformanceDetailMutation, { data, loading, error }] = useUaExportButton_ExportSessionPerformanceDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUaExportButton_ExportSessionPerformanceDetailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UaExportButton_ExportSessionPerformanceDetailMutation,
    UaExportButton_ExportSessionPerformanceDetailMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UaExportButton_ExportSessionPerformanceDetailMutation,
    UaExportButton_ExportSessionPerformanceDetailMutationVariables
  >(UaExportButton_ExportSessionPerformanceDetailDocument, options);
}
export type UaExportButton_ExportSessionPerformanceDetailMutationHookResult =
  ReturnType<typeof useUaExportButton_ExportSessionPerformanceDetailMutation>;
export type UaExportButton_ExportSessionPerformanceDetailMutationResult =
  Apollo.MutationResult<UaExportButton_ExportSessionPerformanceDetailMutation>;
export type UaExportButton_ExportSessionPerformanceDetailMutationOptions =
  Apollo.BaseMutationOptions<
    UaExportButton_ExportSessionPerformanceDetailMutation,
    UaExportButton_ExportSessionPerformanceDetailMutationVariables
  >;
export const UserAnalyticsListDocument = gql`
  query UserAnalyticsList(
    $projectId: ID!
    $organizationID: ID!
    $dateRange: TimeRangeInput!
    $os: [OSCategory!]
    $devices: [DeviceCategory!]
    $browsers: [BrowserCategory!]
    $sources: [String!]
    $mediums: [String!]
    $campaigns: [String!]
    $campaignsFilterType: [UserAnalyticsFilter!]
    $events: [String!]
    $firstPageTitle: String
    $firstPageTitleFilterType: UserAnalyticsFilter
    $secondPageTitle: String
    $secondPageTitleFilterType: UserAnalyticsFilter
    $firstPageLocation: String
    $firstPageLocationURLGroupIDs: [ID!]
    $firstPageLocationFilterType: UserAnalyticsFilter
    $secondPageLocation: String
    $secondPageLocationURLGroupIDs: [ID!]
    $secondPageLocationFilterType: UserAnalyticsFilter
    $sessionPerformanceGroupBy: SessionPerformanceGroupBy
    $isSessionWithNavigation: Boolean
    $defaultChannelGroup: [DefaultChannelGroup!]
    $pageLocationCount: IntRangeInput
  ) {
    node(id: $projectId) {
      ... on UserAnalyticsProject {
        id
        name
        description
        ...ProjectMenu
        userAnalytics {
          ...ViewList_UserAnalytics
          ...ConversionEventsDialog_UserAnalytics
        }
      }
    }
    viewer {
      id
      isAdmin
      isCustomerSupport(organizationID: $organizationID)
      organizations {
        role
        organizationID
      }
    }
    organization: node(id: $organizationID) {
      ... on Organization {
        id
        serviceAccountEmail
      }
    }
  }
  ${ProjectMenuFragmentDoc}
  ${ViewList_UserAnalyticsFragmentDoc}
  ${ConversionEventsDialog_UserAnalyticsFragmentDoc}
`;

/**
 * __useUserAnalyticsListQuery__
 *
 * To run a query within a React component, call `useUserAnalyticsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnalyticsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnalyticsListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      organizationID: // value for 'organizationID'
 *      dateRange: // value for 'dateRange'
 *      os: // value for 'os'
 *      devices: // value for 'devices'
 *      browsers: // value for 'browsers'
 *      sources: // value for 'sources'
 *      mediums: // value for 'mediums'
 *      campaigns: // value for 'campaigns'
 *      campaignsFilterType: // value for 'campaignsFilterType'
 *      events: // value for 'events'
 *      firstPageTitle: // value for 'firstPageTitle'
 *      firstPageTitleFilterType: // value for 'firstPageTitleFilterType'
 *      secondPageTitle: // value for 'secondPageTitle'
 *      secondPageTitleFilterType: // value for 'secondPageTitleFilterType'
 *      firstPageLocation: // value for 'firstPageLocation'
 *      firstPageLocationURLGroupIDs: // value for 'firstPageLocationURLGroupIDs'
 *      firstPageLocationFilterType: // value for 'firstPageLocationFilterType'
 *      secondPageLocation: // value for 'secondPageLocation'
 *      secondPageLocationURLGroupIDs: // value for 'secondPageLocationURLGroupIDs'
 *      secondPageLocationFilterType: // value for 'secondPageLocationFilterType'
 *      sessionPerformanceGroupBy: // value for 'sessionPerformanceGroupBy'
 *      isSessionWithNavigation: // value for 'isSessionWithNavigation'
 *      defaultChannelGroup: // value for 'defaultChannelGroup'
 *      pageLocationCount: // value for 'pageLocationCount'
 *   },
 * });
 */
export function useUserAnalyticsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAnalyticsListQuery,
    UserAnalyticsListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    UserAnalyticsListQuery,
    UserAnalyticsListQueryVariables
  >(UserAnalyticsListDocument, options);
}
export function useUserAnalyticsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAnalyticsListQuery,
    UserAnalyticsListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    UserAnalyticsListQuery,
    UserAnalyticsListQueryVariables
  >(UserAnalyticsListDocument, options);
}
export type UserAnalyticsListQueryHookResult = ReturnType<
  typeof useUserAnalyticsListQuery
>;
export type UserAnalyticsListLazyQueryHookResult = ReturnType<
  typeof useUserAnalyticsListLazyQuery
>;
export type UserAnalyticsListQueryResult = Apollo.QueryResult<
  UserAnalyticsListQuery,
  UserAnalyticsListQueryVariables
>;
export const UpdateUserAnalyticsKeyEventsDocument = gql`
  mutation UpdateUserAnalyticsKeyEvents($id: ID!, $keyEvents: [String!]!) {
    updateUserAnalytics(input: {id: $id, keyEvents: $keyEvents}) {
      id
      keyEvents
      eventNames
    }
  }
`;
export type UpdateUserAnalyticsKeyEventsMutationFn = Apollo.MutationFunction<
  UpdateUserAnalyticsKeyEventsMutation,
  UpdateUserAnalyticsKeyEventsMutationVariables
>;

/**
 * __useUpdateUserAnalyticsKeyEventsMutation__
 *
 * To run a mutation, you first call `useUpdateUserAnalyticsKeyEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAnalyticsKeyEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAnalyticsKeyEventsMutation, { data, loading, error }] = useUpdateUserAnalyticsKeyEventsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      keyEvents: // value for 'keyEvents'
 *   },
 * });
 */
export function useUpdateUserAnalyticsKeyEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAnalyticsKeyEventsMutation,
    UpdateUserAnalyticsKeyEventsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUserAnalyticsKeyEventsMutation,
    UpdateUserAnalyticsKeyEventsMutationVariables
  >(UpdateUserAnalyticsKeyEventsDocument, options);
}
export type UpdateUserAnalyticsKeyEventsMutationHookResult = ReturnType<
  typeof useUpdateUserAnalyticsKeyEventsMutation
>;
export type UpdateUserAnalyticsKeyEventsMutationResult =
  Apollo.MutationResult<UpdateUserAnalyticsKeyEventsMutation>;
export type UpdateUserAnalyticsKeyEventsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserAnalyticsKeyEventsMutation,
    UpdateUserAnalyticsKeyEventsMutationVariables
  >;
export const DeleteUserAnalyticsProjectDocument = gql`
  mutation DeleteUserAnalyticsProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      id
    }
  }
`;
export type DeleteUserAnalyticsProjectMutationFn = Apollo.MutationFunction<
  DeleteUserAnalyticsProjectMutation,
  DeleteUserAnalyticsProjectMutationVariables
>;

/**
 * __useDeleteUserAnalyticsProjectMutation__
 *
 * To run a mutation, you first call `useDeleteUserAnalyticsProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAnalyticsProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAnalyticsProjectMutation, { data, loading, error }] = useDeleteUserAnalyticsProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserAnalyticsProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserAnalyticsProjectMutation,
    DeleteUserAnalyticsProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteUserAnalyticsProjectMutation,
    DeleteUserAnalyticsProjectMutationVariables
  >(DeleteUserAnalyticsProjectDocument, options);
}
export type DeleteUserAnalyticsProjectMutationHookResult = ReturnType<
  typeof useDeleteUserAnalyticsProjectMutation
>;
export type DeleteUserAnalyticsProjectMutationResult =
  Apollo.MutationResult<DeleteUserAnalyticsProjectMutation>;
export type DeleteUserAnalyticsProjectMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteUserAnalyticsProjectMutation,
    DeleteUserAnalyticsProjectMutationVariables
  >;
export const UpdateUserAnalyticsProjectDocument = gql`
  mutation UpdateUserAnalyticsProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      name
      color
    }
  }
`;
export type UpdateUserAnalyticsProjectMutationFn = Apollo.MutationFunction<
  UpdateUserAnalyticsProjectMutation,
  UpdateUserAnalyticsProjectMutationVariables
>;

/**
 * __useUpdateUserAnalyticsProjectMutation__
 *
 * To run a mutation, you first call `useUpdateUserAnalyticsProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAnalyticsProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAnalyticsProjectMutation, { data, loading, error }] = useUpdateUserAnalyticsProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAnalyticsProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAnalyticsProjectMutation,
    UpdateUserAnalyticsProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUserAnalyticsProjectMutation,
    UpdateUserAnalyticsProjectMutationVariables
  >(UpdateUserAnalyticsProjectDocument, options);
}
export type UpdateUserAnalyticsProjectMutationHookResult = ReturnType<
  typeof useUpdateUserAnalyticsProjectMutation
>;
export type UpdateUserAnalyticsProjectMutationResult =
  Apollo.MutationResult<UpdateUserAnalyticsProjectMutation>;
export type UpdateUserAnalyticsProjectMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserAnalyticsProjectMutation,
    UpdateUserAnalyticsProjectMutationVariables
  >;
export const UpdateUserAnalyticsModal_UpdateUserAnalyticsDocument = gql`
  mutation UpdateUserAnalyticsModal_UpdateUserAnalytics(
    $input: UpdateUserAnalyticsInput!
  ) {
    updateUserAnalytics(input: $input) {
      id
      googleCloudProjectID
      datasetName
    }
  }
`;
export type UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationFn =
  Apollo.MutationFunction<
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutation,
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationVariables
  >;

/**
 * __useUpdateUserAnalyticsModal_UpdateUserAnalyticsMutation__
 *
 * To run a mutation, you first call `useUpdateUserAnalyticsModal_UpdateUserAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAnalyticsModal_UpdateUserAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAnalyticsModalUpdateUserAnalyticsMutation, { data, loading, error }] = useUpdateUserAnalyticsModal_UpdateUserAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAnalyticsModal_UpdateUserAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutation,
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutation,
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationVariables
  >(UpdateUserAnalyticsModal_UpdateUserAnalyticsDocument, options);
}
export type UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationHookResult =
  ReturnType<typeof useUpdateUserAnalyticsModal_UpdateUserAnalyticsMutation>;
export type UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationResult =
  Apollo.MutationResult<UpdateUserAnalyticsModal_UpdateUserAnalyticsMutation>;
export type UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutation,
    UpdateUserAnalyticsModal_UpdateUserAnalyticsMutationVariables
  >;
export const CreateUserAnalyticsDocument = gql`
  mutation CreateUserAnalytics($input: CreateUserAnalyticsInput!) {
    createUserAnalytics(input: $input) {
      id
      googleCloudProjectID
      datasetName
      keyEvents
      eventNames
    }
  }
`;
export type CreateUserAnalyticsMutationFn = Apollo.MutationFunction<
  CreateUserAnalyticsMutation,
  CreateUserAnalyticsMutationVariables
>;

/**
 * __useCreateUserAnalyticsMutation__
 *
 * To run a mutation, you first call `useCreateUserAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAnalyticsMutation, { data, loading, error }] = useCreateUserAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserAnalyticsMutation,
    CreateUserAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateUserAnalyticsMutation,
    CreateUserAnalyticsMutationVariables
  >(CreateUserAnalyticsDocument, options);
}
export type CreateUserAnalyticsMutationHookResult = ReturnType<
  typeof useCreateUserAnalyticsMutation
>;
export type CreateUserAnalyticsMutationResult =
  Apollo.MutationResult<CreateUserAnalyticsMutation>;
export type CreateUserAnalyticsMutationOptions = Apollo.BaseMutationOptions<
  CreateUserAnalyticsMutation,
  CreateUserAnalyticsMutationVariables
>;
export const ViewList_UpdateUserAnalyticsViewOrderDocument = gql`
  mutation ViewList_UpdateUserAnalyticsViewOrder(
    $input: UpdateUserAnalyticsViewOrderInput!
  ) {
    updateUserAnalyticsViewOrder(input: $input) {
      views {
        id
      }
    }
  }
`;
export type ViewList_UpdateUserAnalyticsViewOrderMutationFn =
  Apollo.MutationFunction<
    ViewList_UpdateUserAnalyticsViewOrderMutation,
    ViewList_UpdateUserAnalyticsViewOrderMutationVariables
  >;

/**
 * __useViewList_UpdateUserAnalyticsViewOrderMutation__
 *
 * To run a mutation, you first call `useViewList_UpdateUserAnalyticsViewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewList_UpdateUserAnalyticsViewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewListUpdateUserAnalyticsViewOrderMutation, { data, loading, error }] = useViewList_UpdateUserAnalyticsViewOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewList_UpdateUserAnalyticsViewOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewList_UpdateUserAnalyticsViewOrderMutation,
    ViewList_UpdateUserAnalyticsViewOrderMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ViewList_UpdateUserAnalyticsViewOrderMutation,
    ViewList_UpdateUserAnalyticsViewOrderMutationVariables
  >(ViewList_UpdateUserAnalyticsViewOrderDocument, options);
}
export type ViewList_UpdateUserAnalyticsViewOrderMutationHookResult =
  ReturnType<typeof useViewList_UpdateUserAnalyticsViewOrderMutation>;
export type ViewList_UpdateUserAnalyticsViewOrderMutationResult =
  Apollo.MutationResult<ViewList_UpdateUserAnalyticsViewOrderMutation>;
export type ViewList_UpdateUserAnalyticsViewOrderMutationOptions =
  Apollo.BaseMutationOptions<
    ViewList_UpdateUserAnalyticsViewOrderMutation,
    ViewList_UpdateUserAnalyticsViewOrderMutationVariables
  >;
export const DeleteViewDialog_DeleteViewDocument = gql`
  mutation DeleteViewDialog_DeleteView($input: DeleteUserAnalyticsViewInput!) {
    deleteUserAnalyticsView(input: $input) {
      id
    }
  }
`;
export type DeleteViewDialog_DeleteViewMutationFn = Apollo.MutationFunction<
  DeleteViewDialog_DeleteViewMutation,
  DeleteViewDialog_DeleteViewMutationVariables
>;

/**
 * __useDeleteViewDialog_DeleteViewMutation__
 *
 * To run a mutation, you first call `useDeleteViewDialog_DeleteViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewDialog_DeleteViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewDialogDeleteViewMutation, { data, loading, error }] = useDeleteViewDialog_DeleteViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteViewDialog_DeleteViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteViewDialog_DeleteViewMutation,
    DeleteViewDialog_DeleteViewMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteViewDialog_DeleteViewMutation,
    DeleteViewDialog_DeleteViewMutationVariables
  >(DeleteViewDialog_DeleteViewDocument, options);
}
export type DeleteViewDialog_DeleteViewMutationHookResult = ReturnType<
  typeof useDeleteViewDialog_DeleteViewMutation
>;
export type DeleteViewDialog_DeleteViewMutationResult =
  Apollo.MutationResult<DeleteViewDialog_DeleteViewMutation>;
export type DeleteViewDialog_DeleteViewMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteViewDialog_DeleteViewMutation,
    DeleteViewDialog_DeleteViewMutationVariables
  >;
export const CreateViewDialog_CreateUserAnalyticsViewDocument = gql`
  mutation CreateViewDialog_CreateUserAnalyticsView(
    $input: CreateUserAnalyticsViewInput!
  ) {
    createUserAnalyticsView(input: $input) {
      id
      os
      devices
      browsers
      sources
      mediums
      campaigns
      detailGroupBy
      detailOrderBy {
        field
        direction
      }
      name
      description
      defaultDateRange {
        type
        dateRange {
          start
          end
        }
      }
      firstPageLocationURLGroupIDs
      firstPageLocationURL
      firstPageLocationFilterType
      firstPageTitle
      firstPageTitleFilterType
      secondPageLocationURLGroupIDs
      secondPageLocationURL
      secondPageLocationFilterType
      secondPageTitle
      secondPageTitleFilterType
      isSessionWithNavigation
      defaultChannelGroup
    }
  }
`;
export type CreateViewDialog_CreateUserAnalyticsViewMutationFn =
  Apollo.MutationFunction<
    CreateViewDialog_CreateUserAnalyticsViewMutation,
    CreateViewDialog_CreateUserAnalyticsViewMutationVariables
  >;

/**
 * __useCreateViewDialog_CreateUserAnalyticsViewMutation__
 *
 * To run a mutation, you first call `useCreateViewDialog_CreateUserAnalyticsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewDialog_CreateUserAnalyticsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewDialogCreateUserAnalyticsViewMutation, { data, loading, error }] = useCreateViewDialog_CreateUserAnalyticsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateViewDialog_CreateUserAnalyticsViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateViewDialog_CreateUserAnalyticsViewMutation,
    CreateViewDialog_CreateUserAnalyticsViewMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateViewDialog_CreateUserAnalyticsViewMutation,
    CreateViewDialog_CreateUserAnalyticsViewMutationVariables
  >(CreateViewDialog_CreateUserAnalyticsViewDocument, options);
}
export type CreateViewDialog_CreateUserAnalyticsViewMutationHookResult =
  ReturnType<typeof useCreateViewDialog_CreateUserAnalyticsViewMutation>;
export type CreateViewDialog_CreateUserAnalyticsViewMutationResult =
  Apollo.MutationResult<CreateViewDialog_CreateUserAnalyticsViewMutation>;
export type CreateViewDialog_CreateUserAnalyticsViewMutationOptions =
  Apollo.BaseMutationOptions<
    CreateViewDialog_CreateUserAnalyticsViewMutation,
    CreateViewDialog_CreateUserAnalyticsViewMutationVariables
  >;
export const UpdateViewDialog_UpdateUserAnalyticsViewDocument = gql`
  mutation UpdateViewDialog_UpdateUserAnalyticsView(
    $input: UpdateUserAnalyticsViewInput!
  ) {
    updateUserAnalyticsView(input: $input) {
      ...UpdateViewDialog_UserAnalyticsView
    }
  }
  ${UpdateViewDialog_UserAnalyticsViewFragmentDoc}
`;
export type UpdateViewDialog_UpdateUserAnalyticsViewMutationFn =
  Apollo.MutationFunction<
    UpdateViewDialog_UpdateUserAnalyticsViewMutation,
    UpdateViewDialog_UpdateUserAnalyticsViewMutationVariables
  >;

/**
 * __useUpdateViewDialog_UpdateUserAnalyticsViewMutation__
 *
 * To run a mutation, you first call `useUpdateViewDialog_UpdateUserAnalyticsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewDialog_UpdateUserAnalyticsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewDialogUpdateUserAnalyticsViewMutation, { data, loading, error }] = useUpdateViewDialog_UpdateUserAnalyticsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateViewDialog_UpdateUserAnalyticsViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateViewDialog_UpdateUserAnalyticsViewMutation,
    UpdateViewDialog_UpdateUserAnalyticsViewMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateViewDialog_UpdateUserAnalyticsViewMutation,
    UpdateViewDialog_UpdateUserAnalyticsViewMutationVariables
  >(UpdateViewDialog_UpdateUserAnalyticsViewDocument, options);
}
export type UpdateViewDialog_UpdateUserAnalyticsViewMutationHookResult =
  ReturnType<typeof useUpdateViewDialog_UpdateUserAnalyticsViewMutation>;
export type UpdateViewDialog_UpdateUserAnalyticsViewMutationResult =
  Apollo.MutationResult<UpdateViewDialog_UpdateUserAnalyticsViewMutation>;
export type UpdateViewDialog_UpdateUserAnalyticsViewMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateViewDialog_UpdateUserAnalyticsViewMutation,
    UpdateViewDialog_UpdateUserAnalyticsViewMutationVariables
  >;
export const UrlGroup_UserAnalyticsProjectDocument = gql`
  query UrlGroup_UserAnalyticsProject($projectId: ID!, $organizationId: ID!) {
    viewer {
      id
      organizations {
        organizationID
        role
      }
      isAdmin
      isCustomerSupport(organizationID: $organizationId)
    }
    node(id: $projectId) {
      ... on UserAnalyticsProject {
        id
        userAnalytics {
          id
          urlGroups {
            ...UrlGroupTable_URLGroup
            ...DeleteUrlGroupModal_URLGroup
            ...UpdateUrlGroupDialog_URLGroup
          }
        }
      }
    }
    organization: node(id: $organizationId) {
      ... on Organization {
        id
        projects {
          ... on SearchAnalyticsProject {
            ...ImportUrlGroupModal_SearchAnalyticsProject
          }
          ... on UserAnalyticsProject {
            ...ImportUrlGroupModal_UserAnalyticsProject
          }
        }
      }
    }
  }
  ${UrlGroupTable_UrlGroupFragmentDoc}
  ${DeleteUrlGroupModal_UrlGroupFragmentDoc}
  ${UpdateUrlGroupDialog_UrlGroupFragmentDoc}
  ${ImportUrlGroupModal_SearchAnalyticsProjectFragmentDoc}
  ${ImportUrlGroupModal_UserAnalyticsProjectFragmentDoc}
`;

/**
 * __useUrlGroup_UserAnalyticsProjectQuery__
 *
 * To run a query within a React component, call `useUrlGroup_UserAnalyticsProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUrlGroup_UserAnalyticsProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUrlGroup_UserAnalyticsProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUrlGroup_UserAnalyticsProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    UrlGroup_UserAnalyticsProjectQuery,
    UrlGroup_UserAnalyticsProjectQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    UrlGroup_UserAnalyticsProjectQuery,
    UrlGroup_UserAnalyticsProjectQueryVariables
  >(UrlGroup_UserAnalyticsProjectDocument, options);
}
export function useUrlGroup_UserAnalyticsProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UrlGroup_UserAnalyticsProjectQuery,
    UrlGroup_UserAnalyticsProjectQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    UrlGroup_UserAnalyticsProjectQuery,
    UrlGroup_UserAnalyticsProjectQueryVariables
  >(UrlGroup_UserAnalyticsProjectDocument, options);
}
export type UrlGroup_UserAnalyticsProjectQueryHookResult = ReturnType<
  typeof useUrlGroup_UserAnalyticsProjectQuery
>;
export type UrlGroup_UserAnalyticsProjectLazyQueryHookResult = ReturnType<
  typeof useUrlGroup_UserAnalyticsProjectLazyQuery
>;
export type UrlGroup_UserAnalyticsProjectQueryResult = Apollo.QueryResult<
  UrlGroup_UserAnalyticsProjectQuery,
  UrlGroup_UserAnalyticsProjectQueryVariables
>;
export const UrlGroupTable_UpdateUrlGroupOrderDocument = gql`
  mutation UrlGroupTable_UpdateURLGroupOrder(
    $input: UpdateURLGroupOrderForUserAnalyticsInput!
  ) {
    updateURLGroupOrderForUserAnalytics(input: $input) {
      ...UrlGroupTable_URLGroup
    }
  }
  ${UrlGroupTable_UrlGroupFragmentDoc}
`;
export type UrlGroupTable_UpdateUrlGroupOrderMutationFn =
  Apollo.MutationFunction<
    UrlGroupTable_UpdateUrlGroupOrderMutation,
    UrlGroupTable_UpdateUrlGroupOrderMutationVariables
  >;

/**
 * __useUrlGroupTable_UpdateUrlGroupOrderMutation__
 *
 * To run a mutation, you first call `useUrlGroupTable_UpdateUrlGroupOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUrlGroupTable_UpdateUrlGroupOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [urlGroupTableUpdateUrlGroupOrderMutation, { data, loading, error }] = useUrlGroupTable_UpdateUrlGroupOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUrlGroupTable_UpdateUrlGroupOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UrlGroupTable_UpdateUrlGroupOrderMutation,
    UrlGroupTable_UpdateUrlGroupOrderMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UrlGroupTable_UpdateUrlGroupOrderMutation,
    UrlGroupTable_UpdateUrlGroupOrderMutationVariables
  >(UrlGroupTable_UpdateUrlGroupOrderDocument, options);
}
export type UrlGroupTable_UpdateUrlGroupOrderMutationHookResult = ReturnType<
  typeof useUrlGroupTable_UpdateUrlGroupOrderMutation
>;
export type UrlGroupTable_UpdateUrlGroupOrderMutationResult =
  Apollo.MutationResult<UrlGroupTable_UpdateUrlGroupOrderMutation>;
export type UrlGroupTable_UpdateUrlGroupOrderMutationOptions =
  Apollo.BaseMutationOptions<
    UrlGroupTable_UpdateUrlGroupOrderMutation,
    UrlGroupTable_UpdateUrlGroupOrderMutationVariables
  >;
export const CreateUrlGroup_CreateUrlGroupForUserAnalyticsDocument = gql`
  mutation CreateUrlGroup_CreateURLGroupForUserAnalytics(
    $input: CreateURUserAnalyticsURLGroupInput!
  ) {
    createURLGroupForUserAnalytics(input: $input) {
      id
      name
      patterns {
        type
        pattern
      }
      op
    }
  }
`;
export type CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationFn =
  Apollo.MutationFunction<
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation,
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationVariables
  >;

/**
 * __useCreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation__
 *
 * To run a mutation, you first call `useCreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUrlGroupCreateUrlGroupForUserAnalyticsMutation, { data, loading, error }] = useCreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation,
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation,
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationVariables
  >(CreateUrlGroup_CreateUrlGroupForUserAnalyticsDocument, options);
}
export type CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationHookResult =
  ReturnType<typeof useCreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation>;
export type CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationResult =
  Apollo.MutationResult<CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation>;
export type CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutation,
    CreateUrlGroup_CreateUrlGroupForUserAnalyticsMutationVariables
  >;
export const DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsDocument = gql`
  mutation DeleteUrlGroupModal_DeleteUrlGroupForUserAnalytics(
    $input: DeleteURLGroupForUserAnalyticsInput!
  ) {
    deleteURLGroupForUserAnalytics(input: $input) {
      id
    }
  }
`;
export type DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationFn =
  Apollo.MutationFunction<
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation,
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationVariables
  >;

/**
 * __useDeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation__
 *
 * To run a mutation, you first call `useDeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUrlGroupModalDeleteUrlGroupForUserAnalyticsMutation, { data, loading, error }] = useDeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation,
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation,
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationVariables
  >(DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsDocument, options);
}
export type DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationHookResult =
  ReturnType<
    typeof useDeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation
  >;
export type DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationResult =
  Apollo.MutationResult<DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation>;
export type DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutation,
    DeleteUrlGroupModal_DeleteUrlGroupForUserAnalyticsMutationVariables
  >;
export const UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsDocument = gql`
  mutation UpdateUrlGroupDialog_UpdateURLGroupForUserAnalytics(
    $input: UpdateURLGroupForUserAnalyticsInput!
  ) {
    updateURLGroupForUserAnalytics(input: $input) {
      ...UpdateUrlGroupDialog_URLGroup
    }
  }
  ${UpdateUrlGroupDialog_UrlGroupFragmentDoc}
`;
export type UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationFn =
  Apollo.MutationFunction<
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation,
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationVariables
  >;

/**
 * __useUpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation__
 *
 * To run a mutation, you first call `useUpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUrlGroupDialogUpdateUrlGroupForUserAnalyticsMutation, { data, loading, error }] = useUpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation,
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation,
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationVariables
  >(UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsDocument, options);
}
export type UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationHookResult =
  ReturnType<
    typeof useUpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation
  >;
export type UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationResult =
  Apollo.MutationResult<UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation>;
export type UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutation,
    UpdateUrlGroupDialog_UpdateUrlGroupForUserAnalyticsMutationVariables
  >;
export const StripeCustomerPortalUrlDocument = gql`
  query StripeCustomerPortalURL($contractId: ID!) {
    stripeCustomerPortalURL(contractID: $contractId)
  }
`;

/**
 * __useStripeCustomerPortalUrlQuery__
 *
 * To run a query within a React component, call `useStripeCustomerPortalUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeCustomerPortalUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeCustomerPortalUrlQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useStripeCustomerPortalUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    StripeCustomerPortalUrlQuery,
    StripeCustomerPortalUrlQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    StripeCustomerPortalUrlQuery,
    StripeCustomerPortalUrlQueryVariables
  >(StripeCustomerPortalUrlDocument, options);
}
export function useStripeCustomerPortalUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StripeCustomerPortalUrlQuery,
    StripeCustomerPortalUrlQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    StripeCustomerPortalUrlQuery,
    StripeCustomerPortalUrlQueryVariables
  >(StripeCustomerPortalUrlDocument, options);
}
export type StripeCustomerPortalUrlQueryHookResult = ReturnType<
  typeof useStripeCustomerPortalUrlQuery
>;
export type StripeCustomerPortalUrlLazyQueryHookResult = ReturnType<
  typeof useStripeCustomerPortalUrlLazyQuery
>;
export type StripeCustomerPortalUrlQueryResult = Apollo.QueryResult<
  StripeCustomerPortalUrlQuery,
  StripeCustomerPortalUrlQueryVariables
>;
export const CreateContractDocument = gql`
  mutation CreateContract($input: CreateContractInput!) {
    createContract(input: $input) {
      id
      name
      type
      stripeSubscriptionStatus
    }
  }
`;
export type CreateContractMutationFn = Apollo.MutationFunction<
  CreateContractMutation,
  CreateContractMutationVariables
>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractMutation,
    CreateContractMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateContractMutation,
    CreateContractMutationVariables
  >(CreateContractDocument, options);
}
export type CreateContractMutationHookResult = ReturnType<
  typeof useCreateContractMutation
>;
export type CreateContractMutationResult =
  Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  CreateContractMutation,
  CreateContractMutationVariables
>;
export const UpdateContractDocument = gql`
  mutation UpdateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      ...Contract
    }
  }
  ${ContractFragmentDoc}
`;
export type UpdateContractMutationFn = Apollo.MutationFunction<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractMutation,
    UpdateContractMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateContractMutation,
    UpdateContractMutationVariables
  >(UpdateContractDocument, options);
}
export type UpdateContractMutationHookResult = ReturnType<
  typeof useUpdateContractMutation
>;
export type UpdateContractMutationResult =
  Apollo.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;
export const AddOrganizationToContractDocument = gql`
  mutation AddOrganizationToContract($input: AddOrganizationToContractInput!) {
    addOrganizationToContract(input: $input) {
      ...Contract
    }
  }
  ${ContractFragmentDoc}
`;
export type AddOrganizationToContractMutationFn = Apollo.MutationFunction<
  AddOrganizationToContractMutation,
  AddOrganizationToContractMutationVariables
>;

/**
 * __useAddOrganizationToContractMutation__
 *
 * To run a mutation, you first call `useAddOrganizationToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationToContractMutation, { data, loading, error }] = useAddOrganizationToContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrganizationToContractMutation,
    AddOrganizationToContractMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddOrganizationToContractMutation,
    AddOrganizationToContractMutationVariables
  >(AddOrganizationToContractDocument, options);
}
export type AddOrganizationToContractMutationHookResult = ReturnType<
  typeof useAddOrganizationToContractMutation
>;
export type AddOrganizationToContractMutationResult =
  Apollo.MutationResult<AddOrganizationToContractMutation>;
export type AddOrganizationToContractMutationOptions =
  Apollo.BaseMutationOptions<
    AddOrganizationToContractMutation,
    AddOrganizationToContractMutationVariables
  >;
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      ...User
      registeredProperties {
        ...RegisteredSearchConsoleUserProperty
      }
      preferredLanguage
      isTutorialMovieViewed
    }
  }
  ${UserFragmentDoc}
  ${RegisteredSearchConsoleUserPropertyFragmentDoc}
`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  );
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  );
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>;
export const IndexWorkerDocument = gql`
  query IndexWorker($id: ID!) {
    node(id: $id) {
      ... on IndexWorker {
        ...IndexWorker
        jobs(first: 1) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
  ${IndexWorkerFragmentDoc}
`;

/**
 * __useIndexWorkerQuery__
 *
 * To run a query within a React component, call `useIndexWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexWorkerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIndexWorkerQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexWorkerQuery,
    IndexWorkerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<IndexWorkerQuery, IndexWorkerQueryVariables>(
    IndexWorkerDocument,
    options
  );
}
export function useIndexWorkerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexWorkerQuery,
    IndexWorkerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<IndexWorkerQuery, IndexWorkerQueryVariables>(
    IndexWorkerDocument,
    options
  );
}
export type IndexWorkerQueryHookResult = ReturnType<typeof useIndexWorkerQuery>;
export type IndexWorkerLazyQueryHookResult = ReturnType<
  typeof useIndexWorkerLazyQuery
>;
export type IndexWorkerQueryResult = Apollo.QueryResult<
  IndexWorkerQuery,
  IndexWorkerQueryVariables
>;
export const IndexWorkerLatestJobDocument = gql`
  query IndexWorkerLatestJob($indexWorkerId: ID!, $range: TimeRangeInput) {
    node(id: $indexWorkerId) {
      id
      ... on IndexWorker {
        name
        urlType
        schedule
        jobs(first: 1, range: $range) {
          edges {
            node {
              ...IndexWorkerJob
              tasks(first: 2000) {
                edges {
                  node {
                    ...IndexWorkerTask
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${IndexWorkerJobFragmentDoc}
  ${IndexWorkerTaskFragmentDoc}
`;

/**
 * __useIndexWorkerLatestJobQuery__
 *
 * To run a query within a React component, call `useIndexWorkerLatestJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexWorkerLatestJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexWorkerLatestJobQuery({
 *   variables: {
 *      indexWorkerId: // value for 'indexWorkerId'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useIndexWorkerLatestJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexWorkerLatestJobQuery,
    IndexWorkerLatestJobQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    IndexWorkerLatestJobQuery,
    IndexWorkerLatestJobQueryVariables
  >(IndexWorkerLatestJobDocument, options);
}
export function useIndexWorkerLatestJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexWorkerLatestJobQuery,
    IndexWorkerLatestJobQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    IndexWorkerLatestJobQuery,
    IndexWorkerLatestJobQueryVariables
  >(IndexWorkerLatestJobDocument, options);
}
export type IndexWorkerLatestJobQueryHookResult = ReturnType<
  typeof useIndexWorkerLatestJobQuery
>;
export type IndexWorkerLatestJobLazyQueryHookResult = ReturnType<
  typeof useIndexWorkerLatestJobLazyQuery
>;
export type IndexWorkerLatestJobQueryResult = Apollo.QueryResult<
  IndexWorkerLatestJobQuery,
  IndexWorkerLatestJobQueryVariables
>;
export const IndexWorkerJobsDocument = gql`
  query IndexWorkerJobs(
    $indexWorkerId: ID!
    $first: Int!
    $range: TimeRangeInput
  ) {
    node(id: $indexWorkerId) {
      id
      ... on IndexWorker {
        samplingCount
        urlType
        jobs(first: $first, range: $range) {
          edges {
            node {
              ...IndexWorkerJob
            }
          }
        }
      }
    }
  }
  ${IndexWorkerJobFragmentDoc}
`;

/**
 * __useIndexWorkerJobsQuery__
 *
 * To run a query within a React component, call `useIndexWorkerJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexWorkerJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexWorkerJobsQuery({
 *   variables: {
 *      indexWorkerId: // value for 'indexWorkerId'
 *      first: // value for 'first'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useIndexWorkerJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexWorkerJobsQuery,
    IndexWorkerJobsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<IndexWorkerJobsQuery, IndexWorkerJobsQueryVariables>(
    IndexWorkerJobsDocument,
    options
  );
}
export function useIndexWorkerJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexWorkerJobsQuery,
    IndexWorkerJobsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    IndexWorkerJobsQuery,
    IndexWorkerJobsQueryVariables
  >(IndexWorkerJobsDocument, options);
}
export type IndexWorkerJobsQueryHookResult = ReturnType<
  typeof useIndexWorkerJobsQuery
>;
export type IndexWorkerJobsLazyQueryHookResult = ReturnType<
  typeof useIndexWorkerJobsLazyQuery
>;
export type IndexWorkerJobsQueryResult = Apollo.QueryResult<
  IndexWorkerJobsQuery,
  IndexWorkerJobsQueryVariables
>;
export const IndexWorkersJobsDocument = gql`
  query IndexWorkersJobs($projectId: ID!, $first: Int!) {
    node(id: $projectId) {
      id
      ... on IndexWorkerProject {
        color
        name
        indexWorkers {
          ...IndexWorker
          jobs(first: $first) {
            edges {
              node {
                ...IndexWorkerJob
              }
            }
          }
        }
      }
    }
  }
  ${IndexWorkerFragmentDoc}
  ${IndexWorkerJobFragmentDoc}
`;

/**
 * __useIndexWorkersJobsQuery__
 *
 * To run a query within a React component, call `useIndexWorkersJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexWorkersJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexWorkersJobsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useIndexWorkersJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexWorkersJobsQuery,
    IndexWorkersJobsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<IndexWorkersJobsQuery, IndexWorkersJobsQueryVariables>(
    IndexWorkersJobsDocument,
    options
  );
}
export function useIndexWorkersJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexWorkersJobsQuery,
    IndexWorkersJobsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    IndexWorkersJobsQuery,
    IndexWorkersJobsQueryVariables
  >(IndexWorkersJobsDocument, options);
}
export type IndexWorkersJobsQueryHookResult = ReturnType<
  typeof useIndexWorkersJobsQuery
>;
export type IndexWorkersJobsLazyQueryHookResult = ReturnType<
  typeof useIndexWorkersJobsLazyQuery
>;
export type IndexWorkersJobsQueryResult = Apollo.QueryResult<
  IndexWorkersJobsQuery,
  IndexWorkersJobsQueryVariables
>;
export const GetAvailablePropertyTimeDocument = gql`
  query GetAvailablePropertyTime(
    $jobsToAdd: Int!
    $property: String!
    $days: String!
  ) {
    availablePropertyTime(
      input: {jobsToAdd: $jobsToAdd, property: $property, days: $days}
    )
  }
`;

/**
 * __useGetAvailablePropertyTimeQuery__
 *
 * To run a query within a React component, call `useGetAvailablePropertyTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailablePropertyTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailablePropertyTimeQuery({
 *   variables: {
 *      jobsToAdd: // value for 'jobsToAdd'
 *      property: // value for 'property'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useGetAvailablePropertyTimeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailablePropertyTimeQuery,
    GetAvailablePropertyTimeQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetAvailablePropertyTimeQuery,
    GetAvailablePropertyTimeQueryVariables
  >(GetAvailablePropertyTimeDocument, options);
}
export function useGetAvailablePropertyTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailablePropertyTimeQuery,
    GetAvailablePropertyTimeQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetAvailablePropertyTimeQuery,
    GetAvailablePropertyTimeQueryVariables
  >(GetAvailablePropertyTimeDocument, options);
}
export type GetAvailablePropertyTimeQueryHookResult = ReturnType<
  typeof useGetAvailablePropertyTimeQuery
>;
export type GetAvailablePropertyTimeLazyQueryHookResult = ReturnType<
  typeof useGetAvailablePropertyTimeLazyQuery
>;
export type GetAvailablePropertyTimeQueryResult = Apollo.QueryResult<
  GetAvailablePropertyTimeQuery,
  GetAvailablePropertyTimeQueryVariables
>;
export const UrlHistoryDocument = gql`
  query UrlHistory($urlId: ID!, $range: TimeRangeInput) {
    node(id: $urlId) {
      id
      ... on IndexWorkerURL {
        url
        results(range: $range) {
          ...IndexWorkerTask
        }
      }
    }
  }
  ${IndexWorkerTaskFragmentDoc}
`;

/**
 * __useUrlHistoryQuery__
 *
 * To run a query within a React component, call `useUrlHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUrlHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUrlHistoryQuery({
 *   variables: {
 *      urlId: // value for 'urlId'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useUrlHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UrlHistoryQuery,
    UrlHistoryQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<UrlHistoryQuery, UrlHistoryQueryVariables>(
    UrlHistoryDocument,
    options
  );
}
export function useUrlHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UrlHistoryQuery,
    UrlHistoryQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<UrlHistoryQuery, UrlHistoryQueryVariables>(
    UrlHistoryDocument,
    options
  );
}
export type UrlHistoryQueryHookResult = ReturnType<typeof useUrlHistoryQuery>;
export type UrlHistoryLazyQueryHookResult = ReturnType<
  typeof useUrlHistoryLazyQuery
>;
export type UrlHistoryQueryResult = Apollo.QueryResult<
  UrlHistoryQuery,
  UrlHistoryQueryVariables
>;
export const DeleteIndexWorkerDocument = gql`
  mutation DeleteIndexWorker($id: ID!) {
    deleteIndexWorker(input: {id: $id}) {
      id
    }
  }
`;
export type DeleteIndexWorkerMutationFn = Apollo.MutationFunction<
  DeleteIndexWorkerMutation,
  DeleteIndexWorkerMutationVariables
>;

/**
 * __useDeleteIndexWorkerMutation__
 *
 * To run a mutation, you first call `useDeleteIndexWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIndexWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIndexWorkerMutation, { data, loading, error }] = useDeleteIndexWorkerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIndexWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIndexWorkerMutation,
    DeleteIndexWorkerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteIndexWorkerMutation,
    DeleteIndexWorkerMutationVariables
  >(DeleteIndexWorkerDocument, options);
}
export type DeleteIndexWorkerMutationHookResult = ReturnType<
  typeof useDeleteIndexWorkerMutation
>;
export type DeleteIndexWorkerMutationResult =
  Apollo.MutationResult<DeleteIndexWorkerMutation>;
export type DeleteIndexWorkerMutationOptions = Apollo.BaseMutationOptions<
  DeleteIndexWorkerMutation,
  DeleteIndexWorkerMutationVariables
>;
export const MoveIndexWorkerProjectDocument = gql`
  mutation MoveIndexWorkerProject($input: MoveIndexWorkerProjectInput!) {
    moveIndexWorkerProject(input: $input) {
      ...IndexWorker
    }
  }
  ${IndexWorkerFragmentDoc}
`;
export type MoveIndexWorkerProjectMutationFn = Apollo.MutationFunction<
  MoveIndexWorkerProjectMutation,
  MoveIndexWorkerProjectMutationVariables
>;

/**
 * __useMoveIndexWorkerProjectMutation__
 *
 * To run a mutation, you first call `useMoveIndexWorkerProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveIndexWorkerProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveIndexWorkerProjectMutation, { data, loading, error }] = useMoveIndexWorkerProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveIndexWorkerProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveIndexWorkerProjectMutation,
    MoveIndexWorkerProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    MoveIndexWorkerProjectMutation,
    MoveIndexWorkerProjectMutationVariables
  >(MoveIndexWorkerProjectDocument, options);
}
export type MoveIndexWorkerProjectMutationHookResult = ReturnType<
  typeof useMoveIndexWorkerProjectMutation
>;
export type MoveIndexWorkerProjectMutationResult =
  Apollo.MutationResult<MoveIndexWorkerProjectMutation>;
export type MoveIndexWorkerProjectMutationOptions = Apollo.BaseMutationOptions<
  MoveIndexWorkerProjectMutation,
  MoveIndexWorkerProjectMutationVariables
>;
export const UpdateIndexWorkersViewOrderDocument = gql`
  mutation UpdateIndexWorkersViewOrder(
    $input: UpdateIndexWorkerViewOrderInput!
  ) {
    updateIndexWorkerViewOrder(input: $input) {
      ...IndexWorkerProject
    }
  }
  ${IndexWorkerProjectFragmentDoc}
`;
export type UpdateIndexWorkersViewOrderMutationFn = Apollo.MutationFunction<
  UpdateIndexWorkersViewOrderMutation,
  UpdateIndexWorkersViewOrderMutationVariables
>;

/**
 * __useUpdateIndexWorkersViewOrderMutation__
 *
 * To run a mutation, you first call `useUpdateIndexWorkersViewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndexWorkersViewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndexWorkersViewOrderMutation, { data, loading, error }] = useUpdateIndexWorkersViewOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIndexWorkersViewOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIndexWorkersViewOrderMutation,
    UpdateIndexWorkersViewOrderMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateIndexWorkersViewOrderMutation,
    UpdateIndexWorkersViewOrderMutationVariables
  >(UpdateIndexWorkersViewOrderDocument, options);
}
export type UpdateIndexWorkersViewOrderMutationHookResult = ReturnType<
  typeof useUpdateIndexWorkersViewOrderMutation
>;
export type UpdateIndexWorkersViewOrderMutationResult =
  Apollo.MutationResult<UpdateIndexWorkersViewOrderMutation>;
export type UpdateIndexWorkersViewOrderMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateIndexWorkersViewOrderMutation,
    UpdateIndexWorkersViewOrderMutationVariables
  >;
export const RunIndexWorkerDocument = gql`
  mutation RunIndexWorker($input: RunIndexWorkerInput!) {
    runIndexWorker(input: $input) {
      ...IndexWorker
    }
  }
  ${IndexWorkerFragmentDoc}
`;
export type RunIndexWorkerMutationFn = Apollo.MutationFunction<
  RunIndexWorkerMutation,
  RunIndexWorkerMutationVariables
>;

/**
 * __useRunIndexWorkerMutation__
 *
 * To run a mutation, you first call `useRunIndexWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunIndexWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runIndexWorkerMutation, { data, loading, error }] = useRunIndexWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunIndexWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunIndexWorkerMutation,
    RunIndexWorkerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RunIndexWorkerMutation,
    RunIndexWorkerMutationVariables
  >(RunIndexWorkerDocument, options);
}
export type RunIndexWorkerMutationHookResult = ReturnType<
  typeof useRunIndexWorkerMutation
>;
export type RunIndexWorkerMutationResult =
  Apollo.MutationResult<RunIndexWorkerMutation>;
export type RunIndexWorkerMutationOptions = Apollo.BaseMutationOptions<
  RunIndexWorkerMutation,
  RunIndexWorkerMutationVariables
>;
export const NotificationsDocument = gql`
  query Notifications(
    $first: Int = 10
    $after: ID
    $archived: Boolean
    $unread: Boolean
    $type: NotificationType
    $orgId: ID
    $severity: NotificationSeverity
  ) {
    viewer {
      id
      email
      notifications(
        first: $first
        after: $after
        archived: $archived
        unread: $unread
        type: $type
        organizationID: $orgId
        severity: $severity
      ) {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            ...NotificationSummary
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${NotificationSummaryFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      archived: // value for 'archived'
 *      unread: // value for 'unread'
 *      type: // value for 'type'
 *      orgId: // value for 'orgId'
 *      severity: // value for 'severity'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const NotificationDocument = gql`
  query Notification($id: ID!) {
    node(id: $id) {
      ... on Notification {
        ...Notification
      }
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotificationQuery,
    NotificationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<NotificationQuery, NotificationQueryVariables>(
    NotificationDocument,
    options
  );
}
export function useNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationQuery,
    NotificationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<NotificationQuery, NotificationQueryVariables>(
    NotificationDocument,
    options
  );
}
export type NotificationQueryHookResult = ReturnType<
  typeof useNotificationQuery
>;
export type NotificationLazyQueryHookResult = ReturnType<
  typeof useNotificationLazyQuery
>;
export type NotificationQueryResult = Apollo.QueryResult<
  NotificationQuery,
  NotificationQueryVariables
>;
export const ArchiveNotificationDocument = gql`
  mutation ArchiveNotification($id: ID!) {
    archiveNotification(input: {id: $id}) {
      ...NotificationSummary
    }
  }
  ${NotificationSummaryFragmentDoc}
`;
export type ArchiveNotificationMutationFn = Apollo.MutationFunction<
  ArchiveNotificationMutation,
  ArchiveNotificationMutationVariables
>;

/**
 * __useArchiveNotificationMutation__
 *
 * To run a mutation, you first call `useArchiveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveNotificationMutation, { data, loading, error }] = useArchiveNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveNotificationMutation,
    ArchiveNotificationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ArchiveNotificationMutation,
    ArchiveNotificationMutationVariables
  >(ArchiveNotificationDocument, options);
}
export type ArchiveNotificationMutationHookResult = ReturnType<
  typeof useArchiveNotificationMutation
>;
export type ArchiveNotificationMutationResult =
  Apollo.MutationResult<ArchiveNotificationMutation>;
export type ArchiveNotificationMutationOptions = Apollo.BaseMutationOptions<
  ArchiveNotificationMutation,
  ArchiveNotificationMutationVariables
>;
export const UnarchiveNotificationDocument = gql`
  mutation UnarchiveNotification($id: ID!) {
    unarchiveNotification(input: {id: $id}) {
      ...NotificationSummary
    }
  }
  ${NotificationSummaryFragmentDoc}
`;
export type UnarchiveNotificationMutationFn = Apollo.MutationFunction<
  UnarchiveNotificationMutation,
  UnarchiveNotificationMutationVariables
>;

/**
 * __useUnarchiveNotificationMutation__
 *
 * To run a mutation, you first call `useUnarchiveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveNotificationMutation, { data, loading, error }] = useUnarchiveNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveNotificationMutation,
    UnarchiveNotificationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UnarchiveNotificationMutation,
    UnarchiveNotificationMutationVariables
  >(UnarchiveNotificationDocument, options);
}
export type UnarchiveNotificationMutationHookResult = ReturnType<
  typeof useUnarchiveNotificationMutation
>;
export type UnarchiveNotificationMutationResult =
  Apollo.MutationResult<UnarchiveNotificationMutation>;
export type UnarchiveNotificationMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveNotificationMutation,
  UnarchiveNotificationMutationVariables
>;
export const MarkAsReadNotificationDocument = gql`
  mutation MarkAsReadNotification($id: ID!) {
    readNotification(input: {id: $id}) {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;
export type MarkAsReadNotificationMutationFn = Apollo.MutationFunction<
  MarkAsReadNotificationMutation,
  MarkAsReadNotificationMutationVariables
>;

/**
 * __useMarkAsReadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkAsReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadNotificationMutation, { data, loading, error }] = useMarkAsReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAsReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAsReadNotificationMutation,
    MarkAsReadNotificationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    MarkAsReadNotificationMutation,
    MarkAsReadNotificationMutationVariables
  >(MarkAsReadNotificationDocument, options);
}
export type MarkAsReadNotificationMutationHookResult = ReturnType<
  typeof useMarkAsReadNotificationMutation
>;
export type MarkAsReadNotificationMutationResult =
  Apollo.MutationResult<MarkAsReadNotificationMutation>;
export type MarkAsReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  MarkAsReadNotificationMutation,
  MarkAsReadNotificationMutationVariables
>;
export const OrganizationBasicInfoDocument = gql`
  query OrganizationBasicInfo($id: ID!) {
    node(id: $id) {
      ... on Organization {
        ...OrganizationBasicInfo
      }
    }
  }
  ${OrganizationBasicInfoFragmentDoc}
`;

/**
 * __useOrganizationBasicInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationBasicInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationBasicInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationBasicInfoQuery,
    OrganizationBasicInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    OrganizationBasicInfoQuery,
    OrganizationBasicInfoQueryVariables
  >(OrganizationBasicInfoDocument, options);
}
export function useOrganizationBasicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationBasicInfoQuery,
    OrganizationBasicInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    OrganizationBasicInfoQuery,
    OrganizationBasicInfoQueryVariables
  >(OrganizationBasicInfoDocument, options);
}
export type OrganizationBasicInfoQueryHookResult = ReturnType<
  typeof useOrganizationBasicInfoQuery
>;
export type OrganizationBasicInfoLazyQueryHookResult = ReturnType<
  typeof useOrganizationBasicInfoLazyQuery
>;
export type OrganizationBasicInfoQueryResult = Apollo.QueryResult<
  OrganizationBasicInfoQuery,
  OrganizationBasicInfoQueryVariables
>;
export const OrganizationDocument = gql`
  query Organization($id: ID!) {
    node(id: $id) {
      ... on Organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options
  );
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options
  );
}
export type OrganizationQueryHookResult = ReturnType<
  typeof useOrganizationQuery
>;
export type OrganizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationLazyQuery
>;
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>;
export const OrganizationsDocument = gql`
  query Organizations($organizationID: ID!) {
    viewer {
      id
      email
      isAdmin
      isCustomerSupport(organizationID: $organizationID)
      organizations {
        ...UserOrganization
      }
    }
  }
  ${UserOrganizationFragmentDoc}
`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  );
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  );
}
export type OrganizationsQueryHookResult = ReturnType<
  typeof useOrganizationsQuery
>;
export type OrganizationsLazyQueryHookResult = ReturnType<
  typeof useOrganizationsLazyQuery
>;
export type OrganizationsQueryResult = Apollo.QueryResult<
  OrganizationsQuery,
  OrganizationsQueryVariables
>;
export const AllGroupsBySearchAnalyticsDocument = gql`
  query AllGroupsBySearchAnalytics($id: ID!) {
    getAllURLGroupsBySearchAnalytics(organizationID: $id) {
      searchAnalyticsName
      urlGroup {
        ...URLGroup
      }
    }
  }
  ${UrlGroupFragmentDoc}
`;

/**
 * __useAllGroupsBySearchAnalyticsQuery__
 *
 * To run a query within a React component, call `useAllGroupsBySearchAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGroupsBySearchAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGroupsBySearchAnalyticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllGroupsBySearchAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllGroupsBySearchAnalyticsQuery,
    AllGroupsBySearchAnalyticsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    AllGroupsBySearchAnalyticsQuery,
    AllGroupsBySearchAnalyticsQueryVariables
  >(AllGroupsBySearchAnalyticsDocument, options);
}
export function useAllGroupsBySearchAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllGroupsBySearchAnalyticsQuery,
    AllGroupsBySearchAnalyticsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    AllGroupsBySearchAnalyticsQuery,
    AllGroupsBySearchAnalyticsQueryVariables
  >(AllGroupsBySearchAnalyticsDocument, options);
}
export type AllGroupsBySearchAnalyticsQueryHookResult = ReturnType<
  typeof useAllGroupsBySearchAnalyticsQuery
>;
export type AllGroupsBySearchAnalyticsLazyQueryHookResult = ReturnType<
  typeof useAllGroupsBySearchAnalyticsLazyQuery
>;
export type AllGroupsBySearchAnalyticsQueryResult = Apollo.QueryResult<
  AllGroupsBySearchAnalyticsQuery,
  AllGroupsBySearchAnalyticsQueryVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ...OrganizationBasicInfo
    }
  }
  ${OrganizationBasicInfoFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
    }
  }
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
  Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      id
    }
  }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationHookResult = ReturnType<
  typeof useDeleteOrganizationMutation
>;
export type DeleteOrganizationMutationResult =
  Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const ProjectDocument = gql`
  query Project($id: ID!) {
    node(id: $id) {
      ... on IndexWorkerProject {
        ...IndexWorkerProject
      }
      ... on SearchAnalyticsProject {
        ...SearchAnalyticsProject
        searchAnalytics {
          ...SearchAnalyticsBasicInfo
          latestDataDate
        }
      }
    }
  }
  ${IndexWorkerProjectFragmentDoc}
  ${SearchAnalyticsProjectFragmentDoc}
  ${SearchAnalyticsBasicInfoFragmentDoc}
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    options
  );
}
export function useProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    options
  );
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<
  ProjectQuery,
  ProjectQueryVariables
>;
export const ProjectsDocument = gql`
  query Projects($organizationId: ID!) {
    node(id: $organizationId) {
      id
      ... on Organization {
        name
        projects {
          ... on IndexWorkerProject {
            ...IndexWorkerProject
          }
          ... on SearchAnalyticsProject {
            ...SearchAnalyticsProject
          }
        }
      }
    }
  }
  ${IndexWorkerProjectFragmentDoc}
  ${SearchAnalyticsProjectFragmentDoc}
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options
  );
}
export function useProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsQuery,
    ProjectsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options
  );
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<
  typeof useProjectsLazyQuery
>;
export type ProjectsQueryResult = Apollo.QueryResult<
  ProjectsQuery,
  ProjectsQueryVariables
>;
export const CreateProjectDocument = gql`
  mutation CreateProject($input: CreateProjectInput) {
    createProject(input: $input) {
      ... on Project {
        ...Project
      }
    }
  }
  ${ProjectFragmentDoc}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(input: {id: $id}) {
      id
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const DuplicateProjectDocument = gql`
  mutation DuplicateProject($id: ID!) {
    duplicateProject(input: {id: $id}) {
      ... on Project {
        ...Project
      }
    }
  }
  ${ProjectFragmentDoc}
`;
export type DuplicateProjectMutationFn = Apollo.MutationFunction<
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables
>;

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateProjectMutation,
    DuplicateProjectMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DuplicateProjectMutation,
    DuplicateProjectMutationVariables
  >(DuplicateProjectDocument, options);
}
export type DuplicateProjectMutationHookResult = ReturnType<
  typeof useDuplicateProjectMutation
>;
export type DuplicateProjectMutationResult =
  Apollo.MutationResult<DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables
>;
export const RegisteredSeachConsolePropertyDocument = gql`
  query RegisteredSeachConsoleProperty($id: ID!) {
    node(id: $id) {
      ... on RegisteredSearchConsoleUserProperty {
        ...RegisteredSearchConsoleUserProperty
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        ...RegisteredSearchConsoleServiceAccountProperty
      }
    }
  }
  ${RegisteredSearchConsoleUserPropertyFragmentDoc}
  ${RegisteredSearchConsoleServiceAccountPropertyFragmentDoc}
`;

/**
 * __useRegisteredSeachConsolePropertyQuery__
 *
 * To run a query within a React component, call `useRegisteredSeachConsolePropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredSeachConsolePropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredSeachConsolePropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegisteredSeachConsolePropertyQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisteredSeachConsolePropertyQuery,
    RegisteredSeachConsolePropertyQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    RegisteredSeachConsolePropertyQuery,
    RegisteredSeachConsolePropertyQueryVariables
  >(RegisteredSeachConsolePropertyDocument, options);
}
export function useRegisteredSeachConsolePropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisteredSeachConsolePropertyQuery,
    RegisteredSeachConsolePropertyQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    RegisteredSeachConsolePropertyQuery,
    RegisteredSeachConsolePropertyQueryVariables
  >(RegisteredSeachConsolePropertyDocument, options);
}
export type RegisteredSeachConsolePropertyQueryHookResult = ReturnType<
  typeof useRegisteredSeachConsolePropertyQuery
>;
export type RegisteredSeachConsolePropertyLazyQueryHookResult = ReturnType<
  typeof useRegisteredSeachConsolePropertyLazyQuery
>;
export type RegisteredSeachConsolePropertyQueryResult = Apollo.QueryResult<
  RegisteredSeachConsolePropertyQuery,
  RegisteredSeachConsolePropertyQueryVariables
>;
export const RegisteredPropertiesByOrgIdDocument = gql`
  query RegisteredPropertiesByOrgId($id: ID!) {
    node(id: $id) {
      ... on Organization {
        id
        registeredProperties {
          ... on RegisteredSearchConsoleUserProperty {
            ...RegisteredSearchConsoleUserProperty
          }
          ... on RegisteredSearchConsoleServiceAccountProperty {
            ...RegisteredSearchConsoleServiceAccountProperty
          }
        }
      }
    }
  }
  ${RegisteredSearchConsoleUserPropertyFragmentDoc}
  ${RegisteredSearchConsoleServiceAccountPropertyFragmentDoc}
`;

/**
 * __useRegisteredPropertiesByOrgIdQuery__
 *
 * To run a query within a React component, call `useRegisteredPropertiesByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredPropertiesByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredPropertiesByOrgIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegisteredPropertiesByOrgIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisteredPropertiesByOrgIdQuery,
    RegisteredPropertiesByOrgIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    RegisteredPropertiesByOrgIdQuery,
    RegisteredPropertiesByOrgIdQueryVariables
  >(RegisteredPropertiesByOrgIdDocument, options);
}
export function useRegisteredPropertiesByOrgIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisteredPropertiesByOrgIdQuery,
    RegisteredPropertiesByOrgIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    RegisteredPropertiesByOrgIdQuery,
    RegisteredPropertiesByOrgIdQueryVariables
  >(RegisteredPropertiesByOrgIdDocument, options);
}
export type RegisteredPropertiesByOrgIdQueryHookResult = ReturnType<
  typeof useRegisteredPropertiesByOrgIdQuery
>;
export type RegisteredPropertiesByOrgIdLazyQueryHookResult = ReturnType<
  typeof useRegisteredPropertiesByOrgIdLazyQuery
>;
export type RegisteredPropertiesByOrgIdQueryResult = Apollo.QueryResult<
  RegisteredPropertiesByOrgIdQuery,
  RegisteredPropertiesByOrgIdQueryVariables
>;
export const PropertySitemapsDocument = gql`
  query PropertySitemaps($id: ID!) {
    node(id: $id) {
      ... on RegisteredSearchConsoleUserProperty {
        id
        sitemaps {
          ... on SitemapIndex {
            id
            url
          }
          ... on Sitemap {
            url
          }
        }
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        id
        sitemaps {
          ... on SitemapIndex {
            id
            url
          }
          ... on Sitemap {
            url
          }
        }
      }
    }
  }
`;

/**
 * __usePropertySitemapsQuery__
 *
 * To run a query within a React component, call `usePropertySitemapsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertySitemapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertySitemapsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertySitemapsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertySitemapsQuery,
    PropertySitemapsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PropertySitemapsQuery, PropertySitemapsQueryVariables>(
    PropertySitemapsDocument,
    options
  );
}
export function usePropertySitemapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertySitemapsQuery,
    PropertySitemapsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    PropertySitemapsQuery,
    PropertySitemapsQueryVariables
  >(PropertySitemapsDocument, options);
}
export type PropertySitemapsQueryHookResult = ReturnType<
  typeof usePropertySitemapsQuery
>;
export type PropertySitemapsLazyQueryHookResult = ReturnType<
  typeof usePropertySitemapsLazyQuery
>;
export type PropertySitemapsQueryResult = Apollo.QueryResult<
  PropertySitemapsQuery,
  PropertySitemapsQueryVariables
>;
export const OnboardingAvailablePropertiesDocument = gql`
  query OnboardingAvailableProperties {
    viewer {
      ...User
      availableProperties {
        property
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useOnboardingAvailablePropertiesQuery__
 *
 * To run a query within a React component, call `useOnboardingAvailablePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAvailablePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingAvailablePropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingAvailablePropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OnboardingAvailablePropertiesQuery,
    OnboardingAvailablePropertiesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    OnboardingAvailablePropertiesQuery,
    OnboardingAvailablePropertiesQueryVariables
  >(OnboardingAvailablePropertiesDocument, options);
}
export function useOnboardingAvailablePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingAvailablePropertiesQuery,
    OnboardingAvailablePropertiesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    OnboardingAvailablePropertiesQuery,
    OnboardingAvailablePropertiesQueryVariables
  >(OnboardingAvailablePropertiesDocument, options);
}
export type OnboardingAvailablePropertiesQueryHookResult = ReturnType<
  typeof useOnboardingAvailablePropertiesQuery
>;
export type OnboardingAvailablePropertiesLazyQueryHookResult = ReturnType<
  typeof useOnboardingAvailablePropertiesLazyQuery
>;
export type OnboardingAvailablePropertiesQueryResult = Apollo.QueryResult<
  OnboardingAvailablePropertiesQuery,
  OnboardingAvailablePropertiesQueryVariables
>;
export const AvailablePropertiesByOrgIdDocument = gql`
  query AvailablePropertiesByOrgId($orgId: ID!) {
    viewer {
      id
      email
      availableProperties(organizationId: $orgId) {
        property
      }
    }
    node(id: $orgId) {
      ... on Organization {
        serviceAccounts {
          id
          availableProperties {
            property
          }
        }
      }
    }
  }
`;

/**
 * __useAvailablePropertiesByOrgIdQuery__
 *
 * To run a query within a React component, call `useAvailablePropertiesByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePropertiesByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePropertiesByOrgIdQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useAvailablePropertiesByOrgIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailablePropertiesByOrgIdQuery,
    AvailablePropertiesByOrgIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    AvailablePropertiesByOrgIdQuery,
    AvailablePropertiesByOrgIdQueryVariables
  >(AvailablePropertiesByOrgIdDocument, options);
}
export function useAvailablePropertiesByOrgIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailablePropertiesByOrgIdQuery,
    AvailablePropertiesByOrgIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    AvailablePropertiesByOrgIdQuery,
    AvailablePropertiesByOrgIdQueryVariables
  >(AvailablePropertiesByOrgIdDocument, options);
}
export type AvailablePropertiesByOrgIdQueryHookResult = ReturnType<
  typeof useAvailablePropertiesByOrgIdQuery
>;
export type AvailablePropertiesByOrgIdLazyQueryHookResult = ReturnType<
  typeof useAvailablePropertiesByOrgIdLazyQuery
>;
export type AvailablePropertiesByOrgIdQueryResult = Apollo.QueryResult<
  AvailablePropertiesByOrgIdQuery,
  AvailablePropertiesByOrgIdQueryVariables
>;
export const RegisterPropertyDocument = gql`
  mutation RegisterProperty($input: RegisterPropertyInput) {
    registerProperty(input: $input) {
      ...RegisteredSearchConsoleUserProperty
    }
  }
  ${RegisteredSearchConsoleUserPropertyFragmentDoc}
`;
export type RegisterPropertyMutationFn = Apollo.MutationFunction<
  RegisterPropertyMutation,
  RegisterPropertyMutationVariables
>;

/**
 * __useRegisterPropertyMutation__
 *
 * To run a mutation, you first call `useRegisterPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPropertyMutation, { data, loading, error }] = useRegisterPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPropertyMutation,
    RegisterPropertyMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RegisterPropertyMutation,
    RegisterPropertyMutationVariables
  >(RegisterPropertyDocument, options);
}
export type RegisterPropertyMutationHookResult = ReturnType<
  typeof useRegisterPropertyMutation
>;
export type RegisterPropertyMutationResult =
  Apollo.MutationResult<RegisterPropertyMutation>;
export type RegisterPropertyMutationOptions = Apollo.BaseMutationOptions<
  RegisterPropertyMutation,
  RegisterPropertyMutationVariables
>;
export const UpdatePropertyDocument = gql`
  mutation UpdateProperty($input: UpdatePropertyInput) {
    updateProperty(input: $input) {
      ... on RegisteredSearchConsoleUserProperty {
        ...RegisteredSearchConsoleUserProperty
      }
      ... on RegisteredSearchConsoleServiceAccountProperty {
        ...RegisteredSearchConsoleServiceAccountProperty
      }
    }
  }
  ${RegisteredSearchConsoleUserPropertyFragmentDoc}
  ${RegisteredSearchConsoleServiceAccountPropertyFragmentDoc}
`;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >(UpdatePropertyDocument, options);
}
export type UpdatePropertyMutationHookResult = ReturnType<
  typeof useUpdatePropertyMutation
>;
export type UpdatePropertyMutationResult =
  Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;
export const SitemapIndexDocument = gql`
  query SitemapIndex($sitemapId: ID!) {
    node(id: $sitemapId) {
      ... on SitemapIndex {
        id
        url
        sitemaps {
          url
        }
      }
    }
  }
`;

/**
 * __useSitemapIndexQuery__
 *
 * To run a query within a React component, call `useSitemapIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapIndexQuery({
 *   variables: {
 *      sitemapId: // value for 'sitemapId'
 *   },
 * });
 */
export function useSitemapIndexQuery(
  baseOptions: Apollo.QueryHookOptions<
    SitemapIndexQuery,
    SitemapIndexQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<SitemapIndexQuery, SitemapIndexQueryVariables>(
    SitemapIndexDocument,
    options
  );
}
export function useSitemapIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SitemapIndexQuery,
    SitemapIndexQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<SitemapIndexQuery, SitemapIndexQueryVariables>(
    SitemapIndexDocument,
    options
  );
}
export type SitemapIndexQueryHookResult = ReturnType<
  typeof useSitemapIndexQuery
>;
export type SitemapIndexLazyQueryHookResult = ReturnType<
  typeof useSitemapIndexLazyQuery
>;
export type SitemapIndexQueryResult = Apollo.QueryResult<
  SitemapIndexQuery,
  SitemapIndexQueryVariables
>;
export const SearchAnalyticsViewListDocument = gql`
  query SearchAnalyticsViewList($id: ID!) {
    node(id: $id) {
      ... on SearchAnalytics {
        id
        views {
          ...SearchAnalyticsView
        }
      }
    }
  }
  ${SearchAnalyticsViewFragmentDoc}
`;

/**
 * __useSearchAnalyticsViewListQuery__
 *
 * To run a query within a React component, call `useSearchAnalyticsViewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnalyticsViewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnalyticsViewListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchAnalyticsViewListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAnalyticsViewListQuery,
    SearchAnalyticsViewListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SearchAnalyticsViewListQuery,
    SearchAnalyticsViewListQueryVariables
  >(SearchAnalyticsViewListDocument, options);
}
export function useSearchAnalyticsViewListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAnalyticsViewListQuery,
    SearchAnalyticsViewListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SearchAnalyticsViewListQuery,
    SearchAnalyticsViewListQueryVariables
  >(SearchAnalyticsViewListDocument, options);
}
export type SearchAnalyticsViewListQueryHookResult = ReturnType<
  typeof useSearchAnalyticsViewListQuery
>;
export type SearchAnalyticsViewListLazyQueryHookResult = ReturnType<
  typeof useSearchAnalyticsViewListLazyQuery
>;
export type SearchAnalyticsViewListQueryResult = Apollo.QueryResult<
  SearchAnalyticsViewListQuery,
  SearchAnalyticsViewListQueryVariables
>;
export const SearchAnalyticsViewDocument = gql`
  query SearchAnalyticsView($id: ID!) {
    node(id: $id) {
      id
      ... on SearchAnalyticsView {
        ...SearchAnalyticsView
      }
    }
  }
  ${SearchAnalyticsViewFragmentDoc}
`;

/**
 * __useSearchAnalyticsViewQuery__
 *
 * To run a query within a React component, call `useSearchAnalyticsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnalyticsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnalyticsViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchAnalyticsViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAnalyticsViewQuery,
    SearchAnalyticsViewQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SearchAnalyticsViewQuery,
    SearchAnalyticsViewQueryVariables
  >(SearchAnalyticsViewDocument, options);
}
export function useSearchAnalyticsViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAnalyticsViewQuery,
    SearchAnalyticsViewQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SearchAnalyticsViewQuery,
    SearchAnalyticsViewQueryVariables
  >(SearchAnalyticsViewDocument, options);
}
export type SearchAnalyticsViewQueryHookResult = ReturnType<
  typeof useSearchAnalyticsViewQuery
>;
export type SearchAnalyticsViewLazyQueryHookResult = ReturnType<
  typeof useSearchAnalyticsViewLazyQuery
>;
export type SearchAnalyticsViewQueryResult = Apollo.QueryResult<
  SearchAnalyticsViewQuery,
  SearchAnalyticsViewQueryVariables
>;
export const SearchAnalyticsViewAggregatedDataDocument = gql`
  query SearchAnalyticsViewAggregatedData(
    $id: ID!
    $dateRange: TimeRangeInput!
    $groupBy: SearchAnalyticsGroupBy
    $query: String
    $queryFilterType: SearchAnalyticsFilter
    $queryGroupIds: [ID!]
    $url: String
    $urlFilterType: SearchAnalyticsFilter
    $urlGroupIds: [ID!]
    $devices: [String!]
    $searchTypes: [SearchAnalyticsSearchType!]
    $countries: [String!]
    $useSavedDateRange: Boolean
  ) {
    node(id: $id) {
      id
      ... on SearchAnalytics {
        spreadsheetID
        aggregatedData(
          dateRange: $dateRange
          groupBy: $groupBy
          query: $query
          queryFilterType: $queryFilterType
          queryGroupIDs: $queryGroupIds
          url: $url
          urlFilterType: $urlFilterType
          urlGroupIDs: $urlGroupIds
          devices: $devices
          searchTypes: $searchTypes
          countries: $countries
        ) {
          ... on SearchAnalyticsAggregatedDataNonGrouped {
            ...AggregatedDataNonGrouped
          }
          ... on SearchAnalyticsAggregatedDataByQueryGroup {
            ...AggregatedDataByQueryGroup
          }
          ... on SearchAnalyticsAggregatedDataByURLGroup {
            ...AggregatedDataByUrlGroup
          }
        }
      }
      ... on SearchAnalyticsView {
        ...SearchAnalyticsView
        aggregatedData(
          dateRange: $dateRange
          groupBy: $groupBy
          useSavedDateRange: $useSavedDateRange
        ) {
          ... on SearchAnalyticsAggregatedDataNonGrouped {
            ...AggregatedDataNonGrouped
          }
          ... on SearchAnalyticsAggregatedDataByQueryGroup {
            ...AggregatedDataByQueryGroup
          }
          ... on SearchAnalyticsAggregatedDataByURLGroup {
            ...AggregatedDataByUrlGroup
          }
        }
      }
    }
  }
  ${AggregatedDataNonGroupedFragmentDoc}
  ${AggregatedDataByQueryGroupFragmentDoc}
  ${AggregatedDataByUrlGroupFragmentDoc}
  ${SearchAnalyticsViewFragmentDoc}
`;

/**
 * __useSearchAnalyticsViewAggregatedDataQuery__
 *
 * To run a query within a React component, call `useSearchAnalyticsViewAggregatedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnalyticsViewAggregatedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnalyticsViewAggregatedDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateRange: // value for 'dateRange'
 *      groupBy: // value for 'groupBy'
 *      query: // value for 'query'
 *      queryFilterType: // value for 'queryFilterType'
 *      queryGroupIds: // value for 'queryGroupIds'
 *      url: // value for 'url'
 *      urlFilterType: // value for 'urlFilterType'
 *      urlGroupIds: // value for 'urlGroupIds'
 *      devices: // value for 'devices'
 *      searchTypes: // value for 'searchTypes'
 *      countries: // value for 'countries'
 *      useSavedDateRange: // value for 'useSavedDateRange'
 *   },
 * });
 */
export function useSearchAnalyticsViewAggregatedDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAnalyticsViewAggregatedDataQuery,
    SearchAnalyticsViewAggregatedDataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SearchAnalyticsViewAggregatedDataQuery,
    SearchAnalyticsViewAggregatedDataQueryVariables
  >(SearchAnalyticsViewAggregatedDataDocument, options);
}
export function useSearchAnalyticsViewAggregatedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAnalyticsViewAggregatedDataQuery,
    SearchAnalyticsViewAggregatedDataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SearchAnalyticsViewAggregatedDataQuery,
    SearchAnalyticsViewAggregatedDataQueryVariables
  >(SearchAnalyticsViewAggregatedDataDocument, options);
}
export type SearchAnalyticsViewAggregatedDataQueryHookResult = ReturnType<
  typeof useSearchAnalyticsViewAggregatedDataQuery
>;
export type SearchAnalyticsViewAggregatedDataLazyQueryHookResult = ReturnType<
  typeof useSearchAnalyticsViewAggregatedDataLazyQuery
>;
export type SearchAnalyticsViewAggregatedDataQueryResult = Apollo.QueryResult<
  SearchAnalyticsViewAggregatedDataQuery,
  SearchAnalyticsViewAggregatedDataQueryVariables
>;
export const SearchAnalyticsRawDataDocument = gql`
  query SearchAnalyticsRawData(
    $id: ID!
    $first: Int = 20
    $after: String
    $dateRange: TimeRangeInput!
    $compareDateRange: TimeRangeInput!
    $query: String
    $queryFilterType: SearchAnalyticsFilter
    $queryGroupIds: [ID!]
    $url: String
    $urlFilterType: SearchAnalyticsFilter
    $urlGroupIds: [ID!]
    $devices: [String!]
    $searchTypes: [SearchAnalyticsSearchType!]
    $countries: [String!]
    $isAnonymousQuery: Boolean
    $orderBy: SearchAnalyticsRawDataOrderInput!
    $compareOrderBy: SearchAnalyticsRawComparingDataOrderInput!
    $groupBy: [SearchAnalyticsRawDataGroupBy!]!
    $isComparing: Boolean!
    $clicksRange: IntRangeInput
    $impressionsRange: IntRangeInput
    $ctrRange: FloatRangeInput
    $avgOfRankRange: FloatRangeInput
    $distinctQueriesRange: IntRangeInput
    $distinctURLsRange: IntRangeInput
  ) {
    node(id: $id) {
      id
      ... on SearchAnalytics {
        rawData(
          first: $first
          after: $after
          dateRange: $dateRange
          query: $query
          queryFilterType: $queryFilterType
          queryGroupIDs: $queryGroupIds
          url: $url
          urlFilterType: $urlFilterType
          urlGroupIDs: $urlGroupIds
          devices: $devices
          searchTypes: $searchTypes
          countries: $countries
          isAnonymousQuery: $isAnonymousQuery
          orderBy: $orderBy
          groupBy: $groupBy
          clicksRange: $clicksRange
          impressionsRange: $impressionsRange
          ctrRange: $ctrRange
          avgOfRankRange: $avgOfRankRange
          distinctQueriesRange: $distinctQueriesRange
          distinctURLsRange: $distinctURLsRange
        ) @skip(if: $isComparing) {
          ...SearchAnalyticsRawDataConnection
        }
        rawComparingData(
          first: $first
          dateRange: $dateRange
          comparingDateRange: $compareDateRange
          query: $query
          queryFilterType: $queryFilterType
          queryGroupIDs: $queryGroupIds
          url: $url
          urlFilterType: $urlFilterType
          urlGroupIDs: $urlGroupIds
          devices: $devices
          searchTypes: $searchTypes
          countries: $countries
          isAnonymousQuery: $isAnonymousQuery
          orderBy: $compareOrderBy
          groupBy: $groupBy
        ) @include(if: $isComparing) {
          ...SearchAnalyticsRawComparingDataConnection
        }
      }
    }
  }
  ${SearchAnalyticsRawDataConnectionFragmentDoc}
  ${SearchAnalyticsRawComparingDataConnectionFragmentDoc}
`;

/**
 * __useSearchAnalyticsRawDataQuery__
 *
 * To run a query within a React component, call `useSearchAnalyticsRawDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnalyticsRawDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnalyticsRawDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      dateRange: // value for 'dateRange'
 *      compareDateRange: // value for 'compareDateRange'
 *      query: // value for 'query'
 *      queryFilterType: // value for 'queryFilterType'
 *      queryGroupIds: // value for 'queryGroupIds'
 *      url: // value for 'url'
 *      urlFilterType: // value for 'urlFilterType'
 *      urlGroupIds: // value for 'urlGroupIds'
 *      devices: // value for 'devices'
 *      searchTypes: // value for 'searchTypes'
 *      countries: // value for 'countries'
 *      isAnonymousQuery: // value for 'isAnonymousQuery'
 *      orderBy: // value for 'orderBy'
 *      compareOrderBy: // value for 'compareOrderBy'
 *      groupBy: // value for 'groupBy'
 *      isComparing: // value for 'isComparing'
 *      clicksRange: // value for 'clicksRange'
 *      impressionsRange: // value for 'impressionsRange'
 *      ctrRange: // value for 'ctrRange'
 *      avgOfRankRange: // value for 'avgOfRankRange'
 *      distinctQueriesRange: // value for 'distinctQueriesRange'
 *      distinctURLsRange: // value for 'distinctURLsRange'
 *   },
 * });
 */
export function useSearchAnalyticsRawDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAnalyticsRawDataQuery,
    SearchAnalyticsRawDataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    SearchAnalyticsRawDataQuery,
    SearchAnalyticsRawDataQueryVariables
  >(SearchAnalyticsRawDataDocument, options);
}
export function useSearchAnalyticsRawDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAnalyticsRawDataQuery,
    SearchAnalyticsRawDataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    SearchAnalyticsRawDataQuery,
    SearchAnalyticsRawDataQueryVariables
  >(SearchAnalyticsRawDataDocument, options);
}
export type SearchAnalyticsRawDataQueryHookResult = ReturnType<
  typeof useSearchAnalyticsRawDataQuery
>;
export type SearchAnalyticsRawDataLazyQueryHookResult = ReturnType<
  typeof useSearchAnalyticsRawDataLazyQuery
>;
export type SearchAnalyticsRawDataQueryResult = Apollo.QueryResult<
  SearchAnalyticsRawDataQuery,
  SearchAnalyticsRawDataQueryVariables
>;
export const QueryUrlGroupsDocument = gql`
  query QueryUrlGroups($id: ID!) {
    node(id: $id) {
      id
      ... on SearchAnalytics {
        queryGroups {
          ...QueryGroup
        }
        urlGroups {
          ...URLGroup
        }
      }
    }
  }
  ${QueryGroupFragmentDoc}
  ${UrlGroupFragmentDoc}
`;

/**
 * __useQueryUrlGroupsQuery__
 *
 * To run a query within a React component, call `useQueryUrlGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUrlGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUrlGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryUrlGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryUrlGroupsQuery,
    QueryUrlGroupsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<QueryUrlGroupsQuery, QueryUrlGroupsQueryVariables>(
    QueryUrlGroupsDocument,
    options
  );
}
export function useQueryUrlGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryUrlGroupsQuery,
    QueryUrlGroupsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<QueryUrlGroupsQuery, QueryUrlGroupsQueryVariables>(
    QueryUrlGroupsDocument,
    options
  );
}
export type QueryUrlGroupsQueryHookResult = ReturnType<
  typeof useQueryUrlGroupsQuery
>;
export type QueryUrlGroupsLazyQueryHookResult = ReturnType<
  typeof useQueryUrlGroupsLazyQuery
>;
export type QueryUrlGroupsQueryResult = Apollo.QueryResult<
  QueryUrlGroupsQuery,
  QueryUrlGroupsQueryVariables
>;
export const CreateSearchAnalyticsDocument = gql`
  mutation CreateSearchAnalytics($input: CreateSearchAnalyticsInput!) {
    createSearchAnalytics(input: $input) {
      ...SearchAnalyticsBasicInfo
    }
  }
  ${SearchAnalyticsBasicInfoFragmentDoc}
`;
export type CreateSearchAnalyticsMutationFn = Apollo.MutationFunction<
  CreateSearchAnalyticsMutation,
  CreateSearchAnalyticsMutationVariables
>;

/**
 * __useCreateSearchAnalyticsMutation__
 *
 * To run a mutation, you first call `useCreateSearchAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchAnalyticsMutation, { data, loading, error }] = useCreateSearchAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSearchAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSearchAnalyticsMutation,
    CreateSearchAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateSearchAnalyticsMutation,
    CreateSearchAnalyticsMutationVariables
  >(CreateSearchAnalyticsDocument, options);
}
export type CreateSearchAnalyticsMutationHookResult = ReturnType<
  typeof useCreateSearchAnalyticsMutation
>;
export type CreateSearchAnalyticsMutationResult =
  Apollo.MutationResult<CreateSearchAnalyticsMutation>;
export type CreateSearchAnalyticsMutationOptions = Apollo.BaseMutationOptions<
  CreateSearchAnalyticsMutation,
  CreateSearchAnalyticsMutationVariables
>;
export const CreateSearchAnalyticsManagedByJadeDocument = gql`
  mutation CreateSearchAnalyticsManagedByJADE(
    $input: CreateSearchAnalyticsManagedByJADEInput!
  ) {
    createSearchAnalyticsManagedByJADE(input: $input) {
      ...SearchAnalyticsBasicInfo
    }
  }
  ${SearchAnalyticsBasicInfoFragmentDoc}
`;
export type CreateSearchAnalyticsManagedByJadeMutationFn =
  Apollo.MutationFunction<
    CreateSearchAnalyticsManagedByJadeMutation,
    CreateSearchAnalyticsManagedByJadeMutationVariables
  >;

/**
 * __useCreateSearchAnalyticsManagedByJadeMutation__
 *
 * To run a mutation, you first call `useCreateSearchAnalyticsManagedByJadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchAnalyticsManagedByJadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchAnalyticsManagedByJadeMutation, { data, loading, error }] = useCreateSearchAnalyticsManagedByJadeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSearchAnalyticsManagedByJadeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSearchAnalyticsManagedByJadeMutation,
    CreateSearchAnalyticsManagedByJadeMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateSearchAnalyticsManagedByJadeMutation,
    CreateSearchAnalyticsManagedByJadeMutationVariables
  >(CreateSearchAnalyticsManagedByJadeDocument, options);
}
export type CreateSearchAnalyticsManagedByJadeMutationHookResult = ReturnType<
  typeof useCreateSearchAnalyticsManagedByJadeMutation
>;
export type CreateSearchAnalyticsManagedByJadeMutationResult =
  Apollo.MutationResult<CreateSearchAnalyticsManagedByJadeMutation>;
export type CreateSearchAnalyticsManagedByJadeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSearchAnalyticsManagedByJadeMutation,
    CreateSearchAnalyticsManagedByJadeMutationVariables
  >;
export const DeleteSearchAnalyticsDocument = gql`
  mutation DeleteSearchAnalytics($input: DeleteSearchAnalyticsInput!) {
    deleteSearchAnalytics(input: $input) {
      id
    }
  }
`;
export type DeleteSearchAnalyticsMutationFn = Apollo.MutationFunction<
  DeleteSearchAnalyticsMutation,
  DeleteSearchAnalyticsMutationVariables
>;

/**
 * __useDeleteSearchAnalyticsMutation__
 *
 * To run a mutation, you first call `useDeleteSearchAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchAnalyticsMutation, { data, loading, error }] = useDeleteSearchAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSearchAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSearchAnalyticsMutation,
    DeleteSearchAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteSearchAnalyticsMutation,
    DeleteSearchAnalyticsMutationVariables
  >(DeleteSearchAnalyticsDocument, options);
}
export type DeleteSearchAnalyticsMutationHookResult = ReturnType<
  typeof useDeleteSearchAnalyticsMutation
>;
export type DeleteSearchAnalyticsMutationResult =
  Apollo.MutationResult<DeleteSearchAnalyticsMutation>;
export type DeleteSearchAnalyticsMutationOptions = Apollo.BaseMutationOptions<
  DeleteSearchAnalyticsMutation,
  DeleteSearchAnalyticsMutationVariables
>;
export const UpdateSearchAnalyticsDocument = gql`
  mutation UpdateSearchAnalytics($input: UpdateSearchAnalyticsInput!) {
    updateSearchAnalytics(input: $input) {
      ...SearchAnalyticsBasicInfo
    }
  }
  ${SearchAnalyticsBasicInfoFragmentDoc}
`;
export type UpdateSearchAnalyticsMutationFn = Apollo.MutationFunction<
  UpdateSearchAnalyticsMutation,
  UpdateSearchAnalyticsMutationVariables
>;

/**
 * __useUpdateSearchAnalyticsMutation__
 *
 * To run a mutation, you first call `useUpdateSearchAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchAnalyticsMutation, { data, loading, error }] = useUpdateSearchAnalyticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSearchAnalyticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSearchAnalyticsMutation,
    UpdateSearchAnalyticsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateSearchAnalyticsMutation,
    UpdateSearchAnalyticsMutationVariables
  >(UpdateSearchAnalyticsDocument, options);
}
export type UpdateSearchAnalyticsMutationHookResult = ReturnType<
  typeof useUpdateSearchAnalyticsMutation
>;
export type UpdateSearchAnalyticsMutationResult =
  Apollo.MutationResult<UpdateSearchAnalyticsMutation>;
export type UpdateSearchAnalyticsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSearchAnalyticsMutation,
  UpdateSearchAnalyticsMutationVariables
>;
export const CreateSearchAnalyticsViewDocument = gql`
  mutation CreateSearchAnalyticsView($input: CreateSearchAnalyticsViewInput!) {
    createSearchAnalyticsView(input: $input) {
      ...SearchAnalyticsView
    }
  }
  ${SearchAnalyticsViewFragmentDoc}
`;
export type CreateSearchAnalyticsViewMutationFn = Apollo.MutationFunction<
  CreateSearchAnalyticsViewMutation,
  CreateSearchAnalyticsViewMutationVariables
>;

/**
 * __useCreateSearchAnalyticsViewMutation__
 *
 * To run a mutation, you first call `useCreateSearchAnalyticsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchAnalyticsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchAnalyticsViewMutation, { data, loading, error }] = useCreateSearchAnalyticsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSearchAnalyticsViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSearchAnalyticsViewMutation,
    CreateSearchAnalyticsViewMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateSearchAnalyticsViewMutation,
    CreateSearchAnalyticsViewMutationVariables
  >(CreateSearchAnalyticsViewDocument, options);
}
export type CreateSearchAnalyticsViewMutationHookResult = ReturnType<
  typeof useCreateSearchAnalyticsViewMutation
>;
export type CreateSearchAnalyticsViewMutationResult =
  Apollo.MutationResult<CreateSearchAnalyticsViewMutation>;
export type CreateSearchAnalyticsViewMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSearchAnalyticsViewMutation,
    CreateSearchAnalyticsViewMutationVariables
  >;
export const UpdateSearchAnalyticsViewDocument = gql`
  mutation UpdateSearchAnalyticsView($input: UpdateSearchAnalyticsViewInput!) {
    updateSearchAnalyticsView(input: $input) {
      ...SearchAnalyticsView
    }
  }
  ${SearchAnalyticsViewFragmentDoc}
`;
export type UpdateSearchAnalyticsViewMutationFn = Apollo.MutationFunction<
  UpdateSearchAnalyticsViewMutation,
  UpdateSearchAnalyticsViewMutationVariables
>;

/**
 * __useUpdateSearchAnalyticsViewMutation__
 *
 * To run a mutation, you first call `useUpdateSearchAnalyticsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchAnalyticsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchAnalyticsViewMutation, { data, loading, error }] = useUpdateSearchAnalyticsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSearchAnalyticsViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSearchAnalyticsViewMutation,
    UpdateSearchAnalyticsViewMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateSearchAnalyticsViewMutation,
    UpdateSearchAnalyticsViewMutationVariables
  >(UpdateSearchAnalyticsViewDocument, options);
}
export type UpdateSearchAnalyticsViewMutationHookResult = ReturnType<
  typeof useUpdateSearchAnalyticsViewMutation
>;
export type UpdateSearchAnalyticsViewMutationResult =
  Apollo.MutationResult<UpdateSearchAnalyticsViewMutation>;
export type UpdateSearchAnalyticsViewMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSearchAnalyticsViewMutation,
    UpdateSearchAnalyticsViewMutationVariables
  >;
export const UpdateSearchAnalyticsViewOrderDocument = gql`
  mutation UpdateSearchAnalyticsViewOrder(
    $input: UpdateSearchAnalyticsViewOrderInput!
  ) {
    updateSearchAnalyticsViewOrder(input: $input) {
      ...SearchAnalyticsView
    }
  }
  ${SearchAnalyticsViewFragmentDoc}
`;
export type UpdateSearchAnalyticsViewOrderMutationFn = Apollo.MutationFunction<
  UpdateSearchAnalyticsViewOrderMutation,
  UpdateSearchAnalyticsViewOrderMutationVariables
>;

/**
 * __useUpdateSearchAnalyticsViewOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSearchAnalyticsViewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchAnalyticsViewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchAnalyticsViewOrderMutation, { data, loading, error }] = useUpdateSearchAnalyticsViewOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSearchAnalyticsViewOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSearchAnalyticsViewOrderMutation,
    UpdateSearchAnalyticsViewOrderMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateSearchAnalyticsViewOrderMutation,
    UpdateSearchAnalyticsViewOrderMutationVariables
  >(UpdateSearchAnalyticsViewOrderDocument, options);
}
export type UpdateSearchAnalyticsViewOrderMutationHookResult = ReturnType<
  typeof useUpdateSearchAnalyticsViewOrderMutation
>;
export type UpdateSearchAnalyticsViewOrderMutationResult =
  Apollo.MutationResult<UpdateSearchAnalyticsViewOrderMutation>;
export type UpdateSearchAnalyticsViewOrderMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSearchAnalyticsViewOrderMutation,
    UpdateSearchAnalyticsViewOrderMutationVariables
  >;
export const DeleteSearchAnalyticsViewDocument = gql`
  mutation DeleteSearchAnalyticsView($input: DeleteSearchAnalyticsViewInput!) {
    deleteSearchAnalyticsView(input: $input) {
      id
    }
  }
`;
export type DeleteSearchAnalyticsViewMutationFn = Apollo.MutationFunction<
  DeleteSearchAnalyticsViewMutation,
  DeleteSearchAnalyticsViewMutationVariables
>;

/**
 * __useDeleteSearchAnalyticsViewMutation__
 *
 * To run a mutation, you first call `useDeleteSearchAnalyticsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchAnalyticsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchAnalyticsViewMutation, { data, loading, error }] = useDeleteSearchAnalyticsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSearchAnalyticsViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSearchAnalyticsViewMutation,
    DeleteSearchAnalyticsViewMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteSearchAnalyticsViewMutation,
    DeleteSearchAnalyticsViewMutationVariables
  >(DeleteSearchAnalyticsViewDocument, options);
}
export type DeleteSearchAnalyticsViewMutationHookResult = ReturnType<
  typeof useDeleteSearchAnalyticsViewMutation
>;
export type DeleteSearchAnalyticsViewMutationResult =
  Apollo.MutationResult<DeleteSearchAnalyticsViewMutation>;
export type DeleteSearchAnalyticsViewMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSearchAnalyticsViewMutation,
    DeleteSearchAnalyticsViewMutationVariables
  >;
export const CreateQueryGroupDocument = gql`
  mutation CreateQueryGroup($input: CreateQueryGroupInput!) {
    createQueryGroup(input: $input) {
      ...QueryGroup
    }
  }
  ${QueryGroupFragmentDoc}
`;
export type CreateQueryGroupMutationFn = Apollo.MutationFunction<
  CreateQueryGroupMutation,
  CreateQueryGroupMutationVariables
>;

/**
 * __useCreateQueryGroupMutation__
 *
 * To run a mutation, you first call `useCreateQueryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQueryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQueryGroupMutation, { data, loading, error }] = useCreateQueryGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQueryGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQueryGroupMutation,
    CreateQueryGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateQueryGroupMutation,
    CreateQueryGroupMutationVariables
  >(CreateQueryGroupDocument, options);
}
export type CreateQueryGroupMutationHookResult = ReturnType<
  typeof useCreateQueryGroupMutation
>;
export type CreateQueryGroupMutationResult =
  Apollo.MutationResult<CreateQueryGroupMutation>;
export type CreateQueryGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateQueryGroupMutation,
  CreateQueryGroupMutationVariables
>;
export const UpdateQueryGroupDocument = gql`
  mutation UpdateQueryGroup($input: UpdateQueryGroupInput!) {
    updateQueryGroup(input: $input) {
      ...QueryGroup
    }
  }
  ${QueryGroupFragmentDoc}
`;
export type UpdateQueryGroupMutationFn = Apollo.MutationFunction<
  UpdateQueryGroupMutation,
  UpdateQueryGroupMutationVariables
>;

/**
 * __useUpdateQueryGroupMutation__
 *
 * To run a mutation, you first call `useUpdateQueryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQueryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQueryGroupMutation, { data, loading, error }] = useUpdateQueryGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQueryGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQueryGroupMutation,
    UpdateQueryGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateQueryGroupMutation,
    UpdateQueryGroupMutationVariables
  >(UpdateQueryGroupDocument, options);
}
export type UpdateQueryGroupMutationHookResult = ReturnType<
  typeof useUpdateQueryGroupMutation
>;
export type UpdateQueryGroupMutationResult =
  Apollo.MutationResult<UpdateQueryGroupMutation>;
export type UpdateQueryGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateQueryGroupMutation,
  UpdateQueryGroupMutationVariables
>;
export const UpdateQueryGroupOrderDocument = gql`
  mutation UpdateQueryGroupOrder($input: UpdateQueryGroupOrderInput!) {
    updateQueryGroupOrder(input: $input) {
      ...QueryGroup
    }
  }
  ${QueryGroupFragmentDoc}
`;
export type UpdateQueryGroupOrderMutationFn = Apollo.MutationFunction<
  UpdateQueryGroupOrderMutation,
  UpdateQueryGroupOrderMutationVariables
>;

/**
 * __useUpdateQueryGroupOrderMutation__
 *
 * To run a mutation, you first call `useUpdateQueryGroupOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQueryGroupOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQueryGroupOrderMutation, { data, loading, error }] = useUpdateQueryGroupOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQueryGroupOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQueryGroupOrderMutation,
    UpdateQueryGroupOrderMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateQueryGroupOrderMutation,
    UpdateQueryGroupOrderMutationVariables
  >(UpdateQueryGroupOrderDocument, options);
}
export type UpdateQueryGroupOrderMutationHookResult = ReturnType<
  typeof useUpdateQueryGroupOrderMutation
>;
export type UpdateQueryGroupOrderMutationResult =
  Apollo.MutationResult<UpdateQueryGroupOrderMutation>;
export type UpdateQueryGroupOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateQueryGroupOrderMutation,
  UpdateQueryGroupOrderMutationVariables
>;
export const DeleteQueryGroupDocument = gql`
  mutation DeleteQueryGroup($input: DeleteQueryGroupInput!) {
    deleteQueryGroup(input: $input) {
      id
    }
  }
`;
export type DeleteQueryGroupMutationFn = Apollo.MutationFunction<
  DeleteQueryGroupMutation,
  DeleteQueryGroupMutationVariables
>;

/**
 * __useDeleteQueryGroupMutation__
 *
 * To run a mutation, you first call `useDeleteQueryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQueryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQueryGroupMutation, { data, loading, error }] = useDeleteQueryGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQueryGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteQueryGroupMutation,
    DeleteQueryGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteQueryGroupMutation,
    DeleteQueryGroupMutationVariables
  >(DeleteQueryGroupDocument, options);
}
export type DeleteQueryGroupMutationHookResult = ReturnType<
  typeof useDeleteQueryGroupMutation
>;
export type DeleteQueryGroupMutationResult =
  Apollo.MutationResult<DeleteQueryGroupMutation>;
export type DeleteQueryGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteQueryGroupMutation,
  DeleteQueryGroupMutationVariables
>;
export const CreateUrlGroupDocument = gql`
  mutation CreateUrlGroup($input: CreateURLGroupInput!) {
    createURLGroup(input: $input) {
      ...URLGroup
    }
  }
  ${UrlGroupFragmentDoc}
`;
export type CreateUrlGroupMutationFn = Apollo.MutationFunction<
  CreateUrlGroupMutation,
  CreateUrlGroupMutationVariables
>;

/**
 * __useCreateUrlGroupMutation__
 *
 * To run a mutation, you first call `useCreateUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUrlGroupMutation, { data, loading, error }] = useCreateUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUrlGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUrlGroupMutation,
    CreateUrlGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateUrlGroupMutation,
    CreateUrlGroupMutationVariables
  >(CreateUrlGroupDocument, options);
}
export type CreateUrlGroupMutationHookResult = ReturnType<
  typeof useCreateUrlGroupMutation
>;
export type CreateUrlGroupMutationResult =
  Apollo.MutationResult<CreateUrlGroupMutation>;
export type CreateUrlGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateUrlGroupMutation,
  CreateUrlGroupMutationVariables
>;
export const UpdateUrlGroupDocument = gql`
  mutation UpdateUrlGroup($input: UpdateURLGroupInput!) {
    updateURLGroup(input: $input) {
      ...URLGroup
    }
  }
  ${UrlGroupFragmentDoc}
`;
export type UpdateUrlGroupMutationFn = Apollo.MutationFunction<
  UpdateUrlGroupMutation,
  UpdateUrlGroupMutationVariables
>;

/**
 * __useUpdateUrlGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUrlGroupMutation, { data, loading, error }] = useUpdateUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUrlGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUrlGroupMutation,
    UpdateUrlGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUrlGroupMutation,
    UpdateUrlGroupMutationVariables
  >(UpdateUrlGroupDocument, options);
}
export type UpdateUrlGroupMutationHookResult = ReturnType<
  typeof useUpdateUrlGroupMutation
>;
export type UpdateUrlGroupMutationResult =
  Apollo.MutationResult<UpdateUrlGroupMutation>;
export type UpdateUrlGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUrlGroupMutation,
  UpdateUrlGroupMutationVariables
>;
export const UpdateUrlGroupOrderDocument = gql`
  mutation UpdateUrlGroupOrder($input: UpdateURLGroupOrderInput!) {
    updateURLGroupOrder(input: $input) {
      ...URLGroup
    }
  }
  ${UrlGroupFragmentDoc}
`;
export type UpdateUrlGroupOrderMutationFn = Apollo.MutationFunction<
  UpdateUrlGroupOrderMutation,
  UpdateUrlGroupOrderMutationVariables
>;

/**
 * __useUpdateUrlGroupOrderMutation__
 *
 * To run a mutation, you first call `useUpdateUrlGroupOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUrlGroupOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUrlGroupOrderMutation, { data, loading, error }] = useUpdateUrlGroupOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUrlGroupOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUrlGroupOrderMutation,
    UpdateUrlGroupOrderMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUrlGroupOrderMutation,
    UpdateUrlGroupOrderMutationVariables
  >(UpdateUrlGroupOrderDocument, options);
}
export type UpdateUrlGroupOrderMutationHookResult = ReturnType<
  typeof useUpdateUrlGroupOrderMutation
>;
export type UpdateUrlGroupOrderMutationResult =
  Apollo.MutationResult<UpdateUrlGroupOrderMutation>;
export type UpdateUrlGroupOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateUrlGroupOrderMutation,
  UpdateUrlGroupOrderMutationVariables
>;
export const DeleteUrlGroupDocument = gql`
  mutation DeleteUrlGroup($input: DeleteURLGroupInput!) {
    deleteURLGroup(input: $input) {
      id
    }
  }
`;
export type DeleteUrlGroupMutationFn = Apollo.MutationFunction<
  DeleteUrlGroupMutation,
  DeleteUrlGroupMutationVariables
>;

/**
 * __useDeleteUrlGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUrlGroupMutation, { data, loading, error }] = useDeleteUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUrlGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUrlGroupMutation,
    DeleteUrlGroupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteUrlGroupMutation,
    DeleteUrlGroupMutationVariables
  >(DeleteUrlGroupDocument, options);
}
export type DeleteUrlGroupMutationHookResult = ReturnType<
  typeof useDeleteUrlGroupMutation
>;
export type DeleteUrlGroupMutationResult =
  Apollo.MutationResult<DeleteUrlGroupMutation>;
export type DeleteUrlGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteUrlGroupMutation,
  DeleteUrlGroupMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      preferredLanguage
      isTutorialMovieViewed
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const RegisterServiceAccountDocument = gql`
  mutation RegisterServiceAccount($input: RegisterServiceAccountInput!) {
    registerServiceAccount(input: $input) {
      email
    }
  }
`;
export type RegisterServiceAccountMutationFn = Apollo.MutationFunction<
  RegisterServiceAccountMutation,
  RegisterServiceAccountMutationVariables
>;

/**
 * __useRegisterServiceAccountMutation__
 *
 * To run a mutation, you first call `useRegisterServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerServiceAccountMutation, { data, loading, error }] = useRegisterServiceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterServiceAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterServiceAccountMutation,
    RegisterServiceAccountMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RegisterServiceAccountMutation,
    RegisterServiceAccountMutationVariables
  >(RegisterServiceAccountDocument, options);
}
export type RegisterServiceAccountMutationHookResult = ReturnType<
  typeof useRegisterServiceAccountMutation
>;
export type RegisterServiceAccountMutationResult =
  Apollo.MutationResult<RegisterServiceAccountMutation>;
export type RegisterServiceAccountMutationOptions = Apollo.BaseMutationOptions<
  RegisterServiceAccountMutation,
  RegisterServiceAccountMutationVariables
>;
export const UnregisterServiceAccountDocument = gql`
  mutation UnregisterServiceAccount($input: UnregisterServiceAccountInput!) {
    unregisterServiceAccount(input: $input) {
      id
    }
  }
`;
export type UnregisterServiceAccountMutationFn = Apollo.MutationFunction<
  UnregisterServiceAccountMutation,
  UnregisterServiceAccountMutationVariables
>;

/**
 * __useUnregisterServiceAccountMutation__
 *
 * To run a mutation, you first call `useUnregisterServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterServiceAccountMutation, { data, loading, error }] = useUnregisterServiceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnregisterServiceAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnregisterServiceAccountMutation,
    UnregisterServiceAccountMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UnregisterServiceAccountMutation,
    UnregisterServiceAccountMutationVariables
  >(UnregisterServiceAccountDocument, options);
}
export type UnregisterServiceAccountMutationHookResult = ReturnType<
  typeof useUnregisterServiceAccountMutation
>;
export type UnregisterServiceAccountMutationResult =
  Apollo.MutationResult<UnregisterServiceAccountMutation>;
export type UnregisterServiceAccountMutationOptions =
  Apollo.BaseMutationOptions<
    UnregisterServiceAccountMutation,
    UnregisterServiceAccountMutationVariables
  >;
export const ChangeOrganizationRoleDocument = gql`
  mutation ChangeOrganizationRole($input: ChangeOrganizationRoleInput) {
    changeOrganizationRole(input: $input) {
      user {
        id
        email
      }
      organization {
        id
        users {
          role
        }
      }
    }
  }
`;
export type ChangeOrganizationRoleMutationFn = Apollo.MutationFunction<
  ChangeOrganizationRoleMutation,
  ChangeOrganizationRoleMutationVariables
>;

/**
 * __useChangeOrganizationRoleMutation__
 *
 * To run a mutation, you first call `useChangeOrganizationRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrganizationRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrganizationRoleMutation, { data, loading, error }] = useChangeOrganizationRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOrganizationRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeOrganizationRoleMutation,
    ChangeOrganizationRoleMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ChangeOrganizationRoleMutation,
    ChangeOrganizationRoleMutationVariables
  >(ChangeOrganizationRoleDocument, options);
}
export type ChangeOrganizationRoleMutationHookResult = ReturnType<
  typeof useChangeOrganizationRoleMutation
>;
export type ChangeOrganizationRoleMutationResult =
  Apollo.MutationResult<ChangeOrganizationRoleMutation>;
export type ChangeOrganizationRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeOrganizationRoleMutation,
  ChangeOrganizationRoleMutationVariables
>;
export const RemoveUserFromOrganizationDocument = gql`
  mutation RemoveUserFromOrganization(
    $input: RemoveUserFromOrganizationInput!
  ) {
    removeUserFromOrganization(input: $input)
  }
`;
export type RemoveUserFromOrganizationMutationFn = Apollo.MutationFunction<
  RemoveUserFromOrganizationMutation,
  RemoveUserFromOrganizationMutationVariables
>;

/**
 * __useRemoveUserFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromOrganizationMutation, { data, loading, error }] = useRemoveUserFromOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserFromOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserFromOrganizationMutation,
    RemoveUserFromOrganizationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RemoveUserFromOrganizationMutation,
    RemoveUserFromOrganizationMutationVariables
  >(RemoveUserFromOrganizationDocument, options);
}
export type RemoveUserFromOrganizationMutationHookResult = ReturnType<
  typeof useRemoveUserFromOrganizationMutation
>;
export type RemoveUserFromOrganizationMutationResult =
  Apollo.MutationResult<RemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveUserFromOrganizationMutation,
    RemoveUserFromOrganizationMutationVariables
  >;
