import {getRandomColor} from 'common/color/color';
import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai';
import {CreateProjectMutationVariables, ProjectKind} from 'lib/gql/generated';
import {z} from 'zod';

// Should validate
const shouldValidateAtom = atom(false);
export const useCreateProjectShouldValidate = () => useAtom(shouldValidateAtom);

// OrgId
const createProjectOrgIdAtom = atom('');

// ProjectKind
const createProjectKindAtom = atom<ProjectKind | ''>('');
export const useCreateProjectKind = () => useAtom(createProjectKindAtom);
const kindSchema = z.string().min(1);
const createProjectKindErrorAtom = atom(get => {
  const result = kindSchema.safeParse(get(createProjectKindAtom));
  if (result.success) return null;
  return result.error.errors[0]?.message;
});
const createProjectKindDisplayErrorAtom = atom(get => {
  if (!get(shouldValidateAtom)) return null;
  return get(createProjectKindErrorAtom);
});
export const useCreateProjectKindError = () => {
  return useAtomValue(createProjectKindDisplayErrorAtom);
};

// Name
const createProjectNameAtom = atom('');
export const useCreateProejctName = () => useAtom(createProjectNameAtom);
const nameSchema = z.string().min(1).max(100);
const createProjectNameErrorAtom = atom(get => {
  const result = nameSchema.safeParse(get(createProjectNameAtom));
  if (result.success) return null;
  return result.error.errors[0]?.message;
});
const createProjectNameDisplayErrorAtom = atom(get => {
  if (!get(shouldValidateAtom)) return null;
  return get(createProjectNameErrorAtom);
});
export const useCreateProjectNameError = () =>
  useAtomValue(createProjectNameDisplayErrorAtom);

// Color
const createProjectColorAtom = atom(getRandomColor());
export const useCreateProjectColor = () => useAtom(createProjectColorAtom);
const colorSchema = z
  .string()
  .min(1)
  .regex(/^#[0-9a-fA-F]{6}$/);
const createProjectColorErrorAtom = atom(get => {
  const result = colorSchema.safeParse(get(createProjectColorAtom));
  if (result.success) return null;
  return result.error.errors[0]?.message;
});
const createProjectColorDisplayErrorAtom = atom(get => {
  if (!get(shouldValidateAtom)) return null;
  return get(createProjectColorErrorAtom);
});
export const useCreateProjectColorError = () =>
  useAtomValue(createProjectColorDisplayErrorAtom);

// Description
const createProjectDescriptionAtom = atom('');
export const useCreateProjectDescription = () =>
  useAtom(createProjectDescriptionAtom);
const descriptionSchema = z.string().max(500);
const createProjectDescriptionErrorAtom = atom(get => {
  const result = descriptionSchema.safeParse(get(createProjectDescriptionAtom));
  if (result.success) return null;
  return result.error.errors[0]?.message;
});
const createProjectDescriptionDisplayErrorAtom = atom(get => {
  if (!get(shouldValidateAtom)) return null;
  return get(createProjectDescriptionErrorAtom);
});
export const useCreateProjectDescriptionError = () =>
  useAtomValue(createProjectDescriptionDisplayErrorAtom);

// hasError
const createProjectHasErrorAtom = atom(get => {
  return (
    get(createProjectKindErrorAtom) !== null ||
    get(createProjectNameErrorAtom) !== null ||
    get(createProjectColorErrorAtom) !== null ||
    get(createProjectDescriptionErrorAtom) !== null
  );
});
export const useCreateProjectHasError = () =>
  useAtomValue(createProjectHasErrorAtom);

// Variables
// We extend the CreateProjectMutationVariables type to include the description field
// This allows us to access the description from the variables object in the UI
// even though it's not yet included in the actual API call
const createProejctMutationVariablesAtom = atom<
  CreateProjectMutationVariables & {description?: string}
>(get => {
  const variables: CreateProjectMutationVariables & {description?: string} = {
    input: {
      organizationID: get(createProjectOrgIdAtom),
      name: get(createProjectNameAtom),
      color: get(createProjectColorAtom),
      kind: get(createProjectKindAtom) || ProjectKind.IndexWorker,
      description: get(createProjectDescriptionAtom),
    },
  };
  return variables;
});

export const useCreateProjectMutationVariables = () =>
  useAtomValue(createProejctMutationVariablesAtom);

// Reset
const cleanUpCreateProjectAtom = atom(null, (_, set) => {
  set(shouldValidateAtom, false);
  set(createProjectOrgIdAtom, '');
  set(createProjectKindAtom, '');
  set(createProjectNameAtom, '');
  set(createProjectColorAtom, getRandomColor());
  set(createProjectDescriptionAtom, '');
});
export const useCleanUpCreateProject = () =>
  useSetAtom(cleanUpCreateProjectAtom);

// init
const initCreateProjectAtom = atom<null, [string], void>(
  null,
  (_, set, orgId) => {
    set(shouldValidateAtom, false);
    set(createProjectKindAtom, '');
    set(createProjectOrgIdAtom, orgId);
    set(createProjectNameAtom, '');
    set(createProjectColorAtom, getRandomColor());
    set(createProjectDescriptionAtom, '');
  }
);
export const useInitCreateProject = () => useSetAtom(initCreateProjectAtom);
