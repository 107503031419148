import type { Query as Query_17zy6ty } from '../pages/onboarding';
import type { Query as Query_134d2wm } from '../pages/orgs/[orgId]/notification';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "login": {
    "callback": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/login/callback' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  "onboarding": {
    $url: (url: { query: Query_17zy6ty, hash?: string | undefined }) => ({ pathname: '/onboarding' as const, query: url.query, hash: url.hash })
  },
  "orgs": {
    _orgId: (orgId: string | number) => ({
      "getting_started": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/getting_started' as const, query: { orgId }, hash: url?.hash })
      },
      "notification": {
        "settings": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/notification/settings' as const, query: { orgId }, hash: url?.hash })
        },
        $url: (url: { query: Query_134d2wm, hash?: string | undefined }) => ({ pathname: '/orgs/[orgId]/notification' as const, query: { orgId, ...url.query }, hash: url.hash })
      },
      "payment": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/payment' as const, query: { orgId }, hash: url?.hash })
      },
      "permission": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/permission' as const, query: { orgId }, hash: url?.hash })
      },
      "projects": {
        _projectId: (projectId: string | number) => ({
          "index_workers": {
            _indexWorkerId: (indexWorkerId: string | number) => ({
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/index_workers/[indexWorkerId]' as const, query: { orgId, projectId, indexWorkerId }, hash: url?.hash })
            }),
            $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/index_workers' as const, query: { orgId, projectId }, hash: url?.hash })
          },
          "search_analytics": {
            _viewId: (viewId: string | number) => ({
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/search_analytics/[viewId]' as const, query: { orgId, projectId, viewId }, hash: url?.hash })
            }),
            "groups": {
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/search_analytics/groups' as const, query: { orgId, projectId }, hash: url?.hash })
            },
            $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/search_analytics' as const, query: { orgId, projectId }, hash: url?.hash })
          },
          "user_analytics": {
            _userAnalyticsId: (userAnalyticsId: string | number) => ({
              "views": {
                _viewId: (viewId: string | number) => ({
                  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/user_analytics/[userAnalyticsId]/views/[viewId]' as const, query: { orgId, projectId, userAnalyticsId, viewId }, hash: url?.hash })
                })
              }
            }),
            "session_explorer": {
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/user_analytics/session_explorer' as const, query: { orgId, projectId }, hash: url?.hash })
            },
            "url_group": {
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/user_analytics/url_group' as const, query: { orgId, projectId }, hash: url?.hash })
            },
            $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/projects/[projectId]/user_analytics' as const, query: { orgId, projectId }, hash: url?.hash })
          }
        })
      },
      "setting": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]/setting' as const, query: { orgId }, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orgs/[orgId]' as const, query: { orgId }, hash: url?.hash })
    })
  },
  "reset_password": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/reset_password' as const, hash: url?.hash })
  },
  "signup": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  drags: {
    drag___dots_svg: '/drags/drag - dots.svg'
  },
  icons: {
    icon___Index_Worker_svg: '/icons/icon - Index Worker.svg',
    icon___Search_Analytics_svg: '/icons/icon - Search Analytics.svg',
    icon___SessionExplorer_svg: '/icons/icon - SessionExplorer.svg',
    icon___User_Analytics_svg: '/icons/icon - User Analytics.svg',
    icon___add_svg: '/icons/icon - add.svg',
    icon___archive_svg: '/icons/icon - archive.svg',
    icon___checked___color_regular_svg: '/icons/icon - checked - color-regular.svg',
    icon___checked___em_svg: '/icons/icon - checked - em.svg',
    icon___checked___white_svg: '/icons/icon - checked - white.svg',
    icon___checked_with_i_svg: '/icons/icon - checked with i.svg',
    icon___checked_svg: '/icons/icon - checked.svg',
    icon___content_copy_thin_svg: '/icons/icon - content copy thin.svg',
    icon___dash_svg: '/icons/icon - dash.svg',
    icon___delete_member_svg: '/icons/icon - delete member.svg',
    icon___desktop_svg: '/icons/icon - desktop.svg',
    icon___dots_svg: '/icons/icon - dots.svg',
    icon___edit_block_svg: '/icons/icon - edit block.svg',
    icon___error___em_svg: '/icons/icon - error - em.svg',
    icon___error___past___em_svg: '/icons/icon - error - past - em.svg',
    icon___error_svg: '/icons/icon - error.svg',
    icon___expand_svg: '/icons/icon - expand.svg',
    icon___i_svg: '/icons/icon - i.svg',
    icon___job_error_occured_svg: '/icons/icon - job error occured.svg',
    icon___job_no_problem_svg: '/icons/icon - job no problem.svg',
    icon___job_preparing_svg: '/icons/icon - job preparing.svg',
    icon___language_svg: '/icons/icon - language.svg',
    icon___mail_svg: '/icons/icon - mail.svg',
    icon___notification_with_i_svg: '/icons/icon - notification with i.svg',
    icon___notification_svg: '/icons/icon - notification.svg',
    icon___phone_svg: '/icons/icon - phone.svg',
    icon___recyclerbin_svg: '/icons/icon - recyclerbin.svg',
    icon___todo_done_svg: '/icons/icon - todo done.svg',
    icon___todo_svg: '/icons/icon - todo.svg',
    icon___trash_svg: '/icons/icon - trash.svg',
    icon___unarchive_svg: '/icons/icon - unarchive.svg',
    icon___warning___positive_svg: '/icons/icon - warning - positive.svg',
    skip_next_FILL0_wght300_GRAD0_opsz24_1_svg: '/icons/skip_next_FILL0_wght300_GRAD0_opsz24 1.svg'
  },
  images: {
    bigquery_connection: {
      en: {
        managed_by_amethyst: {
          input_project_id_jpg: '/images/bigquery_connection/en/managed_by_amethyst/input_project_id.jpg',
          search_console_export_jpg: '/images/bigquery_connection/en/managed_by_amethyst/search_console_export.jpg'
        },
        self_managed: {
          add_amethyst_service_account: {
            add_amethyst_service_account_jpg: '/images/bigquery_connection/en/self_managed/add_amethyst_service_account/add_amethyst_service_account.jpg',
            add_principal_jpg: '/images/bigquery_connection/en/self_managed/add_amethyst_service_account/add_principal.jpg',
            select_searchconsole_dataset_jpg: '/images/bigquery_connection/en/self_managed/add_amethyst_service_account/select_searchconsole_dataset.jpg',
            share_jpg: '/images/bigquery_connection/en/self_managed/add_amethyst_service_account/share.jpg'
          },
          add_search_console_service_account: {
            apply_permission_jpg: '/images/bigquery_connection/en/self_managed/add_search_console_service_account/apply_permission.jpg',
            apply_roles_jpg: '/images/bigquery_connection/en/self_managed/add_search_console_service_account/apply_roles.jpg',
            finish_jpg: '/images/bigquery_connection/en/self_managed/add_search_console_service_account/finish.jpg',
            input_service_account_jpg: '/images/bigquery_connection/en/self_managed/add_search_console_service_account/input_service_account.jpg',
            select_iam_jpg: '/images/bigquery_connection/en/self_managed/add_search_console_service_account/select_iam.jpg'
          },
          bigquery_api: {
            api_enabled_jpg: '/images/bigquery_connection/en/self_managed/bigquery_api/api_enabled.jpg',
            enable_api_jpg: '/images/bigquery_connection/en/self_managed/bigquery_api/enable_api.jpg',
            enable_bq_api_jpg: '/images/bigquery_connection/en/self_managed/bigquery_api/enable_bq_api.jpg',
            gcloud_top_jpg: '/images/bigquery_connection/en/self_managed/bigquery_api/gcloud_top.jpg',
            search_bigquery_jpg: '/images/bigquery_connection/en/self_managed/bigquery_api/search_bigquery.jpg',
            select_api_jpg: '/images/bigquery_connection/en/self_managed/bigquery_api/select_api.jpg'
          },
          input_project_id_and_dataset_name: {
            check_jpg: '/images/bigquery_connection/en/self_managed/input_project_id_and_dataset_name/check.jpg'
          },
          search_console_export_setting: {
            check_project_id_jpg: '/images/bigquery_connection/en/self_managed/search_console_export_setting/check_project_id.jpg',
            input_project_id_jpg: '/images/bigquery_connection/en/self_managed/search_console_export_setting/input_project_id.jpg',
            search_console_export_jpg: '/images/bigquery_connection/en/self_managed/search_console_export_setting/search_console_export.jpg',
            select_dashboard_jpg: '/images/bigquery_connection/en/self_managed/search_console_export_setting/select_dashboard.jpg'
          }
        }
      },
      ja: {
        managed_by_amethyst: {
          input_project_id_jpg: '/images/bigquery_connection/ja/managed_by_amethyst/input_project_id.jpg',
          search_console_export_jpg: '/images/bigquery_connection/ja/managed_by_amethyst/search_console_export.jpg'
        },
        self_managed: {
          add_amethyst_service_account: {
            add_amethyst_service_account_jpg: '/images/bigquery_connection/ja/self_managed/add_amethyst_service_account/add_amethyst_service_account.jpg',
            add_principal_jpg: '/images/bigquery_connection/ja/self_managed/add_amethyst_service_account/add_principal.jpg',
            select_searchconsole_dataset_jpg: '/images/bigquery_connection/ja/self_managed/add_amethyst_service_account/select_searchconsole_dataset.jpg',
            share_jpg: '/images/bigquery_connection/ja/self_managed/add_amethyst_service_account/share.jpg'
          },
          add_search_console_service_account: {
            apply_permission_jpg: '/images/bigquery_connection/ja/self_managed/add_search_console_service_account/apply_permission.jpg',
            apply_roles_jpg: '/images/bigquery_connection/ja/self_managed/add_search_console_service_account/apply_roles.jpg',
            finish_jpg: '/images/bigquery_connection/ja/self_managed/add_search_console_service_account/finish.jpg',
            input_service_account_jpg: '/images/bigquery_connection/ja/self_managed/add_search_console_service_account/input_service_account.jpg',
            select_iam_jpg: '/images/bigquery_connection/ja/self_managed/add_search_console_service_account/select_iam.jpg'
          },
          bigquery_api: {
            api_enabled_jpg: '/images/bigquery_connection/ja/self_managed/bigquery_api/api_enabled.jpg',
            enable_api_jpg: '/images/bigquery_connection/ja/self_managed/bigquery_api/enable_api.jpg',
            enable_bq_api_jpg: '/images/bigquery_connection/ja/self_managed/bigquery_api/enable_bq_api.jpg',
            gcloud_top_jpg: '/images/bigquery_connection/ja/self_managed/bigquery_api/gcloud_top.jpg',
            search_bigquery_jpg: '/images/bigquery_connection/ja/self_managed/bigquery_api/search_bigquery.jpg',
            select_api_jpg: '/images/bigquery_connection/ja/self_managed/bigquery_api/select_api.jpg'
          },
          input_project_id_and_dataset_name: {
            check_jpg: '/images/bigquery_connection/ja/self_managed/input_project_id_and_dataset_name/check.jpg'
          },
          search_console_export_setting: {
            check_project_id_jpg: '/images/bigquery_connection/ja/self_managed/search_console_export_setting/check_project_id.jpg',
            input_project_id_jpg: '/images/bigquery_connection/ja/self_managed/search_console_export_setting/input_project_id.jpg',
            search_console_export_jpg: '/images/bigquery_connection/ja/self_managed/search_console_export_setting/search_console_export.jpg',
            select_dashboard_jpg: '/images/bigquery_connection/ja/self_managed/search_console_export_setting/select_dashboard.jpg'
          }
        }
      }
    },
    g_logo_png: '/images/g_logo.png',
    google_login: {
      en: {
        sc_permission_png: '/images/google_login/en/sc_permission.png'
      },
      ja: {
        sc_permission_png: '/images/google_login/ja/sc_permission.png'
      }
    },
    information_dummy_png: '/images/information_dummy.png',
    jade_logo_png: '/images/jade_logo.png',
    logo___amethyst___demo_svg: '/images/logo - amethyst - demo.svg',
    logo___amethyst___dev_svg: '/images/logo - amethyst - dev.svg',
    logo___amethyst___local_svg: '/images/logo - amethyst - local.svg',
    logo___amethyst___stg_svg: '/images/logo - amethyst - stg.svg',
    logo___amethyst_svg: '/images/logo - amethyst.svg',
    logo_with_text_on_right_svg: '/images/logo_with-text-on-right.svg',
    plans_jpg: '/images/plans.jpg',
    plans_en_png: '/images/plans_en.png',
    plans_ja_png: '/images/plans_ja.png'
  },
  locales: {
    en: {
      auth_json: '/locales/en/auth.json',
      common_json: '/locales/en/common.json',
      country_json: '/locales/en/country.json',
      coverage_state_json: '/locales/en/coverage_state.json',
      dashboard_json: '/locales/en/dashboard.json',
      getting_started_json: '/locales/en/getting_started.json',
      index_worker_detail_json: '/locales/en/index_worker_detail.json',
      index_worker_list_json: '/locales/en/index_worker_list.json',
      index_worker_shared_json: '/locales/en/index_worker_shared.json',
      layout_json: '/locales/en/layout.json',
      legacy_common_json: '/locales/en/legacy_common.json',
      notification_settings_json: '/locales/en/notification_settings.json',
      onboarding_json: '/locales/en/onboarding.json',
      payment_json: '/locales/en/payment.json',
      permission_json: '/locales/en/permission.json',
      search_analytics_detail_json: '/locales/en/search_analytics_detail.json',
      search_analytics_groups_json: '/locales/en/search_analytics_groups.json',
      search_analytics_list_json: '/locales/en/search_analytics_list.json',
      search_analytics_shared_json: '/locales/en/search_analytics_shared.json',
      session_explorer_json: '/locales/en/session_explorer.json',
      settings_json: '/locales/en/settings.json',
      toast_json: '/locales/en/toast.json',
      tour_json: '/locales/en/tour.json',
      user_analytics_json: '/locales/en/user_analytics.json',
      user_analytics_detail_json: '/locales/en/user_analytics_detail.json',
      user_analytics_list_json: '/locales/en/user_analytics_list.json',
      user_analytics_url_group_json: '/locales/en/user_analytics_url_group.json',
      zod_json: '/locales/en/zod.json'
    },
    ja: {
      auth_json: '/locales/ja/auth.json',
      common_json: '/locales/ja/common.json',
      country_json: '/locales/ja/country.json',
      coverage_state_json: '/locales/ja/coverage_state.json',
      dashboard_json: '/locales/ja/dashboard.json',
      getting_started_json: '/locales/ja/getting_started.json',
      index_worker_detail_json: '/locales/ja/index_worker_detail.json',
      index_worker_list_json: '/locales/ja/index_worker_list.json',
      index_worker_shared_json: '/locales/ja/index_worker_shared.json',
      layout_json: '/locales/ja/layout.json',
      legacy_common_json: '/locales/ja/legacy_common.json',
      notification_settings_json: '/locales/ja/notification_settings.json',
      onboarding_json: '/locales/ja/onboarding.json',
      payment_json: '/locales/ja/payment.json',
      permission_json: '/locales/ja/permission.json',
      search_analytics_detail_json: '/locales/ja/search_analytics_detail.json',
      search_analytics_groups_json: '/locales/ja/search_analytics_groups.json',
      search_analytics_list_json: '/locales/ja/search_analytics_list.json',
      search_analytics_shared_json: '/locales/ja/search_analytics_shared.json',
      session_explorer_json: '/locales/ja/session_explorer.json',
      settings_json: '/locales/ja/settings.json',
      toast_json: '/locales/ja/toast.json',
      tour_json: '/locales/ja/tour.json',
      user_analytics_json: '/locales/ja/user_analytics.json',
      user_analytics_detail_json: '/locales/ja/user_analytics_detail.json',
      user_analytics_list_json: '/locales/ja/user_analytics_list.json',
      user_analytics_url_group_json: '/locales/ja/user_analytics_url_group.json',
      zod_json: '/locales/ja/zod.json'
    }
  },
  pictgrams: {
    pictogram___file___xml___white_svg: '/pictgrams/pictogram - file - xml - white.svg',
    pictogram___file___xml_svg: '/pictgrams/pictogram - file - xml.svg',
    pictogram___keybaord___white_svg: '/pictgrams/pictogram - keybaord - white.svg',
    pictogram___keybaord_svg: '/pictgrams/pictogram - keybaord.svg',
    pictogram___next_svg: '/pictgrams/pictogram - next.svg',
    pictogram___one_off_svg: '/pictgrams/pictogram - one off.svg'
  }
} as const;

export type StaticPath = typeof staticPath;
