import Bugsnag, {NotifiableError} from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React, {useCallback} from 'react';
import getConfig from 'next/config';
import {useViewerQuery} from 'lib/gql/generated';
import {Env} from 'env/env';
import {useToast} from '@chakra-ui/react';
import {useTranslation} from 'next-i18next';

const API_KEY = Env.BUGSNAG_API_KEY;
if (API_KEY && API_KEY !== 'null') {
  Bugsnag.start({
    apiKey: API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: getConfig()?.publicRuntimeConfig?.appVersion,
    releaseStage: Env.ENV,
  });
}

const BugsnagBoundary = Bugsnag.isStarted()
  ? Bugsnag.getPlugin('react')?.createErrorBoundary(React)
  : (props: {children: React.ReactNode}) => <>{props.children}</>;
export const ErrorBoundary = ({children}: {children: React.ReactNode}) => {
  if (BugsnagBoundary) {
    return <BugsnagBoundary>{children}</BugsnagBoundary>;
  }
  return <>{children}</>;
};

const setErrorTrackerUser = (email: string, name: string) =>
  Bugsnag.isStarted() &&
  // AmethystIではemail = idなので、emailをidとして設定する
  Bugsnag.setUser(email, email, name);
export const useSetErrorTrackerUser = () => {
  useViewerQuery({
    onCompleted: data => {
      if (data.viewer) {
        setErrorTrackerUser(data.viewer.email, data.viewer.name);
      }
    },
  });
};

export const notifyError = (error: NotifiableError) =>
  Bugsnag.isStarted() ? Bugsnag.notify(error) : console.error(error);

/**
 * Returns a function that notifies the error to Bugsnag and shows a toast.
 * If `toastMessage` is not provided, it shows a default message "Error occurred"(エラーが発生しました).
 */
export const useNotifyError = () => {
  const toast = useToast();
  const {t} = useTranslation('toast');
  return useCallback(
    (error: NotifiableError, toastMessage?: string) => {
      notifyError(error);
      toast({
        title:
          toastMessage ??
          t('エラーが発生しました') +
            (error instanceof Error ? `: ${error.message}` : ''),
        status: 'error',
      });
    },
    [toast, t]
  );
};
