import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import {FormEvent, useCallback, useState} from 'react';
import {
  GettingStartedTour,
  OrganizationsDocument,
  ProjectKind,
  ProjectsDocument,
  SideNavigationDocument,
  useCreateProjectMutation,
} from 'lib/gql/generated';
import {
  useCleanUpCreateProject,
  useCreateProjectHasError,
  useCreateProjectShouldValidate,
  useCreateProjectMutationVariables,
  useCreateProjectKind,
} from './store';
import {useTranslation} from 'next-i18next';
import {CreateProjectNameInput} from './CreateProjectNameInput';
import {CreateProjectColorInput} from './CreateProjectColorInput';
import {CreateProjectKindSelect} from './CreateProjectKindSelect';
import {CreateProjectDescriptionInput} from './CreateProjectDescriptionInput';
import {InputConfirmDialog} from 'common/InputConfirmDialog';
import {InputConfirmCompleteMode} from 'common/Dialog';
import {useToast} from 'common/toast/useToast';
import NextLink from 'next/link';
import {decodeId} from 'common/convertId';
import {pagesPath} from 'lib/$path';
import {useOrg} from 'common/useOrg';
import {usePlan} from 'common/plan/usePlan';
import {UpgradeAlert} from 'common/plan/UpgradeAlert';
import {useTour} from 'features/getting_started/tour/hook/useTour';
import {TourTooltip} from 'features/getting_started/tour/components/TourTooltip';
import {useMutationUnpermitted} from 'common/useMutationUnpermitted';
import {match} from 'ts-pattern';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CreateProjectModal = ({open, onClose}: Props) => {
  const [addProject, {loading, data}] = useCreateProjectMutation({
    refetchQueries: [
      ProjectsDocument,
      OrganizationsDocument,
      SideNavigationDocument,
    ],
  });
  const [mode, setMode] = useState<InputConfirmCompleteMode>('input');
  const [, setShouldValidate] = useCreateProjectShouldValidate();

  const cleanUp = useCleanUpCreateProject();
  const handleClose = useCallback(() => {
    setShouldValidate(false);
    setMode('input');
    cleanUp();
    onClose();
  }, [cleanUp, onClose, setShouldValidate]);

  const handleBack = useCallback(() => setMode('input'), []);

  const {t} = useTranslation('common', {
    keyPrefix: 'CreateProjectModal',
  });

  return (
    <InputConfirmDialog
      mode={mode}
      isOpen={open}
      onClose={handleClose}
      onBack={handleBack}
      title={t('title')}
      input={<Input onModeChange={setMode} onClose={handleClose} />}
      confirm={
        <Confirm
          addProject={addProject}
          loading={loading}
          onModeChange={setMode}
        />
      }
      complete={
        <Complete
          projectId={data?.createProject.id}
          projectKind={match(data?.createProject.__typename)
            .with('IndexWorkerProject', () => ProjectKind.IndexWorker)
            .with('SearchAnalyticsProject', () => ProjectKind.SearchAnalytics)
            .with('UserAnalyticsProject', () => ProjectKind.UserAnalytics)
            .with(undefined, () => undefined)
            .exhaustive()}
          onClose={handleClose}
        />
      }
    />
  );
};

const Input = ({
  onModeChange,
  onClose,
}: {
  onModeChange: (mode: InputConfirmCompleteMode) => void;
  onClose: () => void;
}) => {
  const [, setShouldValidate] = useCreateProjectShouldValidate();
  const hasError = useCreateProjectHasError();
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setShouldValidate(true);
      if (hasError) return;
      onModeChange('confirm');
    },
    [hasError, setShouldValidate, onModeChange]
  );
  const {t} = useTranslation('common', {
    keyPrefix: 'CreateProjectModal',
  });

  const {currentOrg} = useOrg();
  const [kind] = useCreateProjectKind();
  const currentProjectCount =
    currentOrg?.projects.filter(
      p =>
        p.__typename ===
        (kind === ProjectKind.IndexWorker
          ? 'IndexWorkerProject'
          : kind === ProjectKind.SearchAnalytics
          ? 'SearchAnalyticsProject'
          : kind === ProjectKind.UserAnalytics
          ? 'UserAnalyticsProject'
          : undefined)
    ).length ?? 0;
  const {plan} = usePlan();
  const key =
    kind === ProjectKind.IndexWorker
      ? 'iwProjectCapacity'
      : kind === ProjectKind.UserAnalytics
      ? 'uaProjectCapacity'
      : 'scProjectCapacity';
  const max = plan?.[key] ?? 0;
  const isLimited = max <= currentProjectCount;

  const {progress: progressIw, run: runIw} = useTour({
    tour: GettingStartedTour.IwCreateProject,
    step: 'clickReviewButton',
  });
  const {progress: progressSa} = useTour({
    tour: GettingStartedTour.SaCreateProject,
    step: 'clickReviewButton',
  });

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="regular">
        <Grid
          templateColumns="1fr 3fr"
          alignItems="center"
          maxW="2xl"
          mx="auto"
          gap="gutters"
        >
          <GridItem display="flex" justifyContent="flex-end">
            <Text>{t('type')}</Text>
          </GridItem>
          <GridItem>
            <CreateProjectKindSelect />
          </GridItem>
          <GridItem display="flex" justifyContent="flex-end">
            <Text>{t('name')}</Text>
          </GridItem>
          <GridItem>
            <CreateProjectNameInput />
          </GridItem>
          <GridItem display="flex" justifyContent="flex-end">
            <Text>{t('color')}</Text>
          </GridItem>
          <GridItem>
            <CreateProjectColorInput />
          </GridItem>
          <GridItem display="flex" justifyContent="flex-end">
            <Text>{t('description')}</Text>
          </GridItem>
          <GridItem>
            <CreateProjectDescriptionInput />
          </GridItem>
        </Grid>
        <Center flexDir="column">
          {isLimited && kind ? (
            <UpgradeAlert
              target={
                kind === ProjectKind.IndexWorker
                  ? 'indexWorkerProject'
                  : kind === ProjectKind.SearchAnalytics
                  ? 'searchAnalyticsProject'
                  : 'userAnalyticsProject'
              }
              current={currentProjectCount}
              onClick={onClose}
            />
          ) : (
            <TourTooltip
              placement="bottom"
              tour={
                runIw
                  ? GettingStartedTour.IwCreateProject
                  : GettingStartedTour.SaCreateProject
              }
              step="clickReviewButton"
            >
              <Button
                variant="amethyst"
                type="submit"
                h="60px"
                w="300px"
                onClick={() => {
                  progressIw();
                  progressSa();
                }}
              >
                {t('review')}
              </Button>
            </TourTooltip>
          )}
        </Center>
      </Stack>
    </form>
  );
};

const Confirm = ({
  addProject,
  loading,
  onModeChange,
}: {
  addProject: ReturnType<typeof useCreateProjectMutation>[0];
  loading: boolean;
  onModeChange: (mode: InputConfirmCompleteMode) => void;
}) => {
  const {t} = useTranslation('common');

  const variables = useCreateProjectMutationVariables();

  const toast = useToast();

  const {progress: progressIw, run: runIw} = useTour({
    tour: GettingStartedTour.IwCreateProject,
    step: 'confirmAndCreateProject',
  });

  const {progress: progressSa} = useTour({
    tour: GettingStartedTour.SaCreateProject,
    step: 'confirmAndCreateProject',
  });

  const handleConfirm = useCallback(async () => {
    if (!variables) return;
    try {
      await addProject({
        variables,
      });
      onModeChange('complete');
      // WARNING: progressIw and progressSa must be called based on the value of variables.input.kind
      // because they calls `markAsComplete` of the tour, which is different for each project kind.
      // see src/features/getting_started/tour/hook/useTour.ts
      if (variables.input?.kind === ProjectKind.IndexWorker) {
        progressIw();
      } else if (variables.input?.kind === ProjectKind.SearchAnalytics) {
        progressSa();
      }
    } catch (e) {
      toast({
        resource: 'project',
        operation: 'create',
        error: e,
      });
    }
  }, [variables, addProject, onModeChange, progressIw, progressSa, toast]);

  const {isMutationUnpermitted, reason} = useMutationUnpermitted();

  return (
    <Stack justifyContent="center" gap="30px">
      <Grid
        templateColumns="1fr 3fr"
        alignItems="center"
        gap={6}
        maxW="2xl"
        mx="auto"
      >
        <GridItem display="flex" justifyContent="flex-end">
          <Text>{t('CreateProjectModal.type')}</Text>
        </GridItem>

        <GridItem display="flex">
          {match(variables?.input?.kind)
            .with(ProjectKind.IndexWorker, () => t('projectKind.INDEX_WORKER'))
            .with(ProjectKind.SearchAnalytics, () =>
              t('projectKind.SEARCH_ANALYTICS')
            )
            .with(ProjectKind.UserAnalytics, () =>
              t('projectKind.USER_ANALYTICS')
            )
            .with(undefined, () => '')
            .exhaustive()}
        </GridItem>

        <GridItem display="flex" justifyContent="flex-end">
          <Text>{t('CreateProjectModal.name')}</Text>
        </GridItem>
        <GridItem>
          <Text>{variables?.input?.name}</Text>
        </GridItem>
        <GridItem display="flex" justifyContent="flex-end">
          <Text>{t('CreateProjectModal.color')}</Text>
        </GridItem>
        <GridItem>
          <Box bg={variables?.input?.color} h="24px" w="24px" rounded="sm" />
        </GridItem>
        <GridItem display="flex" justifyContent="flex-end">
          <Text>{t('CreateProjectModal.description')}</Text>
        </GridItem>
        <GridItem>
          <Text>{variables?.description}</Text>
        </GridItem>
      </Grid>
      <Box display="flex" justifyContent="center">
        <TourTooltip
          placement="bottom"
          tour={
            runIw
              ? GettingStartedTour.IwCreateProject
              : GettingStartedTour.SaCreateProject
          }
          step="confirmAndCreateProject"
        >
          <Button
            variant="amethyst"
            isLoading={loading}
            h="60px"
            onClick={handleConfirm}
            width="300px"
            isDisabled={isMutationUnpermitted}
          >
            {t('CreateProjectModal.create')}
          </Button>
        </TourTooltip>
      </Box>
      {isMutationUnpermitted && (
        <Center>
          <Text as="span" ml="small" color="color.attention" variant="memo1">
            {reason}
          </Text>
        </Center>
      )}
    </Stack>
  );
};

const Complete = ({
  projectId,
  projectKind,
  onClose,
}: {
  projectId?: string;
  projectKind?: ProjectKind;
  onClose: () => void;
}) => {
  const docPath = projectId ? decodeId(projectId).docPath : undefined;
  const docPathArray = docPath?.split('/');
  const orgDatabaseId = docPathArray?.[1];
  const projectDatabaseId = docPathArray?.[3];
  const project = pagesPath.orgs
    ._orgId(orgDatabaseId ?? '')
    .projects._projectId(projectDatabaseId ?? '');
  const href = match(projectKind)
    .with(ProjectKind.IndexWorker, () => project.index_workers.$url())
    .with(ProjectKind.SearchAnalytics, () => project.search_analytics.$url())
    .with(ProjectKind.UserAnalytics, () => project.user_analytics.$url())
    .with(undefined, () => {
      return null;
    })
    .exhaustive();

  const {t} = useTranslation('common', {
    keyPrefix: 'CreateProjectModal',
  });

  const handleClick = () => {
    onClose();
  };

  return (
    <Stack spacing="regular">
      <Center>
        <Text variant="text1">{t('success')}</Text>
      </Center>
      <Center>
        {href !== null && (
          <NextLink href={href} legacyBehavior>
            <Button
              as="a"
              cursor="pointer"
              tabIndex={0}
              variant="amethyst"
              w="300px"
              onClick={handleClick}
            >
              {t('goToProject')}
            </Button>
          </NextLink>
        )}
      </Center>
    </Stack>
  );
};
