import {FormControl, FormErrorMessage, Textarea} from '@chakra-ui/react';
import {
  useCreateProjectDescription,
  useCreateProjectDescriptionError,
} from './store';

export const CreateProjectDescriptionInput = () => {
  const [description, setDescription] = useCreateProjectDescription();
  const error = useCreateProjectDescriptionError();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <FormControl isInvalid={!!error}>
      <Textarea
        variant="amethyst"
        value={description}
        onChange={handleChange}
        placeholder="プロジェクトの概要を入力してください"
        rows={3}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
